var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { isSuccessHTTP } from 'cerumx-api/APIRestClient';
import { DashboardAPI } from 'cerumx-api/cerum/desktop/dashboard/DashboardAPI';
import { CerumReduxSubStore } from './CerumReduxSubStore';
export class DashboardStoreLayoutDashboardLayout {
    constructor() {
        this.layouts = [];
        this.loading = false;
    }
}
export class DashboardStoreLayout {
    constructor() {
        this.contexts = {};
        this.spinner = 0;
    }
}
export class DashboardStateStore extends CerumReduxSubStore {
    constructor() {
        super(...arguments);
        /** @override */ this.base_key = 'DashboardStateStore';
    }
    static path(property) {
        return this.staticPath('DashboardStateStore', property);
    }
    static get instance() {
        return this._instance || (this._instance = new DashboardStateStore());
    }
    /** @override */ reducer() {
        return {
            [this.base_key]: (instate = new DashboardStoreLayout(), action) => {
                switch (action.type) {
                    case DashboardStateStore.Actions.RELOAD_LAYOUTS:
                        break;
                    //const context: string = action.payload.context || '';
                    case DashboardStateStore.Actions.LOADING_LAYOUTS:
                        const context = action.payload.context || '';
                        const state = Object.assign({}, instate);
                        state.contexts = Object.assign({}, state.contexts);
                        if (!(context in state.contexts)) {
                            state.contexts[context] = new DashboardStoreLayoutDashboardLayout();
                        }
                        state.contexts[context].loading = true;
                        state.spinner++;
                        //console.log('Ny state 1: ', state);
                        return state;
                    case DashboardStateStore.Actions.LOADED_LAYOUTS:
                        const context2 = action.payload.context || '';
                        const state2 = Object.assign({}, instate);
                        state2.contexts = Object.assign({}, state2.contexts);
                        state2.contexts[context2].loading = false;
                        state2.contexts[context2].layouts = action.payload.layouts || [];
                        state2.spinner++;
                        //console.log('Ny state 2: ', state2);
                        return state2;
                }
                return instate;
            },
        };
    }
}
(function (DashboardStateStore) {
    let Actions;
    (function (Actions) {
        Actions.RELOAD_LAYOUTS = 'RELOAD_LAYOUTS';
        Actions.LOADING_LAYOUTS = 'LOADING_LAYOUTS';
        Actions.LOADED_LAYOUTS = 'LOADED_LAYOUTS';
        Actions.ReloadLayouts = (context) => {
            return (dispatch) => __awaiter(this, void 0, void 0, function* () {
                dispatch(Actions.LoadingLayouts(context));
                const layouts = yield DashboardAPI.getDashboardLayoutsUsingContext(context);
                //console.log('Loaded: ', layouts);
                if (isSuccessHTTP(layouts)) {
                    dispatch(Actions.LoadedLayouts(context, layouts));
                }
                else {
                    dispatch(Actions.LoadedLayouts(context, []));
                }
            });
        };
        Actions.LoadingLayouts = (context) => ({
            type: Actions.LOADING_LAYOUTS,
            payload: { context },
        });
        Actions.LoadedLayouts = (context, layouts) => ({
            type: Actions.LOADED_LAYOUTS,
            payload: {
                context,
                layouts,
            },
        });
    })(Actions = DashboardStateStore.Actions || (DashboardStateStore.Actions = {}));
})(DashboardStateStore || (DashboardStateStore = {}));

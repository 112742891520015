var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { css, html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';
let BoostStepIndicator = class BoostStepIndicator extends LitElement {
    constructor() {
        super(...arguments);
        this.currentStep = 1;
        this.steps = [];
    }
    static get is() {
        return 'boost-step-indicator';
    }
    static get styles() {
        return css `
            .navigation {
                margin: 0;
                padding: 0;
                width: 100%;
                text-align: center;
                overflow: hidden;
                display: flex;
            }

            .navigation-item {
                width: 100%;
            }

            .navigation-link {
                display: flex;
                padding: 5%;
                border-bottom: 6px solid var(--portalAccentBackground, #b4ced5);
                color: var(--portalAccentForeground, #919191);
                font-size: 140%;
                font-weight: 300;
                text-decoration: none;
                cursor: default;
                flex-direction: column;
            }

            .navigation-link.active {
                color: var(--portalAccentForegroundActive, #5d5252);
                border-bottom: 6px solid var(--portalAccentBackgroundActive, #093845);
            }
            span {
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }

            @media only screen and (max-width: 1000px) {
                .navigation-link {
                    font-size: 80%;

                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
            @media only screen and (max-width: 430px) {
                .navigation-link {
                    font-size: 60%;

                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        `;
    }
    /** @override */ render() {
        return html `
            <div>
                <div class="navigation">
                    ${this.steps.map((step, stepIndex) => html `
                            <div
                                class="navigation-item"
                                @click="${() => {
            this.currentStep = stepIndex + 1;
        }}"
                            >
                                <div class="navigation-link ${step.isActive ? 'active' : ''}">
                                    <span> ${stepIndex + 1}.</span><span> ${step.title}</span>
                                </div>
                            </div>
                        `)}
                </div>
            </div>
        `;
    }
    /** @override */ updated(changedProperties) {
        changedProperties.forEach((_oldValue, propertyName) => {
            switch (propertyName) {
                case 'currentStep':
                    this._currentStepObserver();
                    break;
            }
        });
        super.updated(changedProperties);
    }
    _currentStepObserver() {
        this.dispatchEvent(new CustomEvent('currentStep-changed', {
            detail: {
                currentStep: this.currentStep,
            },
            bubbles: true,
            composed: true,
        }));
    }
};
BoostStepIndicator._deps = [];
__decorate([
    property({ type: Number }),
    __metadata("design:type", Number)
], BoostStepIndicator.prototype, "currentStep", void 0);
__decorate([
    property({ type: Array }),
    __metadata("design:type", Array)
], BoostStepIndicator.prototype, "steps", void 0);
BoostStepIndicator = __decorate([
    customElement(BoostStepIndicator.is)
], BoostStepIndicator);
export { BoostStepIndicator };

import { LoadingBoxBase } from 'badphraim/ajax/LoadingBoxBase';
import { ExceptionHandler } from 'badphraim/core/ExceptionHandler';
export class LoadingBox extends LoadingBoxBase {
    constructor(dom) {
        super();
        this.dom = dom;
        this.count = 0;
    }
    /** @override */ end() {
        try {
            this.count--;
            const loadingElement = this._getLoadingDiv();
            loadingElement.style.setProperty('display', 'none');
        }
        catch (e) {
            ExceptionHandler.handle(e);
        }
    }
    /** @override */ start() {
        try {
            const loadingElement = this._getLoadingDiv();
            loadingElement.style.setProperty('display', 'block');
            this.count++;
        }
        catch (e) {
            ExceptionHandler.handle(e);
        }
    }
}

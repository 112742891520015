var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { isSuccessHTTP, isErrorHTTP } from 'boost-api/APIRestClient';
import { MembersignupAPI } from 'boost-api/boost/signup/membersignup/MembersignupAPI';
import { BoostPortalHeader } from '../components/BoostPortalHeader';
import { css, html, LitElement } from 'lit';
import { customElement, state } from 'lit/decorators.js';
import { BoostStatus } from '../components/common/BoostStatus';
import { BoostPortalParamsHandler } from '../helpers/BoostPortalParamsHandler';
import { TrackingEvent } from '../tracking/TrackingEvent';
import { TrackingFactorySingleton } from '../tracking/TrackingFactorySingleton';
let BoostPortalDirectDebitView = class BoostPortalDirectDebitView extends LitElement {
    static get is() {
        return 'boost-portal-direct-debit-view';
    }
    static get styles() {
        return css ``;
    }
    constructor() {
        super();
        this.isDirectDebitSetup = false;
        this.updateComplete.then(() => __awaiter(this, void 0, void 0, function* () {
            const success = BoostPortalParamsHandler.getParameter('success');
            const status = BoostPortalParamsHandler.getParameter('status');
            if (success === 'ok' || status === 'ok') {
                this.isDirectDebitSetup = true;
                TrackingFactorySingleton.getTracker().completedEvent(TrackingEvent.MemberSignupCompleted);
                const departmentId = BoostPortalParamsHandler.getParameter('departmentId');
                if (departmentId) {
                    const portalInfoResult = yield MembersignupAPI.getV1BoostsignupPortalinfo({
                        departmentId,
                    });
                    if (isSuccessHTTP(portalInfoResult)) {
                        this.portalInfo = portalInfoResult.portal_info;
                    }
                    else if (isErrorHTTP(portalInfoResult)) {
                        console.error(portalInfoResult.error);
                    }
                }
            }
            else {
                this.isDirectDebitSetup = false;
            }
        }));
    }
    /** @override */ render() {
        return html `
            <boost-portal-header></boost-portal-header>
            <boost-status type="${this.isDirectDebitSetup ? 'success' : 'danger'}">
                Avtalegiro
                ${this.isDirectDebitSetup
            ? 'er satt opp'
            : 'er ikke satt opp. Du vil innen kort tid motta oppstartsfaktura og link for signering av AvtaleGiro på e-post og sms. Medlemskapet vil være aktivert med en gang du mottar dette'}
            </boost-status>

            ${this.portalInfo && this.isDirectDebitSetup
            ? html `<boost-status-container id="portalInfo" type="info"></boost-status-container>`
            : ''}
        `;
    }
    /** @override */ updated(changedProperties) {
        changedProperties.forEach((_oldValue, propertyName) => {
            switch (propertyName) {
                case 'portalInfo':
                    this._portalInfoObserver();
                    break;
            }
        });
        super.updated(changedProperties);
    }
    _portalInfoObserver() {
        var _a;
        const portalInfoElement = this.shadowRoot && this.shadowRoot.getElementById('portalInfo');
        if (!portalInfoElement) {
            return;
        }
        portalInfoElement.innerHTML = String((_a = this.portalInfo) !== null && _a !== void 0 ? _a : '');
    }
};
BoostPortalDirectDebitView._deps = [BoostStatus, BoostPortalHeader];
__decorate([
    state(),
    __metadata("design:type", Boolean)
], BoostPortalDirectDebitView.prototype, "isDirectDebitSetup", void 0);
__decorate([
    state(),
    __metadata("design:type", String)
], BoostPortalDirectDebitView.prototype, "portalInfo", void 0);
BoostPortalDirectDebitView = __decorate([
    customElement(BoostPortalDirectDebitView.is),
    __metadata("design:paramtypes", [])
], BoostPortalDirectDebitView);
export { BoostPortalDirectDebitView };

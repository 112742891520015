var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
/* tslint:disable: class-name */
/*
 * AUTOGENERATED FILE - DO NOT EDIT
 */
import { ewRestProjectsDetailsDeadlinesHandlerResponse } from './ewRestProjectsDetailsDeadlinesHandlerResponse';
import { ewRestStatusHandlerResponse } from './ewRestStatusHandlerResponse';
import { exAddress } from './exAddress';
import { exCoordinate } from './exCoordinate';
import { exItem } from './exItem';
import { exMatrikkel } from './exMatrikkel';
import { exMunicipality } from './exMunicipality';
import { exProjectRelation } from './exProjectRelation';
import { JSONStruct, array_property, nullable, nullable_array_property, property, } from 'badphraim/data/JSONStruct';
export class exProject extends JSONStruct {
    constructor() {
        super(...arguments);
        /** @override */ this.document_filters = [];
        /** @override */ this.hmsNotificationEmails = [];
        /** @override */ this.id = 0;
        /** @override */ this.status = new ewRestStatusHandlerResponse();
        /** @override */ this.structure_node_id = 0;
    }
}
__decorate([
    nullable,
    __metadata("design:type", exAddress)
], exProject.prototype, "address", void 0);
__decorate([
    nullable,
    __metadata("design:type", String)
], exProject.prototype, "boligmappa_number", void 0);
__decorate([
    nullable,
    __metadata("design:type", String)
], exProject.prototype, "boligmappa_project_number", void 0);
__decorate([
    nullable,
    __metadata("design:type", Number)
], exProject.prototype, "bra", void 0);
__decorate([
    nullable,
    __metadata("design:type", Number)
], exProject.prototype, "bya", void 0);
__decorate([
    nullable,
    __metadata("design:type", Number)
], exProject.prototype, "contract_date", void 0);
__decorate([
    nullable,
    __metadata("design:type", Number)
], exProject.prototype, "contract_sum", void 0);
__decorate([
    nullable,
    __metadata("design:type", exCoordinate)
], exProject.prototype, "coordinates", void 0);
__decorate([
    nullable,
    __metadata("design:type", Number)
], exProject.prototype, "created_date", void 0);
__decorate([
    nullable_array_property(ewRestProjectsDetailsDeadlinesHandlerResponse),
    __metadata("design:type", Array)
], exProject.prototype, "deadlines", void 0);
__decorate([
    nullable,
    __metadata("design:type", String)
], exProject.prototype, "description", void 0);
__decorate([
    array_property(exItem),
    __metadata("design:type", Array)
], exProject.prototype, "document_filters", void 0);
__decorate([
    nullable,
    __metadata("design:type", exProjectRelation)
], exProject.prototype, "employer", void 0);
__decorate([
    array_property(String),
    __metadata("design:type", Array)
], exProject.prototype, "hmsNotificationEmails", void 0);
__decorate([
    property,
    __metadata("design:type", Number)
], exProject.prototype, "id", void 0);
__decorate([
    nullable,
    __metadata("design:type", String)
], exProject.prototype, "image_url", void 0);
__decorate([
    nullable,
    __metadata("design:type", String)
], exProject.prototype, "internal_project_number", void 0);
__decorate([
    nullable,
    __metadata("design:type", exMatrikkel)
], exProject.prototype, "matrix", void 0);
__decorate([
    nullable,
    __metadata("design:type", Number)
], exProject.prototype, "modified_date", void 0);
__decorate([
    nullable,
    __metadata("design:type", exMunicipality)
], exProject.prototype, "municipality", void 0);
__decorate([
    nullable,
    __metadata("design:type", Number)
], exProject.prototype, "number_of_units", void 0);
__decorate([
    nullable,
    __metadata("design:type", Number)
], exProject.prototype, "parent_project_id", void 0);
__decorate([
    nullable,
    __metadata("design:type", Number)
], exProject.prototype, "parent_project_node_id", void 0);
__decorate([
    nullable,
    __metadata("design:type", Number)
], exProject.prototype, "project_number", void 0);
__decorate([
    property,
    __metadata("design:type", ewRestStatusHandlerResponse)
], exProject.prototype, "status", void 0);
__decorate([
    property,
    __metadata("design:type", Number)
], exProject.prototype, "structure_node_id", void 0);
__decorate([
    nullable,
    __metadata("design:type", String)
], exProject.prototype, "title", void 0);
__decorate([
    nullable,
    __metadata("design:type", exItem)
], exProject.prototype, "type", void 0);

/* eslint-disable id-length */
import { exhaustiveMatchingGuard } from 'badphraim/core/ExhaustiveMatchingGuard';
import { TrackingEvent } from './TrackingEvent';
export class GoogleTagManager {
    constructor(gtmId) {
        this.gtmId = gtmId;
        this.disabled = false;
    }
    initialize() {
        // Slightly refactored from https://developers.google.com/tag-platform/tag-manager/web?hl=en#custom_web_installations
        // Push blocklist first, if not it fetches the blocked ones too.
        this.gtm({ 'gtm.blocklist': ['html', 'img'] });
        this.gtm({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
        const scriptTag = document.createElement('script');
        scriptTag.async = true;
        scriptTag.src = 'https://www.googletagmanager.com/gtm.js?id=' + encodeURIComponent(this.gtmId);
        document.head.appendChild(scriptTag);
    }
    gtm(...args) {
        if (this.disabled) {
            return;
        }
        const win = window;
        const dataLayer = 'dataLayer';
        win[dataLayer] = win[dataLayer] || [];
        win[dataLayer].push(...args);
    }
    disable() {
        var _a;
        this.disabled = true;
        if (this.scriptTag) {
            // Try to remove as much as possible
            (_a = this.scriptTag.parentElement) === null || _a === void 0 ? void 0 : _a.removeChild(this.scriptTag);
            this.scriptTag = undefined;
            delete window['gtm'];
            delete window['dataLayer'];
        }
    }
    completedEvent(...args) {
        const [event, parameters] = args;
        switch (event) {
            case TrackingEvent.MemberSignupStarted:
                this.gtm(Object.assign(Object.assign({}, parameters), { event: 'sign_up' }));
                break;
            case TrackingEvent.SelectedMembership:
                this.gtm(Object.assign(Object.assign({}, parameters), { event: 'SelectedProduct' }));
                break;
            case TrackingEvent.PersonalInformationFilled:
                this.gtm(Object.assign(Object.assign({}, parameters), { event: 'PersonalInformationFilled' }));
                break;
            case TrackingEvent.SponsorConfirmed:
                this.gtm(Object.assign(Object.assign({}, parameters), { event: 'SponsorConfirmed' }));
                break;
            case TrackingEvent.BankIDSigningStarted:
                this.gtm(Object.assign(Object.assign({}, parameters), { event: 'BankIDSigningStarted' }));
                break;
            case TrackingEvent.AvtalegiroSigningCompleted:
                this.gtm(Object.assign(Object.assign({}, parameters), { event: 'AvtalegiroSigningCompleted' }));
                break;
            case TrackingEvent.AvtalegiroSigningFailed:
                this.gtm(Object.assign(Object.assign({}, parameters), { event: 'AvtalegiroSigningFailed' }));
                break;
            case TrackingEvent.VippsPaymentComplete:
                this.gtm(Object.assign(Object.assign({}, parameters), { event: 'VippsPaymentComplete' }));
                break;
            case TrackingEvent.CompanySponsoredSignupStarted:
                this.gtm(Object.assign(Object.assign({}, parameters), { event: 'CompanySponsoredSignupStarted' }));
                break;
            case TrackingEvent.CompanySponsoredSelectedDepartment:
                this.gtm(Object.assign(Object.assign({}, parameters), { event: 'CompanySponsoredSelectedDepartment' }));
                break;
            case TrackingEvent.PrivatelySponsoredSelectedDepartment:
                this.gtm(Object.assign(Object.assign({}, parameters), { event: 'PrivatelySponsoredSelectedDepartment' }));
                break;
            case TrackingEvent.PrivatelySponsoredSignupStarted:
                this.gtm(Object.assign(Object.assign({}, parameters), { event: 'PrivatelySponsoredSignupStarted' }));
                break;
            case TrackingEvent.MemberSignupCompleted:
                this.gtm(Object.assign(Object.assign({}, parameters), { event: 'CompleteRegistration' }));
                break;
            case TrackingEvent.RequestedNewPassword:
                this.gtm(Object.assign(Object.assign({}, parameters), { event: 'RequestNewPassword' }));
                break;
            case TrackingEvent.SelectedDepartment:
                this.gtm(Object.assign(Object.assign({}, parameters), { event: 'SelectedDepartment' }));
                break;
            default:
                exhaustiveMatchingGuard(event);
        }
    }
}

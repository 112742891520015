export class BoostPortalParamsHandler {
    static getParameter(parameterName) {
        const searchParams = new URLSearchParams(location.search);
        return searchParams.get(parameterName);
    }
    static removeParameter(parameterName) {
        const searchParams = new URLSearchParams(window.location.search);
        searchParams.delete(parameterName);
        if (history.replaceState) {
            const searchString = searchParams.toString().length > 0 ? '?' + searchParams.toString() : '';
            const newUrl = window.location.protocol +
                '//' +
                window.location.host +
                window.location.pathname +
                searchString +
                window.location.hash;
            history.replaceState(null, '', newUrl);
        }
    }
}

export class UrlMangler {
    static addQueryParam(url, key, value) {
        const u1 = new UrlMangler(url);
        return (url +
            (u1.getQuery() ? '&' : '?') +
            encodeURIComponent(key) +
            '=' +
            encodeURIComponent(String(value)));
    }
    static urlEquals(u1, u2) {
        const url1 = new UrlMangler(u1);
        const url2 = new UrlMangler(u2);
        return url1.equals(url2);
    }
    constructor(u) {
        this.hash = '';
        this.host = '';
        this.path = '';
        this.protocol = '';
        this.query = '';
        this.url = u;
        this.parsed = false;
    }
    d(_s) {
        // debug
    }
    equals(u) {
        //      alert('sammenligner'+this+' med '+u);
        this.d('Sammenligner ' + this.url + ' med ' + u.url);
        if (arguments.length > 1 && arguments[1]) {
            if (this.getProtocol() !== u.getProtocol()) {
                this.d('protokoll forskjellig');
                return false;
            }
            if (this.getHost() !== u.getHost()) {
                this.d('host forskjellig');
                return false;
            }
            if (this.getHash() !== u.getHash()) {
                this.d('hash forskjellig');
                return false;
            }
        }
        this.d('path: ' + this.getPath() + ' vs ' + u.getPath());
        if (this.getPath() !== u.getPath()) {
            this.d('path forskjellig');
            return false;
        }
        const a = this.getQueryParams();
        const b = u.getQueryParams();
        this.d('Sammenligner queryparams...');
        for (const i in a) {
            if (typeof b[i] === 'undefined') {
                this.d('b har ikke ' + i);
                return false;
            }
            if (a[i] !== b[i]) {
                this.d('a og b har forskjellig ' + i);
                return false;
            }
        }
        // crosscheck
        for (const i in b) {
            if (typeof a[i] === 'undefined') {
                this.d('a har ikke ' + i);
                return false;
            }
            if (a[i] !== b[i]) {
                this.d('a og b har forskjellig ' + i);
                return false;
            }
        }
        this.d('de er like..returnerer true');
        return true;
    }
    getHash() {
        this.parse();
        return this.hash;
    }
    getHost() {
        this.parse();
        return this.host;
    }
    getPath() {
        this.parse();
        return this.path;
    }
    getProtocol() {
        this.parse();
        return this.protocol;
    }
    getQuery() {
        this.parse();
        return this.query;
    }
    getQueryParams() {
        this.parse();
        const q = this.query.replace(/^\?/, '');
        const qs = q.split(/&/);
        const ret = {};
        for (let i = 0; i < qs.length; i++) {
            const ko = qs[i].indexOf('=');
            const k = qs[i].substring(0, ko);
            const v = qs[i].substring(ko + 1);
            ret[k] = v;
        }
        return ret;
    }
    parse() {
        if (this.parsed) {
            return;
        }
        let url = this.url;
        let cnt = 1;
        this.d('p' + cnt++ + ': ' + url);
        let c = url.indexOf(':');
        if (c !== -1) {
            this.protocol = url.substring(0, c);
            url = url.substring(c + 1);
            while (url.charAt(0) === '/') {
                url = url.substring(1);
            }
        }
        this.d('p' + cnt++ + ': ' + url);
        c = url.indexOf('/');
        if (c !== -1) {
            this.host = url.substring(0, c);
            url = url.substring(c);
        }
        this.d('p' + cnt++ + ': ' + url);
        c = url.indexOf('?');
        if (c !== -1) {
            this.path = url.substring(0, c);
            url = url.substring(c);
            c = url.indexOf('#');
            if (c !== -1) {
                this.query = url.substring(0, c);
                this.hash = url.substring(c);
                url = url.substring(c + 1);
            }
            else {
                this.query = url;
            }
        }
        else if ((c = url.indexOf('#')) !== -1) {
            this.path = url.substring(0, c);
            url = url.substring(c);
            this.hash = url.substring(c + 1);
        }
        else {
            this.path = url;
        }
        this.d('p' + cnt++ + ': ' + url);
        this.parsed = true;
    }
}

var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { SnackBar } from 'common-uicomponents/components/SnackBar';
import { reduxBind } from 'badphraim/ui/LitReduxMixinBase';
import { isErrorHTTP, isSuccessHTTP } from 'boost-api/APIRestClient';
import { MembersignupAPI } from 'boost-api/boost/signup/membersignup/MembersignupAPI';
import { ewNewProfileInfo } from 'boost-api/schemas/ewNewProfileInfo';
import { BoostPortalPersonalInformation } from '../components/BoostPortalPersonalInformation';
import { BoostButton } from '../components/common/BoostButton';
import { BoostStatus } from '../components/common/BoostStatus';
import { css, html, LitElement } from 'lit';
import { customElement, state } from 'lit/decorators.js';
import { BoostPortalStore } from '../redux/BoostPortalStore';
import { LitReduxBoostPortalMixin } from '../redux/LitReduxBoostPortalMixin';
import { router } from '../router/BoostPortalRouter';
import { BoostPortalHeader } from '../components/BoostPortalHeader';
let BoostPortalRegisterProfileView = class BoostPortalRegisterProfileView extends LitReduxBoostPortalMixin(LitElement) {
    constructor() {
        super(...arguments);
        this.selectedDepartmentId = 0;
        this.registerComplete = false;
        this.userInformation = new ewNewProfileInfo();
        this.isValidationFailed = false;
    }
    static get is() {
        return 'boost-portal-register-profile-view';
    }
    static get styles() {
        return css `
            :host {
                width: 90%;
            }
        `;
    }
    /** @override */ render() {
        return html ` <boost-portal-header .viewLoginButton="${false}"></boost-portal-header>
            <div>
                <boost-portal-choose-department
                    .isCreateProfileView="${true}"
                ></boost-portal-choose-department>
                ${this._showDepartmentErrorBox()}
                <boost-portal-personal-information
                    ?hideSummaryBox="${true}"
                ></boost-portal-personal-information>
                <boost-button
                    type="submit"
                    name="Opprett profil"
                    @click=${() => this._createUser()}
                    ?disabled="${!this._isValidInformation(this.memberInformation)}"
                    width-full
                ></boost-button>
            </div>`;
    }
    _showDepartmentErrorBox() {
        if (this.selectedDepartmentId) {
            return;
        }
        if (this.isValidationFailed) {
            return html `
                <boost-status-container type="info">
                    Du må velge senter før du kan opprette profil
                </boost-status-container>
            `;
        }
    }
    _createUser() {
        return __awaiter(this, void 0, void 0, function* () {
            window.dispatchEvent(new CustomEvent('validateFields', { bubbles: true, composed: true }));
            if (!this.memberInformation) {
                return;
            }
            this.userInformation = Object.assign({}, this.memberInformation);
            this.userInformation.departmentId = this.selectedDepartmentId;
            if (!this._isValidInformation(this.userInformation)) {
                this.isValidationFailed = true;
                return;
            }
            const signupResult = yield MembersignupAPI.postV1BoostsignupProfile(this.userInformation);
            if (isSuccessHTTP(signupResult)) {
                window.history.pushState(null, document.title, router.baseUrl + this.centerShort + '/member/profile/success/');
                window.dispatchEvent(new PopStateEvent('popstate'));
            }
            else if (isErrorHTTP(signupResult)) {
                SnackBar.pushError(signupResult.error.message);
            }
        });
    }
    _isValidInformation(information) {
        if (!information ||
            !information.firstname ||
            !information.lastname ||
            !information.address ||
            !information.postal_code ||
            !information.municipality ||
            !information.phone_number ||
            !information.email ||
            !information.date_of_birth ||
            !information.gender ||
            !information.approve_tos ||
            !this.selectedDepartmentId) {
            return false;
        }
        return true;
    }
};
BoostPortalRegisterProfileView._deps = [BoostPortalPersonalInformation, BoostButton, BoostStatus, BoostPortalHeader];
__decorate([
    reduxBind(BoostPortalStore.path('memberInformation')),
    state(),
    __metadata("design:type", Object)
], BoostPortalRegisterProfileView.prototype, "memberInformation", void 0);
__decorate([
    reduxBind(BoostPortalStore.path('selectedDepartmentId')),
    state(),
    __metadata("design:type", Number)
], BoostPortalRegisterProfileView.prototype, "selectedDepartmentId", void 0);
__decorate([
    reduxBind(BoostPortalStore.path('centerShort')),
    state(),
    __metadata("design:type", String)
], BoostPortalRegisterProfileView.prototype, "centerShort", void 0);
__decorate([
    state(),
    __metadata("design:type", Boolean)
], BoostPortalRegisterProfileView.prototype, "registerComplete", void 0);
__decorate([
    state(),
    __metadata("design:type", Object)
], BoostPortalRegisterProfileView.prototype, "userInformation", void 0);
__decorate([
    state(),
    __metadata("design:type", Boolean)
], BoostPortalRegisterProfileView.prototype, "isValidationFailed", void 0);
BoostPortalRegisterProfileView = __decorate([
    customElement(BoostPortalRegisterProfileView.is)
], BoostPortalRegisterProfileView);
export { BoostPortalRegisterProfileView };

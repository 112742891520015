var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var OverlayWindow_1;
import { LitReduxCerumMixin } from '../mixins/LitReduxCerumMixin';
import { AsyncContainer } from 'badphraim/core/AsyncContainer';
import { reduxBind } from 'badphraim/ui/LitReduxMixinBase';
import { css, html, LitElement } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { CerumMainUIStateStore } from 'cerum-stores/cerum-main-ui-state-store';
import { styleMap } from 'lit/directives/style-map.js';
import { SnackBar } from './SnackBar';
import { AllowClosingOfModalResult, RedirectPermission, isAllowClosingModalView, } from './IOverlayWindowObject';
export class OverlayHandle {
    constructor(overlayWindow, overlaidWindow) {
        this.overlayWindow = overlayWindow;
        this.overlaidWindow = overlaidWindow;
    }
    /** @override */ closeOverlay() {
        if (this.isOnTop()) {
            this.overlayWindow.popWindow(undefined, true);
        }
        else {
            console.error('Vet ikke hva vi skal gjøre her');
        }
    }
    isOnTop() {
        return this.overlayWindow.peekWindow() === this.overlaidWindow;
    }
}
export function OverlayableComponent(Base) {
    class Mixin extends Base {
        /** @override */ closeOverlay() {
            OverlayWindow.asyncGetHandleForElement(this).then((handle) => handle ? handle.closeOverlay() : console.log('Fant ikke noe handle for', this));
        }
    }
    Mixin._deps = [];
    return Mixin;
}
let OverlayWindow = OverlayWindow_1 = class OverlayWindow extends LitReduxCerumMixin(LitElement) {
    static get is() {
        return 'overlay-window';
    }
    get overlayContainer() {
        return this.shadowRoot && this.shadowRoot.getElementById('overlayContainer');
    }
    get overlayContents() {
        return this.shadowRoot && this.shadowRoot.getElementById('overlayContents');
    }
    static get styles() {
        return css `
            :host {
                position: fixed;
                height: 100%;
                top: 0;
                right: 0;
                left: 0;
                bottom: 0;
                z-index: 9;
                background-color: rgba(0, 0, 0, 0.4);
                animation: fade-in 0.4s ease;
                display: none;
            }

            :host(.active) {
                display: block;
            }

            .overlay {
                background-color: #fff;
                max-height: calc(100vh - 20px);
                overflow: auto;
                padding: 10px 30px;
                right: 0;
                position: absolute;
                height: 100%;
            }

            :host(.small) .overlay {
                right: 50%;
                width: calc(100vw - 50%);
                max-width: 600px;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                padding: 1em 2em 2em 2em;
                background: #ffffff;
                max-height: 100vh;
                height: unset;
                border-radius: 4px;
                box-shadow: 4px 4px 8px 0px #888888;
            }

            :host(.medium) .overlay {
                right: 50%;
                width: calc(100vw - 50%);
                max-width: 950px;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                padding: 1em 2em 2em 2em;
                background: #ffffff;
                max-height: 100vh;
                height: unset;
                border-radius: 4px;
                box-shadow: 4px 4px 8px 0px #888888;
            }

            .overlay.left-wide {
                left: 256px;
            }

            .overlay.left-narrow {
                left: 64px;
            }

            .overlay.fullscreen {
                left: 0px !important;
            }

            :host(.small) .overlay.left-wide,
            :host(.small) .overlay.left-narrow {
                left: 50%;
            }

            .header {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }

            h1,
            .breadcrumb {
                font-size: var(--text-2xl);
                font-weight: var(--font-light);
                font-family: var(--font-primary);
                margin-top: 16px;
                margin-bottom: 30px;
            }

            .breadcrumb span:not(:last-child)::after {
                content: ' >';
            }

            .icon {
                cursor: pointer;
                width: 32px;
                vertical-align: middle;
            }

            @keyframes fade-in {
                0% {
                    opacity: 0;
                }
                100% {
                    opacity: 1;
                }
            }

            #overlayContents {
                height: var(--overlayContentsHeight);
            }

            .preview {
                padding: 0px !important;
                border-radius: 12px !important;
            }
        `;
    }
    static asyncGetHandleForElement(element) {
        return __awaiter(this, void 0, void 0, function* () {
            return (yield this.instance.getValue()).getHandleForElement(element);
        });
    }
    static asyncInject(object) {
        return __awaiter(this, void 0, void 0, function* () {
            return (yield this.instance.getValue()).inject(object);
        });
    }
    static asyncPeekWindow() {
        return __awaiter(this, void 0, void 0, function* () {
            return (yield this.instance.getValue()).peekWindow();
        });
    }
    static asyncPopAllWindows() {
        return __awaiter(this, void 0, void 0, function* () {
            (yield this.instance.getValue()).popAllWindows();
        });
    }
    static asyncPopWindow(object, force) {
        return __awaiter(this, void 0, void 0, function* () {
            return (yield this.instance.getValue()).popWindow(object, force);
        });
    }
    static asyncPushWindow(windowObject) {
        return __awaiter(this, void 0, void 0, function* () {
            return (yield this.instance.getValue()).pushWindow(windowObject);
        });
    }
    static isRedirectAllowed() {
        return __awaiter(this, void 0, void 0, function* () {
            return (yield OverlayWindow_1.instance.getValue()).allowRedirect;
        });
    }
    static setPopObject(popObject) {
        return __awaiter(this, void 0, void 0, function* () {
            (yield OverlayWindow_1.instance.getValue()).popObject = popObject;
        });
    }
    constructor() {
        super();
        this.name = '';
        this.breadcrumbArr = [];
        this.windows = [];
        this.collapsedMenu = false;
        this.allowRedirect = () => RedirectPermission.Allow;
        this.fullscreen = false;
        this.preview = 'border';
        this.boundVoidClickPopWindow = this.popWindowHandler.bind(this);
        if (!OverlayWindow_1.instance.hasValue()) {
            OverlayWindow_1.instance.setValue(this);
        }
        this.fixCloseIconUrl();
    }
    /** @override */ connectedCallback() {
        super.connectedCallback();
        window.addEventListener(OverlayWindow_1.scrollModalEvent, () => this._scrollToBottom());
        this.addEventListener('click', this.boundVoidClickPopWindow);
        this.fixCloseIconUrl();
    }
    popWindowHandler(object) {
        const topMost = this.windows.at(this.windows.length - 1);
        if (!topMost) {
            return;
        }
        if (isAllowClosingModalView(topMost)) {
            const [result, message, customConfirmCallback] = topMost.onAllowClosingOfModal();
            switch (result) {
                case AllowClosingOfModalResult.Allow:
                    this._closeTopWindow(object);
                    break;
                case AllowClosingOfModalResult.InformMessage:
                    SnackBar.push({ type: 'info', title: message !== null && message !== void 0 ? message : "Popup can't be closed" });
                    // abort closing
                    return;
                case AllowClosingOfModalResult.ConfirmMessage:
                    if (customConfirmCallback) {
                        customConfirmCallback();
                        return;
                    }
                    if (window.confirm(message)) {
                        // Ok to close, continue to popWindow
                        this._closeTopWindow(object);
                    }
                    else {
                        // abort closing
                        return;
                    }
            }
        }
        else {
            this._closeTopWindow(object);
        }
    }
    dispatchCloseEvent() {
        this.dispatchEvent(new CustomEvent('modal-closed', { bubbles: true, composed: true }));
    }
    /** @override */ disconnectedCallback() {
        super.disconnectedCallback();
        window.removeEventListener(OverlayWindow_1.scrollModalEvent, () => this._scrollToBottom());
        this.removeEventListener('click', this.boundVoidClickPopWindow);
    }
    getHandleForElement(element) {
        for (let i = 0; i < this.windows.length; i++) {
            if (this.windows[i].element === element) {
                return new OverlayHandle(this, this.windows[i]);
            }
        }
        return null;
    }
    inject(object) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!object.componentTagName) {
                return;
            }
            try {
                if (typeof object.componentTagName !== 'string') {
                    object.componentTagName = object.componentTagName.is;
                }
                if (!window.customElements.get(object.componentTagName)) {
                    console.error(': customElement er ikke deklarert:', object.componentTagName);
                }
                const createdElement = document.createElement(object.componentTagName);
                if (typeof object.attributes === 'object') {
                    for (const attribute in object.attributes) {
                        if (object.attributes.hasOwnProperty(attribute)) {
                            const attributeValue = object.attributes[attribute];
                            if (typeof attributeValue === 'boolean') {
                                if (attributeValue) {
                                    createdElement.setAttribute(attribute, '');
                                }
                                else {
                                    createdElement.removeAttribute(attribute);
                                }
                            }
                            else {
                                createdElement.setAttribute(attribute, String(attributeValue));
                            }
                        }
                    }
                }
                if (object.onCreated) {
                    object.onCreated(createdElement);
                }
                return this.pushWindow(Object.assign(Object.assign({}, object), { element: createdElement }));
            }
            catch (exception) {
                console.error(': får ikke opprettet element', object.componentTagName, exception);
            }
        });
    }
    peekWindow(offset = 0) {
        if (this.windows.length > offset) {
            return this.windows[this.windows.length - 1 - offset];
        }
        return null;
    }
    popAllWindows() {
        if (!this.overlayContents) {
            return;
        }
        while (this.overlayContents.lastChild) {
            this.overlayContents.removeChild(this.overlayContents.lastChild);
            const poppedWindow = this.windows.pop();
            if (poppedWindow &&
                poppedWindow.hasOwnProperty('onClose') &&
                poppedWindow.onClose !== undefined) {
                poppedWindow.onClose();
            }
        }
        document.body.classList.remove('overflow-hidden');
        this.classList.remove('active', 'small', 'medium');
    }
    popWindow(object, force) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.overlayContents) {
                return;
            }
            if (!object && this.popObject) {
                object = this.popObject;
            }
            if (force) {
                this._closeTopWindow(object);
                return;
            }
            this.popWindowHandler(object);
        });
    }
    _closeTopWindow(object) {
        if (!this.overlayContents) {
            return;
        }
        // Remove top-most window from the stack
        if (this.overlayContents.lastChild) {
            this.overlayContents.removeChild(this.overlayContents.lastChild);
            const poppedWindow = this.windows.pop();
            if (poppedWindow &&
                poppedWindow.hasOwnProperty('onClose') &&
                poppedWindow.onClose !== undefined) {
                poppedWindow.onClose(object);
                this.popObject = undefined;
            }
        }
        const topMostWindowIndex = this.overlayContents.childNodes.length - 1;
        const topMostWindowElement = this.overlayContents.childNodes[topMostWindowIndex];
        const topMostWindow = this.windows.at(topMostWindowIndex);
        if (this.overlayContents.childNodes.length) {
            if (!topMostWindow) {
                return;
            }
            // Set new top-most window as visible with the correct title
            topMostWindowElement.hidden = false;
            if (topMostWindow.title instanceof Array) {
                this.breadcrumbArr = topMostWindow.title;
                this.name = '';
            }
            else {
                this.breadcrumbArr = [];
                this.name = topMostWindow.title;
            }
            this._setTopWindowSizeClass(topMostWindow);
        }
        else {
            document.body.classList.remove('overflow-hidden');
            this.classList.remove('active', 'small', 'medium');
        }
        if (!this.allowRedirect() && OverlayWindow_1.hasRedirected) {
            this.allowRedirect = () => RedirectPermission.Allow;
        }
        this.dispatchCloseEvent();
    }
    _setTopWindowSizeClass(topMostWindow) {
        if (topMostWindow.hasOwnProperty('size')) {
            switch (topMostWindow.size) {
                case 'small':
                    this.classList.remove('medium', 'full');
                    this.classList.add(topMostWindow.size);
                    break;
                case 'medium':
                    this.classList.remove('small', 'full');
                    this.classList.add(topMostWindow.size);
                    break;
                case 'full':
                    this.classList.remove('small', 'medium');
                    this.classList.add(topMostWindow.size);
                    break;
            }
        }
        else {
            this.classList.remove('small', 'medium');
        }
    }
    pushWindow(windowObject) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.overlayContents) {
                return;
            }
            if (!this.classList.contains('active')) {
                document.body.classList.add('overflow-hidden');
                this.classList.add('active');
            }
            if (windowObject.hasOwnProperty('size')) {
                this._setTopWindowSizeClass(windowObject);
            }
            else {
                this.classList.remove('small', 'medium');
            }
            if (this.overlayContents.childNodes.length) {
                this.overlayContents.childNodes[this.overlayContents.childNodes.length - 1].hidden = true;
            }
            if (windowObject.title instanceof Array) {
                this.breadcrumbArr = windowObject.title;
                this.name = '';
            }
            else {
                this.breadcrumbArr = [];
                this.name = windowObject.title;
            }
            let element;
            if (windowObject.element instanceof Promise) {
                element = yield windowObject.element;
            }
            else if (windowObject.element) {
                element = windowObject.element;
            }
            else {
                return;
            }
            if (windowObject.onAllowRedirect) {
                this.allowRedirect = windowObject.onAllowRedirect;
            }
            const stackElement = Object.assign(Object.assign({}, windowObject), { element });
            this.windows.push(stackElement);
            this.requestUpdate('windows');
            this.overlayContents.appendChild(element);
            return new OverlayHandle(this, stackElement);
        });
    }
    _getHeaderStyling(preview) {
        if (!preview) {
            return {};
        }
        return { padding: '0rem 1rem' };
    }
    /** @override */ render() {
        var _a;
        const { fullscreen, preview } = this._getViewCases();
        return html `
            <div
                id="overlayContainer"
                class="${this._getOverlayContainerClasses(fullscreen, preview)}"
                @click="${this._stopPropagation}"
            >
                <div class="header" style="${styleMap(this._getHeaderStyling(preview))}">
                    <div>
                        ${this.name
            ? html ` <h1 style="${preview ? 'margin-bottom: 13px' : ''}">${this.name}</h1> `
            : html ``}
                        ${this.breadcrumbArr.length ? this._renderHeadCrumb() : html ``}
                    </div>

                    <div @click="${() => this.popWindow()}">
                        <img class="icon" src="${(_a = this.closeIconUrl) !== null && _a !== void 0 ? _a : ''}" />
                    </div>
                </div>

                <div id="overlayContents"></div>
            </div>
        `;
    }
    _renderHeadCrumb() {
        return html `
            <div class="breadcrumb">
                ${this.breadcrumbArr.map((breadcrumb) => html `
                        <span>
                            ${breadcrumb.onclick
            ? html `<a style="cursor:pointer;" @click=${breadcrumb.onclick}>
                                      ${breadcrumb.title}
                                  </a>`
            : html `${breadcrumb.title}`}
                        </span>
                    `)}
            </div>
        `;
    }
    fixCloseIconUrl() {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.closeIconUrl) {
                let url = '';
                if ('getCerumWebpackPublicPath' in window) {
                    const path = window.getCerumWebpackPublicPath();
                    if (typeof path === 'string') {
                        url = path + '/';
                    }
                }
                this.closeIconUrl = url + 'images/icons/close.svg';
            }
            return this.closeIconUrl;
        });
    }
    _getViewCases() {
        let fullscreen = this.fullscreen;
        let preview = this.preview === 'borderless';
        if (this.overlayContents && this.windows.length) {
            const topMostWindowIndex = this.windows.length - 1;
            const topMostWindow = this.windows[topMostWindowIndex];
            if (topMostWindow.fullscreen) {
                fullscreen = true;
            }
            else if (topMostWindow.preview) {
                preview = topMostWindow.preview === 'borderless';
            }
        }
        return { fullscreen, preview };
    }
    _getOverlayContainerClasses(fullscreen, preview) {
        const classes = ['overlay'];
        if (fullscreen) {
            classes.push('fullscreen');
        }
        else if (this.collapsedMenu) {
            classes.push('left-narrow');
        }
        else {
            classes.push('left-wide');
        }
        if (preview) {
            classes.push('preview');
        }
        return classes.join(' ');
    }
    _scrollToBottom() {
        this.updateComplete.then(() => {
            if (!this.overlayContainer) {
                return;
            }
            const scrollElement = this.overlayContainer;
            scrollElement.scrollTo({ top: scrollElement.scrollHeight, behavior: 'smooth' });
        });
    }
    _stopPropagation(event) {
        event.stopPropagation();
    }
};
OverlayWindow.hasRedirected = false;
OverlayWindow.instance = new AsyncContainer();
OverlayWindow.scrollModalEvent = 'scroll_modal_event';
OverlayWindow._deps = [SnackBar];
__decorate([
    state(),
    __metadata("design:type", Object)
], OverlayWindow.prototype, "closeIconUrl", void 0);
__decorate([
    state(),
    __metadata("design:type", String)
], OverlayWindow.prototype, "name", void 0);
__decorate([
    state(),
    __metadata("design:type", Array)
], OverlayWindow.prototype, "breadcrumbArr", void 0);
__decorate([
    state(),
    __metadata("design:type", Array)
], OverlayWindow.prototype, "windows", void 0);
__decorate([
    reduxBind(CerumMainUIStateStore.path('collapsedMenu')),
    state(),
    __metadata("design:type", Boolean)
], OverlayWindow.prototype, "collapsedMenu", void 0);
__decorate([
    state(),
    __metadata("design:type", Object)
], OverlayWindow.prototype, "allowRedirect", void 0);
__decorate([
    property({ type: Boolean }),
    __metadata("design:type", Boolean)
], OverlayWindow.prototype, "fullscreen", void 0);
__decorate([
    property({ type: String }),
    __metadata("design:type", String)
], OverlayWindow.prototype, "preview", void 0);
__decorate([
    state(),
    __metadata("design:type", Object)
], OverlayWindow.prototype, "popObject", void 0);
OverlayWindow = OverlayWindow_1 = __decorate([
    customElement(OverlayWindow.is),
    __metadata("design:paramtypes", [])
], OverlayWindow);
export { OverlayWindow };

var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { JSONStruct, nullable, property } from 'badphraim/data/JSONStruct';
export class ewRestRequestHandlerProjectStatistics_innsjekk_JSON extends JSONStruct {
    constructor() {
        super(...arguments);
        /** @override */ this.ansatte_fail = 0;
        /** @override */ this.ansatte_ok = 0;
        /** @override */ this.antall_aktive = 0;
        /** @override */ this.gjest = 0;
        /** @override */ this.project_count = 0;
        /** @override */ this.ue_fail = 0;
        /** @override */ this.ue_ok = 0;
    }
}
__decorate([
    property,
    __metadata("design:type", Number)
], ewRestRequestHandlerProjectStatistics_innsjekk_JSON.prototype, "ansatte_fail", void 0);
__decorate([
    property,
    __metadata("design:type", Number)
], ewRestRequestHandlerProjectStatistics_innsjekk_JSON.prototype, "ansatte_ok", void 0);
__decorate([
    property,
    __metadata("design:type", Number)
], ewRestRequestHandlerProjectStatistics_innsjekk_JSON.prototype, "antall_aktive", void 0);
__decorate([
    nullable,
    __metadata("design:type", Number)
], ewRestRequestHandlerProjectStatistics_innsjekk_JSON.prototype, "from", void 0);
__decorate([
    property,
    __metadata("design:type", Number)
], ewRestRequestHandlerProjectStatistics_innsjekk_JSON.prototype, "gjest", void 0);
__decorate([
    property,
    __metadata("design:type", Number)
], ewRestRequestHandlerProjectStatistics_innsjekk_JSON.prototype, "project_count", void 0);
__decorate([
    nullable,
    __metadata("design:type", Number)
], ewRestRequestHandlerProjectStatistics_innsjekk_JSON.prototype, "to", void 0);
__decorate([
    property,
    __metadata("design:type", Number)
], ewRestRequestHandlerProjectStatistics_innsjekk_JSON.prototype, "ue_fail", void 0);
__decorate([
    property,
    __metadata("design:type", Number)
], ewRestRequestHandlerProjectStatistics_innsjekk_JSON.prototype, "ue_ok", void 0);

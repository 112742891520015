import moment from 'moment';
import { CerumContactScheduleTaskVisibility } from 'cerum-work-schedule-defs/CerumContactScheduleTaskVisibility';
import { TaskStatus } from 'cerum-work-schedule-defs/TaskStatus';
export class CerumWorkScheduleDataHelper {
    prepareAssignedTask(task) {
        var _a;
        const taskStart = moment(task.start);
        const single = 'single' in task && task.single ? true : false;
        let durationPerPersonInMinutes = (_a = task.duration_in_minutes) !== null && _a !== void 0 ? _a : 0;
        if (task.assignees.length > 1 && task.duration_in_minutes && task.duration_in_minutes > 0) {
            durationPerPersonInMinutes = task.duration_in_minutes / task.assignees.length;
        }
        const taskEnd = taskStart.clone().add(durationPerPersonInMinutes, 'minutes');
        const formattedProperties = this._getAssignedTaskFormattedProperties(task, taskStart, taskEnd);
        const isColliding = false;
        // @TODO FIKS KOLLISJONER
        /*const tasksWithSameStart = this._getAssignedTasks().filter(
            (filteredTask) =>
                filteredTask.assignee?.data_id == task.assignee?.data_id &&
                moment(filteredTask.start).isSame(task.start) &&
                task != filteredTask
        );*/
        return Object.assign(Object.assign({}, task), { level: 0, formattedProperties,
            taskStart,
            taskEnd,
            single,
            isColliding,
            durationPerPersonInMinutes });
    }
    plannerPrepareAssignedTask(viewConfiguration, task) {
        const visibility = this._getAssignedTaskVisibility(viewConfiguration, task.taskStart, task.taskEnd);
        const quarterSpan = this._getAssignedTaskQuarterSpan(viewConfiguration, task.taskStart, task.taskEnd);
        return Object.assign(Object.assign({}, task), { visibility,
            quarterSpan });
    }
    _getAssignedTaskFormattedProperties(task, taskStart, taskEnd) {
        var _a, _b;
        return {
            start: taskStart.format('HH:mm'),
            end: taskEnd.format('HH:mm'),
            name: task.name,
            client: (_b = (_a = task.client) === null || _a === void 0 ? void 0 : _a.name) !== null && _b !== void 0 ? _b : '',
        };
    }
    _getAssignedTaskVisibility(viewConfiguration, taskStart, taskEnd) {
        const { startTimeMoment, endTimeMoment } = viewConfiguration;
        if (taskStart.isSameOrAfter(startTimeMoment) && taskEnd.isSameOrBefore(endTimeMoment)) {
            return CerumContactScheduleTaskVisibility.Between;
        }
        else if (taskStart.isSameOrBefore(startTimeMoment) &&
            (taskEnd.isSame(endTimeMoment) || taskEnd.isBetween(startTimeMoment, endTimeMoment))) {
            return CerumContactScheduleTaskVisibility.Before;
        }
        else if ((taskStart.isSame(startTimeMoment) || taskStart.isBetween(startTimeMoment, endTimeMoment)) &&
            taskEnd.isSameOrAfter(endTimeMoment)) {
            return CerumContactScheduleTaskVisibility.After;
        }
        else if ((taskStart.isBefore(startTimeMoment) && taskEnd.isBefore(startTimeMoment)) ||
            (taskStart.isSameOrAfter(endTimeMoment) && taskEnd.isAfter(endTimeMoment))) {
            return CerumContactScheduleTaskVisibility.Outside;
        }
        return CerumContactScheduleTaskVisibility.During;
    }
    _getAssignedTaskQuarterSpan(viewConfiguration, taskStart, taskEnd) {
        const { startTimeMoment, endTimeMoment } = viewConfiguration;
        let taskStartTime;
        if (taskStart.isBefore(startTimeMoment)) {
            taskStartTime = startTimeMoment.clone();
        }
        else {
            taskStartTime = taskStart.clone();
        }
        let taskEndTime;
        if (taskEnd.isAfter(endTimeMoment)) {
            taskEndTime = endTimeMoment.clone();
        }
        else {
            taskEndTime = taskEnd.clone();
        }
        taskEndTime = this.moduloRoundNumber(taskEndTime);
        const quarterSpan = Math.round(Math.abs(taskStartTime.diff(taskEndTime, 'minutes')) / 15);
        return quarterSpan;
    }
    moduloRoundNumber(timestamp, roundTo = 15) {
        timestamp = timestamp.clone();
        const modulo = timestamp.minute() % roundTo;
        if (modulo === 0) {
            return timestamp;
        }
        const remainderOfModulo = roundTo - modulo;
        return timestamp.add(remainderOfModulo, 'minutes');
    }
    // eslint-disable-next-line @typescript-eslint/naming-convention
    filterTasks(tasks, include) {
        if (include === null || include === void 0 ? void 0 : include.length) {
            return tasks.filter((task) => task.completion_status !== TaskStatus.PROPOSED ||
                !include.includes(task.completion_status));
        }
        return tasks.filter((task) => task.completion_status !== TaskStatus.PROPOSED);
    }
}

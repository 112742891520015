var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Ajax } from 'badphraim/ajax/Ajax';
import ClientOAuth2 from 'client-oauth2';
export class CerumOAuth2ClientBase {
    constructor(as_endpoint) {
        this.as_endpoint = as_endpoint;
    }
    getAjaxHandler() {
        return Ajax.stdInstance();
    }
    getCode() {
        return __awaiter(this, void 0, void 0, function* () {
            const oauth2 = yield this.getOAuth2();
            return oauth2.code;
        });
    }
    /**
     * @deprecated gitlab støtter ikke dette lenger
     */
    getTokenFlow() {
        return __awaiter(this, void 0, void 0, function* () {
            const oauth2 = yield this.getOAuth2();
            return oauth2.token;
        });
    }
    getOAuth2TokenUrl() {
        return this.as_endpoint_str + '/oauth2/token';
    }
    getOAuth2AuthorizeUrl() {
        return this.as_endpoint_str + '/oauth2/authorize';
    }
    getOAuth2() {
        return __awaiter(this, void 0, void 0, function* () {
            if (this._oauth2) {
                return this._oauth2;
            }
            this.as_endpoint_str =
                this.as_endpoint instanceof Promise ? yield this.as_endpoint : this.as_endpoint;
            const data = {
                clientId: this.getClientId(),
                //       clientSecret: '123',
                accessTokenUri: this.getOAuth2TokenUrl(),
                authorizationUri: this.getOAuth2AuthorizeUrl(),
                redirectUri: this.getRedirectUri(),
                scopes: this.getScopes(),
            };
            const secret = this.getClientSecret();
            if (secret !== undefined) {
                data.clientSecret = secret;
            }
            return (this._oauth2 = new ClientOAuth2(data));
        });
    }
    getRedirectUri() {
        let redir = document.location.href;
        console.log('Lager redirecturl fra ' + redir);
        redir = redir.replace(/#.*/, '');
        redir = redir.replace(/\?.*/, '');
        redir += '?action=oauth_response';
        const m = document.location.href.match(/\bservice=([^&]+)\b/);
        if (m) {
            redir += '&service=' + m[1];
        }
        return redir;
    }
    getTokenStore() {
        if (this.store) {
            return this.store;
        }
        this.store = this.createTokenStore();
        //let store = CerumTokenStore.getInstance();
        //        this.store.client = this.oauth2;
        return this.store;
    }
    logout() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const base = this.as_endpoint instanceof Promise ? yield this.as_endpoint : this.as_endpoint;
                const token_store = this.getTokenStore();
                const ac_token = yield token_store.getAccessToken();
                const url = base + '/oauth2/revoke';
                if (ac_token) {
                    try {
                        yield this.getAjaxHandler().doPostAjax(url, {
                            token: ac_token.accessToken,
                            token_type_hint: 'access_token',
                        });
                    }
                    catch (e) {
                        console.error(e);
                    }
                    token_store.setAccessToken(null);
                }
                const ref_token = yield token_store.getRefreshToken();
                if (ref_token) {
                    try {
                        yield this.getAjaxHandler().doPostAjax(url, {
                            token: ref_token.accessToken,
                            token_type_hint: 'refresh_token',
                        });
                    }
                    catch (e) {
                        console.error(e);
                    }
                    token_store.setRefreshToken(null);
                }
            }
            catch (e) {
                console.error(e);
                const token_store = this.getTokenStore();
                token_store.setAccessToken(null);
                token_store.setRefreshToken(null);
            }
        });
    }
    RequestCode() {
        return __awaiter(this, void 0, void 0, function* () {
            const code_uri = (yield this.getOAuth2()).code.getUri();
            // if (confirm("Vil du navigere til "+code_uri+" for å få en auth-code?")) {
            document.location.href = code_uri;
            // }
        });
    }
    RequestToken() {
        return __awaiter(this, void 0, void 0, function* () {
            const token_uri = (yield this.getOAuth2()).token.getUri();
            console.log('Navigere til token url', token_uri);
            // if (confirm("Vil du navigere til "+code_uri+" for å få en auth-code?")) {
            document.location.href = token_uri;
            // }
        });
    }
    getClientSecret() {
        return undefined;
    }
}

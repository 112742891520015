export class TypedLocalStorageAccess {
    constructor(key) {
        this.key = key;
    }
    getData() {
        const rawJson = window.localStorage.getItem(this.key);
        if (!rawJson) {
            return null;
        }
        const data = JSON.parse(rawJson);
        if (!data) {
            return null;
        }
        return data;
    }
    setData(data) {
        const rawJson = JSON.stringify(data);
        window.localStorage.setItem(this.key, rawJson);
    }
    clearData() {
        window.localStorage.removeItem(this.key);
    }
}

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { AsyncContainer } from 'badphraim/core/AsyncContainer';
export class VippsPaymentHelper {
    constructor(token, checkoutUrl, iframeContainer) {
        this.token = token;
        this.checkoutUrl = checkoutUrl;
        if (iframeContainer) {
            this.iframeContainer = iframeContainer;
        }
    }
    _loadVippsCheckoutScript() {
        return __awaiter(this, void 0, void 0, function* () {
            const vippsContainer = new AsyncContainer();
            if (this.isVippsCheckoutWindow(window)) {
                const vippsCheckout = window.VippsCheckout;
                vippsContainer.setValue(vippsCheckout);
                return vippsContainer.getValue();
            }
            const script = document.createElement('script');
            script.onload = () => {
                if (!this.isVippsCheckoutWindow(window)) {
                    vippsContainer.reject('Failed to load script');
                    return;
                }
                const vippsCheckout = window.VippsCheckout;
                vippsContainer.setValue(vippsCheckout);
            };
            script.onerror = () => {
                vippsContainer.reject('Failed to load script');
            };
            script.src = 'https://checkout.vipps.no/vippsCheckoutSDK.js';
            script.async = true;
            document.head.appendChild(script);
            return vippsContainer.getValue();
        });
    }
    setupVippsCheckout(checkoutOn) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.token || !this.checkoutUrl || !this.iframeContainer) {
                throw new Error("Can't setup Vipps checkout without token, checkoutUrl and iframeContainer");
            }
            const vippsCheckout = yield this._loadVippsCheckoutScript();
            this.iframeContainer.style.height = 'calc(100vh - 60px)';
            this.iframeContainer.style.width = '100%';
            vippsCheckout({
                checkoutFrontendUrl: this.checkoutUrl,
                iFrameContainerId: 'vipps-checkout-frame-container',
                language: 'no',
                token: this.token,
                on: checkoutOn,
            });
            const containerIframe = this.iframeContainer.querySelector('iframe');
            if (containerIframe) {
                containerIframe.style.height = '100%';
            }
        });
    }
    isVippsCheckoutWindow(window) {
        return 'VippsCheckout' in window;
    }
    getIframeContainer() {
        const existingIframeElement = document.body.querySelector('#vipps-checkout-frame-container');
        if (existingIframeElement) {
            this.iframeContainer = existingIframeElement;
            return existingIframeElement;
        }
        const containerElement = document.createElement('div');
        containerElement.id = 'vipps-checkout-frame-container';
        this.iframeContainer = containerElement;
        return document.body.appendChild(containerElement);
    }
}

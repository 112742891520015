var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
/* tslint:disable: class-name */
/*
 * AUTOGENERATED FILE - DO NOT EDIT
 */
import { DeliveryLineRepetition } from './DeliveryLineRepetition';
import { JSONStruct, property } from 'badphraim/data/JSONStruct';
export class DeliveryLineInterval extends JSONStruct {
    constructor() {
        super(...arguments);
        /** @override */ this.interval = 0;
        /** @override */ this.repetition = new DeliveryLineRepetition();
        /** @override */ this.type = '';
    }
}
__decorate([
    property,
    __metadata("design:type", Number)
], DeliveryLineInterval.prototype, "interval", void 0);
__decorate([
    property,
    __metadata("design:type", DeliveryLineRepetition)
], DeliveryLineInterval.prototype, "repetition", void 0);
__decorate([
    property,
    __metadata("design:type", String)
], DeliveryLineInterval.prototype, "type", void 0);

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { AsyncContainer } from 'badphraim/core/AsyncContainer';
import { AsyncTracking } from './AsyncTracking';
import { FacebookPixelTracking } from './FacebookPixelTracking';
import { GoogleTagTracking } from './GoogleTagTracking';
import { GoogleTagManager } from './GoogleTagManager';
import { LoggingTracking } from './LoggingTracking';
import { VoidTracking } from './VoidTracking';
export class TrackingFactorySingleton {
    static doesTracking() {
        if (!this._trackingSetup.hasValue()) {
            throw new Error('Not ready');
        }
        const tracking = this._trackingSetup.getValueIfReady();
        if (!tracking) {
            return false;
        }
        if (tracking.pixelId) {
            return true;
        }
        if (tracking.googleTagId) {
            return true;
        }
        if (tracking.googleTagManagerId) {
            return true;
        }
        return false;
    }
    static setTrackingSetup(tracking) {
        this._trackingSetup.setValue(tracking);
    }
    static acceptanceStateReady(accepted) {
        return __awaiter(this, void 0, void 0, function* () {
            // Det er avklart hva brukeren mener
            if (this.tracker.hasValue()) {
                // Det har vært avklart tidligere...!?!?
                console.error('Acceptance-state changed midflight?');
                if (!accepted) {
                    (() => __awaiter(this, void 0, void 0, function* () {
                        (yield this.tracker.getValue()).disable();
                    }))();
                }
                return;
            }
            const tracker = yield TrackingFactorySingleton.tracker.asyncSetValue(TrackingFactorySingleton.createTracker(accepted));
            tracker.initialize();
        });
    }
    static asyncTracker(onTracker) {
        return __awaiter(this, void 0, void 0, function* () {
            return onTracker(yield this.tracker.getValue());
        });
    }
    static getTracker() {
        if (TrackingFactorySingleton.tracker.hasValue()) {
            const tracking = TrackingFactorySingleton.tracker.getValueIfReady();
            if (tracking) {
                return tracking;
            }
        }
        return new AsyncTracking(TrackingFactorySingleton.tracker.getValue());
    }
    static getTrackingSetup() {
        return __awaiter(this, void 0, void 0, function* () {
            return this._trackingSetup.getValue();
        });
    }
    static createTracker(acceptance) {
        return __awaiter(this, void 0, void 0, function* () {
            if (acceptance === false) {
                return new VoidTracking();
            }
            const setup = yield this.getTrackingSetup();
            if (setup.pixelId) {
                return new FacebookPixelTracking(setup.pixelId);
            }
            if (setup.googleTagId) {
                return new GoogleTagTracking(setup.googleTagId);
            }
            if (setup.googleTagManagerId) {
                return new GoogleTagManager(setup.googleTagManagerId);
            }
            // enn så lenge bruk logging trackker
            if (this.log) {
                return new LoggingTracking();
            }
            // Til vanlig skal vel denne brukes
            return new VoidTracking();
        });
    }
}
TrackingFactorySingleton._trackingSetup = new AsyncContainer();
TrackingFactorySingleton.tracker = new AsyncContainer();
TrackingFactorySingleton.log = true;

/* tslint:disable: class-name */
/*
 * AUTOGENERATED FILE - DO NOT EDIT
 */
import { APIRestClient } from '../../../APIRestClient';
import { ewJSONWhoamiFailed } from '../../../schemas/ewJSONWhoamiFailed';
import { ewJSONWhoamiSuccess } from '../../../schemas/ewJSONWhoamiSuccess';
import { ewRestPortalLoginResourceHandler_NewPassword_RESULT } from '../../../schemas/ewRestPortalLoginResourceHandler_NewPassword_RESULT';
import { ewRestPortalLoginResourceHandler_RESULT } from '../../../schemas/ewRestPortalLoginResourceHandler_RESULT';
export class InnloggingPortalAPI extends APIRestClient {
    /**
     * Logg inn
     * Implementert hovedsakelig i *ewRestPortalLoginResourceHandler*::*doLogin*
     */
    static getLogin() {
        return this._get('/login', {}, null, null, ewJSONWhoamiSuccess.classOf(ewJSONWhoamiSuccess, new ewJSONWhoamiSuccess()), ['https://api.boostsystem.no/signup', 'https://api.boostsystem.no/app'], 'json');
    }
    /**
     * Logg ut?
     * Implementert hovedsakelig i *ewRestPortalLoginResourceHandler*::*doLogout*
     */
    static getLogout() {
        return this._get('/logout', {}, null, null, ewJSONWhoamiFailed.classOf(ewJSONWhoamiFailed, new ewJSONWhoamiFailed()), ['https://api.boostsystem.no/signup', 'https://api.boostsystem.no/app'], 'json');
    }
    /**
     * Innloggsinfo
     * Implementert hovedsakelig i *ewRestPortalLoginResourceHandler*::*doWhoami*
     */
    static getWhoami() {
        return this._get('/whoami', {}, null, null, ewJSONWhoamiSuccess.classOf(ewJSONWhoamiSuccess, new ewJSONWhoamiSuccess()), ['https://api.boostsystem.no/signup', 'https://api.boostsystem.no/app'], 'json');
    }
    /**
     * Be om nytt passord
     * Implementert hovedsakelig i *ewRestPortalLoginResourceHandler*::*doNewPassword*
     */
    static postPortalNewpassword(__DATA) {
        return this._post('/portal/newpassword', {}, null, JSON.stringify(__DATA), ewRestPortalLoginResourceHandler_NewPassword_RESULT.classOf(ewRestPortalLoginResourceHandler_NewPassword_RESULT, new ewRestPortalLoginResourceHandler_NewPassword_RESULT()), ['https://api.boostsystem.no/signup', 'https://api.boostsystem.no/app'], 'json');
    }
    /**
     * Denne boer erstattes av oauth-lignende ting etterhvert
     * Implementert hovedsakelig i *ewRestV1LoginHandler*::*doPostLogin*
     */
    static postV1PortalLogin(__DATA) {
        return this._post('/v1/portal/login', {}, null, JSON.stringify(__DATA), ewRestPortalLoginResourceHandler_RESULT.classOf(ewRestPortalLoginResourceHandler_RESULT, new ewRestPortalLoginResourceHandler_RESULT()), ['https://api.boostsystem.no/signup', 'https://api.boostsystem.no/app'], 'json');
    }
}

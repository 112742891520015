var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { JSONStruct, nullable, property } from 'badphraim/data/JSONStruct';
export class ewNewMemberInfoResult extends JSONStruct {
    constructor() {
        super(...arguments);
        /** @override */ this.address = '';
        /** @override */ this.contractId = 0;
        /** @override */ this.date_of_birth = '';
        /** @override */ this.departmentId = 0;
        /** @override */ this.email = '';
        /** @override */ this.firstname = '';
        /** @override */ this.gender = '';
        /** @override */ this.hasSignedAG = false;
        /** @override */ this.lastname = '';
        /** @override */ this.link_to_nets = '';
        /** @override */ this.link_to_sign = '';
        /** @override */ this.memberId = 0;
        /** @override */ this.membershipId = 0;
        /** @override */ this.municipality = '';
        /** @override */ this.netsCallbackUrl = '';
        /** @override */ this.ownerId = 0;
        /** @override */ this.phone_number = '';
        /** @override */ this.postal_code = '';
    }
}
__decorate([
    property,
    __metadata("design:type", String)
], ewNewMemberInfoResult.prototype, "address", void 0);
__decorate([
    nullable,
    __metadata("design:type", Boolean)
], ewNewMemberInfoResult.prototype, "approve_storage", void 0);
__decorate([
    nullable,
    __metadata("design:type", Boolean)
], ewNewMemberInfoResult.prototype, "approve_tos", void 0);
__decorate([
    property,
    __metadata("design:type", Number)
], ewNewMemberInfoResult.prototype, "contractId", void 0);
__decorate([
    property,
    __metadata("design:type", String)
], ewNewMemberInfoResult.prototype, "date_of_birth", void 0);
__decorate([
    property,
    __metadata("design:type", Number)
], ewNewMemberInfoResult.prototype, "departmentId", void 0);
__decorate([
    property,
    __metadata("design:type", String)
], ewNewMemberInfoResult.prototype, "email", void 0);
__decorate([
    property,
    __metadata("design:type", String)
], ewNewMemberInfoResult.prototype, "firstname", void 0);
__decorate([
    property,
    __metadata("design:type", String)
], ewNewMemberInfoResult.prototype, "gender", void 0);
__decorate([
    property,
    __metadata("design:type", Boolean)
], ewNewMemberInfoResult.prototype, "hasSignedAG", void 0);
__decorate([
    nullable,
    __metadata("design:type", Number)
], ewNewMemberInfoResult.prototype, "invoiceId", void 0);
__decorate([
    nullable,
    __metadata("design:type", Boolean)
], ewNewMemberInfoResult.prototype, "is_privately_sponsored", void 0);
__decorate([
    nullable,
    __metadata("design:type", Boolean)
], ewNewMemberInfoResult.prototype, "is_sponsor", void 0);
__decorate([
    property,
    __metadata("design:type", String)
], ewNewMemberInfoResult.prototype, "lastname", void 0);
__decorate([
    property,
    __metadata("design:type", String)
], ewNewMemberInfoResult.prototype, "link_to_nets", void 0);
__decorate([
    property,
    __metadata("design:type", String)
], ewNewMemberInfoResult.prototype, "link_to_sign", void 0);
__decorate([
    property,
    __metadata("design:type", Number)
], ewNewMemberInfoResult.prototype, "memberId", void 0);
__decorate([
    property,
    __metadata("design:type", Number)
], ewNewMemberInfoResult.prototype, "membershipId", void 0);
__decorate([
    property,
    __metadata("design:type", String)
], ewNewMemberInfoResult.prototype, "municipality", void 0);
__decorate([
    property,
    __metadata("design:type", String)
], ewNewMemberInfoResult.prototype, "netsCallbackUrl", void 0);
__decorate([
    property,
    __metadata("design:type", Number)
], ewNewMemberInfoResult.prototype, "ownerId", void 0);
__decorate([
    property,
    __metadata("design:type", String)
], ewNewMemberInfoResult.prototype, "phone_number", void 0);
__decorate([
    property,
    __metadata("design:type", String)
], ewNewMemberInfoResult.prototype, "postal_code", void 0);
__decorate([
    nullable,
    __metadata("design:type", String)
], ewNewMemberInfoResult.prototype, "sponsor_code", void 0);

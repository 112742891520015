var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { css, html, LitElement } from 'lit';
import { customElement } from 'lit/decorators.js';
import { BoostButton } from '../components/common/BoostButton';
import { TrackingAcceptanceStorage } from '../tracking/TrackingAcceptanceStorage';
let BoostPortalTrackingNotification = class BoostPortalTrackingNotification extends LitElement {
    static get is() {
        return 'boost-portal-tracking-notification';
    }
    static get styles() {
        return css `
            .tracker-background {
                position: fixed;
                top: 0;
                left: 0;
                z-index: 2;
                background: rgba(0, 0, 0, 0.8);
                min-height: 100vh;
                width: 100%;
            }
            .tracker-modal {
                position: relative;
                background-color: white;
                border-radius: 10px;
                padding: 10px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                max-width: 42rem;
                width: 80%;
                z-index: 3;
                margin-bottom: 0.5rem;
                margin-left: auto;
                margin-right: auto;
                margin-top: 20%;
            }
            .tracker-foreground {
                width: 100%;
                margin-left: auto;
                margin-right: auto;
                margin-bottom: 0.5rem;
            }
        `;
    }
    /** @override */ render() {
        return html `<div class="tracker-background">
            <div class="tracker-modal">
                <div>
                    <p style="margin: 0.5rem;">
                        På våre nettsider bruker vi informasjonskapsler (cookies) for personlig tilpasning av
                        annonser og måling. Du kan lese mer om dette i vår
                        <a href="#">personvernpolicy</a>.
                    </p>
                    <div style="margin-top: 10px; display: flex; justify-content: space-evenly;">
                        <boost-button
                            @click=${this.accept.bind(this)}
                            type="submit"
                            name="Det er greit"
                        ></boost-button>
                        <boost-button
                            @click=${this.reject.bind(this)}
                            type="submit"
                            name="Nei takk"
                        ></boost-button>
                    </div>
                </div>
            </div>
        </div>`;
    }
    accept() {
        new TrackingAcceptanceStorage().setAccepted(true);
        // Hvem skal logge til oss?
        this.close();
    }
    reject() {
        new TrackingAcceptanceStorage().setAccepted(false);
        // Hvem skal logge til oss?
        this.close();
    }
    getContainingShadowRoot() {
        const node = this.getRootNode();
        if (node instanceof ShadowRoot) {
            return node;
        }
    }
    close() {
        const parent = this.getContainingShadowRoot();
        parent === null || parent === void 0 ? void 0 : parent.removeChild(this);
    }
};
BoostPortalTrackingNotification._deps = [BoostButton];
BoostPortalTrackingNotification = __decorate([
    customElement(BoostPortalTrackingNotification.is)
], BoostPortalTrackingNotification);
export { BoostPortalTrackingNotification };

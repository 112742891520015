var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { JSONStruct, nullable, property } from 'badphraim/data/JSONStruct';
export class WorkplanningDeliveryTask extends JSONStruct {
    constructor() {
        super(...arguments);
        /** @override */ this.delivery_id = 0;
        /** @override */ this.delivery_line_id = 0;
        /** @override */ this.duration_in_minutes = 0;
        /** @override */ this.event_instance_id = 0;
        /** @override */ this.id = 0;
        /** @override */ this.planned_time = '';
        /** @override */ this.single = false;
    }
}
__decorate([
    property,
    __metadata("design:type", Number)
], WorkplanningDeliveryTask.prototype, "delivery_id", void 0);
__decorate([
    property,
    __metadata("design:type", Number)
], WorkplanningDeliveryTask.prototype, "delivery_line_id", void 0);
__decorate([
    property,
    __metadata("design:type", Number)
], WorkplanningDeliveryTask.prototype, "duration_in_minutes", void 0);
__decorate([
    property,
    __metadata("design:type", Number)
], WorkplanningDeliveryTask.prototype, "event_instance_id", void 0);
__decorate([
    property,
    __metadata("design:type", Number)
], WorkplanningDeliveryTask.prototype, "id", void 0);
__decorate([
    nullable,
    __metadata("design:type", String)
], WorkplanningDeliveryTask.prototype, "name", void 0);
__decorate([
    nullable,
    __metadata("design:type", Number)
], WorkplanningDeliveryTask.prototype, "person_id", void 0);
__decorate([
    property,
    __metadata("design:type", String)
], WorkplanningDeliveryTask.prototype, "planned_time", void 0);
__decorate([
    nullable,
    __metadata("design:type", String)
], WorkplanningDeliveryTask.prototype, "resource_name", void 0);
__decorate([
    nullable,
    __metadata("design:type", Number)
], WorkplanningDeliveryTask.prototype, "resource_node_id", void 0);
__decorate([
    property,
    __metadata("design:type", Boolean)
], WorkplanningDeliveryTask.prototype, "single", void 0);

import { CerumConnect } from 'cerum-services/websockets/CerumConnect';
import { CerumReduxSubStore } from './CerumReduxSubStore';
export class CerumNotificationRecord {
    constructor(msg, importance, kategori) {
        this.id = 0;
        this.kategori = '';
        this.message = '';
        this.id = ++CerumNotificationRecord._primary_key;
        this.message = msg;
        this.importance = importance;
        this.tidspunkt = new Date();
        if (kategori) {
            this.kategori = kategori;
        }
    }
}
CerumNotificationRecord._primary_key = 0;
export class CerumNotificationStoreLayout {
    constructor() {
        this.count = 0;
        this.notifications = [];
    }
}
export class CerumNotificationSupervisor {
    constructor() {
        this.pubsub = CerumConnect.getGlobalInstance();
        /*    this.pubsub.registerEventListener('cerum.notification_center', (msg) => {
                this.onNoticationMessage(msg);
            });
            */
    }
    onNotificationMessage(_msg) {
        /* void */
    }
}
export class CerumNotificationStore extends CerumReduxSubStore {
    static get instance() {
        return this._instance || (this._instance = new CerumNotificationStore());
    }
    static path(property) {
        return this.staticPath('CerumNotificationStore', property);
    }
    constructor() {
        super();
        /** @override */ this.base_key = 'CerumNotificationStore';
        this.supervisor = new CerumNotificationSupervisor();
    }
    /** @override */ reducer() {
        return {
            [this.base_key]: (state = new CerumNotificationStoreLayout(), action) => {
                switch (action.type) {
                    case CerumNotificationStore.Actions.NEW_NOTIFICATION:
                        const newNotifications = state.notifications.slice();
                        const payload = action.payload;
                        if ('message' in payload) {
                            newNotifications.push(new CerumNotificationRecord(payload.message, payload.importance, payload.kategori));
                            state = Object.assign(Object.assign({}, state), {
                                notifications: newNotifications,
                                count: newNotifications.length,
                            });
                        }
                        break;
                    case CerumNotificationStore.Actions.REMOVE_NOTIFICATION:
                        const newNotifications2 = state.notifications.slice();
                        const removePayload = action.payload;
                        for (let i = state.notifications.length; i-- > 0;) {
                            const notification = state.notifications[i];
                            const id = removePayload.id;
                            if (notification.id === id) {
                                newNotifications2.splice(i, 1);
                            }
                        }
                        state = Object.assign(Object.assign({}, state), {
                            notifications: newNotifications2,
                            count: newNotifications2.length,
                        });
                        break;
                }
                return state;
            },
        };
    }
}
(function (CerumNotificationStore) {
    // eslint-disable-next-line id-length
    CerumNotificationStore.RED = 1;
    CerumNotificationStore.GREEN = 2;
    CerumNotificationStore.YELLOW = 3;
    let Actions;
    (function (Actions) {
        Actions.NEW_NOTIFICATION = 'NEW_NOTIFICATION';
        Actions.REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION';
        Actions.newNotification = (message, importance, kategori) => {
            const payload = {
                message,
                importance,
                kategori,
            };
            return {
                type: Actions.NEW_NOTIFICATION,
                payload,
            };
        };
        Actions.removeNotification = (id) => {
            const payload = {
                id,
            };
            return {
                type: Actions.REMOVE_NOTIFICATION,
                payload,
            };
        };
    })(Actions = CerumNotificationStore.Actions || (CerumNotificationStore.Actions = {}));
})(CerumNotificationStore || (CerumNotificationStore = {}));

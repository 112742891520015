var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { reduxBind } from 'badphraim/ui/LitReduxMixinBase';
import { css, html, LitElement } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { BoostPortalStore } from '../redux/BoostPortalStore';
import { LitReduxBoostPortalMixin } from '../redux/LitReduxBoostPortalMixin';
import { BoostButton } from './common/BoostButton';
import { BoostPortalMemberStandardProgress } from './BoostPortalMemberStandardProgress';
import { TrackingFactorySingleton } from '../tracking/TrackingFactorySingleton';
import { TrackingEvent } from '../tracking/TrackingEvent';
let BoostPortalMemberStandardConfirm = class BoostPortalMemberStandardConfirm extends LitReduxBoostPortalMixin(LitElement) {
    constructor() {
        super(...arguments);
        this.currentStep = 1;
    }
    static get is() {
        return 'boost-portal-member-standard-confirm';
    }
    static get styles() {
        return css `
            .progress {
                display: flex;
                margin: 20px 0;
                font-size: 140%;
                font-weight: 300;
            }

            .progress div {
                flex: 1;
            }

            .progress div:first-child {
                margin-right: 10px;
            }
        `;
    }
    /** @override */ render() {
        return html `
            <boost-portal-member-standard-progress></boost-portal-member-standard-progress>

            <div class="progress">
                <div>Signering</div>
                <div style="text-align: right;">
                    <boost-button
                        type="submit"
                        name="Signer med BankID"
                        @click="${this._openAvtalegiro}"
                    ></boost-button>
                </div>
            </div>
        `;
    }
    _openAvtalegiro() {
        if (!this.memberInformation) {
            return;
        }
        TrackingFactorySingleton.getTracker().completedEvent(TrackingEvent.BankIDSigningStarted);
        window.location.replace(this.memberInformation.link_to_nets);
    }
};
BoostPortalMemberStandardConfirm._deps = [BoostButton, BoostPortalMemberStandardProgress];
__decorate([
    reduxBind(BoostPortalStore.path('memberInformation')),
    state(),
    __metadata("design:type", Object)
], BoostPortalMemberStandardConfirm.prototype, "memberInformation", void 0);
__decorate([
    reduxBind(BoostPortalStore.path('memberships')),
    state(),
    __metadata("design:type", Array)
], BoostPortalMemberStandardConfirm.prototype, "memberships", void 0);
__decorate([
    reduxBind(BoostPortalStore.path('selectedMembershipId')),
    state(),
    __metadata("design:type", Number)
], BoostPortalMemberStandardConfirm.prototype, "selectedMembershipId", void 0);
__decorate([
    property({ type: Number }),
    __metadata("design:type", Number)
], BoostPortalMemberStandardConfirm.prototype, "currentStep", void 0);
BoostPortalMemberStandardConfirm = __decorate([
    customElement(BoostPortalMemberStandardConfirm.is)
], BoostPortalMemberStandardConfirm);
export { BoostPortalMemberStandardConfirm };

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { isSuccessHTTP } from 'boost-api/APIRestClient';
import { MembersignupAPI } from 'boost-api/boost/signup/membersignup/MembersignupAPI';
import { RequestRetryHelper } from 'cerumx-shared/helpers/RequestRetryHelper';
import { AsyncContainer } from 'badphraim/core/AsyncContainer';
export var PaymentStatus;
(function (PaymentStatus) {
    PaymentStatus["PAYMENTABORT"] = "PAYMENTABORT";
    PaymentStatus["PAYMENTFAILED"] = "PAYMENTFAILED";
    PaymentStatus["PAYMENTSUCCESS"] = "PAYMENTSUCCESS";
    PaymentStatus["VOID"] = "VOID";
})(PaymentStatus || (PaymentStatus = {}));
export class BoostPortalPaymentStatusHelper {
    static getPaymentStatus(invoiceId) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!invoiceId) {
                return;
            }
            const paymentStatusResult = yield MembersignupAPI.getV2BoostsignupPaymentUsingInvoiceid(invoiceId);
            if (isSuccessHTTP(paymentStatusResult)) {
                if (paymentStatusResult.status === PaymentStatus.VOID) {
                    return PaymentStatus.VOID;
                }
                if (!paymentStatusResult.is_ended) {
                    const retryResult = yield this._retryPaymentStatus(invoiceId);
                    return retryResult;
                }
                if (paymentStatusResult.is_paid) {
                    return PaymentStatus.PAYMENTSUCCESS;
                }
                return PaymentStatus.PAYMENTFAILED;
            }
            return PaymentStatus.VOID;
        });
    }
    static _retryPaymentStatus(invoiceId) {
        return __awaiter(this, void 0, void 0, function* () {
            const asyncContainer = new AsyncContainer();
            const isSuccess = (result) => result === PaymentStatus.PAYMENTSUCCESS;
            const retryHelper = new RequestRetryHelper(6, 200);
            yield retryHelper
                .retryRequest(this.getPaymentStatus, invoiceId, isSuccess)
                .then((result) => asyncContainer.setValue(result))
                .catch(() => asyncContainer.reject("Couldn't get payment status"));
            return asyncContainer.getValue();
        });
    }
}

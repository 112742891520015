var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { AsyncContainer } from './core/AsyncContainer';
export class PubSubClient {
    constructor(ident) {
        this._operationCount = 0;
        this._operations = {};
        this._subCounter = {};
        this._subHandleCnt = 0;
        this._subscriptions = [];
        this.webSocket = new AsyncContainer();
        //super();
        this.globalid = ident;
        this.startWebSocketClient();
    }
    getSubscriptions() {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield this.operation('listsubs', {});
            if (!('parameters' in result)) {
                throw new Error('bad response: ' + JSON.stringify(result));
            }
            const params = result.parameters;
            if (params && 'channels' in params) {
                return params.channels;
            }
            throw new Error('bad response params: ' + params);
        });
    }
    handleEvent(_msg) {
        /** void  */
    }
    handleMessage(message) {
        if (typeof message !== 'object') {
            console.error('PubSubClient.handleMessage(): Message was not handled', message);
            return;
        }
        if ('operation_id' in message && ('operation_response' in message || 'operation_result' in message)) {
            return this.handleOperationResult(message);
        }
        if ('channel' in message && 'data' in message && 'message' in message) {
            const pubSubMessage = message;
            if (this.handlePubSubMessage(pubSubMessage)) {
                return;
            }
            if ('event' in message.data && 'data' in message.data) {
                const pubsubEvent = message.data;
                return this.handleEvent(pubsubEvent);
            }
        }
        if ('event' in message && message.event) {
            const pubsubEvent = message;
            return this.handleEvent(pubsubEvent);
        }
    }
    handleOperationResult(result) {
        const operationId = result.operation_id;
        let operationResponse = result.operation_response;
        if (!operationResponse && 'operation' in result) {
            operationResponse = result.operation;
        }
        if (!(operationId in this._operations)) {
            console.error('Received operation result for an unknown operation');
            return;
        }
        const stashed = this._operations[operationId];
        if (stashed.operation !== operationResponse) {
            const reason = 'Received operation result for operation_id ' +
                operationId +
                ' which sould be ' +
                stashed.operation +
                ', but received result for an ' +
                operationResponse;
            console.error(reason);
            stashed.reject(reason);
            return;
        }
        if (result.operation_result !== 200) {
            stashed.reject(result);
        }
        else {
            stashed.resolve(result);
        }
        if (stashed.timer) {
            clearTimeout(stashed.timer);
        }
        delete this._operations[operationId];
        /** void */
    }
    handlePubSubMessage(message) {
        let handled = false;
        this._subscriptions.slice().forEach((subscriptionHandle) => {
            if (subscriptionHandle.channel !== message.channel) {
                return;
            }
            handled = true;
            try {
                subscriptionHandle.callback(message);
            }
            catch (error) {
                console.error('PubSubClient.handlePubSubMessage: ', error);
            }
        });
        return handled;
    }
    operation(operation, parameters, timeoutSec) {
        return __awaiter(this, void 0, void 0, function* () {
            const websocket = yield this.webSocket.getValue();
            const operationId = ++this._operationCount;
            let pending;
            const promise = new Promise((resolve, reject) => {
                /** void  */
                let timer;
                if (timeoutSec) {
                    const started = new Date();
                    timer = window.setTimeout(() => {
                        this.operationTimedOut(operationId, started);
                    }, timeoutSec * 1000);
                }
                pending = {
                    resolve,
                    reject,
                    timer,
                    operation,
                    parameters,
                };
                websocket.send(JSON.stringify({
                    operation,
                    operation_id: operationId,
                    parameters,
                }));
            });
            if (pending) {
                // Bare for å bligjøre typesjekker. pending er bestandig satt her
                // fordi initialiseren til promisen blir kjørt som en microtask før vi kommer hit
                pending.promise = promise;
                this._operations[operationId] = pending;
            }
            else {
                console.error('operation: this state is not reachable!?!?');
            }
            return promise;
        });
    }
    operationTimedOut(operationId, startedTime) {
        if (!(operationId in this._operations)) {
            return;
        }
        const stashed = this._operations[operationId];
        if (!stashed) {
            return;
        }
        const currentTime = new Date();
        stashed.reject('timed out after ' + (currentTime.getTime() - startedTime.getTime()) / 1000 + ' seconds');
        delete this._operations[operationId];
    }
    publish(channel, data, timeoutSec) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.operation('publish', {
                channel,
                data,
            }, timeoutSec);
        });
    }
    sendMessage(payload) {
        return __awaiter(this, void 0, void 0, function* () {
            const websocket = yield this.webSocket.getValue();
            websocket.send(JSON.stringify(payload));
        });
    }
    startWebSocketClient() {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.webSocket.hasValue()) {
                try {
                    const oldWebsocket = yield this.webSocket.getValue();
                    oldWebsocket.close();
                }
                catch (_error) {
                    // void
                }
                this.webSocket = new AsyncContainer();
            }
            const url = yield this.getWebSocketUrl();
            if (!url) {
                console.error('Aborting ws connect due to missing target url');
                return;
            }
            return new Promise((resolve, reject) => {
                const websocket = new WebSocket(url, 'echo-protocol');
                websocket.onmessage = this._onmessage.bind(this);
                websocket.onclose = this._onclose.bind(this);
                websocket.onerror = (error) => {
                    console.error('Feil under websocket-tilkobling', error);
                    reject(error);
                };
                websocket.onopen = (openEvent) => {
                    websocket.onerror = this._onerror.bind(this);
                    websocket.onopen = this._onopen.bind(this);
                    this.webSocket.setValue(websocket);
                    console.log('Åpnet WebSocket ' + url, websocket); // Eskil denne skal være her...
                    resolve(websocket);
                    this._onopen(openEvent);
                };
            });
        });
    }
    getRegisteredSubscriptions() {
        return this._subscriptions;
    }
    subscribe(channel, callback, timeoutSec) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.registerSubscription(channel, callback, timeoutSec);
            const handle = ++this._subHandleCnt;
            const coercedCallback = callback;
            this._subscriptions.push({ handle, channel, callback: coercedCallback });
            return handle;
        });
    }
    registerSubscription(channel, _callback, timeoutSec) {
        return __awaiter(this, void 0, void 0, function* () {
            const subCounter = yield this.subscriptionCounter(channel, timeoutSec);
            subCounter.count++;
        });
    }
    subscriptionCounter(channel, timeoutSec) {
        return __awaiter(this, void 0, void 0, function* () {
            if (channel in this._subCounter) {
                return this._subCounter[channel];
            }
            const promiseHandler = (resolve, reject) => __awaiter(this, void 0, void 0, function* () {
                try {
                    yield this.operation('subscribe', { channel }, timeoutSec);
                    resolve({
                        count: 0,
                    });
                }
                catch (error) {
                    reject(error);
                    delete this._subCounter[channel];
                }
            });
            this._subCounter[channel] = new Promise((resolve, reject) => {
                promiseHandler(resolve, reject);
            });
            return this._subCounter[channel];
        });
    }
    unsubscribe(handle) {
        return __awaiter(this, void 0, void 0, function* () {
            let subscription;
            for (let i = 0; i < this._subscriptions.length; i++) {
                if (this._subscriptions[i].handle === handle) {
                    subscription = this._subscriptions[i];
                    this._subscriptions.splice(i, 1);
                    break;
                }
            }
            if (!subscription) {
                return;
            }
            if (subscription.channel in this._subCounter) {
                const subscriptionCount = yield this.subscriptionCounter(subscription.channel);
                subscriptionCount.count--;
                if (subscriptionCount.count === 0) {
                    delete this._subCounter[subscription.channel];
                    this.operation('unsubscribe', { channel: subscription.channel });
                }
            }
        });
    }
    _onclose(_closeEvent) {
        // void
    }
    _onerror(errorEvent) {
        console.error('onerror', errorEvent);
    }
    _onmessage(msgEvent) {
        return __awaiter(this, void 0, void 0, function* () {
            const message = String(msgEvent.data);
            let msgs = [];
            let parsed;
            message.startsWith('{');
            if (message.startsWith('{')) {
                parsed = JSON.parse(message);
                if (parsed) {
                    msgs.push(parsed);
                }
            }
            else if (message.startsWith('[')) {
                parsed = JSON.parse(message);
                if (parsed) {
                    msgs = parsed;
                }
            }
            else if (message.match(/^SKA(\d+)?$/)) {
                const websocket = yield this.webSocket.getValue();
                websocket.send(message);
            }
            else if (message.match(/^CKA(\d+)?$/)) {
                // void
            }
            //for (let i = 0; i < msgs.length; i++) {
            for (const _msg of msgs) {
                this.handleMessage(_msg);
            }
        });
    }
    _onopen(_openEvent) {
        // void
    }
}

export class ObjectHelper {
    static cloneStruct(noe) {
        if (noe instanceof Array) {
            const ret = [];
            for (const i in noe) {
                ret[i] = ObjectHelper.cloneStruct(noe[i]);
            }
            return ret;
        }
        if (noe instanceof Object) {
            const obj = {};
            for (const i in noe) {
                if (noe.hasOwnProperty(i)) {
                    obj[i] = ObjectHelper.cloneStruct(noe[i]);
                }
            }
            return obj;
        }
        return noe;
    }
}

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { attachAllBindings, getStateValueByPath, releaseAllBindings, } from '../redux/Common';
export { reduxBind } from '../redux/Common';
export function LitReduxMixinBase(Base, getStore) {
    // const subscriptions = new Map();
    class Mixin extends Base {
        /** @override */ connectedCallback() {
            super.connectedCallback();
            this._redux_bindings = attachAllBindings(this);
        }
        /** @override */ disconnectedCallback() {
            if (this._redux_bindings) {
                releaseAllBindings(this, this._redux_bindings);
            }
            super.disconnectedCallback();
        }
        /** @override */ dispatch(act) {
            this.asyncDispatch(act);
        }
        asyncDispatch(act) {
            return __awaiter(this, void 0, void 0, function* () {
                (yield this.getStore()).dispatch(act);
            });
        }
        getStateValueByPath(path) {
            return __awaiter(this, void 0, void 0, function* () {
                return getStateValueByPath((yield this.getStore()).getState(), path);
            });
        }
        /** @override */ getStore() {
            return __awaiter(this, void 0, void 0, function* () {
                return getStore();
            });
        }
    }
    Mixin.__redux_state_paths = new Map();
    Mixin._deps = [];
    return Mixin;
}

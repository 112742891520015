export class WebWorkerWrapperBase {
    constructor() {
        this.workers = [];
        this.pendingRequests = new Map();
    }
    get concurrency() {
        return navigator.hardwareConcurrency;
    }
    getAvailableWorker() {
        for (const workerIdx in this.workers) {
            const workerPtr = this.workers[workerIdx];
            if (workerPtr.activeTasks === 0) {
                return workerPtr;
            }
        }
        if (this.workers.length >= this.concurrency) {
            // Return the worker with lowest number of active tasks
            for (const workerPtr of this.workers.sort((workerA, workerB) => workerA.activeTasks - workerB.activeTasks)) {
                return workerPtr;
            }
        }
        const worker = this.createWorker();
        worker.onerror = this.onError.bind(this);
        worker.onmessage = this.onMessage.bind(this);
        const workerPtr = {
            activeTasks: 0,
            worker,
        };
        this.workers.push(workerPtr);
        return workerPtr;
    }
    onError(error) {
        console.error('workerError', error);
        // void
    }
    onMessage(msgEvent) {
        // void
        const response = msgEvent.data;
        const requestId = response.id;
        const pendingRequest = this.pendingRequests.get(requestId);
        if (!pendingRequest) {
            console.error('Unknown request: ', msgEvent);
            return;
        }
        if (response.status == 'ok') {
            const result = response.result;
            if (result) {
                pendingRequest.resolve(result);
            }
            else {
                console.error('Missing result payload: ', msgEvent);
            }
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        }
        else if (response.status == 'error') {
            pendingRequest.reject(response.error);
        }
        else {
            console.error('Unknown response status: ', msgEvent);
        }
        pendingRequest.workerPtr.activeTasks--;
        this.pendingRequests.delete(requestId);
    }
    sendRequest(request) {
        const requestId = ++WebWorkerWrapperBase._sequence;
        const requestMsg = {
            id: requestId,
            data: request,
        };
        return new Promise((resolve, reject) => {
            const workerPtr = this.getAvailableWorker();
            this.pendingRequests.set(requestId, {
                id: requestId,
                resolve,
                reject,
                workerPtr,
            });
            workerPtr.worker.postMessage(requestMsg);
            workerPtr.activeTasks++;
        });
    }
}
WebWorkerWrapperBase._sequence = 0;

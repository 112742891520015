var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Ajax } from 'cerum-services/Ajax';
import { CerumServices } from 'cerum-services/CerumServices';
import { CerumReduxSubStore } from './CerumReduxSubStore';
export class CerumPanelRecord {
    constructor(id, title, ingress, text, error) {
        this.error = '';
        this.ingress = '';
        this.pub_id = 0;
        this.text = '';
        this.title = '';
        this.pub_id = Number(id);
        this.title = title;
        this.ingress = ingress;
        this.text = text;
        this.error = error;
    }
}
CerumPanelRecord._primary_key = 0;
export class CerumPanelStoreLayout {
    constructor() {
        this.count = 0;
        this.load_ids = [];
        //public load_keys: string[] = [];
        this.panels_by_id = {};
        //public panels_by_key: { [key: string]: CerumPanelRecord[] } = {};
    }
}
export class CerumPanelStore extends CerumReduxSubStore {
    constructor() {
        super(...arguments);
        /** @override */ this.base_key = 'CerumPanelStore';
    }
    static get instance() {
        return this._instance || (this._instance = new CerumPanelStore());
    }
    static path(property) {
        return this.staticPath('CerumPanelStore', property);
    }
    /** @override */ reducer() {
        return {
            [this.base_key]: (state = new CerumPanelStoreLayout(), action) => {
                switch (action.type) {
                    case CerumPanelStore.LOADED_PANEL:
                        const panel = action.payload.panel;
                        const new_state = Object.assign(Object.assign({}, state), { panels_by_id: Object.assign(Object.assign({}, state.panels_by_id), { [panel.pub_id]: panel }) });
                        new_state.load_ids = Object.keys(new_state.panels_by_id).map((key) => parseInt(key, 10));
                        return new_state;
                    case CerumPanelStore.PANEL_FAILURE:
                        const panel2 = action.payload.panel;
                        panel2.error = 'FAILURE';
                        const new_state2 = Object.assign(Object.assign({}, state), { panels_by_id: Object.assign(Object.assign({}, state.panels_by_id), { [panel2.pub_id]: panel2 }) });
                        new_state2.load_ids = Object.keys(new_state2.panels_by_id).map((key) => parseInt(key, 10));
                        return new_state2;
                    case CerumPanelStore.REMOVE_PANEL:
                        break;
                }
                return state;
            },
        };
    }
}
(function (CerumPanelStore) {
    CerumPanelStore.PANEL_FAILURE = 'PANEL_FAILURE';
    CerumPanelStore.LOADED_PANEL = 'LOADED_PANEL';
    CerumPanelStore.REMOVE_PANEL = 'REMOVE_PANEL';
    let Actions;
    (function (Actions) {
        Actions.panelError = (error, id, panel) => ({
            type: CerumPanelStore.PANEL_FAILURE,
            payload: { error, id, panel },
        });
        Actions.getPanel = (id) => {
            return (dispatch) => __awaiter(this, void 0, void 0, function* () {
                let uri = yield CerumServices.instance().getRestBaseUrl();
                uri += '/publisering/' + id;
                return Ajax.stdInstance()
                    .fetch(uri)
                    .then(handleErrors)
                    .then((res) => res.json())
                    .then((json) => {
                    if ('error' in json && json.error) {
                        const { error } = json;
                        if (typeof error == 'string') {
                            throw Error(error);
                        }
                    }
                    dispatch(Actions.loadedPanel(json));
                    return json;
                })
                    .catch((error) => dispatch(Actions.panelError(error, id, new CerumPanelRecord(id, '', '', '', String(error)))));
            });
        };
        Actions.loadedPanel = (panel) => ({
            type: CerumPanelStore.LOADED_PANEL,
            payload: { panel },
        });
        Actions.markAsRead = (id) => {
            return (dispatch) => __awaiter(this, void 0, void 0, function* () {
                let uri = yield CerumServices.instance().getRestBaseUrl();
                uri += '/publisering/markread/' + id;
                return Ajax.stdInstance()
                    .fetch(uri)
                    .then(handleErrors)
                    .then((res) => res.json())
                    .then((json) => {
                    if ('error' in json) {
                        throw Error(String(json.error));
                    }
                    dispatch(Actions.loadedPanel(json));
                    return json;
                })
                    .catch((error) => dispatch(Actions.panelError(error, id, new CerumPanelRecord(id, '', '', '', String(error)))));
            });
        };
        function handleErrors(response) {
            if (!response.ok) {
                throw Error(response.statusText);
            }
            return response;
        }
    })(Actions = CerumPanelStore.Actions || (CerumPanelStore.Actions = {}));
})(CerumPanelStore || (CerumPanelStore = {}));

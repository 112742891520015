var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { reduxBind } from 'badphraim/ui/LitReduxMixinBase';
import { DocumentViewer } from 'common-uicomponents/components/DocumentViewer';
import { OverlayWindow } from 'common-uicomponents/components/OverlayWindow';
import { LitElement } from 'lit';
import { customElement, state } from 'lit/decorators.js';
import { BoostPortalStore } from '../redux/BoostPortalStore';
import { PaymentConditionType } from '../interfaces/PaymentConditionType';
import { LitReduxBoostPortalMixin } from '../redux/LitReduxBoostPortalMixin';
let BoostPortalPaymentVippsCondition = class BoostPortalPaymentVippsCondition extends LitReduxBoostPortalMixin(LitElement) {
    static get is() {
        return 'boost-portal-payment-vipps-condition';
    }
    _paymentConditionsHandler() {
        return __awaiter(this, void 0, void 0, function* () {
            const paymentCondition = this.paymentCondition;
            if (!paymentCondition) {
                return;
            }
            if (paymentCondition.type === PaymentConditionType.PDF && paymentCondition.blob) {
                const pdfBlob = paymentCondition.blob;
                const documentUrl = URL.createObjectURL(pdfBlob);
                OverlayWindow.asyncInject({
                    title: 'Kjøpsvilkår Vipps',
                    componentTagName: DocumentViewer.is,
                    attributes: { documentUrl },
                    onClose: () => {
                        history.back();
                    },
                });
            }
            else if (paymentCondition.type === PaymentConditionType.REDIRECT && paymentCondition.url) {
                window.open(paymentCondition.url, '_blank');
            }
        });
    }
    /** @override */ updated(changedProperties) {
        changedProperties.forEach((_oldValue, propertyName) => {
            switch (propertyName) {
                case 'paymentCondition':
                    this._paymentConditionsHandler();
                    break;
            }
        });
        super.updated(changedProperties);
    }
};
__decorate([
    reduxBind(BoostPortalStore.path('paymentCondition')),
    state(),
    __metadata("design:type", Object)
], BoostPortalPaymentVippsCondition.prototype, "paymentCondition", void 0);
BoostPortalPaymentVippsCondition = __decorate([
    customElement(BoostPortalPaymentVippsCondition.is)
], BoostPortalPaymentVippsCondition);
export { BoostPortalPaymentVippsCondition };

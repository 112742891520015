import { ProjectNewInformation } from 'cerum-data/project/ProjectNewInformation';
import { CerumReduxSubStore } from './CerumReduxSubStore';
class ProjectNewStoreLayout {
    constructor() {
        this.project = new ProjectNewInformation();
        this.selectedParticipants = [];
        this.skipBoligmappa = false;
    }
}
export class ProjectNewStore extends CerumReduxSubStore {
    constructor() {
        super(...arguments);
        /** @override */ this.base_key = 'ProjectNewStore';
    }
    static get instance() {
        return this._instance || (this._instance = new ProjectNewStore());
    }
    static path(property) {
        return this.staticPath('ProjectNewStore', property);
    }
    /** @override */ reducer() {
        return {
            [this.base_key]: (state = new ProjectNewStoreLayout(), action) => {
                switch (action.type) {
                    case ProjectNewStore.Actions.SET_PROJECT:
                        return Object.assign(Object.assign({}, state), { project: action.payload.project });
                    case ProjectNewStore.Actions.SET_PHOTO:
                        return Object.assign(Object.assign({}, state), { photo: action.payload.photo });
                    case ProjectNewStore.Actions.SET_SELECTED_ADDRESS:
                        return Object.assign(Object.assign({}, state), { selectedAddress: action.payload.selectedAddress, selectedBoligmappaBuilding: undefined, selectedBoligmappaProperty: undefined });
                    case ProjectNewStore.Actions.SET_MANUAL_ADDRESS:
                        return Object.assign(Object.assign({}, state), { manualAddress: action.payload.manualAddress, selectedBoligmappaBuilding: undefined, selectedBoligmappaProperty: undefined });
                    case ProjectNewStore.Actions.TRUNCATE_PARTICIPANTS:
                        return Object.assign(Object.assign({}, state), { selectedParticipants: [] });
                    case ProjectNewStore.Actions.ADD_PARTICIPANT:
                        return Object.assign(Object.assign({}, state), { selectedParticipants: [...state.selectedParticipants, action.payload.participant] });
                    case ProjectNewStore.Actions.REMOVE_PARTICIPANT:
                        return Object.assign(Object.assign({}, state), { selectedParticipants: [
                                ...state.selectedParticipants.filter((participant) => participant.personId !== action.payload.participant.personId),
                            ] });
                    case ProjectNewStore.Actions.UPDATE_PARTICIPANT_ROLE:
                        return Object.assign(Object.assign({}, state), { selectedParticipants: state.selectedParticipants.map((participant, index) => {
                                if (index === action.payload.participantIndex) {
                                    return Object.assign(Object.assign({}, participant), { roleId: action.payload.roleId, roleName: action.payload.roleName });
                                }
                                return participant;
                            }) });
                    case ProjectNewStore.Actions.SET_SELECTED_BOLIGMAPPA_BUILDING:
                        return Object.assign(Object.assign({}, state), { selectedBoligmappaBuilding: action.payload.selectedBoligmappaBuilding });
                    case ProjectNewStore.Actions.SET_SELECTED_BOLIGMAPPA_PROPERTY:
                        return Object.assign(Object.assign({}, state), { selectedBoligmappaProperty: action.payload.selectedBoligmappaProperty });
                    case ProjectNewStore.Actions.SET_SKIP_BOLIGMAPPA:
                        return Object.assign(Object.assign({}, state), { skipBoligmappa: action.payload.skipBoligmappa });
                    case ProjectNewStore.Actions.SET_CADASTRAL_UNITS:
                        return Object.assign(Object.assign({}, state), { cadastralUnits: action.payload.cadastralUnits, selectedBoligmappaBuilding: undefined, selectedBoligmappaProperty: undefined });
                }
                return state;
            },
        };
    }
}
(function (ProjectNewStore) {
    let Actions;
    (function (Actions) {
        Actions.SET_PROJECT = 'SET_PROJECT';
        Actions.SET_PHOTO = 'SET_PHOTO';
        Actions.SET_SELECTED_ADDRESS = 'SET_SELECTED_ADDRESS';
        Actions.SET_MANUAL_ADDRESS = 'SET_MANUAL_ADDRESS';
        Actions.TRUNCATE_PARTICIPANTS = 'TRUNCATE_PARTICIPANTS';
        Actions.ADD_PARTICIPANT = 'ADD_PARTICIPANT';
        Actions.REMOVE_PARTICIPANT = 'REMOVE_PARTICIPANT';
        Actions.UPDATE_PARTICIPANT_ROLE = 'UPDATE_PARTICIPANT_ROLE';
        Actions.SET_SKIP_BOLIGMAPPA = 'SET_SKIP_BOLIGMAPPA';
        Actions.SET_CADASTRAL_UNITS = 'SET_CADASTRAL_UNITS';
        Actions.SET_SELECTED_BOLIGMAPPA_BUILDING = 'SET_SELECTED_BOLIGMAPPA_BUILDING';
        Actions.SET_SELECTED_BOLIGMAPPA_PROPERTY = 'SET_SELECTED_BOLIGMAPPA_PROPERTY';
        Actions.SetProject = (project) => ({
            type: Actions.SET_PROJECT,
            payload: {
                project,
            },
        });
        Actions.SetPhoto = (photo) => ({
            type: Actions.SET_PHOTO,
            payload: {
                photo,
            },
        });
        Actions.SetManualAddress = (manualAddress) => ({
            type: Actions.SET_MANUAL_ADDRESS,
            payload: {
                manualAddress,
            },
        });
        Actions.SetSelectedAddress = (selectedAddress) => ({
            type: Actions.SET_SELECTED_ADDRESS,
            payload: {
                selectedAddress,
            },
        });
        Actions.TruncateParticipants = () => ({
            type: Actions.TRUNCATE_PARTICIPANTS,
            payload: {},
        });
        Actions.AddParticipant = (participant) => ({
            type: Actions.ADD_PARTICIPANT,
            payload: {
                participant,
            },
        });
        Actions.RemoveParticipant = (participant) => ({
            type: Actions.REMOVE_PARTICIPANT,
            payload: {
                participant,
            },
        });
        Actions.UpdateParticipantRole = (participantIndex, roleId, roleName) => ({
            type: Actions.UPDATE_PARTICIPANT_ROLE,
            payload: {
                participantIndex,
                roleId,
                roleName,
            },
        });
        Actions.SetSelectedBoligmappaBuilding = (selectedBoligmappaBuilding) => ({
            type: Actions.SET_SELECTED_BOLIGMAPPA_BUILDING,
            payload: {
                selectedBoligmappaBuilding,
            },
        });
        Actions.SetSelectedBoligmappaProperty = (selectedBoligmappaProperty) => ({
            type: Actions.SET_SELECTED_BOLIGMAPPA_PROPERTY,
            payload: {
                selectedBoligmappaProperty,
            },
        });
        Actions.SetSkipBoligmappa = (skipBoligmappa) => ({
            type: Actions.SET_SKIP_BOLIGMAPPA,
            payload: {
                skipBoligmappa,
            },
        });
        Actions.SetCadastralUnits = (cadastralUnits) => ({
            type: Actions.SET_CADASTRAL_UNITS,
            payload: {
                cadastralUnits,
            },
        });
    })(Actions = ProjectNewStore.Actions || (ProjectNewStore.Actions = {}));
})(ProjectNewStore || (ProjectNewStore = {}));

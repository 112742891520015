var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { reduxBind } from 'badphraim/ui/LitReduxMixinBase';
import { css, html, LitElement } from 'lit';
import { customElement, state } from 'lit/decorators.js';
import { BoostPortalStore } from '../redux/BoostPortalStore';
import { LitReduxBoostPortalMixin } from '../redux/LitReduxBoostPortalMixin';
let BoostPortalMemberStandardProgress = class BoostPortalMemberStandardProgress extends LitReduxBoostPortalMixin(LitElement) {
    static get is() {
        return 'boost-portal-member-standard-progress';
    }
    static get styles() {
        return css `
            .progress {
                display: flex;
                align-items: center;
                margin: 20px 0;
            }

            .progress div:last-child {
                flex: 1;
            }

            .progress div:first-child {
                margin-right: 10px;
            }

            .progress-heading {
                font-size: 140%;
                font-weight: 300;
            }

            .progress-info {
                font-size: 120%;
                font-weight: 300;
                text-align: right;
            }
        `;
    }
    /** @override */ render() {
        return html `
            <div>
                ${this.selectedDepartmentId
            ? html `
                              <div class="progress">
                                  <div class="progress-heading">Valgt senter</div>
                                  <div class="progress-info">${this._getSelectedDepartment()}</div>
                              </div>
                          `
            : html ``}
                ${this.selectedMembershipId
            ? html `
                              <div class="progress">
                                  <div class="progress-heading">Valgt medlemskap</div>
                                  <div class="progress-info">${this._getSelectedMembership()}</div>
                              </div>
                          `
            : html ``}
                       ${this.memberInformation && this._isValidMemberInformation()
            ? html `
                                     <div class="progress">
                                         <div class="progress-heading">Gjelder medlem</div>
                                         <div class="progress-info">
                                             ${this.memberInformation.firstname}
                                             ${this.memberInformation.lastname},
                                             ${this.memberInformation.postal_code}
                                             ${this.memberInformation.municipality}
                                         </div>
                                     </div>
                                 `
            : html ``}
            </div>
                
             <hr />
            </div>`;
    }
    _getSelectedDepartment() {
        const selectedDepartment = this.departments &&
            this.departments.find((department) => department.id === this.selectedDepartmentId);
        if (!selectedDepartment) {
            return html ``;
        }
        return html ` ${selectedDepartment.navn} `;
    }
    _getSelectedMembership() {
        const selectedMembership = this.memberships &&
            this.memberships.find((membership) => membership.id === this.selectedMembershipId);
        if (!selectedMembership) {
            return html ``;
        }
        return html ` ${selectedMembership.title} `;
    }
    _isValidMemberInformation() {
        return (this.memberInformation &&
            this.memberInformation.firstname &&
            this.memberInformation.lastname &&
            this.memberInformation.address &&
            this.memberInformation.postal_code &&
            this.memberInformation.municipality &&
            this.memberInformation.phone_number &&
            this.memberInformation.email &&
            this.memberInformation.date_of_birth &&
            this.memberInformation.gender &&
            this.memberInformation.approve_tos);
    }
};
BoostPortalMemberStandardProgress._deps = [];
__decorate([
    reduxBind(BoostPortalStore.path('departments')),
    state(),
    __metadata("design:type", Array)
], BoostPortalMemberStandardProgress.prototype, "departments", void 0);
__decorate([
    reduxBind(BoostPortalStore.path('memberInformation')),
    state(),
    __metadata("design:type", Object)
], BoostPortalMemberStandardProgress.prototype, "memberInformation", void 0);
__decorate([
    reduxBind(BoostPortalStore.path('memberships')),
    state(),
    __metadata("design:type", Array)
], BoostPortalMemberStandardProgress.prototype, "memberships", void 0);
__decorate([
    reduxBind(BoostPortalStore.path('selectedDepartmentId')),
    state(),
    __metadata("design:type", Number)
], BoostPortalMemberStandardProgress.prototype, "selectedDepartmentId", void 0);
__decorate([
    reduxBind(BoostPortalStore.path('selectedMembershipId')),
    state(),
    __metadata("design:type", Number)
], BoostPortalMemberStandardProgress.prototype, "selectedMembershipId", void 0);
BoostPortalMemberStandardProgress = __decorate([
    customElement(BoostPortalMemberStandardProgress.is)
], BoostPortalMemberStandardProgress);
export { BoostPortalMemberStandardProgress };

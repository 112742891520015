var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { reduxBind } from 'badphraim/ui/LitReduxMixinBase';
import { isSuccessHTTP, isErrorHTTP } from 'boost-api/APIRestClient';
import { MembersignupAPI } from 'boost-api/boost/signup/membersignup/MembersignupAPI';
import { css, html, LitElement } from 'lit';
import { customElement, state } from 'lit/decorators.js';
import { BoostPortalStore } from '../redux/BoostPortalStore';
import { LitReduxBoostPortalMixin } from '../redux/LitReduxBoostPortalMixin';
import { router } from '../router/BoostPortalRouter';
import { BoostButton } from './common/BoostButton';
import { BoostStatusContainer } from './common/BoostStatusContainer';
import { TextField } from '@vaadin/text-field';
let BoostPortalLookupSponsor = class BoostPortalLookupSponsor extends LitReduxBoostPortalMixin(LitElement) {
    constructor() {
        super(...arguments);
        this.phoneNumber = '';
        this.sponsorHasBeenContacted = false;
    }
    static get is() {
        return 'boost-portal-lookup-sponsor';
    }
    static get styles() {
        return css `
            .flex {
                display: flex;
                flex-wrap: wrap;
            }

            .flex div {
                flex: 1;
            }

            .flex div:first-child {
                margin-right: 10px;
            }

            @media only screen and (max-width: 768px) {
                .flex div:first-child {
                    margin-right: 0px;
                }
            }

            vaadin-text-field,
            vaadin-number-field {
                width: 100%;
            }

            h4 {
                color: var(--portalForeground, #404040);
                font-weight: 500;
                margin-bottom: 10px;
            }

            .error {
                color: red;
            }
        `;
    }
    /** @override */ render() {
        return html `
            <div class="flex" style="margin-top: 20px;">
                <div>
                    <h4><b>Her skal du legge inn mobilnummeret til den som skal betale for deg.</b></h4>

                    <small>
                        Personen du sender melding til må være over 18 år. Mottaker får videre instruksjoner.
                    </small>
                </div>

                <div style="margin-top: 8px;">
                    <div style="display: flex; align-items: flex-end;">
                        <vaadin-text-field
                            label="Telefonnummer"
                            @value-changed="${(event) => {
            this.phoneNumber = event.detail.value;
        }}"
                        ></vaadin-text-field>

                        <boost-button
                            type="header-button"
                            name="Send SMS"
                            ?disabled="${this.phoneNumber === '' || this.sponsorHasBeenContacted}"
                            @click="${this._sponsorLookup}"
                        ></boost-button>
                    </div>
                </div>
            </div>

            ${this.sponsorLookupStatus
            ? html `
                      <boost-status-container type="${this.sponsorLookupStatus.type}">
                          ${this._getSponsorLookupText(this.sponsorLookupStatus.status)}
                      </boost-status-container>
                  `
            : html ``}
        `;
    }
    _sponsorLookup(event) {
        return __awaiter(this, void 0, void 0, function* () {
            const buttonElement = event.srcElement;
            if (buttonElement.disabled) {
                return;
            }
            if (!this.phoneNumber) {
                return;
            }
            if (!this.selectedDepartmentId || !(this.memberInformation && this.memberInformation.memberId)) {
                return;
            }
            this.sponsorLookupStatus = undefined;
            buttonElement.setLoading(true);
            const sponsorLookupResult = yield MembersignupAPI.getV1BoostsignupLookupSponsorUsingPhonenumber(this.phoneNumber, {
                departmentId: this.selectedDepartmentId,
                memberId: this.memberInformation.memberId,
                signupUrl: window.location.origin +
                    router.baseUrl +
                    this.centerShort +
                    '/sponsor?departmentId=' +
                    this.selectedDepartmentId,
                netsCallbackUrl: window.location.origin + router.baseUrl + this.centerShort + '/avtalegiro',
                acceptanceCallbackUrl: window.location.origin + router.baseUrl + this.centerShort + '/sponsor/accept',
                contractId: this.memberInformation.contractId,
            });
            if (isSuccessHTTP(sponsorLookupResult) && sponsorLookupResult) {
                this.sponsorHasBeenContacted = true;
                switch (sponsorLookupResult) {
                    case 'Sponsor registration requested.':
                    case 'Sponsor has not signed AG.':
                        this.sponsorLookupStatus = {
                            type: 'info',
                            status: 'notSigned',
                        };
                        break;
                    case 'Sponsor has signed.':
                        this.sponsorLookupStatus = {
                            type: 'info',
                            status: 'signed',
                        };
                        break;
                    default:
                        this.sponsorLookupStatus = {
                            type: 'danger',
                            status: 'unknown',
                        };
                }
            }
            else if (isErrorHTTP(sponsorLookupResult)) {
                if (sponsorLookupResult.error.http_status === 412 &&
                    sponsorLookupResult.error.message === 'Ugyldig sponsor') {
                    this.sponsorLookupStatus = {
                        type: 'danger',
                        status: 'illegal',
                    };
                }
                else {
                    this.sponsorLookupStatus = {
                        type: 'danger',
                        status: 'unknownError',
                        text: sponsorLookupResult.error.message,
                    };
                }
            }
            buttonElement.setLoading(false);
        });
    }
    _getSponsorLookupText(status) {
        var _a;
        switch (status) {
            case 'notSigned':
                return html `Det er sendt en SMS med instruksjoner til telefonnummeret du har oppgitt. Din
                    registrering er fullført. Når betaler har bekreftet vil du motta en SMS. <br />
                    Du kan nå lukke dette vinduet.`;
            case 'signed':
                return html `Det er sendt en SMS til telefonnummeret du har angitt. Din registrering er
                    fullført. Når betaler har bekreftet vil du motta en SMS. <br />
                    Du kan nå lukke dette vinduet.`;
            case 'illegal':
                return html `Beklager, personen du har søkt opp kan ikke stå som betaler for deg. Ta kontakt
                med senteret.`;
            case 'unknown':
                return html `Ukjent tilbakemelding fra server.`;
            case 'unknownError':
                return html `${(_a = this.sponsorLookupStatus) === null || _a === void 0 ? void 0 : _a.text}`;
        }
    }
};
BoostPortalLookupSponsor._deps = [BoostButton, BoostStatusContainer, TextField];
__decorate([
    reduxBind(BoostPortalStore.path('centerShort')),
    state(),
    __metadata("design:type", String)
], BoostPortalLookupSponsor.prototype, "centerShort", void 0);
__decorate([
    reduxBind(BoostPortalStore.path('memberInformation')),
    state(),
    __metadata("design:type", Object)
], BoostPortalLookupSponsor.prototype, "memberInformation", void 0);
__decorate([
    state(),
    __metadata("design:type", String)
], BoostPortalLookupSponsor.prototype, "phoneNumber", void 0);
__decorate([
    reduxBind(BoostPortalStore.path('selectedDepartmentId')),
    state(),
    __metadata("design:type", Number)
], BoostPortalLookupSponsor.prototype, "selectedDepartmentId", void 0);
__decorate([
    state(),
    __metadata("design:type", Object)
], BoostPortalLookupSponsor.prototype, "sponsorLookupStatus", void 0);
__decorate([
    state(),
    __metadata("design:type", Boolean)
], BoostPortalLookupSponsor.prototype, "sponsorHasBeenContacted", void 0);
BoostPortalLookupSponsor = __decorate([
    customElement(BoostPortalLookupSponsor.is)
], BoostPortalLookupSponsor);
export { BoostPortalLookupSponsor };

var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
/* tslint:disable: class-name */
/*
 * AUTOGENERATED FILE - DO NOT EDIT
 */
import { ewJSONInvoice2Line } from './ewJSONInvoice2Line';
import { JSONStruct, array_property, nullable, property } from 'badphraim/data/JSONStruct';
export class ewJSONInvoice2 extends JSONStruct {
    constructor() {
        super(...arguments);
        /** @override */ this.eier = 0;
        /** @override */ this.id = 0;
        /** @override */ this.linjer = [];
    }
}
__decorate([
    nullable,
    __metadata("design:type", String)
], ewJSONInvoice2.prototype, "URLtoPDF", void 0);
__decorate([
    nullable,
    __metadata("design:type", Number)
], ewJSONInvoice2.prototype, "avdeling", void 0);
__decorate([
    nullable,
    __metadata("design:type", String)
], ewJSONInvoice2.prototype, "avdelingtekst", void 0);
__decorate([
    nullable,
    __metadata("design:type", Number)
], ewJSONInvoice2.prototype, "avtalegiro_avtale", void 0);
__decorate([
    nullable,
    __metadata("design:type", Number)
], ewJSONInvoice2.prototype, "dato", void 0);
__decorate([
    property,
    __metadata("design:type", Number)
], ewJSONInvoice2.prototype, "eier", void 0);
__decorate([
    nullable,
    __metadata("design:type", Number)
], ewJSONInvoice2.prototype, "faktura_bunt", void 0);
__decorate([
    nullable,
    __metadata("design:type", Number)
], ewJSONInvoice2.prototype, "fakturanr", void 0);
__decorate([
    nullable,
    __metadata("design:type", String)
], ewJSONInvoice2.prototype, "fakturatype", void 0);
__decorate([
    nullable,
    __metadata("design:type", Number)
], ewJSONInvoice2.prototype, "forfall", void 0);
__decorate([
    nullable,
    __metadata("design:type", Number)
], ewJSONInvoice2.prototype, "frist", void 0);
__decorate([
    nullable,
    __metadata("design:type", Number)
], ewJSONInvoice2.prototype, "godkjent_av", void 0);
__decorate([
    nullable,
    __metadata("design:type", String)
], ewJSONInvoice2.prototype, "godkjent_avtekst", void 0);
__decorate([
    nullable,
    __metadata("design:type", Number)
], ewJSONInvoice2.prototype, "godkjent_dato", void 0);
__decorate([
    property,
    __metadata("design:type", Number)
], ewJSONInvoice2.prototype, "id", void 0);
__decorate([
    nullable,
    __metadata("design:type", String)
], ewJSONInvoice2.prototype, "kid", void 0);
__decorate([
    nullable,
    __metadata("design:type", Boolean)
], ewJSONInvoice2.prototype, "kredit", void 0);
__decorate([
    nullable,
    __metadata("design:type", Number)
], ewJSONInvoice2.prototype, "kunde", void 0);
__decorate([
    array_property(ewJSONInvoice2Line),
    __metadata("design:type", Array)
], ewJSONInvoice2.prototype, "linjer", void 0);
__decorate([
    nullable,
    __metadata("design:type", Number)
], ewJSONInvoice2.prototype, "mvaverdi", void 0);
__decorate([
    nullable,
    __metadata("design:type", Number)
], ewJSONInvoice2.prototype, "ordre", void 0);
__decorate([
    nullable,
    __metadata("design:type", Number)
], ewJSONInvoice2.prototype, "prosjekt", void 0);
__decorate([
    nullable,
    __metadata("design:type", String)
], ewJSONInvoice2.prototype, "ref", void 0);
__decorate([
    nullable,
    __metadata("design:type", Number)
], ewJSONInvoice2.prototype, "rest", void 0);
__decorate([
    nullable,
    __metadata("design:type", Number)
], ewJSONInvoice2.prototype, "status", void 0);
__decorate([
    nullable,
    __metadata("design:type", String)
], ewJSONInvoice2.prototype, "statustekst", void 0);
__decorate([
    nullable,
    __metadata("design:type", String)
], ewJSONInvoice2.prototype, "tekst", void 0);
__decorate([
    nullable,
    __metadata("design:type", String)
], ewJSONInvoice2.prototype, "var_ref", void 0);
__decorate([
    nullable,
    __metadata("design:type", Number)
], ewJSONInvoice2.prototype, "verdi", void 0);

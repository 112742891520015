export class CerumXState {
    static isCerumX() {
        let w = window;
        do {
            try {
                const cerumx = w.document.querySelectorAll('meta[name=cerumx]').item(0);
                if (cerumx) {
                    return Boolean(cerumx.getAttribute('content'));
                }
            }
            catch (e) {
                /** void  */
            }
            try {
                if (w.parent && w.parent !== w) {
                    w = w.parent;
                }
                else if (w.opener && w.opener !== w) {
                    w = w.opener;
                }
                else {
                    w = null;
                }
            }
            catch (e) {
                w = null;
            }
        } while (w);
        return false;
    }
}

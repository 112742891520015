var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { JSONStruct, property } from 'badphraim/data/JSONStruct';
export class ewRestRequestHandlerProjectView_DATA extends JSONStruct {
    constructor() {
        super(...arguments);
        /** @override */ this.boligmappa = false;
        /** @override */ this.byggsok = false;
        /** @override */ this.contracts = false;
        /** @override */ this.deviations = false;
        /** @override */ this.hours = false;
        /** @override */ this.invoices = false;
        /** @override */ this.onworksite = false;
        /** @override */ this.orders = false;
        /** @override */ this.progress = false;
        /** @override */ this.resources = false;
    }
}
__decorate([
    property,
    __metadata("design:type", Boolean)
], ewRestRequestHandlerProjectView_DATA.prototype, "boligmappa", void 0);
__decorate([
    property,
    __metadata("design:type", Boolean)
], ewRestRequestHandlerProjectView_DATA.prototype, "byggsok", void 0);
__decorate([
    property,
    __metadata("design:type", Boolean)
], ewRestRequestHandlerProjectView_DATA.prototype, "contracts", void 0);
__decorate([
    property,
    __metadata("design:type", Boolean)
], ewRestRequestHandlerProjectView_DATA.prototype, "deviations", void 0);
__decorate([
    property,
    __metadata("design:type", Boolean)
], ewRestRequestHandlerProjectView_DATA.prototype, "hours", void 0);
__decorate([
    property,
    __metadata("design:type", Boolean)
], ewRestRequestHandlerProjectView_DATA.prototype, "invoices", void 0);
__decorate([
    property,
    __metadata("design:type", Boolean)
], ewRestRequestHandlerProjectView_DATA.prototype, "onworksite", void 0);
__decorate([
    property,
    __metadata("design:type", Boolean)
], ewRestRequestHandlerProjectView_DATA.prototype, "orders", void 0);
__decorate([
    property,
    __metadata("design:type", Boolean)
], ewRestRequestHandlerProjectView_DATA.prototype, "progress", void 0);
__decorate([
    property,
    __metadata("design:type", Boolean)
], ewRestRequestHandlerProjectView_DATA.prototype, "resources", void 0);

var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { reduxBind } from 'badphraim/ui/LitReduxMixinBase';
import { css, html, LitElement } from 'lit';
import { customElement, state } from 'lit/decorators.js';
import { BoostPortalStore } from '../redux/BoostPortalStore';
import { LitReduxBoostPortalMixin } from '../redux/LitReduxBoostPortalMixin';
import { BoostPortalRegisterMemberView } from '../views/BoostPortalRegisterMemberView';
import { BoostPortalRegisterMemberViewWithPayment } from '../views/BoostPortalRegisterMemberWithPaymentView';
let BoostPortalVippsWrapper = class BoostPortalVippsWrapper extends LitReduxBoostPortalMixin(LitElement) {
    static get is() {
        return 'boost-portal-vipps-wrapper';
    }
    static get styles() {
        return css `
            :host {
                width: 90%;
            }
            @media only screen and (max-width: 1100px) {
                :host {
                    width: 96%;
                    padding: 1rem;
                }
            }
        `;
    }
    /** @override */ render() {
        var _a;
        return html `${((_a = this.center) === null || _a === void 0 ? void 0 : _a.use_direct_payment)
            ? html `<boost-portal-register-member-view-with-payment></boost-portal-register-member-view-with-payment>`
            : html `<boost-portal-register-member-view></boost-portal-register-member-view>`}`;
    }
};
BoostPortalVippsWrapper._deps = [BoostPortalRegisterMemberViewWithPayment, BoostPortalRegisterMemberView];
__decorate([
    reduxBind(BoostPortalStore.path('center')),
    state(),
    __metadata("design:type", Object)
], BoostPortalVippsWrapper.prototype, "center", void 0);
BoostPortalVippsWrapper = __decorate([
    customElement(BoostPortalVippsWrapper.is)
], BoostPortalVippsWrapper);
export { BoostPortalVippsWrapper };

var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { css, html, LitElement } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { SnackBar } from 'common-uicomponents/components/SnackBar';
import { ArticlesAPI } from 'cerumx-api/cerum/desktop/articles/ArticlesAPI';
import { isErrorHTTP, isSuccessHTTP } from 'cerumx-api/APIRestClient';
import { SelectedItem } from './SelectedItem';
import { Item } from '@vaadin/item';
import { ListBox } from '@vaadin/list-box';
import { multiSelectComboBoxRenderer, } from '@vaadin/multi-select-combo-box/lit.js';
import { MultiSelectComboBox, } from '@vaadin/multi-select-combo-box';
import { LoadingMixin } from './LoadingMixin';
import { NumberField } from '@vaadin/number-field';
import { ComboBox } from '@vaadin/combo-box';
import { comboBoxRenderer } from '@vaadin/combo-box/lit';
import { CerumFormLabel } from 'common-uicomponents/CerumFormLabel';
let CerumArticleLookup = class CerumArticleLookup extends LoadingMixin(LitElement) {
    constructor() {
        super(...arguments);
        this.primaryArticles = [];
        this.extraArticles = [];
        this.selectedExtraArticles = [];
        this.extraArticlesInput = [];
        this.disabled = false;
        this.errorMessage = '';
        this.required = false;
        this.filteredPrimaryArticles = [];
        this.filteredExtraArticles = [];
        this.primaryArticlesRenderer = (article) => {
            return html `<div>${article.article_number}/${article.name}</div>`;
        };
        this.extraArticlesRenderer = (article) => {
            return html `<div>${article.article_number}/${article.name}</div>`;
        };
    }
    static get is() {
        return 'cerum-article-lookup';
    }
    static get styles() {
        return css `
            vaadin-custom-field,
            vaadin-combo-box,
            vaadin-multi-select-combo-box {
                width: 100%;
            }

            .disabled {
                opacity: 0.4;
            }

            .selected-article {
                display: flex;
                flex-direction: row;
                background-color: #e7ebef;
                padding: 6px;
                padding-left: 1rem;
                border-radius: 6px;
                justify-content: space-between;
                align-items: center;
            }

            .selected-articles-container {
                display: flex;
                flex-direction: column;
                gap: 6px;
            }

            vaadin-number-field {
                text-align: center;
            }

            cerum-form-label {
                --cerum-form-label-font-size: 12px;
            }
        `;
    }
    connectedCallback() {
        super.connectedCallback();
        this._loadArticles();
    }
    /** @override */ render() {
        return html `
            ${this.isLoading
            ? this.showLoading()
            : html `<div>
                      <vaadin-combo-box
                          label="Hovedartikkel"
                          class="validate"
                          .required="${this.required}"
                          .disabled="${this.disabled}"
                          .items="${this.primaryArticles}"
                          .selectedItem="${this.selectedPrimaryArticle}"
                          error-message="${this.errorMessage}"
                          item-label-path="name"
                          item-value-path="id"
                          .filteredItems="${this.filteredPrimaryArticles.length
                ? this.filteredPrimaryArticles
                : undefined}"
                          @filter-changed="${(event) => this.filterPrimaryChanged(event)}"
                          @value-changed="${(event) => this._selectedArticleChanged(event)}"
                          ${comboBoxRenderer(this.primaryArticlesRenderer, [])}
                      ></vaadin-combo-box>

                      <vaadin-multi-select-combo-box
                          label="Ekstra artikler"
                          .items="${this.extraArticles}"
                          .selectedItems="${this.selectedExtraArticles}"
                          .disabled="${this.disabled}"
                          .filteredItems="${this.filteredPrimaryArticles.length
                ? this.filteredExtraArticles
                : undefined}"
                          selected-items-on-top
                          item-label-path="name"
                          item-id-path="id"
                          error-message="${this.errorMessage}"
                          @filter-changed="${(event) => this.filterExtraChanged(event)}"
                          @selected-items-changed="${(event) => this._selectedArticlesChanged(event)}"
                          ${multiSelectComboBoxRenderer(this.extraArticlesRenderer, [])}
                      ></vaadin-multi-select-combo-box>

                      ${this.selectedExtraArticles.length
                ? this._renderExtraArticlesWithAmountSelector()
                : ''}
                  </div>`}
        `;
    }
    _renderExtraArticlesWithAmountSelector() {
        return html `<div style="margin-left: 1rem; margin-bottom: 1rem;">
            <cerum-form-label>Valgte artikler</cerum-form-label>
            <div class="selected-articles-container">
                ${this.selectedExtraArticles
            .reduce((lhs, rhs) => {
            const foundArticle = lhs.find((item) => item.id === rhs.id);
            if (!foundArticle) {
                lhs.push(rhs);
            }
            return lhs;
        }, [])
            .map((selectedArticle) => {
            const articleResource = this._getArticleDataFromArticle(selectedArticle);
            const articleCount = this._getArticleCount(articleResource === null || articleResource === void 0 ? void 0 : articleResource.id);
            if (!(articleResource === null || articleResource === void 0 ? void 0 : articleResource.id)) {
                return html `<div class="selected-article">
                                Noe gikk feil! Finner ikke artikkel.
                            </div>`;
            }
            return html `<div class="selected-article">
                            <span>${articleResource.article_number + '/' + articleResource.name}</span>
                            <div style="white-space: nowrap;">
                                <cerum-form-label>(Velg eller skriv ønsket antall)</cerum-form-label>
                                <vaadin-number-field
                                    theme="small align-center"
                                    step="1"
                                    .value="${articleCount ? String(articleCount) : '0'}"
                                    step-buttons-visible
                                    @validated="${(event) => this._onNumberFieldValidated(event)}"
                                    @value-changed="${(event) => this._dispatchArticleCountChange(event, articleResource.id)}"
                                ></vaadin-number-field>
                            </div>
                        </div>`;
        })}
            </div>
        </div>`;
    }
    _onNumberFieldValidated(event) {
        const numberField = event.target;
        if (numberField && numberField.value.length) {
            numberField.removeAttribute('invalid');
        }
    }
    _dispatchArticleCountChange(event, articleId) {
        this.dispatchEvent(new CustomEvent('article-count-changed', {
            bubbles: true,
            composed: true,
            detail: {
                value: {
                    article_id: articleId,
                    count: Number(event.detail.value),
                },
            },
        }));
    }
    _getArticleCount(articleId) {
        var _a;
        if (!articleId) {
            return '0';
        }
        return (_a = this.extraArticlesInput.find((filterArticle) => filterArticle.article_id === articleId)) === null || _a === void 0 ? void 0 : _a.count;
    }
    filterPrimaryChanged(event) {
        const filter = event.detail.value;
        this.filteredPrimaryArticles = this.primaryArticles.filter(({ name, article_number: articleNumber }) => articleNumber.toLowerCase().startsWith(filter.toLowerCase()) ||
            name.toLowerCase().startsWith(filter.toLowerCase()));
    }
    filterExtraChanged(event) {
        const filter = event.detail.value;
        this.filteredExtraArticles = this.extraArticles.filter(({ name, article_number: articleNumber }) => articleNumber.toLowerCase().startsWith(filter.toLowerCase()) ||
            name.toLowerCase().startsWith(filter.toLowerCase()));
    }
    getArticle(articleId, articles) {
        return articles.find((article) => article.id === articleId);
    }
    getArticles(type) {
        if (type === 'primary') {
            return this.primaryArticles;
        }
        return this.extraArticles;
    }
    _getArticleDataFromArticle(article) {
        return this.getArticle(article.id, this.extraArticles);
    }
    _selectedArticlesChanged(event) {
        this.selectedExtraArticles = event.detail.value;
    }
    _selectedArticleChanged(event) {
        const article = this.getArticle(Number(event.detail.value), this.primaryArticles);
        if (article) {
            this.selectedPrimaryArticle = Object.assign(Object.assign({}, article), { resources: [] });
        }
    }
    _dispatchSelectedExtraArticles() {
        this.dispatchEvent(new CustomEvent('selected-extra-articles-changed', {
            bubbles: true,
            composed: true,
            detail: {
                value: this.selectedExtraArticles,
            },
        }));
    }
    _dispatchSelectedPrimaryArticle() {
        this.dispatchEvent(new CustomEvent('selected-primary-articles-changed', {
            bubbles: true,
            composed: true,
            detail: {
                value: this.selectedPrimaryArticle,
            },
        }));
    }
    _loadArticles() {
        return __awaiter(this, void 0, void 0, function* () {
            this.isLoading = true;
            const articlesResult = yield ArticlesAPI.getV1ArticlesResources({ active: true });
            if (isSuccessHTTP(articlesResult)) {
                this.primaryArticles = articlesResult.filter((article) => article.type === 1);
                this.extraArticles = articlesResult.filter((article) => article.type !== 1);
            }
            else if (isErrorHTTP(articlesResult)) {
                SnackBar.pushError(articlesResult.error);
            }
            this.isLoading = false;
        });
    }
    getSelectedRequiredArticle() {
        return this.selectedPrimaryArticle;
    }
    validateSelectedArticle() {
        var _a;
        const validateArticleField = (_a = this.shadowRoot) === null || _a === void 0 ? void 0 : _a.querySelector('.validate');
        if (!validateArticleField) {
            return false;
        }
        return Boolean(this.selectedPrimaryArticle);
    }
    /** @override */
    updated(changedProperties) {
        changedProperties.forEach((_oldValue, propertyName) => {
            switch (propertyName) {
                case 'selectedExtraArticles':
                    this._dispatchSelectedExtraArticles();
                    break;
                case 'selectedPrimaryArticle':
                    this._dispatchSelectedPrimaryArticle();
                    break;
            }
        });
        super.updated(changedProperties);
    }
};
CerumArticleLookup._deps = [
    SelectedItem,
    Item,
    ListBox,
    MultiSelectComboBox,
    ComboBox,
    NumberField,
    CerumFormLabel,
];
__decorate([
    state(),
    __metadata("design:type", Array)
], CerumArticleLookup.prototype, "primaryArticles", void 0);
__decorate([
    state(),
    __metadata("design:type", Array)
], CerumArticleLookup.prototype, "extraArticles", void 0);
__decorate([
    property({ type: Array }),
    __metadata("design:type", Array)
], CerumArticleLookup.prototype, "selectedExtraArticles", void 0);
__decorate([
    property({ type: Object }),
    __metadata("design:type", Object)
], CerumArticleLookup.prototype, "selectedPrimaryArticle", void 0);
__decorate([
    property({ type: Array }),
    __metadata("design:type", Array)
], CerumArticleLookup.prototype, "extraArticlesInput", void 0);
__decorate([
    property({ type: Boolean, attribute: 'disabled' }),
    __metadata("design:type", Boolean)
], CerumArticleLookup.prototype, "disabled", void 0);
__decorate([
    property({ type: String }),
    __metadata("design:type", String)
], CerumArticleLookup.prototype, "errorMessage", void 0);
__decorate([
    property({ type: Boolean }),
    __metadata("design:type", Boolean)
], CerumArticleLookup.prototype, "required", void 0);
__decorate([
    state(),
    __metadata("design:type", Array)
], CerumArticleLookup.prototype, "filteredPrimaryArticles", void 0);
__decorate([
    state(),
    __metadata("design:type", Array)
], CerumArticleLookup.prototype, "filteredExtraArticles", void 0);
CerumArticleLookup = __decorate([
    customElement(CerumArticleLookup.is)
], CerumArticleLookup);
export { CerumArticleLookup };

import { CerumReduxSubStore } from './CerumReduxSubStore';
import { ActiveDepartmentStore } from './ActiveDepartmentStore';
class CerumUserStoreLayout {
}
export class CerumUserStore extends CerumReduxSubStore {
    constructor() {
        super(...arguments);
        /** @override */ this.base_key = 'CerumUserStore';
    }
    static get instance() {
        return this._instance || (this._instance = new CerumUserStore());
    }
    static path(property) {
        return this.staticPath('CerumUserStore', property);
    }
    /** @override */ reducer() {
        return {
            [this.base_key]: (state = new CerumUserStoreLayout(), action) => {
                switch (action.type) {
                    case CerumUserStore.Actions.SET_USER_INFO:
                        const userInfo = action.payload.userInfo;
                        if (userInfo) {
                            window.setTimeout(() => {
                                this.dispatch(ActiveDepartmentStore.Actions.loadInitialReportingDepartmentFromUserinfo(userInfo));
                            });
                        }
                        return Object.assign(Object.assign({}, state), { userInfo: action.payload.userInfo });
                }
                return state;
            },
        };
    }
}
(function (CerumUserStore) {
    let Actions;
    (function (Actions) {
        Actions.SET_USER_INFO = 'SET_USER_INFO';
        Actions.SetUserInfo = (userInfo) => ({
            type: Actions.SET_USER_INFO,
            payload: {
                userInfo,
            },
        });
    })(Actions = CerumUserStore.Actions || (CerumUserStore.Actions = {}));
})(CerumUserStore || (CerumUserStore = {}));

var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { css, html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';
let BoostLoading = class BoostLoading extends LitElement {
    constructor() {
        super(...arguments);
        this.title = '';
    }
    static get is() {
        return 'boost-loading';
    }
    static get styles() {
        return css `
            .loader {
                border: 10px solid #b4ced5;
                border-radius: 50%;
                border-top: 10px solid #093845;
                width: 40px;
                height: 40px;
                -webkit-animation: spin 1s ease-in-out infinite;
                animation: spin 1s ease-in-out infinite;
                margin-bottom: 15px;
            }
            .container {
                display: flex;
                justify-content: center;
                flex-direction: column;
                align-items: center;
            }

            @-webkit-keyframes spin {
                0% {
                    -webkit-transform: rotate(0deg);
                }
                100% {
                    -webkit-transform: rotate(360deg);
                }
            }

            @keyframes spin {
                0% {
                    transform: rotate(0deg);
                }
                100% {
                    transform: rotate(360deg);
                }
            }
        `;
    }
    /** @override */ render() {
        return html ` <div class="container">
            <div class="loader"></div>
            <span>${this.title}</span>
        </div>`;
    }
};
BoostLoading._deps = [];
__decorate([
    property({ type: String }),
    __metadata("design:type", String)
], BoostLoading.prototype, "title", void 0);
BoostLoading = __decorate([
    customElement(BoostLoading.is)
], BoostLoading);
export { BoostLoading };

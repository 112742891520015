var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { CerumReduxSubStore } from './CerumReduxSubStore';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { CerumWorkScheduleDataProvider } from 'cerum-work-schedule-data/CerumWorkScheduleDataProvider';
import { WorkSchedulePortletStoreHelper } from './WorkSchedulePortletStoreHelper';
class SomeKindOfReduxStore extends CerumReduxSubStore {
    constructor(slice) {
        super();
        this._slice = slice;
    }
    getSlice() {
        return this._slice;
    }
}
export class WorkSchedulePortletStore extends SomeKindOfReduxStore {
    constructor() {
        super(...arguments);
        /** @override */ this.base_key = WorkSchedulePortletStore.storeName;
    }
    static get instance() {
        return (this._instance ||
            (this._instance = new WorkSchedulePortletStore(WorkSchedulePortletStore.createSlice())));
    }
    static get storeName() {
        return 'WorkSchedulePortletStore';
    }
    static path(property) {
        return this.staticPath(WorkSchedulePortletStore.storeName, property);
    }
    // eslint-disable-next-line @typescript-eslint/naming-convention
    static get Actions() {
        const { loadTaskDataForAssigneesInTimePeriod, reloadData } = this;
        return Object.assign(Object.assign({}, this.instance.getSlice().actions), { loadTaskDataForAssigneesInTimePeriod,
            reloadData });
    }
    /** @override */ reducer() {
        return {
            [this.base_key]: this.getSlice().reducer,
        };
    }
    static createSlice() {
        return createSlice({
            name: WorkSchedulePortletStore.storeName,
            initialState: {
                metrics: [],
                selectedFilters: [],
                availableFilters: [],
                deletedTasks: [],
            },
            reducers: {
                setMetrics(state, action) {
                    state.metrics = action.payload;
                },
                getPreviousQuery(state) {
                    const query = state.previousQuery;
                    state.previousQuery = query;
                },
                setPreviousQuery(state, action) {
                    const previousQuery = action.payload;
                    state.previousQuery = previousQuery;
                },
                setIsDataAggregated(state, action) {
                    const isDataAggregated = action.payload;
                    state.isDataAggregated = isDataAggregated;
                },
                setSelectedFilters(state, action) {
                    const selectedFilters = action.payload;
                    state.selectedFilters = selectedFilters;
                },
                setAvailableFilters(state, action) {
                    const availableFilters = action.payload;
                    state.availableFilters = availableFilters;
                },
                addSelectedFilter(state, action) {
                    const selectedFilter = action.payload;
                    state.selectedFilters = [...state.selectedFilters, selectedFilter];
                },
                addDeletedTask(state, action) {
                    const deletedTasks = action.payload;
                    state.deletedTasks = [...state.deletedTasks, ...deletedTasks];
                },
                setSharedScheduledRequestResult(state, action) {
                    state.sharedScheduledRequestResult = action.payload;
                },
            },
            extraReducers: (builder) => {
                const { loadTaskDataForAssigneesInTimePeriod } = WorkSchedulePortletStore;
                builder.addCase(loadTaskDataForAssigneesInTimePeriod.fulfilled, (state, action) => {
                    state.aggregatedData = action.payload;
                });
            },
        });
    }
    static getReduxState(thunkAPI) {
        const state = thunkAPI.getState();
        if (state && typeof state == 'object' && this.storeName in state) {
            return state[this.storeName];
        }
        throw new Error('redux state bad');
    }
    static get reloadData() {
        return createAsyncThunk('tasks/reload', (_, thunkAPI) => __awaiter(this, void 0, void 0, function* () {
            const { previousQuery } = this.getReduxState(thunkAPI);
            if (previousQuery) {
                thunkAPI.dispatch(this.Actions.loadTaskDataForAssigneesInTimePeriod(previousQuery));
            }
            else {
                // WHAT
                console.error('Kan ikke reloade????');
            }
        }));
    }
    static get loadTaskDataForAssigneesInTimePeriod() {
        return createAsyncThunk('tasks/loadTaskDataForAssigneesInTimePeriod', (parameters, thunkAPI) => __awaiter(this, void 0, void 0, function* () {
            const { selectedFilters, deletedTasks } = this.getReduxState(thunkAPI);
            thunkAPI.dispatch(WorkSchedulePortletStore.Actions.setIsDataAggregated(false));
            const dataProvider = new CerumWorkScheduleDataProvider();
            const tasksResult = yield dataProvider.loadTaskDataForAssigneesInTimePeriod({
                assignees: parameters.assignees,
                start: parameters.start,
                stop: parameters.end,
                showCancelledTasks: parameters.showCancelledTasks,
                allowSimulatedTasks: parameters.allowSimulatedTasks,
            });
            (() => __awaiter(this, void 0, void 0, function* () {
                const data = this.helper.getAggregateTaskMetrics(tasksResult, false, parameters.assignees, parameters.includeDuplicateTasks, deletedTasks);
                const availableFilters = [
                    ...new Set(data.flatMap((metric) => metric.name)),
                ];
                let filteredFilters = [];
                for (const selectedFilter of selectedFilters) {
                    if (!availableFilters.includes(selectedFilter)) {
                        filteredFilters = selectedFilters.filter((selectedFilter2) => selectedFilter === selectedFilter2);
                    }
                }
                if (filteredFilters.length) {
                    thunkAPI.dispatch(WorkSchedulePortletStore.Actions.setSelectedFilters(filteredFilters));
                }
                thunkAPI.dispatch(WorkSchedulePortletStore.Actions.setMetrics(data));
                thunkAPI.dispatch(WorkSchedulePortletStore.Actions.setPreviousQuery(parameters));
                thunkAPI.dispatch(WorkSchedulePortletStore.Actions.setIsDataAggregated(true));
                thunkAPI.dispatch(WorkSchedulePortletStore.Actions.setAvailableFilters(availableFilters));
            }))();
            return tasksResult;
        }));
    }
}
WorkSchedulePortletStore.helper = new WorkSchedulePortletStoreHelper();

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import moment from 'moment';
import { RenholdAPI } from 'cerumx-api/cerum/desktop/renhold/RenholdAPI';
import { isErrorHTTP, isSuccessHTTP } from 'cerumx-api/APIRestClient';
import { WorkScheduleDataWorkerWrapper } from './worker/WorkScheduleDataWorkerWrapper';
import { CerumWorkScheduleDataHelper } from './CerumWorkScheduleDataHelper';
import { PersonAPI } from 'cerumx-api/cerum/desktop/person/PersonAPI';
import { TaskStatus } from 'cerum-work-schedule-defs/TaskStatus';
import { WorkplanningTaskHelper } from './WorkplanningTaskHelper';
export class CerumWorkScheduleDataProvider {
    constructor() {
        this.workerWrapper = new WorkScheduleDataWorkerWrapper();
        this.taskHelper = new WorkplanningTaskHelper();
        this.viewConfigurationCache = {};
        this.dataHelper = new CerumWorkScheduleDataHelper();
    }
    prepareAssignedAndPlannerPrepareTasks(inTasks, currentMoment, startTime, endTime) {
        return __awaiter(this, void 0, void 0, function* () {
            const assignedTasks = yield this.prepareAssignedTasks(inTasks);
            const helper = new CerumWorkScheduleDataHelper();
            const viewConfiguration = {
                day: currentMoment.clone().startOf('day'),
                startTimeMoment: currentMoment.clone().startOf('day').add(startTime, 'hour'),
                endTimeMoment: currentMoment
                    .clone()
                    .startOf('day')
                    .add(endTime + 1, 'hour'),
            };
            /**
             * TODO Finn ut om dette faktisk kan ligg her, eller om det må workerifiseres
             */
            return assignedTasks.map((task) => helper.plannerPrepareAssignedTask(viewConfiguration, task));
        });
    }
    prepareAssignedTasks(inTasks) {
        return __awaiter(this, void 0, void 0, function* () {
            const tasks = inTasks.filter((task) => task.assignee);
            /*.filter((task) => {
                    const sameDay = moment(task.start).startOf('day').isSame(currentMoment);
    
                    return task.start && sameDay;
                })*/
            /*.sort((lhs: IWorkplanningTaskSummary, rhs: IWorkplanningTaskSummary) =>
                    moment(lhs.start).isBefore(moment(rhs.start)) ? 0 : 1
                );*/
            const parallelism = this.workerWrapper.concurrency;
            const groupSize = Math.ceil(tasks.length / parallelism);
            const pending = [];
            for (let i = 0; i < parallelism; i++) {
                const group = tasks.slice(i * groupSize, i * groupSize + groupSize);
                pending.push(this.workerWrapper.sendRequest({
                    tasks: group,
                }));
            }
            const responses = yield Promise.all(pending);
            /*
            const viewConfiguration: ViewMatrixConfiguration = {
                day: currentMoment.clone().startOf('day'),
    
                startTimeMoment: currentMoment.clone().startOf('day').add(startTime, 'hour'),
                endTimeMoment: currentMoment
                    .clone()
                    .startOf('day')
                    .add(endTime + 1, 'hour'),
            };*/
            // this.getCachedViewConfigurationForDay();
            //const helper = new CerumWorkScheduleDataHelper();
            let assignedTasks = [];
            for (const sets of responses) {
                assignedTasks = assignedTasks.concat(sets.expanded.map((transferrable) => {
                    const task = this.taskHelper.mapTransferrableToTask(transferrable);
                    return task;
                    //return helper.plannerPrepareAssignedTask(viewConfiguration, task);
                }));
            }
            return this.groupTasksByAssignee(assignedTasks);
        });
    }
    groupTasksByAssignee(assignedTasks) {
        var _a;
        const groupedTasks = {};
        assignedTasks.sort((lhs, rhs) => (lhs.taskStart.isSameOrBefore(rhs.taskStart) ? -1 : 1));
        for (const assignedTask of assignedTasks) {
            const assigneeId = (_a = assignedTask.assignee) === null || _a === void 0 ? void 0 : _a.data_id;
            if (!assigneeId) {
                continue;
            }
            if (!(assigneeId in groupedTasks)) {
                groupedTasks[assigneeId] = [];
            }
            groupedTasks[assigneeId].push(assignedTask);
        }
        for (const assigneeId in groupedTasks) {
            /*const oneAssigneesTasks = groupedTasks[assigneeId].sort((lhs, rhs) =>
                lhs.taskStart.isSameOrBefore(rhs.taskStart) ? -1 : 1
            );*/
            this._levelTasks(groupedTasks[assigneeId]);
        }
        return this.dataHelper.filterTasks(assignedTasks); // .sort((lhs, rhs) => (lhs.taskStart.isSameOrBefore(rhs.taskStart) ? -1 : 1));
    }
    _levelTasks(tasks) {
        const doubleMappedList = [];
        /**
         * Create a sortable list with entries for both start an stop-time
         * for easier column-level-assignments in the section below
         */
        for (const assignedTask of tasks) {
            const startEntry = {
                timeKey: assignedTask.taskStart.unix(),
                task: assignedTask,
                mode: 'start',
            };
            doubleMappedList.push(startEntry);
            const endEntry = {
                timeKey: assignedTask.taskEnd.unix(),
                task: assignedTask,
                mode: 'stop',
            };
            doubleMappedList.push(endEntry);
        }
        doubleMappedList.sort((aEntry, bEntry) => aEntry.timeKey - bEntry.timeKey);
        let colPositionNValue = 0;
        /*
         * Uses a bitmask to mark which column the tasks are placed in (level).
         * some bitmingling to find the first available column for each `start`-event
         *
         * const bitValue = ~colPositionNValue & -~colPositionNValue;
         *
         * This returns rightmost zero-bit in the bit-mask, hence the first available
         * column
         *
         * The bitmask is mirrored as the rightmost bit is the first column in the
         * "indenting"-scheme
         */
        for (const entry of doubleMappedList) {
            switch (entry.mode) {
                case 'start':
                    const bitValue = ~colPositionNValue & -~colPositionNValue;
                    entry.task.level = Math.log2(bitValue);
                    colPositionNValue |= bitValue;
                    break;
                case 'stop':
                    const stopBitValue = Math.pow(2, entry.task.level);
                    colPositionNValue &= ~stopBitValue;
                    break;
                default:
                    throw Error('Illegal state');
            }
        }
        return tasks;
    }
    loadUnassignedTasksData(currentMoment) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.loadUnassignedTasksDataForTimePeriod(currentMoment, currentMoment);
        });
    }
    loadUnassignedTasksDataForTimePeriod(start, stop) {
        return __awaiter(this, void 0, void 0, function* () {
            const startTime = start.format('YYYY-MM-DD');
            const endTime = stop.format('YYYY-MM-DD');
            // Send request for unassigned tasks
            const unassignedTasksResult = yield RenholdAPI.getV1SafiWorkplanningTasksUnassigned({
                start: startTime,
                stop: endTime,
            });
            if (isErrorHTTP(unassignedTasksResult)) {
                throw unassignedTasksResult;
            }
            return unassignedTasksResult;
        });
    }
    /**
     *
     */
    loadTaskDataForAssignees(assignees, currentMoment, _startTime, _endTime, showCancelledTasks) {
        return __awaiter(this, void 0, void 0, function* () {
            // query
            // results
            const start = currentMoment.format('YYYY-MM-DD');
            const stop = start;
            let numberOfCancelledTasks = 0;
            let tasks = [];
            let assignedTasks = [];
            let perAssigneeWorkhours = [];
            if (!assignees.length) {
                console.log('Nullstilt pga ingen brukere valgt');
            }
            else {
                ({ numberOfCancelledTasks, assignedTasks, tasks, perAssigneeWorkhours } =
                    yield this.loadAndPrepareTasksForAssignees(assignees, currentMoment, start, stop, showCancelledTasks));
            }
            // Send request for workhours
            let pendingFixedHoursResult;
            let hoursPerPerson = [];
            if (assignees.length) {
                const start = currentMoment.clone().startOf('day');
                const stop = currentMoment.clone().endOf('day');
                pendingFixedHoursResult = PersonAPI.getV1PersonsWorkhours({
                    start: start.unix(),
                    stop: stop.unix(),
                    person_ids: assignees.map((assignee) => assignee.id),
                });
                hoursPerPerson = yield this.innerLoadFixedWorkHours(assignees, pendingFixedHoursResult, perAssigneeWorkhours, start, stop);
            }
            tasks = this.dataHelper.filterTasks(tasks);
            return {
                numberOfCancelledTasks,
                tasks,
                assignedTasks,
                hoursPerPerson,
            };
        });
    }
    loadTaskDataForContactInTimePeriod(parameters) {
        return this._loadAndPrepareTasksForContactInTimePeriod(parameters);
    }
    loadTaskDataForAssigneesInTimePeriod(parameters) {
        return __awaiter(this, void 0, void 0, function* () {
            // query
            // results
            const { start, stop, assignees, showCancelledTasks, allowSimulatedTasks, groupRequests } = parameters;
            const startTime = start;
            const endTime = stop;
            let numberOfCancelledTasks = 0;
            let tasks = [];
            let assignedTasks = undefined;
            let perAssigneeWorkhours = [];
            if (!assignees.length) {
                tasks = [];
                console.log('Nullstilt pga ingen brukere valgt');
                assignedTasks = [];
                perAssigneeWorkhours = [];
            }
            else {
                ({ numberOfCancelledTasks, assignedTasks, tasks, perAssigneeWorkhours } =
                    yield this.loadAndPrepareTasksForAssigneesInTimePeriod({
                        assignees,
                        start: startTime,
                        stop: endTime,
                        showCancelledTasks,
                        allowSimulatedTasks,
                        groupRequests,
                    }));
            }
            // Send request for workhours
            let pendingFixedHoursResult;
            let hoursPerPerson = [];
            if (assignees.length) {
                pendingFixedHoursResult = PersonAPI.getV1PersonsWorkhours({
                    start: start.unix(),
                    stop: stop.unix(),
                    person_ids: assignees.map((assignee) => assignee.id),
                });
                hoursPerPerson = yield this.innerLoadFixedWorkHours(assignees, pendingFixedHoursResult, perAssigneeWorkhours, start, stop);
            }
            return {
                numberOfCancelledTasks,
                tasks,
                assignedTasks,
                hoursPerPerson,
            };
        });
    }
    loadAndPrepareTasksForAssignees(assignees, currentMoment, start, stop, showCancelledTasks) {
        return __awaiter(this, void 0, void 0, function* () {
            let tasks = [];
            let assignedTasks = undefined;
            const perAssigneeWorkhoursMap = {};
            let numberOfCancelledTasks = 0;
            const groups = [];
            let assigneeNodeIds = assignees.map((assignee) => assignee.node_id);
            const sliceSize = Math.max(Math.ceil(assigneeNodeIds.length / 6), 1);
            while (assigneeNodeIds.length) {
                groups.push(assigneeNodeIds.slice(0, sliceSize));
                assigneeNodeIds = assigneeNodeIds.slice(sliceSize);
            }
            let useSimulatedTasks = false;
            if (currentMoment.isSameOrAfter(moment().add(2, 'weeks'), 'days')) {
                useSimulatedTasks = true;
            }
            const allTaskRequests = groups.map((nodeIds) => __awaiter(this, void 0, void 0, function* () {
                let tasksResult = [];
                let simulatedTasks = [];
                if (useSimulatedTasks) {
                    const simulatedTasksResult = yield RenholdAPI.postV1SafiWorkplanningTasksAutoassign({
                        start,
                        stop,
                        node_ids: nodeIds,
                        test: true,
                    });
                    if (isSuccessHTTP(simulatedTasksResult)) {
                        simulatedTasks = this.mapSimulatedTasks(simulatedTasksResult.assigned_tasks);
                    }
                    if (isErrorHTTP(tasksResult)) {
                        throw tasksResult;
                    }
                }
                tasksResult = yield RenholdAPI.getV1SafiWorkplanningTasks({
                    node_ids: nodeIds,
                    start,
                    stop,
                });
                if (isErrorHTTP(tasksResult)) {
                    throw tasksResult;
                }
                tasksResult = [...tasksResult, ...simulatedTasks];
                if (!tasksResult.length) {
                    return [[], []];
                }
                numberOfCancelledTasks += tasksResult.filter((task) => task.completion_status === 'CANCELLED').length;
                const tasks = tasksResult.filter((task) => showCancelledTasks ? true : task.completion_status !== 'CANCELLED');
                const assignedTasksResult = yield this.workerWrapper.sendRequest({
                    tasks,
                    // currentMoment: currentMoment.toISOString(),
                    // startTime,
                    // endTime,
                });
                const assignedTasks = assignedTasksResult.expanded.map((transferrableAssignedTask) => {
                    const assignedTask = this.taskHelper.mapTransferrableToTask(transferrableAssignedTask);
                    this.updateWorkHourMap(perAssigneeWorkhoursMap, assignedTask);
                    return assignedTask;
                });
                return [tasks, assignedTasks];
            }));
            const allTaskResults = yield Promise.all(allTaskRequests);
            assignedTasks = [];
            for (const [tasksSet, assignedTasksSet] of allTaskResults) {
                tasks = tasks.concat(tasksSet);
                assignedTasks = assignedTasks.concat(assignedTasksSet);
            }
            const perAssigneeWorkhours = Object.values(perAssigneeWorkhoursMap);
            return {
                numberOfCancelledTasks,
                assignedTasks: this.groupTasksByAssignee(assignedTasks),
                tasks,
                perAssigneeWorkhours,
            };
        });
    }
    countCancelledTasksInList(
    // eslint-disable-next-line @typescript-eslint/naming-convention
    cancelledTasks) {
        return cancelledTasks.filter((task) => task.completion_status !== TaskStatus.CANCELLED).length;
    }
    _allowSimulatedTasks(selectedWeek, currentMomentInTwoWeeks = moment().clone().add(2, 'weeks')) {
        if (moment(selectedWeek).isSameOrAfter(currentMomentInTwoWeeks, 'isoWeek')) {
            return true;
        }
        return false;
    }
    _loadAndPrepareTasksForContactInTimePeriod(parameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const { contactId, start, stop, showCancelledTasks, allowSimulatedTasks } = parameters;
            const perAssigneeWorkhoursMap = {};
            let useSimulatedTasks = false;
            const currentMoment = moment(start);
            if (currentMoment.isSameOrAfter(moment().add(2, 'weeks'), 'days') && allowSimulatedTasks) {
                useSimulatedTasks = true;
            }
            const allTaskRequests = [
                this._loadTaskRequests(useSimulatedTasks, [contactId], start, stop, showCancelledTasks, perAssigneeWorkhoursMap),
            ];
            return yield this._prepareTasksFromRequests(allTaskRequests, perAssigneeWorkhoursMap);
        });
    }
    loadAndPrepareTasksForAssigneesInTimePeriod(parameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const { start, stop, assignees, showCancelledTasks, allowSimulatedTasks, groupRequests } = parameters;
            const perAssigneeWorkhoursMap = {};
            const groups = [];
            let assigneeNodeIds = assignees.map((assignee) => assignee.node_id);
            if (!groupRequests) {
                const sliceSize = Math.max(Math.ceil(assigneeNodeIds.length / 6), 1);
                while (assigneeNodeIds.length) {
                    groups.push(assigneeNodeIds.slice(0, sliceSize));
                    assigneeNodeIds = assigneeNodeIds.slice(sliceSize);
                }
            }
            let useSimulatedTasks = false;
            const currentMoment = moment(start);
            if (currentMoment.isSameOrAfter(moment().add(2, 'weeks'), 'days') && allowSimulatedTasks) {
                useSimulatedTasks = true;
            }
            let allTaskRequests;
            if (groupRequests) {
                allTaskRequests = [
                    this._loadTaskRequests(useSimulatedTasks, assigneeNodeIds, start, stop, showCancelledTasks, perAssigneeWorkhoursMap),
                ];
            }
            else {
                allTaskRequests = groups.map((nodeIds) => __awaiter(this, void 0, void 0, function* () {
                    return yield this._loadTaskRequests(useSimulatedTasks, nodeIds, start, stop, showCancelledTasks, perAssigneeWorkhoursMap);
                }));
            }
            return yield this._prepareTasksFromRequests(allTaskRequests, perAssigneeWorkhoursMap);
        });
    }
    _prepareTasksFromRequests(allTaskRequests, perAssigneeWorkhoursMap) {
        return __awaiter(this, void 0, void 0, function* () {
            let tasks = [];
            let assignedTasks = undefined;
            const allTaskResults = yield Promise.all(allTaskRequests);
            let numberOfCancelledTasks = 0;
            allTaskResults.map((result) => {
                const [_one, _two, three] = result;
                return (numberOfCancelledTasks += three);
            });
            assignedTasks = [];
            for (const [tasksSet, assignedTasksSet] of allTaskResults) {
                tasks = tasks.concat(tasksSet);
                assignedTasks = assignedTasks.concat(assignedTasksSet);
            }
            const perAssigneeWorkhours = Object.values(perAssigneeWorkhoursMap);
            return {
                numberOfCancelledTasks,
                assignedTasks: this.groupTasksByAssignee(assignedTasks),
                tasks,
                perAssigneeWorkhours,
            };
        });
    }
    _loadTaskRequests(useSimulatedTasks, nodeIds, start, stop, showCancelledTasks, perAssigneeWorkhoursMap) {
        return __awaiter(this, void 0, void 0, function* () {
            let tasksResult = [];
            let numberOfCancelledTasks = 0;
            if (useSimulatedTasks) {
                tasksResult = yield this._loadSimulatedTasks(start, stop, nodeIds);
            }
            else {
                tasksResult = yield RenholdAPI.getV1SafiWorkplanningTasks({
                    node_ids: nodeIds,
                    start: start.toISOString(),
                    stop: stop.toISOString(),
                });
                if (isErrorHTTP(tasksResult)) {
                    throw tasksResult;
                }
            }
            if (!tasksResult.length) {
                return [[], [], numberOfCancelledTasks];
            }
            numberOfCancelledTasks += tasksResult.filter((task) => task.completion_status === 'CANCELLED').length;
            const tasks = this.dataHelper.filterTasks(tasksResult.filter((task) => (showCancelledTasks ? true : task.completion_status !== 'CANCELLED')));
            const assignedTasksResult = yield this.workerWrapper.sendRequest({
                tasks,
            });
            const assignedTasks = assignedTasksResult.expanded.map((transferrableAssignedTask) => {
                const assignedTask = this.taskHelper.mapTransferrableToTask(transferrableAssignedTask);
                this.updateWorkHourMap(perAssigneeWorkhoursMap, assignedTask);
                return assignedTask;
            });
            return [tasks, assignedTasks, numberOfCancelledTasks];
        });
    }
    _loadSimulatedTasks(start, stop, nodeIds) {
        return __awaiter(this, void 0, void 0, function* () {
            const simulatedTasksResult = yield RenholdAPI.postV1SafiWorkplanningTasksAutoassign({
                start: start.toISOString(),
                stop: stop.toISOString(),
                node_ids: nodeIds,
                test: true,
            });
            if (isSuccessHTTP(simulatedTasksResult)) {
                return this.mapSimulatedTasks(simulatedTasksResult.assigned_tasks);
            }
            return [];
        });
    }
    mapSimulatedTasks(simulatedTasks) {
        return simulatedTasks.map((task) => {
            var _a, _b, _c;
            const { name, single } = task;
            const assignee = {
                data_id: (_a = task.person_id) !== null && _a !== void 0 ? _a : -1,
                datatype_id: -1,
                id: -1,
                name: '',
                node_id: -1,
            };
            const client = {
                name: (_b = task.resource_name) !== null && _b !== void 0 ? _b : '',
                contact_id: -1,
                contact_node_id: (_c = task.resource_node_id) !== null && _c !== void 0 ? _c : -1,
            };
            return {
                assignees: [],
                assignee,
                client,
                completion_status: TaskStatus.SIMULATED,
                duration_in_minutes: task.duration_in_minutes,
                id: '-1',
                name: name !== null && name !== void 0 ? name : '',
                start: task.planned_time,
                single,
                deviations: [],
                is_static: false,
                is_absence: false,
                exact_time_requirement: false,
            };
        });
    }
    updateWorkHourMap(perAssigneeWorkhoursMap, assignedTask) {
        var _a;
        const personId = (_a = assignedTask.assignee) === null || _a === void 0 ? void 0 : _a.data_id;
        if (personId && assignedTask.durationPerPersonInMinutes) {
            const date = assignedTask.taskStart.format('Y-M-D');
            const assignedWorkhourKey = personId + '-' + date;
            if (!(assignedWorkhourKey in perAssigneeWorkhoursMap)) {
                perAssigneeWorkhoursMap[assignedWorkhourKey] = {
                    personId,
                    date,
                    hours: 0.0,
                };
            }
            perAssigneeWorkhoursMap[assignedWorkhourKey].hours +=
                assignedTask.durationPerPersonInMinutes / 60;
        }
    }
    loadTasksForTimePeriod(selectedWeek, assignee, startTime, endTime) {
        return __awaiter(this, void 0, void 0, function* () {
            const currentMomentInTwoWeeks = moment().clone().add(2, 'weeks');
            const useSimulatedTasks = this._allowSimulatedTasks(selectedWeek, currentMomentInTwoWeeks);
            this.viewConfigurationCache = {};
            const perAssigneeWorkhoursMap = {};
            let assignedTasks = [];
            let assignedWorkHours = [];
            const start = moment(selectedWeek).startOf('isoWeek').format('YYYY-MM-DD');
            const stop = moment(selectedWeek).endOf('isoWeek').format('YYYY-MM-DD');
            const pendingRequests = [];
            if (useSimulatedTasks) {
                pendingRequests.push(this._loadSimulatedTasksForTimePeriod({
                    start,
                    stop,
                    personIDs: [assignee.id],
                    perAssigneeWorkhoursMap,
                    startTime,
                    endTime,
                    includeDuplicateTasks: false,
                }));
            }
            pendingRequests.push(this._loadTasksForPeriod({
                start,
                stop,
                startTime,
                nodeIDs: [assignee.node_id],
                endTime,
                perAssigneeWorkhoursMap,
                includeDuplicateTasks: false,
            }));
            const tasksResults = yield Promise.all(pendingRequests);
            for (const tasksResult of tasksResults) {
                assignedTasks = [...assignedTasks, ...tasksResult.tasks];
                assignedWorkHours = [...assignedWorkHours, ...tasksResult.perAssigneeWorkhours];
            }
            return { tasks: assignedTasks, perAssigneeWorkhours: assignedWorkHours };
        });
    }
    _loadTasksForPeriod(parameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const { start, stop, nodeIDs, startTime, endTime, perAssigneeWorkhoursMap } = parameters;
            if (!(nodeIDs === null || nodeIDs === void 0 ? void 0 : nodeIDs.length)) {
                throw new Error('nodeIDs can not be empty');
            }
            const tasksResult = yield RenholdAPI.getV1SafiWorkplanningTasks({
                node_ids: nodeIDs,
                start,
                stop,
            });
            if (isErrorHTTP(tasksResult)) {
                throw tasksResult;
            }
            const aggregatedData = this._aggregateTasksAndWorkHours(tasksResult, startTime, endTime, perAssigneeWorkhoursMap);
            return aggregatedData;
        });
    }
    _loadSimulatedTasksForTimePeriod(parameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const { start, stop, personIDs, startTime, endTime, perAssigneeWorkhoursMap } = parameters;
            if (!(personIDs === null || personIDs === void 0 ? void 0 : personIDs.length)) {
                throw new Error('personIds can not be empty');
            }
            const simulatedTasksResult = yield RenholdAPI.postV1SafiWorkplanningTasksAutoassign({
                start,
                stop,
                person_id: personIDs,
                test: true,
            });
            if (isSuccessHTTP(simulatedTasksResult)) {
                const tasks = this.mapSimulatedTasks(simulatedTasksResult.assigned_tasks).map((task) => {
                    var _a;
                    const viewConfiguration = this.getCachedViewConfigurationForDay(
                    // FIXME hva gjør vi med tasks uten start? Kan de i det hele tatt ha det?
                    (_a = task.start) !== null && _a !== void 0 ? _a : '', startTime, endTime);
                    const assignedTask = this.dataHelper.prepareAssignedTask(task);
                    const assignedTaskAndPlannerPrepared = this.dataHelper.plannerPrepareAssignedTask(viewConfiguration, assignedTask);
                    this.updateWorkHourMap(perAssigneeWorkhoursMap, assignedTaskAndPlannerPrepared);
                    return assignedTask;
                });
                const perAssigneeWorkhours = Object.values(perAssigneeWorkhoursMap);
                return { tasks: this.dataHelper.filterTasks(tasks), perAssigneeWorkhours };
            }
            throw simulatedTasksResult.error;
        });
    }
    enumerateDaysBetweenDates(start, stop) {
        var _a;
        const dates = [];
        const currentMoment = moment(start).startOf('day');
        const endMoment = moment(stop).startOf('day');
        while (currentMoment.add(1, 'days').diff(endMoment) < 0) {
            dates.push(currentMoment.clone());
        }
        const stopMoment = (_a = dates.sort((dateOne, dateTwo) => (dateOne.isAfter(dateTwo, 'day') ? 1 : -1)).pop()) !== null && _a !== void 0 ? _a : start;
        return { start, stop: stopMoment };
    }
    _aggregateTasksAndWorkHours(tasksResult, startTime, endTime, perAssigneeWorkhoursMap) {
        let tasks = tasksResult
            .filter((task) => task.completion_status !== 'CANCELLED')
            .map((task) => {
            var _a;
            const viewConfiguration = this.getCachedViewConfigurationForDay(
            // FIXME hva gjør vi med tasks uten start? Kan de i det hele tatt ha det?
            (_a = task.start) !== null && _a !== void 0 ? _a : '', startTime, endTime);
            const aTask = this.dataHelper.prepareAssignedTask(task);
            const bTask = this.dataHelper.plannerPrepareAssignedTask(viewConfiguration, aTask);
            this.updateWorkHourMap(perAssigneeWorkhoursMap, bTask);
            return aTask;
        });
        const perAssigneeWorkhours = Object.values(perAssigneeWorkhoursMap);
        tasks = this.dataHelper.filterTasks(tasks);
        return { tasks, perAssigneeWorkhours };
    }
    getCachedViewConfigurationForDay(taskStart, startTime, endTime) {
        const key = taskStart + '_' + startTime + '_' + endTime;
        if (key in this.viewConfigurationCache) {
            return this.viewConfigurationCache[key];
        }
        const startOfDay = moment(taskStart).startOf('day');
        const viewConfiguration = {
            day: startOfDay,
            startTimeMoment: startOfDay.clone().add(startTime, 'hour'),
            endTimeMoment: startOfDay.clone().add(endTime + 1, 'hour'),
        };
        return (this.viewConfigurationCache[key] = viewConfiguration);
    }
    /**
     *
     */
    loadFixedWorkHours(assignees, start, stop, perAssigneeWorkhours) {
        return __awaiter(this, void 0, void 0, function* () {
            const pendingWorkhourRequest = PersonAPI.getV1PersonsWorkhours({
                start: start.unix(),
                stop: stop.unix(),
                person_ids: assignees.map((assignee) => assignee.id),
            });
            return this.innerLoadFixedWorkHours(assignees, pendingWorkhourRequest, perAssigneeWorkhours, start, stop);
        });
    }
    innerLoadFixedWorkHours(assignees, pendingWorkhourRequest, perAssigneeWorkhours, start, stop) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!assignees.length) {
                return [];
            }
            const days = [];
            /**
             * Traverse each day in the interval
             */
            for (let dayMoment = start.clone().startOf('day'); dayMoment.isBefore(stop); dayMoment = dayMoment.add(1, 'day')) {
                days.push({
                    iso8601date: dayMoment.format('Y-M-D'),
                    unix: dayMoment.unix(),
                });
            }
            const aggregatedPersonHoursMap = {};
            /**
             * Traverse each person and prepare data
             */
            for (const assignee of assignees) {
                const personId = assignee.data_id;
                const personAggregate = {
                    personId,
                    days: [],
                };
                for (const day of days) {
                    const assigneeWorkhours = perAssigneeWorkhours.find((perAssigneeWorkhour) => perAssigneeWorkhour.personId == personId &&
                        perAssigneeWorkhour.date == day.iso8601date);
                    if (!assigneeWorkhours) {
                        continue;
                    }
                    const dayWithTotal = {
                        availableWorkHours: 0,
                        totalAssignedTaskHours: assigneeWorkhours.hours,
                        date: day.unix,
                        holiday: false,
                    };
                    personAggregate.days.push(dayWithTotal);
                }
                aggregatedPersonHoursMap[personId] = personAggregate;
                // void
            }
            // Collect request result
            const fixedHoursResult = yield pendingWorkhourRequest;
            if (isErrorHTTP(fixedHoursResult)) {
                throw fixedHoursResult;
            }
            // Merge aggregated and fixedhours
            fixedHoursResult.map((fixedHours) => {
                const personId = fixedHours.person_id;
                if (!(personId in aggregatedPersonHoursMap)) {
                    console.error('Fikk personer i fixedhours responsen som vi ikke ba om??', personId);
                    return;
                }
                const aggregatedEntry = aggregatedPersonHoursMap[personId];
                for (const inDay of fixedHours.days) {
                    const dayEntry = aggregatedEntry.days.find((aggregateDay) => aggregateDay.date == inDay.date);
                    if (dayEntry) {
                        dayEntry.availableWorkHours = inDay.hours;
                        dayEntry.expenses = inDay.expenses;
                        dayEntry.holiday = inDay.holiday;
                        continue;
                    }
                    const newdayEntry = Object.assign(Object.assign({}, inDay), { availableWorkHours: inDay.hours, totalAssignedTaskHours: 0 });
                    aggregatedEntry.days.push(newdayEntry);
                }
            });
            return Object.values(aggregatedPersonHoursMap);
        });
    }
}

export class CerumXRouterConfig {
    static isEnabled() {
        if (!top) {
            return false;
        }
        const setting = top.localStorage.getItem(this.key);
        if (setting && setting.length) {
            return Boolean(parseInt(setting, 10));
        }
        return true;
    }
    static setEnabled(b) {
        if (top) {
            top.localStorage.setItem(this.key, b ? '1' : '0');
        }
    }
}
CerumXRouterConfig.key = 'CerumXRouter.enabled';

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { CerumReduxSubStore } from './CerumReduxSubStore';
import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { DepartmentsAPI } from 'cerumx-api/cerum/desktop/departments/DepartmentsAPI';
import { isErrorHTTP } from 'cerumx-api/APIRestClient';
class SomeKindOfReduxStore extends CerumReduxSubStore {
    constructor(slice) {
        super();
        this._slice = slice;
    }
    getSlice() {
        return this._slice;
    }
}
const setReportingDepartment = createAsyncThunk('department/setReportingDepartment', (activeDepartmentId, _thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    const setReportingDepartmentResult = yield DepartmentsAPI.putV1DepartmentsReportingUsingDepartmentid(activeDepartmentId !== null && activeDepartmentId !== void 0 ? activeDepartmentId : 0);
    return setReportingDepartmentResult;
}));
const loadInitialReportingDepartmentFromUserinfo = createAction('department/loadInitialReportingDepartmentFromUserinfo');
const fetchReportingDepartmentData = createAsyncThunk('department/fetchReportingDepartmentData', (departmentId, _thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    return DepartmentsAPI.getV1DepartmentsUsingDepartmentid(departmentId);
}));
export class ActiveDepartmentStore extends SomeKindOfReduxStore {
    constructor() {
        super(...arguments);
        /** @override */ this.base_key = ActiveDepartmentStore.storeName;
    }
    static get instance() {
        return (this._instance ||
            (this._instance = new ActiveDepartmentStore(ActiveDepartmentStore.createSlice())));
    }
    static get storeName() {
        return 'ActiveDepartmentStore';
    }
    static path(property) {
        return this.staticPath(ActiveDepartmentStore.storeName, property);
    }
    // eslint-disable-next-line @typescript-eslint/naming-convention
    static get Actions() {
        return Object.assign(Object.assign({}, this.instance.getSlice().actions), { setReportingDepartment,
            loadInitialReportingDepartmentFromUserinfo, fetchReportingDepartment: fetchReportingDepartmentData });
    }
    /** @override */ reducer() {
        return {
            [this.base_key]: this.getSlice().reducer,
        };
    }
    static createSlice() {
        return createSlice({
            name: ActiveDepartmentStore.storeName,
            initialState: {},
            // void
            reducers: {
                setActiveDepartment(state, action) {
                    // void
                    const [departmentId, departmentName, departmentContactId] = action.payload;
                    state.activeDepartmentId = departmentId;
                    state.activeDepartmentName = departmentName;
                    state.activeDepartmentContactId = departmentContactId;
                },
            },
            extraReducers: (builder) => {
                builder.addCase(setReportingDepartment.fulfilled, (state, action) => {
                    var _a;
                    if (isErrorHTTP(action.payload)) {
                        throw new Error(action.payload.error.message);
                    }
                    state.activeDepartmentId = (_a = action.payload.department_id) !== null && _a !== void 0 ? _a : state.activeDepartmentId;
                });
                builder.addCase(loadInitialReportingDepartmentFromUserinfo, (state, action) => {
                    var _a;
                    const { payload } = action;
                    if (payload.current_reporting_department) {
                        state.activeDepartmentId =
                            (_a = action.payload.current_reporting_department_id) !== null && _a !== void 0 ? _a : undefined;
                        const { activeDepartmentId } = state;
                        if (activeDepartmentId) {
                            window.setTimeout(() => {
                                this.instance.dispatch(fetchReportingDepartmentData(activeDepartmentId));
                            }, 0);
                        }
                    }
                });
                builder.addCase(fetchReportingDepartmentData.fulfilled, (state, action) => {
                    if (isErrorHTTP(action.payload)) {
                        throw new Error(action.payload.error.message);
                    }
                    const departmentData = action.payload;
                    const { id, name, contactID } = departmentData;
                    if (!id) {
                        return;
                    }
                    if (!state.activeDepartmentId || id == state.activeDepartmentId) {
                        state.activeDepartmentId = id;
                        state.activeDepartmentName = name !== null && name !== void 0 ? name : undefined;
                        state.activeDepartmentContactId = contactID !== null && contactID !== void 0 ? contactID : undefined;
                    }
                });
            },
        });
    }
}

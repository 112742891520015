var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { css, html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';
let EitherOrSpacer = class EitherOrSpacer extends LitElement {
    constructor() {
        super(...arguments);
        this.text = '';
    }
    static get is() {
        return 'either-or-spacer';
    }
    static get styles() {
        return css `
            .container {
                display: flex;
                justify-content: space-evenly;
                align-items: center;
                margin-bottom: 14px;
            }

            hr {
                height: 1px;
                border: none;
                background-color: var(--cerum-grey-lighter, #ddd);
            }

            .text {
                margin: 0px 8px;
                font-size: var(--text-2xs);
                font-weight: var(--font-medium);
                text-transform: uppercase;
                color: var(--cerum-grey-lighter);
            }
        `;
    }
    /** @override */ render() {
        return html `
            <div class="container">
                <div style="flex: 1">
                    <hr />
                </div>
                <div class="text">${this.text ? this.text : 'eller'}</div>
                <div style="flex: 1">
                    <hr />
                </div>
            </div>
        `;
    }
};
EitherOrSpacer._deps = [];
__decorate([
    property({ type: String, attribute: 'text' }),
    __metadata("design:type", String)
], EitherOrSpacer.prototype, "text", void 0);
EitherOrSpacer = __decorate([
    customElement(EitherOrSpacer.is)
], EitherOrSpacer);
export { EitherOrSpacer };

var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { css, html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';
let SelectedItem = class SelectedItem extends LitElement {
    constructor() {
        super(...arguments);
        this.disabled = false;
    }
    static get is() {
        return 'selected-item';
    }
    static get styles() {
        return css `
            .container {
                background-color: #fff;
                display: flex;
                justify-content: space-between;
                align-items: center;
                border: 1px solid hsl(214, 90%, 52%);
                border-radius: 8px;
                padding: 8px 14px;
                margin-bottom: var(--bottom-margin, 0);
            }

            .close {
                background-color: hsl(213, 14%, 89%);
                border-radius: 50%;
                min-width: 30px;
                min-height: 30px;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
            }

            .close svg {
                stroke: #000;
            }

            .container.enabled:hover {
                cursor: pointer;
            }

            .container.enabled:hover .close {
                background-color: hsl(3, 100%, 76%);
            }

            .container.enabled:hover .close svg {
                stroke: hsl(360, 76%, 20%);
            }
        `;
    }
    /** @override */ render() {
        return html `
            <div
                class="container ${this.disabled ? '' : 'enabled'}"
                @click="${() => {
            if (!this.disabled) {
                this.dispatchEvent(new CustomEvent('deselect', {
                    detail: {},
                    bubbles: true,
                    composed: true,
                }));
            }
        }}"
            >
                <slot></slot>
                ${!this.disabled
            ? html `<div class="close">
                          <svg
                              xmlns="http://www.w3.org/2000/svg"
                              version="1.1"
                              xmlns:xlink="http://www.w3.org/1999/xlink"
                              xmlns:svgjs="http://svgjs.com/svgjs"
                              viewBox="0 0 140 140"
                              width="16"
                              height="16"
                          >
                              <g transform="matrix(5.833333333333333,0,0,5.833333333333333,0,0)">
                                  <path
                                      d="M0.75 23.249L23.25 0.749"
                                      fill="none"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      stroke-width="2"
                                  ></path>
                                  <path
                                      d="M23.25 23.249L0.75 0.749"
                                      fill="none"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      stroke-width="2"
                                  ></path>
                              </g>
                          </svg>
                      </div>`
            : ''}
            </div>
        `;
    }
};
SelectedItem._deps = [];
__decorate([
    property({ type: Boolean, attribute: 'disabled' }),
    __metadata("design:type", Boolean)
], SelectedItem.prototype, "disabled", void 0);
SelectedItem = __decorate([
    customElement(SelectedItem.is)
], SelectedItem);
export { SelectedItem };

export class BoostPortalStartupHandler {
    static replaceContents(contents) {
        const startupInnerElement = document.querySelector('#startup-inner');
        if (!startupInnerElement) {
            return;
        }
        startupInnerElement.innerHTML = contents;
    }
    static removeLoadingScreen() {
        const startupElement = document.querySelector('#startup');
        if (!startupElement) {
            return;
        }
        document.body.classList.remove('no-scroll');
        startupElement.classList.add('visuallyhidden');
        startupElement.addEventListener('transitionend', () => {
            startupElement.classList.add('hidden');
        }, {
            capture: false,
            once: true,
            passive: false,
        });
    }
}

import { CerumReduxSubStore } from './CerumReduxSubStore';
class CerumOverviewStoreLayout {
    constructor() {
        this.contactId = 0;
        this.nodeId = 0;
    }
}
export class CerumOverviewStore extends CerumReduxSubStore {
    constructor() {
        super(...arguments);
        /** @override */ this.base_key = 'CerumOverviewStore';
    }
    static get instance() {
        return this._instance || (this._instance = new CerumOverviewStore());
    }
    static path(property) {
        return this.staticPath('CerumOverviewStore', property);
    }
    /** @override */ reducer() {
        return {
            [this.base_key]: (state = new CerumOverviewStoreLayout(), action) => {
                switch (action.type) {
                    case CerumOverviewStore.Actions.SET_CONTACT_ID:
                        return Object.assign(Object.assign({}, state), { contactId: action.payload.contactId });
                    case CerumOverviewStore.Actions.SET_NODE_ID:
                        return Object.assign(Object.assign({}, state), { nodeId: action.payload.nodeId });
                }
                return state;
            },
        };
    }
}
(function (CerumOverviewStore) {
    let Actions;
    (function (Actions) {
        Actions.SET_CONTACT_ID = 'SET_CONTACT_ID';
        Actions.SET_NODE_ID = 'SET_NODE_ID';
        Actions.setContactId = (contactId) => ({
            type: Actions.SET_CONTACT_ID,
            payload: {
                contactId,
            },
        });
        Actions.setNodeId = (nodeId) => ({
            type: Actions.SET_NODE_ID,
            payload: {
                nodeId,
            },
        });
    })(Actions = CerumOverviewStore.Actions || (CerumOverviewStore.Actions = {}));
})(CerumOverviewStore || (CerumOverviewStore = {}));

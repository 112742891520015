/*

live-feed og session-holde-åpner

*/
export class GlobalWindowPropertyHelper {
    static get(property, default_value) {
        let w = this.getAccesibleTop();
        let val;
        let prev_w = w;
        let cnt = 0;
        do {
            try {
                val = this._getHelper(w, property);
            }
            catch (e) {
                val = undefined;
                w = prev_w;
                break;
            }
            if (val === undefined && window.opener) {
                if (w.document.head.querySelector('link[rel="c:baseurl"]')) {
                    // Vi er i hovedframen i zoot, ikke klatre ut window.opener
                    break;
                }
                else if (w.document.head.querySelector('meta[name="cerumx"][content="1"]')) {
                    // Vi er i hovedframen til cerumx, ikke klatre ut window.opener
                    break;
                }
                else {
                    prev_w = w;
                    w = window.opener.top;
                }
            }
            else {
                break;
            }
            if (cnt++ > 10) {
                break;
            }
        } while (val === undefined);
        if (val === undefined && w) {
            val = this._getHelper(w, property, default_value);
        }
        if (val === undefined && default_value !== undefined) {
            throw new Error('Gikk meg vill');
        }
        return val;
    }
    static _getHelper(win, property, default_value) {
        let hash;
        if ('_glob_hash' in win) {
            hash = win._glob_hash;
            if (property in hash) {
                return hash[property];
            }
        }
        if (default_value !== undefined) {
            if (!hash) {
                hash = win._glob_hash = {};
            }
            hash[property] = default_value;
            return hash[property];
        }
        return undefined;
    }
    static getAccesibleTop() {
        try {
            if (top && top.document.location) {
                return top;
            }
        }
        catch (e) {
            // void
        }
        let w = window;
        let last;
        do {
            last = w;
            try {
                w = w.parent;
                w.document;
            }
            catch (e) {
                return last;
            }
            last = w;
        } while (last !== w);
        return last;
    }
}

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Ajax as badphraim_ajax } from 'badphraim/ajax/Ajax';
import { SingletonInstanceLike } from 'badphraim/core/Singleton';
import { Dom } from 'badphraim/dom/Dom';
import { BoostPortalTokenContainer } from './BoostPortalTokenContainer';
export class BoostPortalAjax extends badphraim_ajax {
    static stdInstance() {
        return SingletonInstanceLike(BoostPortalAjax, () => {
            return new BoostPortalAjax(new Dom(document));
        });
    }
    /** @override */ _sendRequest(xhrq, url, data, loadingBoxHandle, scopes) {
        const _super = Object.create(null, {
            _sendRequest: { get: () => super._sendRequest }
        });
        return __awaiter(this, void 0, void 0, function* () {
            const token = yield this.getToken();
            if (token) {
                xhrq.setRequestHeader('Authorization', 'Bearer ' + token);
            }
            return _super._sendRequest.call(this, xhrq, url, data, loadingBoxHandle, scopes);
        });
    }
    getToken() {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.token) {
                return this.token;
            }
            if (BoostPortalTokenContainer.isLoadingToken) {
                return;
            }
            return (this.token = yield BoostPortalTokenContainer.token.getValue());
        });
    }
}

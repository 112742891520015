var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
/* tslint:disable: class-name */
/*
 * AUTOGENERATED FILE - DO NOT EDIT
 */
import { Attachment } from './Attachment';
import { MessageSender } from './MessageSender';
import { JSONStruct, array_property, property } from 'badphraim/data/JSONStruct';
export class Message extends JSONStruct {
    constructor() {
        super(...arguments);
        /** @override */ this.attachments = [];
        /** @override */ this.conversation_id = 0;
        /** @override */ this.from = new MessageSender();
        /** @override */ this.id = 0;
        /** @override */ this.sent_by_user = false;
        /** @override */ this.status = '';
        /** @override */ this.text = '';
        /** @override */ this.timestamp = '';
    }
}
__decorate([
    array_property(Attachment),
    __metadata("design:type", Array)
], Message.prototype, "attachments", void 0);
__decorate([
    property,
    __metadata("design:type", Number)
], Message.prototype, "conversation_id", void 0);
__decorate([
    property,
    __metadata("design:type", MessageSender)
], Message.prototype, "from", void 0);
__decorate([
    property,
    __metadata("design:type", Number)
], Message.prototype, "id", void 0);
__decorate([
    property,
    __metadata("design:type", Boolean)
], Message.prototype, "sent_by_user", void 0);
__decorate([
    property,
    __metadata("design:type", String)
], Message.prototype, "status", void 0);
__decorate([
    property,
    __metadata("design:type", String)
], Message.prototype, "text", void 0);
__decorate([
    property,
    __metadata("design:type", String)
], Message.prototype, "timestamp", void 0);

var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { reduxBind } from 'badphraim/ui/LitReduxMixinBase';
import { isSuccessHTTP } from 'boost-api/APIRestClient';
import { MembersignupAPI } from 'boost-api/boost/signup/membersignup/MembersignupAPI';
import { css, html, LitElement } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { BoostPortalStore } from '../redux/BoostPortalStore';
import { LitReduxBoostPortalMixin } from '../redux/LitReduxBoostPortalMixin';
import { SnackBar } from 'common-uicomponents/components/SnackBar';
import { BoostPortalParamsHandler } from '../helpers/BoostPortalParamsHandler';
import { TrackingFactorySingleton } from '../tracking/TrackingFactorySingleton';
import { Select } from '@vaadin/select';
import { TrackingEvent } from '../tracking/TrackingEvent';
let BoostPortalChooseDepartment = class BoostPortalChooseDepartment extends LitReduxBoostPortalMixin(LitElement) {
    constructor() {
        super(...arguments);
        this.departments = [];
        this.availableDepartments = [];
        this.lockedToDepartment = false;
        this.isCreateProfileView = false;
        this.disabled = false;
    }
    static get is() {
        return 'boost-portal-choose-department';
    }
    static get styles() {
        return css `
            vaadin-select,
            vaadin-text-field {
                width: 100%;
            }

            h4 {
                color: var(--portalForeground, #404040);
                font-weight: 500;
                margin-bottom: 10px;
            }

            vaadin-text-field[id='department-name'] > input:disabled {
                --lumo-disabled-text-color: #262626;
            }
        `;
    }
    /** @override */ render() {
        return html `
            ${this.selectedDepartmentId
            ? html `<div style="margin-bottom:25px;"></div>`
            : html `${this.isCreateProfileView
                ? html `<h4 style="padding:16px 0;">Velg senter for å opprette profil</h4>`
                : html `<h4 style="padding:16px 0;">
                            Velg senter for å få tilgjengelige medlemskap
                        </h4>`}`}

            <div>${this._renderDepartmentSelection(this.departments)}</div>
        `;
    }
    /** @override */ updated(changedProperties) {
        changedProperties.forEach((_oldValue, propertyName) => {
            switch (propertyName) {
                case 'token':
                    this._tokenObserver();
                    break;
            }
        });
        super.updated(changedProperties);
    }
    _renderDepartmentSelection(departments) {
        var _a, _b;
        if (departments.length === 1) {
            return html `<vaadin-text-field
                id="department-name"
                value="${this.departments[0].navn}, ${(_a = this.departments[0].adresse) !== null && _a !== void 0 ? _a : ''}"
                disabled
            ></vaadin-text-field>`;
        }
        return html ` <vaadin-select
            placeholder="Velg ditt senter"
            value="${(_b = this.selectedDepartmentId) !== null && _b !== void 0 ? _b : ''}"
            @value-changed="${this._selectedDepartment}"
            .renderer="${this._departmentsSelectRenderer(this.departments)}"
            ?disabled="${this.disabled}"
        ></vaadin-select>`;
    }
    _departmentsSelectRenderer(departments) {
        return (root) => {
            if (root.firstChild) {
                return;
            }
            const listBox = document.createElement('vaadin-list-box');
            departments.forEach((department) => {
                var _a, _b;
                const item = document.createElement('vaadin-item');
                item.setAttribute('value', String((_a = department.id) !== null && _a !== void 0 ? _a : ''));
                if (this.availableDepartments.length) {
                    if (department.id && this.availableDepartments.indexOf(department.id) == -1) {
                        item.setAttribute('disabled', 'disabled');
                    }
                }
                else if (this.lockedToDepartment && this.selectedDepartmentId) {
                    if (department.id !== this.selectedDepartmentId) {
                        item.setAttribute('disabled', 'disabled');
                    }
                }
                if (department.navn && department.adresse && department.sted) {
                    item.textContent = department.navn + ', ' + department.adresse + ' ' + department.sted;
                    listBox.appendChild(item);
                }
                else if (department.navn && department.adresse && !department.sted) {
                    item.textContent = department.navn + ', ' + department.adresse;
                    listBox.appendChild(item);
                }
                else if (department.navn && !department.adresse && !department.sted) {
                    item.textContent = department.navn;
                    listBox.appendChild(item);
                }
                else {
                    item.textContent = (_b = department.navn) !== null && _b !== void 0 ? _b : '';
                    listBox.appendChild(item);
                }
            });
            root.appendChild(listBox);
        };
    }
    _selectedDepartment(event) {
        return __awaiter(this, void 0, void 0, function* () {
            var _a, _b;
            const selectedDepartmentId = parseInt(event.detail.value, 0);
            if (!selectedDepartmentId) {
                return;
            }
            if (selectedDepartmentId != this.selectedDepartmentId) {
                this.dispatch(BoostPortalStore.Actions.SetSelectedMembershipId());
                const departmentName = (_b = (_a = this.departments.find((department) => department.id === selectedDepartmentId)) === null || _a === void 0 ? void 0 : _a.navn) !== null && _b !== void 0 ? _b : '';
                // Tracking events må legges inn på strategiske plasser i løypen
                TrackingFactorySingleton.getTracker().completedEvent(TrackingEvent.SelectedDepartment, {
                    departmentId: selectedDepartmentId,
                    departmentName,
                });
            }
            this.dispatch(BoostPortalStore.Actions.SetSelectedDepartmentId(selectedDepartmentId));
            const departmentId = BoostPortalParamsHandler.getParameter('departmentId');
            if (departmentId) {
                this.dispatch(BoostPortalStore.Actions.SetSelectedMembershipId());
            }
        });
    }
    _tokenObserver() {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.token) {
                return;
            }
            const departmentsResult = yield MembersignupAPI.getV1BoostsignupDepartments();
            if (isSuccessHTTP(departmentsResult)) {
                if (this.availableDepartments.length === 1) {
                    const availableDepartment = departmentsResult.find((department) => department.id === this.availableDepartments[0]);
                    if (availableDepartment && availableDepartment.id) {
                        this.dispatch(BoostPortalStore.Actions.SetSelectedDepartmentId(availableDepartment.id));
                    }
                }
                else {
                    if (departmentsResult.length === 1 && departmentsResult[0].id) {
                        this.dispatch(BoostPortalStore.Actions.SetSelectedDepartmentId(departmentsResult[0].id));
                    }
                }
                this.dispatch(BoostPortalStore.Actions.SetDepartments(departmentsResult));
            }
            else {
                SnackBar.pushError(departmentsResult.error);
            }
            const departmentId = BoostPortalParamsHandler.getParameter('departmentId');
            if (departmentId && parseInt(departmentId, 0)) {
                this.dispatch(BoostPortalStore.Actions.SetSelectedDepartmentId(parseInt(departmentId, 0)));
            }
        });
    }
};
BoostPortalChooseDepartment._deps = [Select];
__decorate([
    reduxBind(BoostPortalStore.path('departments')),
    state(),
    __metadata("design:type", Array)
], BoostPortalChooseDepartment.prototype, "departments", void 0);
__decorate([
    reduxBind(BoostPortalStore.path('selectedDepartmentId')),
    state(),
    __metadata("design:type", Number)
], BoostPortalChooseDepartment.prototype, "selectedDepartmentId", void 0);
__decorate([
    reduxBind(BoostPortalStore.path('availableDepartments')),
    state(),
    __metadata("design:type", Array)
], BoostPortalChooseDepartment.prototype, "availableDepartments", void 0);
__decorate([
    reduxBind(BoostPortalStore.path('token')),
    state(),
    __metadata("design:type", String)
], BoostPortalChooseDepartment.prototype, "token", void 0);
__decorate([
    state(),
    __metadata("design:type", Boolean)
], BoostPortalChooseDepartment.prototype, "lockedToDepartment", void 0);
__decorate([
    property({ type: Boolean }),
    __metadata("design:type", Boolean)
], BoostPortalChooseDepartment.prototype, "isCreateProfileView", void 0);
__decorate([
    property({ type: Boolean }),
    __metadata("design:type", Boolean)
], BoostPortalChooseDepartment.prototype, "disabled", void 0);
BoostPortalChooseDepartment = __decorate([
    customElement(BoostPortalChooseDepartment.is)
], BoostPortalChooseDepartment);
export { BoostPortalChooseDepartment };

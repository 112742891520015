var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { NewsArticleAttachment } from './NewsArticleAttachment';
import { NewsArticleCategory } from './NewsArticleCategory';
import { NewsArticleLink } from './NewsArticleLink';
import { NewsDepartment } from './NewsDepartment';
import { JSONStruct, array_property, nullable, property } from 'badphraim/data/JSONStruct';
export class NewsArticle extends JSONStruct {
    constructor() {
        super(...arguments);
        /** @override */ this.attachments = [];
        /** @override */ this.categories = [];
        /** @override */ this.created_time = '';
        /** @override */ this.departments = [];
        /** @override */ this.id = 0;
        /** @override */ this.is_pinned = false;
        /** @override */ this.links = [];
        /** @override */ this.published_at = '';
        /** @override */ this.read = false;
    }
}
__decorate([
    array_property(NewsArticleAttachment),
    __metadata("design:type", Array)
], NewsArticle.prototype, "attachments", void 0);
__decorate([
    array_property(NewsArticleCategory),
    __metadata("design:type", Array)
], NewsArticle.prototype, "categories", void 0);
__decorate([
    nullable,
    __metadata("design:type", String)
], NewsArticle.prototype, "content", void 0);
__decorate([
    property,
    __metadata("design:type", String)
], NewsArticle.prototype, "created_time", void 0);
__decorate([
    nullable,
    __metadata("design:type", String)
], NewsArticle.prototype, "creator", void 0);
__decorate([
    array_property(NewsDepartment),
    __metadata("design:type", Array)
], NewsArticle.prototype, "departments", void 0);
__decorate([
    property,
    __metadata("design:type", Number)
], NewsArticle.prototype, "id", void 0);
__decorate([
    property,
    __metadata("design:type", Boolean)
], NewsArticle.prototype, "is_pinned", void 0);
__decorate([
    array_property(NewsArticleLink),
    __metadata("design:type", Array)
], NewsArticle.prototype, "links", void 0);
__decorate([
    property,
    __metadata("design:type", String)
], NewsArticle.prototype, "published_at", void 0);
__decorate([
    nullable,
    __metadata("design:type", String)
], NewsArticle.prototype, "published_until", void 0);
__decorate([
    property,
    __metadata("design:type", Boolean)
], NewsArticle.prototype, "read", void 0);
__decorate([
    nullable,
    __metadata("design:type", String)
], NewsArticle.prototype, "title", void 0);

/* tslint:disable: class-name */
/*
 * AUTOGENERATED FILE - DO NOT EDIT
 */
import { APIRestClient } from '../../../APIRestClient';
import { ewRestRequestHandlerDashboard_AvailablePortlets } from '../../../schemas/ewRestRequestHandlerDashboard_AvailablePortlets';
import { ewRestRequestHandlerDashboard_Dashboard } from '../../../schemas/ewRestRequestHandlerDashboard_Dashboard';
import { ewRestRequestHandlerDashboard_DashboardWithPortlets } from '../../../schemas/ewRestRequestHandlerDashboard_DashboardWithPortlets';
import { ewRestRequestHandlerDashboard_Portlet } from '../../../schemas/ewRestRequestHandlerDashboard_Portlet';
import { ewRestRequestHandlerDashboard_Portlet_delete_result } from '../../../schemas/ewRestRequestHandlerDashboard_Portlet_delete_result';
export class DashboardAPI extends APIRestClient {
    /**
     * Slett en portlet fra en layout
     * Implementert hovedsakelig i *ewRestRequestHandlerDashboard*::*deleteDashboardLayoutPortlet*
     */
    static deleteDashboardLayoutPortletUsingContextLayoutIdPortletId(context, layout_id, portlet_id) {
        return this._delete('/dashboard/{context}/layout/{layout_id}/portlet/{portlet_id}', { context, layout_id, portlet_id }, null, null, ewRestRequestHandlerDashboard_Portlet_delete_result
            .classOf(ewRestRequestHandlerDashboard_Portlet_delete_result, new ewRestRequestHandlerDashboard_Portlet_delete_result())
            .arrayOf(), ['https://api.cerum.no/cerumx/login'], 'json');
    }
    /**
     * Slett en hel layout
     * Implementert hovedsakelig i *ewRestRequestHandlerDashboard*::*deleteDashboardLayout*
     */
    static deleteDashboardLayoutUsingContextLayoutId(context, layout_id) {
        return this._delete('/dashboard/{context}/layout/{layout_id}', { context, layout_id }, null, null, APIRestClient.Native.boolean(), ['https://api.cerum.no/cerumx/login'], 'json');
    }
    /**
     * Hent ut active dashboard-layout med portlets for gitt context
     * Implementert hovedsakelig i *ewRestRequestHandlerDashboard*::*readActiveDashboardLayout*
     */
    static getDashboardActiveUsingContext(context) {
        return this._get('/dashboard/{context}/active', { context }, null, null, ewRestRequestHandlerDashboard_DashboardWithPortlets.classOf(ewRestRequestHandlerDashboard_DashboardWithPortlets, new ewRestRequestHandlerDashboard_DashboardWithPortlets()), ['https://api.cerum.no/cerumx/login'], 'json');
    }
    /**
     * Hent ut dashboard-layout med portlets ut fra layout_id
     * Implementert hovedsakelig i *ewRestRequestHandlerDashboard*::*readSpecificDashboardLayout*
     */
    static getDashboardLayoutUsingContextLayoutId(context, layout_id) {
        return this._get('/dashboard/{context}/layout/{layout_id}', { context, layout_id }, null, null, ewRestRequestHandlerDashboard_DashboardWithPortlets.classOf(ewRestRequestHandlerDashboard_DashboardWithPortlets, new ewRestRequestHandlerDashboard_DashboardWithPortlets()), ['https://api.cerum.no/cerumx/login'], 'json');
    }
    /**
     * Hent ut tilgjengelige dashboard layouts for gitt context
     * Implementert hovedsakelig i *ewRestRequestHandlerDashboard*::*readAvailableDashboardLayouts*
     */
    static getDashboardLayoutsUsingContext(context) {
        return this._get('/dashboard/{context}/layouts', { context }, null, null, ewRestRequestHandlerDashboard_Dashboard
            .classOf(ewRestRequestHandlerDashboard_Dashboard, new ewRestRequestHandlerDashboard_Dashboard())
            .arrayOf(), ['https://api.cerum.no/cerumx/login'], 'json');
    }
    /**
     * Hent ut tilgjengelige portlets som kan legges til en dashboard-layout for gitt context
     * Implementert hovedsakelig i *ewRestRequestHandlerDashboard*::*readAvailableDashboardPortlets*
     */
    static getDashboardPortletsUsingContext(context) {
        return this._get('/dashboard/{context}/portlets', { context }, null, null, ewRestRequestHandlerDashboard_AvailablePortlets.classOf(ewRestRequestHandlerDashboard_AvailablePortlets, new ewRestRequestHandlerDashboard_AvailablePortlets()), ['https://api.cerum.no/cerumx/login'], 'json');
    }
    /**
     * Hent lagret s�k
     * Implementert hovedsakelig i *ewRestResourceHandlerSavedSearch*::*read*
     */
    static getPorletSavedsearch() {
        return this._get('/porlet/savedsearch', {}, null, null, APIRestClient.Native.string(), ['https://api.cerum.no/cerumx/login'], 'json');
    }
    /**
     * Oppdater portlet-detaljer for en portlet med gitt portlet_id
     * Implementert hovedsakelig i *ewRestRequestHandlerDashboard*::*updateSpecificDashboardLayoutPortlet*
     */
    static patchDashboardLayoutPortletUsingContextLayoutIdPortletId(context, layout_id, portlet_id, __DATA) {
        return this._patch('/dashboard/{context}/layout/{layout_id}/portlet/{portlet_id}', { context, layout_id, portlet_id }, null, JSON.stringify(__DATA), ewRestRequestHandlerDashboard_Portlet.classOf(ewRestRequestHandlerDashboard_Portlet, new ewRestRequestHandlerDashboard_Portlet()), ['https://api.cerum.no/cerumx/login'], 'json');
    }
    /**
     * Opprett ny portlet i en layout
     * Implementert hovedsakelig i *ewRestRequestHandlerDashboard*::*createDashboardLayoutPortlet*
     */
    static postDashboardLayoutPortletUsingContextLayoutId(context, layout_id, __DATA) {
        return this._post('/dashboard/{context}/layout/{layout_id}/portlet', { context, layout_id }, null, JSON.stringify(__DATA), ewRestRequestHandlerDashboard_Portlet.classOf(ewRestRequestHandlerDashboard_Portlet, new ewRestRequestHandlerDashboard_Portlet()), ['https://api.cerum.no/cerumx/login'], 'json');
    }
    /**
     * Opprett ny dashboardlayout
     * Implementert hovedsakelig i *ewRestRequestHandlerDashboard*::*createDashboardLayout*
     */
    static postDashboardLayoutUsingContext(context, __DATA) {
        return this._post('/dashboard/{context}/layout', { context }, null, JSON.stringify(__DATA), ewRestRequestHandlerDashboard_Dashboard.classOf(ewRestRequestHandlerDashboard_Dashboard, new ewRestRequestHandlerDashboard_Dashboard()), ['https://api.cerum.no/cerumx/login'], 'json');
    }
    /**
     * Aktiver en layout
     * Implementert hovedsakelig i *ewRestRequestHandlerDashboard*::*updateDashboardLayoutActivate*
     */
    static putDashboardLayoutActivateUsingContextLayoutId(context, layout_id) {
        return this._put('/dashboard/{context}/layout/{layout_id}/activate', { context, layout_id }, null, null, APIRestClient.Native.boolean(), ['https://api.cerum.no/cerumx/login'], 'json');
    }
    /**
     * Oppdater portlet-detaljer for en portlet med gitt portlet_id
     * Implementert hovedsakelig i *ewRestRequestHandlerDashboard*::*updateSpecificDashboardLayoutPortlet*
     */
    static putDashboardLayoutPortletUsingContextLayoutIdPortletId(context, layout_id, portlet_id, __DATA) {
        return this._put('/dashboard/{context}/layout/{layout_id}/portlet/{portlet_id}', { context, layout_id, portlet_id }, null, JSON.stringify(__DATA), ewRestRequestHandlerDashboard_Portlet.classOf(ewRestRequestHandlerDashboard_Portlet, new ewRestRequestHandlerDashboard_Portlet()), ['https://api.cerum.no/cerumx/login'], 'json');
    }
    /**
     * Endre dashboard-metadata
     * Implementert hovedsakelig i *ewRestRequestHandlerDashboard*::*updateDashboardLayout*
     */
    static putDashboardLayoutUsingContextLayoutId(context, layout_id, __DATA) {
        return this._put('/dashboard/{context}/layout/{layout_id}', { context, layout_id }, null, JSON.stringify(__DATA), ewRestRequestHandlerDashboard_Dashboard.classOf(ewRestRequestHandlerDashboard_Dashboard, new ewRestRequestHandlerDashboard_Dashboard()), ['https://api.cerum.no/cerumx/login'], 'json');
    }
}

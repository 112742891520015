var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { isSuccessHTTP, isErrorHTTP } from 'base-api/APIBase';
import { BoostPortalTokenContainer } from 'boost-services/BoostPortalTokenContainer';
import { MembersignupaccessAPI } from './boost/signup/membersignupaccess/MembersignupaccessAPI';
export class BoostPortalTokenHandler {
    static loadToken(center, error_cb) {
        return __awaiter(this, void 0, void 0, function* () {
            BoostPortalTokenContainer.isLoadingToken = true;
            try {
                const tokenResult = yield MembersignupaccessAPI.getV1BoostsignupTokenUsingCenterident(center);
                if (isSuccessHTTP(tokenResult)) {
                    BoostPortalTokenContainer.token.setValue(tokenResult.token);
                }
                else if (isErrorHTTP(tokenResult) && tokenResult) {
                    error_cb(tokenResult);
                }
                BoostPortalTokenContainer.isLoadingToken = false;
            }
            catch (e) {
                BoostPortalTokenContainer.isLoadingToken = false;
                throw e;
            }
        });
    }
}

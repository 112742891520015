var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { ShowTrackingNotificationIfNeeded } from './components/ShowTrackingNotificationIfNeeded';
import { reduxBind } from 'badphraim/ui/LitReduxMixinBase';
import { isErrorHTTP, isSuccessHTTP } from 'boost-api/APIRestClient';
import { MembersignupAPI } from 'boost-api/boost/signup/membersignup/MembersignupAPI';
import { BoostPortalTokenHandler } from 'boost-api/BoostPortalTokenHandler';
import { BoostServices } from 'boost-services/BoostServices';
import { BoostPortalTokenContainer } from 'boost-services/BoostPortalTokenContainer';
import { css, html, LitElement } from 'lit';
import { customElement, state } from 'lit/decorators.js';
import { BoostPortalStore } from './redux/BoostPortalStore';
import { LitReduxBoostPortalMixin } from './redux/LitReduxBoostPortalMixin';
import { router } from './router/BoostPortalRouter';
import { SnackBar } from 'common-uicomponents/components/SnackBar';
import { BoostButton } from './components/common/BoostButton';
import { BoostPortalSessionHandler } from './helpers/BoostPortalSessionHandler';
import { BoostPortalStartupHandler } from './helpers/BoostPortalStartupHandler';
import { BoostPortalHeader } from './components/BoostPortalHeader';
import { OverlayWindow } from 'common-uicomponents/components/OverlayWindow';
import { PaymentConditionType } from './interfaces/PaymentConditionType';
import { BoostPortalParamsHandler } from './helpers/BoostPortalParamsHandler';
import { Status } from './enums/VippsStatus';
let BoostPortal = class BoostPortal extends LitReduxBoostPortalMixin(LitElement) {
    static get is() {
        return 'boost-portal';
    }
    get routerOutlet() {
        return this.shadowRoot && this.shadowRoot.getElementById('routerOutlet');
    }
    static get styles() {
        return css `
            body {
                margin: 0;
            }

            body,
            input,
            select {
                font-weight: lighter;
            }

            .container {
                padding: 50px;
            }

            .content {
                grid-area: content;
                display: grid;
                grid-template-areas:
                    'content-main'
                    'content-footer';
                grid-template-rows: 1fr 100px;
                position: relative;
                width: 60%;
                max-width: 800px;
                margin: 0 auto;
            }

            .content h1,
            .sidebar h1 {
                margin: 0;
                font-weight: 300;
                font-size: 1.5em;
            }

            .content-background {
                position: absolute;
                background-color: var(--portalBackground, #f4f4f4);
                border-radius: 10px;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                opacity: 0.95;
                z-index: 1;
            }

            .logo {
                max-height: 50px;
                background-color: var(--portalSidebarContentBackground);
                padding: 4px;
            }

            .content-main {
                grid-area: content-main;
                display: flex;
                justify-content: center;
                z-index: 2;
            }

            overlay-window {
                font-size: 26px;
            }

            @media only screen and (max-width: 900px) {
                .content {
                    width: 100%;
                }
                .container {
                    padding: 10px;
                }
                .logo {
                    max-width: 200px;
                }
            }

            @media only screen and (max-width: 375px) {
                .content {
                    grid-template-rows: 1fr 100px;
                }

                .logo {
                    max-width: 200px;
                }
            }

            .content-footer {
                grid-area: content-footer;
                padding: 2em;
                display: flex;
                align-items: center;
                font-size: 1em;
                color: var(--portalForeground);
                z-index: 2;
                justify-content: center;
            }

            .content-footer a {
                margin: 6px;
                color: var(--portalLink, #2a9ba3);
            }

            @media screen and (max-width: 600px) {
                .content-footer {
                    flex-direction: column;
                    padding: 0;
                    margin-bottom: 1rem;
                }
            }
        `;
    }
    constructor() {
        super();
        this.colors = [];
        this.images = [];
        this.currentStep = 1;
        this.sessionHandler = new BoostPortalSessionHandler();
        this.departments = [];
        this.updateComplete.then(() => this.onUpdateComplete());
    }
    /**
     * @TODO noe av denne logikken burde kanskje blitt flyttet ut i en egen laste-data-vi-trenger-klasse-av-noe-slag
     * kanskje samtidig som vi rydder/oppgraderer redux-store-greia (redux-toolkit)
     */
    onUpdateComplete() {
        return __awaiter(this, void 0, void 0, function* () {
            router.setOutlet(this.routerOutlet);
            yield router.ready;
            // prepare for server-communication
            const cookieSession = this.sessionHandler.getLoginSession();
            if (cookieSession) {
                this.dispatch(BoostPortalStore.Actions.SetSession(this.sessionHandler.getLoginSession()));
            }
            else {
                this.dispatch(BoostPortalStore.Actions.SetSession());
            }
            const centerShortName = router.location.params.center;
            this.dispatch(BoostPortalStore.Actions.SetCenterShort(centerShortName));
            if (!centerShortName) {
                window.location.replace('https://www.boostsystem.no/?redir');
                return;
            }
            yield BoostPortalTokenHandler.loadToken(centerShortName, (tokenResult) => {
                BoostPortalStartupHandler.replaceContents('Kunne ikke laste siden: ' + tokenResult.error.message);
                window.location.replace('https://www.boostsystem.no/?centernotfound');
            });
            this.dispatch(BoostPortalStore.Actions.SetToken(yield BoostPortalTokenContainer.token.getValue()));
            // Load center data
            const center = yield MembersignupAPI.getV1BoostsignupCenter();
            if (isSuccessHTTP(center)) {
                this.dispatch(BoostPortalStore.Actions.SetCenter(center));
            }
            else if (isErrorHTTP(center)) {
                SnackBar.pushError(center.error);
            }
            const serviceUrl = yield BoostServices.instance().getBaseUrl();
            this.dispatch(BoostPortalStore.Actions.SetServiceUrl(serviceUrl));
            const requiredPendingRequests = [];
            requiredPendingRequests.push(this.loadPortalColors());
            requiredPendingRequests.push(this.loadPortalImages());
            this.loadSponsorSetup();
            this.loadPaymentVippsConditions();
            yield Promise.all(requiredPendingRequests);
            this._getUrlParameters();
            // Fire up remainder of GUI
            BoostPortalStartupHandler.removeLoadingScreen();
        });
    }
    _getUrlParameters() {
        const callback = BoostPortalParamsHandler.getParameter('callback');
        const vippsSetup = BoostPortalParamsHandler.getParameter('vippsSetup');
        const netsStatus = BoostPortalParamsHandler.getParameter('success');
        const centerShort = BoostPortalParamsHandler.getParameter('centerShort');
        const invoiceId = BoostPortalParamsHandler.getParameter('invoiceId');
        const shortTerm = BoostPortalParamsHandler.getParameter('shortterm');
        const noDirectDebit = BoostPortalParamsHandler.getParameter('directdebit');
        const urlParameterData = {
            callback: '',
            vippsSetup: false,
            netsStatus: '',
            centerShort: '',
            invoiceId: 0,
            shortTerm: false,
            noDirectDebit: false,
        };
        if (invoiceId) {
            urlParameterData.invoiceId = parseInt(invoiceId);
        }
        if (vippsSetup === Status.VIPPS) {
            urlParameterData.vippsSetup = true;
        }
        if (callback) {
            urlParameterData.callback = callback;
        }
        if (netsStatus) {
            urlParameterData.netsStatus = netsStatus;
        }
        if (centerShort) {
            urlParameterData.centerShort = centerShort;
        }
        if (shortTerm) {
            urlParameterData.shortTerm = shortTerm === 'true' ? true : false;
        }
        if (noDirectDebit) {
            urlParameterData.noDirectDebit = noDirectDebit === 'true' ? true : false;
        }
        this._hideParametersInURL(urlParameterData);
    }
    loadPortalColors() {
        return __awaiter(this, void 0, void 0, function* () {
            const colors = yield MembersignupAPI.getV1BoostsignupColors();
            if (isSuccessHTTP(colors)) {
                this.dispatch(BoostPortalStore.Actions.SetColors(colors));
            }
            else if (isErrorHTTP(colors)) {
                SnackBar.pushError(colors.error);
            }
        });
    }
    loadPortalImages() {
        return __awaiter(this, void 0, void 0, function* () {
            const images = yield MembersignupAPI.getV1BoostsignupImages();
            if (isSuccessHTTP(images)) {
                this.dispatch(BoostPortalStore.Actions.SetImages(images));
            }
            else if (isErrorHTTP(images)) {
                SnackBar.pushError(images.error);
            }
        });
    }
    loadPaymentVippsCondition() {
        return __awaiter(this, void 0, void 0, function* () {
            const conditionResult = yield MembersignupAPI.getV1BoostsignupPaymentConditions();
            if (isSuccessHTTP(conditionResult)) {
                return conditionResult;
            }
        });
    }
    loadPaymentVippsConditionUrl() {
        return __awaiter(this, void 0, void 0, function* () {
            const conditionResult = yield MembersignupAPI.getV1BoostsignupPaymentConditionurl();
            if (isSuccessHTTP(conditionResult)) {
                return conditionResult;
            }
        });
    }
    loadPaymentVippsConditions() {
        return __awaiter(this, void 0, void 0, function* () {
            const [pdfBlob, conditionUrl] = yield Promise.all([
                this.loadPaymentVippsCondition(),
                this.loadPaymentVippsConditionUrl(),
            ]);
            if (conditionUrl === null || conditionUrl === void 0 ? void 0 : conditionUrl.url) {
                this.dispatch(BoostPortalStore.Actions.SetPaymentCondition({
                    url: conditionUrl.url,
                    type: PaymentConditionType.REDIRECT,
                }));
            }
            else if (pdfBlob) {
                this.dispatch(BoostPortalStore.Actions.SetPaymentCondition({
                    blob: pdfBlob,
                    type: PaymentConditionType.PDF,
                }));
            }
        });
    }
    loadSponsorSetup() {
        return __awaiter(this, void 0, void 0, function* () {
            const sponsorSetupResult = yield MembersignupAPI.getV1BoostsignupSponsorsetup();
            if (isSuccessHTTP(sponsorSetupResult)) {
                this.dispatch(BoostPortalStore.Actions.SetSponsorSetup(sponsorSetupResult.status));
            }
            else if (isErrorHTTP(sponsorSetupResult)) {
                SnackBar.pushError(sponsorSetupResult.error);
            }
        });
    }
    /** @override */ render() {
        return html `
            <style>
                ${html ` * { `}
                ${this.colors.map((color) => this._getColorAsCSSVariable(color))}
                ${html ` } `}
            </style>

            <div style="${this._getBackgroundImage()}"></div>
            <!-- <show-tracking-notification-if-needed></show-tracking-notification-if-needed> -->
            <div class="container">
                <div
                    class="content"
                    @currentStep-changed="${(event) => {
            this.currentStep = event.detail.currentStep;
        }}"
                >
                    <div class="content-background"></div>
                    <div class="content-main" id="routerOutlet"></div>

                    ${this._renderMailFromContact(this.departments)}
                </div>
            </div>
            <overlay-window fullscreen></overlay-window>
            <show-tracking-notification-if-needed></show-tracking-notification-if-needed>
            <snack-bar></snack-bar>
        `;
    }
    _renderMailFromContact(departments) {
        var _a;
        const department = departments.find((department) => department.id === this.selectedDepartmentId);
        if (this.selectedDepartmentId && (department === null || department === void 0 ? void 0 : department.epost)) {
            return html `<div class="content-footer">
                Ta kontakt med
                <a href="mailto: ${department.epost}">${department.epost}</a> hvis du trenger hjelp
            </div>`;
        }
        else if ((_a = this.center) === null || _a === void 0 ? void 0 : _a.contact_email) {
            return html `<div class="content-footer">
                Ta kontakt med
                <a href="mailto: ${this.center.contact_email}">${this.center.contact_email}</a> hvis du
                trenger hjelp
            </div>`;
        }
        return html `<div class="content-footer">Ta kontakt med senteret hvis du trenger hjelp</div>`;
    }
    _getBackgroundImage() {
        if (this.images.length) {
            return this._getBackgroundImageAsStyle(this.images[Math.floor(Math.random() * this.images.length)]);
        }
        if (this.currentImage) {
            return this._getBackgroundImageAsStyle(this.currentImage);
        }
        this.currentImage = 'images/splash-' + (Math.floor(Math.random() * 9) + 1) + '.jpg';
        return this._getBackgroundImageAsStyle(this.currentImage);
    }
    _getBackgroundImageAsStyle(backgroundImage) {
        return ('background: url("' +
            backgroundImage +
            '") no-repeat center center fixed; background-size: cover; position: absolute; top: 0; left: 0; right: 0; bottom: 0; position: fixed;');
    }
    _getColorAsCSSVariable(color) {
        return '--' + color.name + ': ' + color.color + ';';
    }
    _selectedDepartmentIdObserver() {
        return __awaiter(this, void 0, void 0, function* () {
            let departmentIdQueryObject = {};
            if (this.selectedDepartmentId) {
                departmentIdQueryObject = {
                    departmentId: String(this.selectedDepartmentId),
                };
            }
            const colors = yield MembersignupAPI.getV1BoostsignupColors(departmentIdQueryObject);
            if (isSuccessHTTP(colors)) {
                this.dispatch(BoostPortalStore.Actions.SetColors(colors));
            }
            else if (isErrorHTTP(colors)) {
                this.dispatch(BoostPortalStore.Actions.SetColors());
                SnackBar.pushError(colors.error);
            }
            const images = yield MembersignupAPI.getV1BoostsignupImages(departmentIdQueryObject);
            if (isSuccessHTTP(images)) {
                this.dispatch(BoostPortalStore.Actions.SetImages(images));
            }
            else if (isErrorHTTP(images)) {
                this.dispatch(BoostPortalStore.Actions.SetImages());
                SnackBar.pushError(images.error);
            }
        });
    }
    _centerObserver() {
        var _a;
        if (((_a = this.center) === null || _a === void 0 ? void 0 : _a.title) && this.center.title != '') {
            window.document.title = this.center.title + ' - Boost System';
        }
        else {
            window.document.title = 'Portal - Boost System';
        }
    }
    _hideParametersInURL(urlParameterData) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.token) {
                return;
            }
            const membershipId = BoostPortalParamsHandler.getParameter('membershipId');
            const departmentId = BoostPortalParamsHandler.getParameter('departmentId');
            const urlParameters = window.location.search;
            if (!urlParameters) {
                return;
            }
            if (membershipId) {
                this.dispatch(BoostPortalStore.Actions.SetSelectedMembershipId(parseInt(membershipId)));
                BoostPortalParamsHandler.removeParameter('membershipId');
            }
            if (departmentId) {
                this.dispatch(BoostPortalStore.Actions.SetSelectedDepartmentId(parseInt(departmentId)));
                BoostPortalParamsHandler.removeParameter('departmentId');
            }
            if (this.centerShort) {
                history.replaceState(urlParameterData, this.centerShort, window.location.pathname);
                const popstateEvent = new PopStateEvent('popstate', {
                    state: urlParameterData,
                    bubbles: true,
                    composed: true,
                });
                this.dispatchEvent(popstateEvent);
            }
        });
    }
    /** @override */ updated(changedProperties) {
        changedProperties.forEach((_oldValue, propertyName) => {
            switch (propertyName) {
                case 'selectedDepartmentId':
                    this._selectedDepartmentIdObserver();
                    break;
                case 'center':
                    this._centerObserver();
                    break;
            }
        });
        super.updated(changedProperties);
    }
};
BoostPortal._deps = [
    BoostButton,
    SnackBar,
    BoostPortalHeader,
    ShowTrackingNotificationIfNeeded,
    OverlayWindow,
];
__decorate([
    reduxBind(BoostPortalStore.path('center')),
    state(),
    __metadata("design:type", Object)
], BoostPortal.prototype, "center", void 0);
__decorate([
    reduxBind(BoostPortalStore.path('centerShort')),
    state(),
    __metadata("design:type", String)
], BoostPortal.prototype, "centerShort", void 0);
__decorate([
    reduxBind(BoostPortalStore.path('colors')),
    state(),
    __metadata("design:type", Array)
], BoostPortal.prototype, "colors", void 0);
__decorate([
    reduxBind(BoostPortalStore.path('images')),
    state(),
    __metadata("design:type", Array)
], BoostPortal.prototype, "images", void 0);
__decorate([
    state(),
    __metadata("design:type", String)
], BoostPortal.prototype, "currentImage", void 0);
__decorate([
    reduxBind(BoostPortalStore.path('selectedDepartmentId')),
    state(),
    __metadata("design:type", Number)
], BoostPortal.prototype, "selectedDepartmentId", void 0);
__decorate([
    reduxBind(BoostPortalStore.path('serviceUrl')),
    state(),
    __metadata("design:type", String)
], BoostPortal.prototype, "serviceUrl", void 0);
__decorate([
    reduxBind(BoostPortalStore.path('token')),
    state(),
    __metadata("design:type", String)
], BoostPortal.prototype, "token", void 0);
__decorate([
    reduxBind(BoostPortalStore.path('session')),
    state(),
    __metadata("design:type", String)
], BoostPortal.prototype, "session", void 0);
__decorate([
    reduxBind(BoostPortalStore.path('selectedMembershipId')),
    state(),
    __metadata("design:type", Number)
], BoostPortal.prototype, "selectedMembershipId", void 0);
__decorate([
    state(),
    __metadata("design:type", Number)
], BoostPortal.prototype, "currentStep", void 0);
__decorate([
    reduxBind(BoostPortalStore.path('departments')),
    state(),
    __metadata("design:type", Array)
], BoostPortal.prototype, "departments", void 0);
BoostPortal = __decorate([
    customElement(BoostPortal.is),
    __metadata("design:paramtypes", [])
], BoostPortal);
export { BoostPortal };

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
export class RequestRetryHelper {
    constructor(maxRetries, retryInterval) {
        this.maxRetries = maxRetries;
        this.retryInterval = retryInterval;
    }
    retryRequest(requestFunction, params, successCondition) {
        return __awaiter(this, void 0, void 0, function* () {
            let retries = 0;
            while (retries < this.maxRetries) {
                try {
                    const result = yield requestFunction(params);
                    if (successCondition(result)) {
                        return result;
                    }
                    yield this.delay(this.retryInterval);
                }
                catch (error) {
                    // Retry after a delay
                    yield this.delay(this.retryInterval);
                }
                retries++;
            }
            throw new Error(`Maximum number of retries (${this.maxRetries}) reached.`);
        });
    }
    delay(ms) {
        return new Promise((resolve) => setTimeout(resolve, ms));
    }
}

export var TrackingEvent;
(function (TrackingEvent) {
    TrackingEvent[TrackingEvent["MemberSignupStarted"] = 0] = "MemberSignupStarted";
    TrackingEvent[TrackingEvent["SelectedDepartment"] = 1] = "SelectedDepartment";
    TrackingEvent[TrackingEvent["SelectedMembership"] = 2] = "SelectedMembership";
    TrackingEvent[TrackingEvent["PersonalInformationFilled"] = 3] = "PersonalInformationFilled";
    TrackingEvent[TrackingEvent["SponsorConfirmed"] = 4] = "SponsorConfirmed";
    TrackingEvent[TrackingEvent["BankIDSigningStarted"] = 5] = "BankIDSigningStarted";
    TrackingEvent[TrackingEvent["AvtalegiroSigningCompleted"] = 6] = "AvtalegiroSigningCompleted";
    TrackingEvent[TrackingEvent["AvtalegiroSigningFailed"] = 7] = "AvtalegiroSigningFailed";
    TrackingEvent[TrackingEvent["VippsPaymentComplete"] = 8] = "VippsPaymentComplete";
    TrackingEvent[TrackingEvent["CompanySponsoredSignupStarted"] = 9] = "CompanySponsoredSignupStarted";
    TrackingEvent[TrackingEvent["CompanySponsoredSelectedDepartment"] = 10] = "CompanySponsoredSelectedDepartment";
    TrackingEvent[TrackingEvent["PrivatelySponsoredSignupStarted"] = 11] = "PrivatelySponsoredSignupStarted";
    TrackingEvent[TrackingEvent["PrivatelySponsoredSelectedDepartment"] = 12] = "PrivatelySponsoredSelectedDepartment";
    TrackingEvent[TrackingEvent["MemberSignupCompleted"] = 13] = "MemberSignupCompleted";
    TrackingEvent[TrackingEvent["RequestedNewPassword"] = 14] = "RequestedNewPassword";
})(TrackingEvent || (TrackingEvent = {}));

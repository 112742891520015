var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { reduxBind } from 'badphraim/ui/LitReduxMixinBase';
import { isErrorHTTP, isSuccessHTTP } from 'boost-api/APIRestClient';
import { MembersignupAPI, } from 'boost-api/boost/signup/membersignup/MembersignupAPI';
import { css, html, LitElement } from 'lit';
import { customElement, state } from 'lit/decorators.js';
import { BoostPortalStore } from '../redux/BoostPortalStore';
import { LitReduxBoostPortalMixin } from '../redux/LitReduxBoostPortalMixin';
import { BoostButton } from './common/BoostButton';
import { BoostStatusContainer } from './common/BoostStatusContainer';
import { TextField } from '@vaadin/text-field';
let BoostPortalValidateSponsorCode = class BoostPortalValidateSponsorCode extends LitReduxBoostPortalMixin(LitElement) {
    static get is() {
        return 'boost-portal-validate-sponsor-code';
    }
    static get styles() {
        return css `
            h4 {
                color: var(--portalForeground, #404040);
                font-weight: 500;
                margin-bottom: 10px;
            }

            .error {
                color: red;
            }

            #codeButton {
                align-self: center;
            }
        `;
    }
    constructor() {
        super();
        this.isValidSponsorCode = false;
        this.sponsorCode = 'LD-FLB-XCSI-S5K';
        this.sponsorCodeLookupError = '';
        this.updateComplete.then(() => __awaiter(this, void 0, void 0, function* () {
            const urlParameters = new URLSearchParams(location.search);
            const sponsorCode = urlParameters.get('code');
            if (sponsorCode) {
                this.sponsorCode = sponsorCode;
            }
        }));
    }
    /** @override */ render() {
        return html `
            <h4>Skriv inn koden den som skal betale har mottatt her:</h4>

            <div style="display: flex; flex-direction: column; align-items: center; justify-content: center;">
                <div style="display: flex;">
                    <vaadin-text-field
                        value="${this.sponsorCode}"
                        placeholder="AG-123-GJFA-452"
                        required
                        error-message="Kode må fylles ut"
                        @value-changed="${(event) => {
            this.isValidSponsorCode = false;
            const eventValue = event.detail.value.toUpperCase();
            const cleanInput = eventValue.replace(/-/g, '').substring(0, 12);
            const first = cleanInput.substring(0, 2);
            const second = cleanInput.substring(2, 5);
            const third = cleanInput.substring(5, 9);
            const fourth = cleanInput.substring(9, 12);
            if (cleanInput.length > 9) {
                this.sponsorCode = `${first}-${second}-${third}-${fourth}`;
            }
            else if (cleanInput.length > 5) {
                this.sponsorCode = `${first}-${second}-${third}`;
            }
            else if (cleanInput.length > 2) {
                this.sponsorCode = `${first}-${second}`;
            }
            else if (cleanInput.length > 0) {
                this.sponsorCode = `${first}`;
            }
        }}"
                    ></vaadin-text-field>

                    <boost-button
                        id="codeButton"
                        type="submit"
                        name="Send inn kode"
                        ?disabled="${!this.sponsorCode || this.sponsorCode === ''}"
                        @click="${this._sponsorCodeLookup}"
                    ></boost-button>
                </div>

                ${this.sponsorCodeLookupError
            ? html ` <div class="error">${this.sponsorCodeLookupError}</div> `
            : ''}
            </div>
        `;
    }
    /** @override */ updated(changedProperties) {
        changedProperties.forEach((_oldValue, propertyName) => {
            switch (propertyName) {
                case 'isValidSponsorCode':
                    this._isValidSponsorCodeObserver();
                    break;
            }
        });
        super.updated(changedProperties);
    }
    _isValidForm() {
        return this.isValidSponsorCode;
    }
    _isValidSponsorCodeObserver() {
        this.dispatchEvent(new CustomEvent('isValidSponsorCode-changed', {
            detail: {
                isValidSponsorCode: this.isValidSponsorCode,
            },
            bubbles: true,
            composed: true,
        }));
    }
    _sponsorCodeLookup(event) {
        return __awaiter(this, void 0, void 0, function* () {
            const buttonElement = event.srcElement;
            this.isValidSponsorCode = false;
            this.sponsorCodeLookupError = '';
            if (!this.sponsorCode || this.sponsorCode === '') {
                return;
            }
            let queryObject = undefined;
            if (this.memberInformation) {
                queryObject = { contractId: this.memberInformation.contractId };
            }
            buttonElement.setLoading(true);
            const sponsorCodeResult = yield MembersignupAPI.getV1BoostsignupSponsorUsingSponsorcode(this.sponsorCode, queryObject);
            if (isSuccessHTTP(sponsorCodeResult) && sponsorCodeResult) {
                this.isValidSponsorCode = true;
                this.dispatch(BoostPortalStore.Actions.SetSponsorCode(this.sponsorCode));
                this.dispatch(BoostPortalStore.Actions.SetAvailableDepartments(sponsorCodeResult.AG_departments));
            }
            else if (isErrorHTTP(sponsorCodeResult)) {
                this.isValidSponsorCode = false;
                this.sponsorCodeLookupError = sponsorCodeResult.error.message;
            }
            buttonElement.setLoading(false);
        });
    }
};
BoostPortalValidateSponsorCode._deps = [BoostButton, BoostStatusContainer, TextField];
__decorate([
    state(),
    __metadata("design:type", Boolean)
], BoostPortalValidateSponsorCode.prototype, "isValidSponsorCode", void 0);
__decorate([
    reduxBind(BoostPortalStore.path('sponsorCode')),
    state(),
    __metadata("design:type", String)
], BoostPortalValidateSponsorCode.prototype, "sponsorCode", void 0);
__decorate([
    reduxBind(BoostPortalStore.path('memberInformation')),
    state(),
    __metadata("design:type", Object)
], BoostPortalValidateSponsorCode.prototype, "memberInformation", void 0);
__decorate([
    state(),
    __metadata("design:type", String)
], BoostPortalValidateSponsorCode.prototype, "sponsorCodeLookupError", void 0);
BoostPortalValidateSponsorCode = __decorate([
    customElement(BoostPortalValidateSponsorCode.is),
    __metadata("design:paramtypes", [])
], BoostPortalValidateSponsorCode);
export { BoostPortalValidateSponsorCode };

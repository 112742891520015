var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { reduxBind } from 'badphraim/ui/LitReduxMixinBase';
import { css, html, LitElement } from 'lit';
import { customElement, state } from 'lit/decorators.js';
import { BoostPortalStore } from '../../redux/BoostPortalStore';
import { LitReduxBoostPortalMixin } from '../../redux/LitReduxBoostPortalMixin';
let BoostPortalPaymentSuccess = class BoostPortalPaymentSuccess extends LitReduxBoostPortalMixin(LitElement) {
    static get is() {
        return 'boost-portal-payment-success';
    }
    static get styles() {
        return css `
            .payment-text-container {
                padding: 30px;
                background-color: #aed1e0;
                border-radius: 6px;
                margin-bottom: 10px;
                border: 1px solid #89aebd;
                text-align: center;
                display: flex;
                flex-direction: column;
                margin-top: 30px;
            }

            span {
                display: inline-block;
                line-height: 30px;
                font-size: 18px;
            }

            .payment-container {
                display: flex;
                justify-content: space-evenly;
                flex-direction: row;
            }
            .vipps-payment-container {
                padding: 15px;
                background-color: #014a6b;
                margin-top: 25px;
                width: 20%;
                border-radius: 10px;
                border: 1px solid #0202021f;
                justify-content: center;
                display: flex;
            }

            .mail-payment-container {
                padding: 15px;
                background-color: #014a6b;
                margin-top: 25px;
                width: 20%;
                border-radius: 8px;
                border: 1px solid #0202021f;
                color: #fff;
                display: flex;
                justify-content: center;
            }

            .vipps-payment-container:hover,
            .mail-payment-container:hover,
            .selected-payment {
                background-color: #01354c !important;
                color: #fff;
                cursor: pointer;
            }

            .center-text::first-letter {
                text-transform: capitalize;
            }
        `;
    }
    /** @override */ render() {
        var _a, _b, _c;
        return html `<div>
            <div class="payment-text-container">
                <span style="font-weight:600; font-size:22px; padding-bottom:20px">
                    Velkommen som medlem!
                </span>
                <span
                    >Din registrering er fullført og betaling er gjennomført. <br />
                    Se din e-post for informasjon. <br />
                    <p>
                        Hilsen oss i
                        <span class="center-text">${(_c = (_b = (_a = this.center) === null || _a === void 0 ? void 0 : _a.title) === null || _b === void 0 ? void 0 : _b.toLowerCase()) !== null && _c !== void 0 ? _c : 'Ditt senter'}</span>
                    </p></span
                >
            </div>
        </div>`;
    }
};
BoostPortalPaymentSuccess._deps = [BoostPortalStore];
__decorate([
    reduxBind(BoostPortalStore.path('center')),
    state(),
    __metadata("design:type", Object)
], BoostPortalPaymentSuccess.prototype, "center", void 0);
BoostPortalPaymentSuccess = __decorate([
    customElement(BoostPortalPaymentSuccess.is)
], BoostPortalPaymentSuccess);
export { BoostPortalPaymentSuccess };

/* eslint-disable id-length */
import { exhaustiveMatchingGuard } from 'badphraim/core/ExhaustiveMatchingGuard';
import { TrackingEvent } from './TrackingEvent';
/**
 * Wrapper-implementation for the Facebook/meta-pixel tracking facility.
 *
 * Relevant documentation
 * *
 * * Standard events: https://developers.facebook.com/docs/meta-pixel/implementation/conversion-tracking#standard-events
 * * Standard events reference: https://developers.facebook.com/docs/meta-pixel/reference#standard-events
 * *
 */
export class FacebookPixelTracking {
    constructor(pixelId) {
        this.pixelId = pixelId;
        this.disabled = false;
    }
    initialize() {
        // Slightly refactored from https://developers.facebook.com/docs/meta-pixel/get-started
        const self = this;
        (function (globalWindow, doc, libraryScriptSource) {
            if (globalWindow.fbq)
                return;
            const fbqThingy = (globalWindow.fbq = function () {
                if (fbqThingy.callMethod) {
                    fbqThingy.callMethod.apply(fbqThingy, arguments);
                }
                else {
                    fbqThingy.queue.push(arguments);
                }
            });
            if (!globalWindow._fbq)
                globalWindow._fbq = fbqThingy;
            fbqThingy.push = fbqThingy;
            fbqThingy.loaded = !0;
            fbqThingy.version = '2.0';
            fbqThingy.queue = [];
            const scriptTag = doc.createElement('script');
            scriptTag.async = true;
            scriptTag.src = libraryScriptSource;
            self.scriptTag = scriptTag;
            doc.head.appendChild(scriptTag);
        })(window, document, 'https://connect.facebook.net/en_US/fbevents.js');
        this.fbq('init', this.pixelId);
        this.fbq('track', 'PageView');
    }
    fbq(...args) {
        if (this.disabled) {
            return;
        }
        window.fbq(...args);
    }
    disable() {
        var _a;
        this.disabled = true;
        if (this.scriptTag) {
            // Try to remove as much as possible
            (_a = this.scriptTag.parentElement) === null || _a === void 0 ? void 0 : _a.removeChild(this.scriptTag);
            this.scriptTag = undefined;
            delete window['fbq'];
        }
    }
    completedEvent(...args) {
        const [event, parameters] = args;
        if (parameters !== undefined) {
            console.error('Ignoring parameter value for event ' + event, parameters);
        }
        switch (event) {
            case TrackingEvent.MemberSignupStarted:
                this.fbq('trackCustom', 'MemberSignupStarted');
                break;
            case TrackingEvent.SelectedMembership:
                this.fbq('trackCustom', 'SelectedProduct');
                break;
            case TrackingEvent.PersonalInformationFilled:
                this.fbq('trackCustom', 'PersonalInformationFilled');
                break;
            case TrackingEvent.SponsorConfirmed:
                this.fbq('trackCustom', 'SponsorConfirmed');
                break;
            case TrackingEvent.BankIDSigningStarted:
                this.fbq('trackCustom', 'BankIDSigningStarted');
                break;
            case TrackingEvent.AvtalegiroSigningCompleted:
                this.fbq('trackCustom', 'AvtalegiroSigningCompleted');
                break;
            case TrackingEvent.AvtalegiroSigningFailed:
                this.fbq('trackCustom', 'AvtalegiroSigningFailed');
                break;
            case TrackingEvent.VippsPaymentComplete:
                this.fbq('trackCustom', 'VippsPaymentComplete');
                break;
            case TrackingEvent.CompanySponsoredSignupStarted:
                this.fbq('trackCustom', 'CompanySponsoredSignupStarted');
                break;
            case TrackingEvent.CompanySponsoredSelectedDepartment:
                this.fbq('trackCustom', 'CompanySponsoredSelectedDepartment');
                break;
            case TrackingEvent.PrivatelySponsoredSignupStarted:
                this.fbq('trackCustom', 'PrivatelySponsoredSignupStarted');
                break;
            case TrackingEvent.PrivatelySponsoredSelectedDepartment:
                this.fbq('trackCustom', 'PrivatelySponsoredSelectedDepartment');
                break;
            case TrackingEvent.MemberSignupCompleted:
                this.fbq('track', 'CompleteRegistration');
                break;
            case TrackingEvent.RequestedNewPassword:
                this.fbq('trackCustom', 'RequestNewPassword');
                break;
            case TrackingEvent.SelectedDepartment:
                this.fbq('trackCustom', 'SelectedDepartment');
                break;
            default:
                exhaustiveMatchingGuard(event);
        }
    }
}

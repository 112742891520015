export class BoostPortalSessionHandler {
    constructor() {
        this.cookieName = 'BoostPortal';
    }
    getLoginSession() {
        var _a;
        const value = '; ' + document.cookie;
        const parts = value.split('; ' + this.cookieName + '=');
        if (parts.length == 2) {
            return (_a = parts.pop()) === null || _a === void 0 ? void 0 : _a.split(';').shift();
        }
    }
    setLoginSession(session) {
        const date = new Date();
        date.setTime(date.getTime() + 7 * 24 * 60 * 60 * 1000);
        document.cookie = this.cookieName + '=' + session + '; expires=' + date.toUTCString() + '; path=/';
    }
    deleteLoginSession() {
        const date = new Date();
        date.setTime(date.getTime() + -1 * 24 * 60 * 60 * 1000);
        document.cookie = this.cookieName + '=; expires=' + date.toUTCString() + '; path=/';
    }
}

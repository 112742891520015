var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { JSONStruct, nullable, property } from 'badphraim/data/JSONStruct';
export class ewJSONWhoamiSuccess extends JSONStruct {
    constructor() {
        super(...arguments);
        /** @override */ this.admin = false;
        /** @override */ this.article = false;
        /** @override */ this.attend = false;
        /** @override */ this.beta_project_selector = false;
        /** @override */ this.boligmappa = false;
        /** @override */ this.booking = false;
        /** @override */ this.caption_internaltext = false;
        /** @override */ this.change_request = false;
        /** @override */ this.checkin_history = false;
        /** @override */ this.checklist = false;
        /** @override */ this.class_admin = false;
        /** @override */ this.colors = {};
        /** @override */ this.contact = false;
        /** @override */ this.deviation = false;
        /** @override */ this.document = false;
        /** @override */ this.door_logging = false;
        /** @override */ this.doors = false;
        /** @override */ this.event = false;
        /** @override */ this.geo_features = false;
        /** @override */ this.image = false;
        /** @override */ this.instructor_admin = false;
        /** @override */ this.is_rest_user = false;
        /** @override */ this.login_status = false;
        /** @override */ this.mannskapsliste = false;
        /** @override */ this.news_module = false;
        /** @override */ this.occurences = false;
        /** @override */ this.oid = 0;
        /** @override */ this.pid = 0;
        /** @override */ this.primary = false;
        /** @override */ this.scan = false;
        /** @override */ this.seen_declaration = false;
        /** @override */ this.service_url = '';
        /** @override */ this.sessid = '';
        /** @override */ this.show_internaltext = false;
        /** @override */ this.show_ndt = false;
        /** @override */ this.show_start_stop = false;
        /** @override */ this.show_vr = false;
        /** @override */ this.showbuttons = false;
        /** @override */ this.signed_declaration = false;
        /** @override */ this.sjekket_inn = false;
        /** @override */ this.time = false;
        /** @override */ this.time_lock = false;
        /** @override */ this.timeoverview = false;
        /** @override */ this.tool = false;
        /** @override */ this.uid = 0;
    }
}
__decorate([
    nullable,
    __metadata("design:type", String)
], ewJSONWhoamiSuccess.prototype, "access_token", void 0);
__decorate([
    property,
    __metadata("design:type", Boolean)
], ewJSONWhoamiSuccess.prototype, "admin", void 0);
__decorate([
    nullable,
    __metadata("design:type", String)
], ewJSONWhoamiSuccess.prototype, "adresse", void 0);
__decorate([
    property,
    __metadata("design:type", Boolean)
], ewJSONWhoamiSuccess.prototype, "article", void 0);
__decorate([
    property,
    __metadata("design:type", Boolean)
], ewJSONWhoamiSuccess.prototype, "attend", void 0);
__decorate([
    property,
    __metadata("design:type", Boolean)
], ewJSONWhoamiSuccess.prototype, "beta_project_selector", void 0);
__decorate([
    property,
    __metadata("design:type", Boolean)
], ewJSONWhoamiSuccess.prototype, "boligmappa", void 0);
__decorate([
    property,
    __metadata("design:type", Boolean)
], ewJSONWhoamiSuccess.prototype, "booking", void 0);
__decorate([
    nullable,
    __metadata("design:type", Number)
], ewJSONWhoamiSuccess.prototype, "byggekort_no", void 0);
__decorate([
    property,
    __metadata("design:type", Boolean)
], ewJSONWhoamiSuccess.prototype, "caption_internaltext", void 0);
__decorate([
    property,
    __metadata("design:type", Boolean)
], ewJSONWhoamiSuccess.prototype, "change_request", void 0);
__decorate([
    property,
    __metadata("design:type", Boolean)
], ewJSONWhoamiSuccess.prototype, "checkin_history", void 0);
__decorate([
    property,
    __metadata("design:type", Boolean)
], ewJSONWhoamiSuccess.prototype, "checklist", void 0);
__decorate([
    property,
    __metadata("design:type", Boolean)
], ewJSONWhoamiSuccess.prototype, "class_admin", void 0);
__decorate([
    property,
    __metadata("design:type", Object)
], ewJSONWhoamiSuccess.prototype, "colors", void 0);
__decorate([
    property,
    __metadata("design:type", Boolean)
], ewJSONWhoamiSuccess.prototype, "contact", void 0);
__decorate([
    property,
    __metadata("design:type", Boolean)
], ewJSONWhoamiSuccess.prototype, "deviation", void 0);
__decorate([
    nullable,
    __metadata("design:type", Number)
], ewJSONWhoamiSuccess.prototype, "deviceChangesCount", void 0);
__decorate([
    nullable,
    __metadata("design:type", Number)
], ewJSONWhoamiSuccess.prototype, "deviceChangesPeriodInMonths", void 0);
__decorate([
    nullable,
    __metadata("design:type", Number)
], ewJSONWhoamiSuccess.prototype, "did", void 0);
__decorate([
    property,
    __metadata("design:type", Boolean)
], ewJSONWhoamiSuccess.prototype, "document", void 0);
__decorate([
    property,
    __metadata("design:type", Boolean)
], ewJSONWhoamiSuccess.prototype, "door_logging", void 0);
__decorate([
    property,
    __metadata("design:type", Boolean)
], ewJSONWhoamiSuccess.prototype, "doors", void 0);
__decorate([
    nullable,
    __metadata("design:type", String)
], ewJSONWhoamiSuccess.prototype, "epost", void 0);
__decorate([
    nullable,
    __metadata("design:type", String)
], ewJSONWhoamiSuccess.prototype, "error", void 0);
__decorate([
    nullable,
    __metadata("design:type", String)
], ewJSONWhoamiSuccess.prototype, "etternavn", void 0);
__decorate([
    property,
    __metadata("design:type", Boolean)
], ewJSONWhoamiSuccess.prototype, "event", void 0);
__decorate([
    nullable,
    __metadata("design:type", String)
], ewJSONWhoamiSuccess.prototype, "fornavn", void 0);
__decorate([
    property,
    __metadata("design:type", Boolean)
], ewJSONWhoamiSuccess.prototype, "geo_features", void 0);
__decorate([
    property,
    __metadata("design:type", Boolean)
], ewJSONWhoamiSuccess.prototype, "image", void 0);
__decorate([
    nullable,
    __metadata("design:type", String)
], ewJSONWhoamiSuccess.prototype, "initialer", void 0);
__decorate([
    property,
    __metadata("design:type", Boolean)
], ewJSONWhoamiSuccess.prototype, "instructor_admin", void 0);
__decorate([
    property,
    __metadata("design:type", Boolean)
], ewJSONWhoamiSuccess.prototype, "is_rest_user", void 0);
__decorate([
    property,
    __metadata("design:type", Boolean)
], ewJSONWhoamiSuccess.prototype, "login_status", void 0);
__decorate([
    property,
    __metadata("design:type", Boolean)
], ewJSONWhoamiSuccess.prototype, "mannskapsliste", void 0);
__decorate([
    nullable,
    __metadata("design:type", String)
], ewJSONWhoamiSuccess.prototype, "mobil", void 0);
__decorate([
    nullable,
    __metadata("design:type", String)
], ewJSONWhoamiSuccess.prototype, "name", void 0);
__decorate([
    property,
    __metadata("design:type", Boolean)
], ewJSONWhoamiSuccess.prototype, "news_module", void 0);
__decorate([
    property,
    __metadata("design:type", Boolean)
], ewJSONWhoamiSuccess.prototype, "occurences", void 0);
__decorate([
    property,
    __metadata("design:type", Number)
], ewJSONWhoamiSuccess.prototype, "oid", void 0);
__decorate([
    nullable,
    __metadata("design:type", Number)
], ewJSONWhoamiSuccess.prototype, "orgnr", void 0);
__decorate([
    nullable,
    __metadata("design:type", String)
], ewJSONWhoamiSuccess.prototype, "ownername", void 0);
__decorate([
    property,
    __metadata("design:type", Number)
], ewJSONWhoamiSuccess.prototype, "pid", void 0);
__decorate([
    nullable,
    __metadata("design:type", String)
], ewJSONWhoamiSuccess.prototype, "postnr", void 0);
__decorate([
    nullable,
    __metadata("design:type", String)
], ewJSONWhoamiSuccess.prototype, "poststed", void 0);
__decorate([
    property,
    __metadata("design:type", Boolean)
], ewJSONWhoamiSuccess.prototype, "primary", void 0);
__decorate([
    property,
    __metadata("design:type", Boolean)
], ewJSONWhoamiSuccess.prototype, "scan", void 0);
__decorate([
    property,
    __metadata("design:type", Boolean)
], ewJSONWhoamiSuccess.prototype, "seen_declaration", void 0);
__decorate([
    nullable,
    __metadata("design:type", Number)
], ewJSONWhoamiSuccess.prototype, "seen_time", void 0);
__decorate([
    property,
    __metadata("design:type", String)
], ewJSONWhoamiSuccess.prototype, "service_url", void 0);
__decorate([
    property,
    __metadata("design:type", String)
], ewJSONWhoamiSuccess.prototype, "sessid", void 0);
__decorate([
    property,
    __metadata("design:type", Boolean)
], ewJSONWhoamiSuccess.prototype, "show_internaltext", void 0);
__decorate([
    property,
    __metadata("design:type", Boolean)
], ewJSONWhoamiSuccess.prototype, "show_ndt", void 0);
__decorate([
    property,
    __metadata("design:type", Boolean)
], ewJSONWhoamiSuccess.prototype, "show_start_stop", void 0);
__decorate([
    property,
    __metadata("design:type", Boolean)
], ewJSONWhoamiSuccess.prototype, "show_vr", void 0);
__decorate([
    property,
    __metadata("design:type", Boolean)
], ewJSONWhoamiSuccess.prototype, "showbuttons", void 0);
__decorate([
    property,
    __metadata("design:type", Boolean)
], ewJSONWhoamiSuccess.prototype, "signed_declaration", void 0);
__decorate([
    property,
    __metadata("design:type", Boolean)
], ewJSONWhoamiSuccess.prototype, "sjekket_inn", void 0);
__decorate([
    nullable,
    __metadata("design:type", Number)
], ewJSONWhoamiSuccess.prototype, "sjekket_inn_id", void 0);
__decorate([
    nullable,
    __metadata("design:type", Number)
], ewJSONWhoamiSuccess.prototype, "sjekket_inn_prosjekt_id", void 0);
__decorate([
    nullable,
    __metadata("design:type", String)
], ewJSONWhoamiSuccess.prototype, "sjekket_inn_prosjekt_navn", void 0);
__decorate([
    property,
    __metadata("design:type", Boolean)
], ewJSONWhoamiSuccess.prototype, "time", void 0);
__decorate([
    property,
    __metadata("design:type", Boolean)
], ewJSONWhoamiSuccess.prototype, "time_lock", void 0);
__decorate([
    property,
    __metadata("design:type", Boolean)
], ewJSONWhoamiSuccess.prototype, "timeoverview", void 0);
__decorate([
    property,
    __metadata("design:type", Boolean)
], ewJSONWhoamiSuccess.prototype, "tool", void 0);
__decorate([
    property,
    __metadata("design:type", Number)
], ewJSONWhoamiSuccess.prototype, "uid", void 0);

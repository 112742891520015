var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
/* eslint "@typescript-eslint/no-unsafe-argument": "warn" */
// Bruker Dom fra badphraim fordi den er god nok, og skaper sirkulærreferanse ved å bruke cerums
import { Dom } from 'badphraim/dom/Dom';
import { GlobalSingletonInstanceOf } from 'badphraim/core/Singleton';
export class EventDispatcher {
    constructor() {
        this._cerumEventMap = {};
        this._cerumEventRX = {};
        this._eventHandlerMap = {};
        this._handlerId = 0;
    }
    /**
     * @todo bear: 2019.01.23 Den her bør ryddes opp i
     */
    static getGlobalInstance() {
        return GlobalSingletonInstanceOf('EventDispatcher', EventDispatcher);
    }
    _getHandlersForName(eventname) {
        if (typeof this._cerumEventMap[eventname] === 'undefined') {
            return [];
        }
        const handlers = [];
        for (const i in this._cerumEventMap[eventname]) {
            const entry = this._cerumEventMap[eventname][i];
            if (!entry) {
                continue;
            }
            const nodes = entry.length > 1 ? entry[1] : null;
            if (nodes && !this._validNodes(nodes)) {
                this._cerumEventMap[eventname][i] = null;
            }
            else {
                handlers.push(entry);
            }
        } // end foreach map
        return handlers;
    }
    /*
     * Kalles bare i top-framen
     */
    _registerEventListener(eventname, funcClosure, domElements) {
        /*		if (typeof(eventname) == 'object' &&
            typeof(eventname.test) == 'function') {
*/
        if (eventname instanceof RegExp) {
            this._cerumEventRX[++this._handlerId] = [eventname, funcClosure, domElements];
            return this._handlerId;
        }
        //		if (typeof(this._cerumEventMap[eventname]) == 'undefined') {
        if (!(eventname in this._cerumEventMap)) {
            this._cerumEventMap[eventname] = {}; // Vi legg inn et tomt element p� index 0, s� f�r alle som bi adda et non-zero id
        }
        this._cerumEventMap[eventname][++this._handlerId] = [funcClosure, domElements];
        // Den her treng vi tell seinar naar vi ska rydd opp i remove-farta
        this._eventHandlerMap[this._handlerId] = eventname;
        return this._handlerId;
    }
    _removeEventListener(eventname, handlerId) {
        if (typeof eventname === 'object') {
            this._cerumEventRX[handlerId] = null;
        }
        if (typeof this._cerumEventMap[eventname] !== 'undefined') {
            /**
             * Vi setter den til 0, slik at vi ikke roter til idene til tidligere
             * tildelte handlere. S� f�r vi heller hoppe over der vi henter fra
             * arrayet
             */
            this._cerumEventMap[eventname][handlerId] = null;
            //			delete this._cerumEventMap[eventname][handler_id];
        }
    }
    _triggerEvent(eventname, event, ...extraArgs) {
        let handlers = [];
        handlers = handlers.concat(this._getHandlersForName(eventname));
        handlers = handlers.concat(this._getHandlersForName('*'));
        // Kvern igjen regexp-regler
        for (const i in this._cerumEventRX) {
            const entry = this._cerumEventRX[i];
            if (!entry) {
                continue;
            }
            const [regex, callback, domElements] = entry;
            // Rydd i lista
            if (domElements) {
                if (!this._validNodes(domElements)) {
                    // rensk ut ugyldige handlera
                    this._cerumEventRX[i] = null;
                    continue;
                }
            }
            // Skal den være med?
            if (regex && regex.test(eventname)) {
                handlers.push([callback, null]);
            }
        }
        if (!handlers.length) {
            return;
        }
        for (let i = 0; i < handlers.length; i++) {
            const eventHandler = handlers[i];
            if (!eventHandler) {
                // Hopper over, siden den tydeligvis er fjernet
                continue;
            }
            const [eventCallback] = eventHandler;
            try {
                if (eventCallback) {
                    eventCallback(eventname, event, ...extraArgs);
                }
            }
            catch (error) {
                console.error('cerum._triggerEvent("' + eventname + '", ...), cb.apply caused', [
                    error,
                    eventCallback instanceof Object ? eventCallback.toString() : '',
                    eventCallback,
                ]);
            }
        }
    } // end function _triggerEvent
    _validNodes(nodes) {
        const domHelper = new Dom();
        if (nodes instanceof HTMLElement) {
            nodes = [nodes];
        }
        for (let i = 0; i < nodes.length; i++) {
            if (!nodes[i]) {
                continue;
            }
            if (!domHelper.validNode(nodes[i])) {
                return false;
            }
        }
        return true;
    }
    registerEventListener(eventname, closure, domElement = null) {
        /*		var dom = null;
                if (arguments.length > 2) {
                    dom = arguments[2];
                    if (!(dom instanceof Array)) {
                        dom = [dom];
                    }
                }*/
        return this._registerEventListener(eventname, closure, domElement);
    }
    removeEventListener(eventname, handlerId) {
        return this._removeEventListener(eventname, handlerId);
    }
    triggerEvent(evntname, targetDomNode, _doc, _win, event) {
        if (event) {
            event;
        }
        else if (targetDomNode instanceof Event) {
            event = targetDomNode;
        }
        (() => __awaiter(this, void 0, void 0, function* () {
            this._triggerEvent(evntname, event);
        }))();
        //		return cerum.top()._triggerEvent(evntname);
    }
}

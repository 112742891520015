var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { reduxBind } from 'badphraim/ui/LitReduxMixinBase';
import { isErrorHTTP, isSuccessHTTP } from 'boost-api/APIRestClient';
import { MembersignupAPI } from 'boost-api/boost/signup/membersignup/MembersignupAPI';
import { css, html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { BoostPortalStore } from '../redux/BoostPortalStore';
import { LitReduxBoostPortalMixin } from '../redux/LitReduxBoostPortalMixin';
import { router } from '../router/BoostPortalRouter';
import { BoostButton } from '../components/common/BoostButton';
import { BoostPortalChooseDepartment } from '../components/BoostPortalChooseDepartment';
import { BoostPortalPersonalInformation } from '../components/BoostPortalPersonalInformation';
import { BoostStepIndicator } from '../components/common/BoostStepIndicator';
import { BoostPortalSponsorConfirm } from '../components/BoostPortalSponsorConfirm';
import { BoostPortalSponsorProgress } from '../components/BoostPortalSponsorProgress';
import { SnackBar } from 'common-uicomponents/components/SnackBar';
import { BoostPortalParamsHandler } from '../helpers/BoostPortalParamsHandler';
import { ewNewSponsorInfo } from 'boost-api/schemas/ewNewSponsorInfo';
let BoostPortalRegisterSponsorView = class BoostPortalRegisterSponsorView extends LitReduxBoostPortalMixin(LitElement) {
    static get is() {
        return 'boost-portal-register-sponsor-view';
    }
    static get styles() {
        return css `
            :host {
                padding: 3rem;
            }

            h3 {
                color: var(--portalForeground, #404040);
                font-weight: 500;
                margin-bottom: 10px;
            }
        `;
    }
    constructor() {
        super();
        this.currentStep = 1;
        this.sponsorInformation = new ewNewSponsorInfo();
        this.sponsoredId = 0;
        this.connectionHash = '';
        this.updateComplete.then(() => __awaiter(this, void 0, void 0, function* () {
            const sponsorConnectionHash = BoostPortalParamsHandler.getParameter('sponsorConnectionHash');
            if (sponsorConnectionHash) {
                this.connectionHash = sponsorConnectionHash;
                this.sponsoredId = parseInt(sponsorConnectionHash);
                const sponsoredMember = yield MembersignupAPI.getV1BoostsignupSponsed({
                    sponsorConnectionHash,
                });
                if (isSuccessHTTP(sponsoredMember)) {
                    this.sponsoredMember = sponsoredMember;
                }
                else if (isErrorHTTP(sponsoredMember)) {
                    console.error(sponsoredMember.error);
                }
            }
        }));
    }
    /** @override */ render() {
        this._currentStepObserver(); // Føler ikke at denne hører helt hjemme her?
        return html `
            <boost-portal-header .viewLoginButton=${false}></boost-portal-header>

            <boost-step-indicator
                .currentStep="${this.currentStep}"
                .steps="${this._getSteps(this.currentStep)}"
                @currentStep-changed="${(event) => {
            this.currentStep = event.detail.currentStep;
        }}"
            ></boost-step-indicator>

            ${this._showViewForCurrentStep(this.currentStep)}
        `;
    }
    /** @override */ updated(changedProperties) {
        changedProperties.forEach((_oldValue, propertyName) => {
            switch (propertyName) {
                case 'currentStep':
                    this._currentStepObserver();
                    break;
                case 'selectedDepartmentId':
                    this.currentStep = this._determineCurrentStep();
                    break;
            }
        });
        super.updated(changedProperties);
    }
    _createSponsorMember(event) {
        return __awaiter(this, void 0, void 0, function* () {
            window.dispatchEvent(new CustomEvent('validateFields', { bubbles: true, composed: true }));
            const buttonElement = event.srcElement;
            if (buttonElement.disabled) {
                return;
            }
            if (!this.memberInformation || !this._isValidMemberInformation() || !this.selectedDepartmentId) {
                return;
            }
            this.memberInformation.is_sponsor = true;
            this.memberInformation.departmentId = this.selectedDepartmentId;
            this.memberInformation.netsCallbackUrl =
                window.location.origin +
                    router.baseUrl +
                    this.centerShort +
                    '/avtalegiro?departmentId=' +
                    this.selectedDepartmentId;
            this.requestUpdate('memberInformation');
            buttonElement.setLoading(true);
            this.sponsorInformation = this.memberInformation;
            this.sponsorInformation.acceptanceCallbackUrl =
                window.location.origin + router.baseUrl + this.centerShort + '/sponsor/accept';
            this.sponsorInformation.sponsorConnectionHash = this.connectionHash;
            const createdMemberResult = yield MembersignupAPI.postV1BoostsignupSponsor(this.sponsorInformation);
            if (isSuccessHTTP(createdMemberResult)) {
                this.memberInformation = createdMemberResult;
                this.dispatch(BoostPortalStore.Actions.SetMemberInformation(this.memberInformation));
                this.currentStep += 1;
            }
            else if (isErrorHTTP(createdMemberResult)) {
                if (createdMemberResult.error.http_status === 409 &&
                    createdMemberResult.error.message === 'Member already exists.') {
                    SnackBar.push({ type: 'danger', title: 'Medlem eksisterer allerede' });
                }
                else {
                    SnackBar.pushError(createdMemberResult.error);
                }
            }
            buttonElement.setLoading(false);
        });
    }
    _currentStepObserver() {
        if (this.currentStep > this._determineCurrentStep()) {
            this.currentStep = this._determineCurrentStep();
        }
    }
    _determineCurrentStep() {
        if (!this.selectedDepartmentId) {
            return 1;
        }
        else if (!this._isValidMemberInformation()) {
            return 2;
        }
        return 3;
    }
    _getSteps(currentStep) {
        const steps = [
            {
                title: 'Personalia',
                isActive: currentStep === 2,
                subtitle: '',
            },
            {
                title: 'Bekreft',
                isActive: currentStep === 3,
                subtitle: '',
            },
        ];
        return steps;
    }
    _isValidMemberInformation() {
        if (!this.memberInformation) {
            return false;
        }
        if (!this.memberInformation.firstname ||
            !this.memberInformation.lastname ||
            !this.memberInformation.address ||
            !this.memberInformation.postal_code ||
            !this.memberInformation.municipality ||
            !this.memberInformation.phone_number ||
            !this.memberInformation.email ||
            !this.memberInformation.date_of_birth ||
            !this.memberInformation.gender ||
            !this.memberInformation.approve_tos) {
            return false;
        }
        return true;
    }
    _showViewForCurrentStep(currentStep) {
        var _a;
        switch (currentStep) {
            case 1:
                return html `
                    <boost-portal-choose-department
                        lockedToDepartment="${this.selectedDepartmentId != null}"
                    ></boost-portal-choose-department>

                    <div>
                        <boost-button
                            type="submit"
                            name="Gå videre"
                            @click="${() => {
                    if (!this.selectedDepartmentId) {
                        return;
                    }
                    this.currentStep += 1;
                }}"
                            ?disabled="${!this.selectedDepartmentId}"
                            width-full
                        ></boost-button>
                    </div>
                `;
            case 2:
                return html `
                    <boost-portal-personal-information
                        .sponsoredMember="${(_a = this.sponsoredMember) !== null && _a !== void 0 ? _a : undefined}"
                        ?isSponsor="${true}"
                        @memberIsUnderaged-changed="${(event) => {
                    this.memberIsUnderaged = event.detail.memberIsUnderaged;
                }}"
                    ></boost-portal-personal-information>

                    ${this.memberIsUnderaged
                    ? html `
                              <boost-status-container type="info">
                                  Betaler må være over 18 år.
                              </boost-status-container>
                          `
                    : html ` <div>
                              <boost-button
                                  type="submit"
                                  name="Gå videre"
                                  @click="${this._createSponsorMember}"
                                  ?disabled="${!this._isValidMemberInformation()}"
                                  width-full
                              ></boost-button>
                          </div>`}
                `;
            case 3:
                return html ` <boost-portal-sponsor-confirm></boost-portal-sponsor-confirm> `;
        }
    }
};
BoostPortalRegisterSponsorView._deps = [
    BoostButton,
    BoostStepIndicator,
    BoostPortalChooseDepartment,
    BoostPortalPersonalInformation,
    BoostPortalSponsorProgress,
    BoostPortalSponsorConfirm,
];
__decorate([
    reduxBind(BoostPortalStore.path('centerShort')),
    property({ type: String }),
    __metadata("design:type", String)
], BoostPortalRegisterSponsorView.prototype, "centerShort", void 0);
__decorate([
    property({ type: Number }),
    __metadata("design:type", Number)
], BoostPortalRegisterSponsorView.prototype, "currentStep", void 0);
__decorate([
    reduxBind(BoostPortalStore.path('memberInformation')),
    property({ type: Object }),
    __metadata("design:type", Object)
], BoostPortalRegisterSponsorView.prototype, "memberInformation", void 0);
__decorate([
    property({ type: Object }),
    __metadata("design:type", Object)
], BoostPortalRegisterSponsorView.prototype, "sponsorInformation", void 0);
__decorate([
    reduxBind(BoostPortalStore.path('selectedDepartmentId')),
    property({ type: Number }),
    __metadata("design:type", Number)
], BoostPortalRegisterSponsorView.prototype, "selectedDepartmentId", void 0);
__decorate([
    property({ type: Boolean }),
    __metadata("design:type", Boolean)
], BoostPortalRegisterSponsorView.prototype, "memberIsUnderaged", void 0);
__decorate([
    property({ type: Object }),
    __metadata("design:type", Object)
], BoostPortalRegisterSponsorView.prototype, "sponsoredMember", void 0);
__decorate([
    property({ type: Number }),
    __metadata("design:type", Number)
], BoostPortalRegisterSponsorView.prototype, "sponsoredId", void 0);
__decorate([
    property({ type: String }),
    __metadata("design:type", String)
], BoostPortalRegisterSponsorView.prototype, "connectionHash", void 0);
BoostPortalRegisterSponsorView = __decorate([
    customElement(BoostPortalRegisterSponsorView.is),
    __metadata("design:paramtypes", [])
], BoostPortalRegisterSponsorView);
export { BoostPortalRegisterSponsorView };

/* tslint:disable: class-name */
/*
 * AUTOGENERATED FILE - DO NOT EDIT
 */
import { APIRestClient } from '../../../APIRestClient';
import { Department } from '../../../schemas/Department';
import { ewCurrentReportingDepartment_JSON } from '../../../schemas/ewCurrentReportingDepartment_JSON';
export class DepartmentsAPI extends APIRestClient {
    /**
     * Get departments
     * Implementert hovedsakelig i *ewRestResourceV1Departments*::*getAvailableDepartments*
     * bpRestRequestJsonResponse<array<DepartmentJson>>
     */
    static getV1Departments() {
        return this._get('/v1/departments', {}, null, null, Department.classOf(Department, new Department()).arrayOf(), ['https://api.cerum.no/cerumx/login'], 'json');
    }
    /**
     * Get Current Reporting Department
     * Implementert hovedsakelig i *ewRestResourceV1DepartmentsCurrentReporting*::*getCurrentReportingDepartment*
     */
    static getV1DepartmentsReporting() {
        return this._get('/v1/departments/reporting', {}, null, null, ewCurrentReportingDepartment_JSON.classOf(ewCurrentReportingDepartment_JSON, new ewCurrentReportingDepartment_JSON()), ['https://api.cerum.no/cerumx/login'], 'json');
    }
    /**
     * Get department by id
     * Implementert hovedsakelig i *ewRestResourceV1Departments*::*getOneDepartment*
     * bpRestRequestJsonResponse<DepartmentJson>
     */
    static getV1DepartmentsUsingDepartmentid(departmentID) {
        return this._get('/v1/departments/{departmentID}', { departmentID }, null, null, Department.classOf(Department, new Department()), ['https://api.cerum.no/cerumx/login'], 'json');
    }
    /**
     * update department by id
     * Implementert hovedsakelig i *ewRestResourceV1Departments*::*updateOneDepartment*
     * string $resource
     *  bpRestRequestJsonResponse<DepartmentJson>
     *  RestException
     */
    static patchV1DepartmentUsingDepartmentid(departmentID) {
        return this._patch('/v1/department/{departmentID}', { departmentID }, null, null, Department.classOf(Department, new Department()), ['https://api.cerum.no/cerumx/login'], 'json');
    }
    /**
     * Create new department
     * Implementert hovedsakelig i *ewRestResourceV1Departments*::*create*
     */
    static postV1Departments(__DATA) {
        return this._post('/v1/departments', {}, null, JSON.stringify(__DATA), Department.classOf(Department, new Department()), ['https://api.cerum.no/cerumx/login'], 'json');
    }
    /**
     * update department by id
     * Implementert hovedsakelig i *ewRestResourceV1Departments*::*updateOneDepartment*
     * string $resource
     *  bpRestRequestJsonResponse<DepartmentJson>
     *  RestException
     */
    static putV1DepartmentUsingDepartmentid(departmentID) {
        return this._put('/v1/department/{departmentID}', { departmentID }, null, null, Department.classOf(Department, new Department()), ['https://api.cerum.no/cerumx/login'], 'json');
    }
    /**
     * Update Current Reporting Department
     * Implementert hovedsakelig i *ewRestResourceV1DepartmentsCurrentReporting*::*updateCurrentReportingDepartment*
     */
    static putV1DepartmentsReportingUsingDepartmentid(departmentID) {
        return this._put('/v1/departments/reporting/{departmentID}', { departmentID }, null, null, ewCurrentReportingDepartment_JSON.classOf(ewCurrentReportingDepartment_JSON, new ewCurrentReportingDepartment_JSON()), ['https://api.cerum.no/cerumx/login'], 'json');
    }
}

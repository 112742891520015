/* tslint:disable: class-name */
/*
 * AUTOGENERATED FILE - DO NOT EDIT
 */
import { APIRestClient } from '../../../APIRestClient';
import { ewRestPersonHours } from '../../../schemas/ewRestPersonHours';
import { exPerson } from '../../../schemas/exPerson';
export class PersonAPI extends APIRestClient {
    /**
     * Opprett person
     * Implementert hovedsakelig i *ewRestResourceV1Persons*::*read*
     */
    static getV1Persons(__QUERY) {
        return this._get('/v1/persons', {}, __QUERY, null, exPerson.classOf(exPerson, new exPerson()).arrayOf(), ['https://api.cerum.no/cerumx/login'], 'json');
    }
    /**
     * Hent ut info om person. Laget for � hente rolle-statistikken
     * Implementert hovedsakelig i *ewRestRequestPerson*::*read*
     */
    static getV1PersonsUsingPersonId(person_id) {
        return this._get('/v1/persons/{person_id}', { person_id }, null, null, exPerson.classOf(exPerson, new exPerson()), ['https://api.cerum.no/cerumx/login'], 'json');
    }
    /**
     * Hent antall arbeidstimer personer har i en tidsperiode
     * Implementert hovedsakelig i *ewRestRequestPersonWorkHours*::*read*
     */
    static getV1PersonsWorkhours(__QUERY) {
        return this._get('/v1/persons/workhours', {}, __QUERY, null, ewRestPersonHours.classOf(ewRestPersonHours, new ewRestPersonHours()).arrayOf(), ['https://api.cerum.no/cerumx/login'], 'json');
    }
    /**
     * Opprett person
     * Implementert hovedsakelig i *ewRestResourceV1Persons*::*create*
     */
    static postV1Persons(__DATA) {
        return this._post('/v1/persons', {}, null, JSON.stringify(__DATA), exPerson.classOf(exPerson, new exPerson()), ['https://api.cerum.no/cerumx/login'], 'json');
    }
    /**
     * Endre en person
     * Implementert hovedsakelig i *ewRestRequestPerson*::*update*
     */
    static putV1PersonsUsingPersonId(person_id, __DATA) {
        return this._put('/v1/persons/{person_id}', { person_id }, null, JSON.stringify(__DATA), exPerson.classOf(exPerson, new exPerson()), ['https://api.cerum.no/cerumx/login'], 'json');
    }
}

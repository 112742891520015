var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { css, html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';
let CerumFormLabel = class CerumFormLabel extends LitElement {
    constructor() {
        super(...arguments);
        this.required = false;
    }
    static get is() {
        return 'cerum-form-label';
    }
    static get styles() {
        return css `
            :host {
                color: var(--lumo-body-text-color);
                font-size: var(--lumo-font-size-m, 1rem);
                font-family: var(--lumo-font-family);
                -moz-osx-font-smoothing: grayscale;
            }

            label {
                align-self: flex-start;
                color: var(--lumo-secondary-text-color);
                font-weight: 500;
                font-size: var(--cerum-form-label-font-size, --lumo-font-size-s, 0.875rem);
                margin-left: calc(var(--lumo-border-radius-m, 0.25em) / 4);
                transition: color 0.2s;
                line-height: 1;
                padding-right: 1em;
                padding-bottom: 0.5em;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                position: relative;
                max-width: 100%;
                box-sizing: border-box;
            }

            :host([required='']) label::after {
                content: '•';
                color: #0060dd;
                margin-left: 3px;
            }
        `;
    }
    /** @override */ render() {
        return html ` <label><slot></slot></label>`;
    }
};
CerumFormLabel._deps = [];
__decorate([
    property({ type: Boolean, attribute: 'required' }),
    __metadata("design:type", Object)
], CerumFormLabel.prototype, "required", void 0);
CerumFormLabel = __decorate([
    customElement(CerumFormLabel.is)
], CerumFormLabel);
export { CerumFormLabel };

/* tslint:disable: class-name */
/*
 * AUTOGENERATED FILE - DO NOT EDIT
 */
import { APIRestClient } from '../../../APIRestClient';
import { ewSystemPersistentRestHandler_VALUE } from '../../../schemas/ewSystemPersistentRestHandler_VALUE';
export class PersistentAPI extends APIRestClient {
    /**
     * Hent verdi
     * Implementert hovedsakelig i *ewSystemPersistentRestHandler*::*read*
     */
    static getSystemPersistentUsingKey(key) {
        return this._get('/system/persistent/{key}', { key }, null, null, ewSystemPersistentRestHandler_VALUE.classOf(ewSystemPersistentRestHandler_VALUE, new ewSystemPersistentRestHandler_VALUE()), ['https://api.cerum.no/cerumx/login'], 'json');
    }
    /**
     * Sett verdi
     * Implementert hovedsakelig i *ewSystemPersistentRestHandler*::*create*
     */
    static postSystemPersistentUsingKey(key, __DATA) {
        return this._post('/system/persistent/{key}', { key }, null, JSON.stringify(__DATA), ewSystemPersistentRestHandler_VALUE.classOf(ewSystemPersistentRestHandler_VALUE, new ewSystemPersistentRestHandler_VALUE()), ['https://api.cerum.no/cerumx/login'], 'json');
    }
}

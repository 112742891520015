var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { css, html, LitElement } from 'lit';
import { customElement, state } from 'lit/decorators.js';
import { BoostButton } from '../components/common/BoostButton';
import { BoostStatusContainer } from '../components/common/BoostStatusContainer';
import { router } from '../router/BoostPortalRouter';
import { LitReduxBoostPortalMixin } from '../redux/LitReduxBoostPortalMixin';
import { reduxBind } from 'badphraim/ui/LitReduxMixinBase';
import { BoostPortalStore } from '../redux/BoostPortalStore';
import { BoostMetoderTilgjengeligEtterInnloggAPI } from 'boost-api/boost/signup/boost_metoder_tilgjengelig_etter_innlogg/BoostMetoderTilgjengeligEtterInnloggAPI';
import { isSuccessHTTP, isErrorHTTP } from 'boost-api/APIRestClient';
import { SnackBar } from 'common-uicomponents/components/SnackBar';
import { BoostPortalSessionHandler } from '../helpers/BoostPortalSessionHandler';
import { BoostPortalPersonalInformation } from '../components/BoostPortalPersonalInformation';
import { MembersignupAPI } from 'boost-api/boost/signup/membersignup/MembersignupAPI';
import { BoostPortalHeader } from '../components/BoostPortalHeader';
let BoostPortalProfileView = class BoostPortalProfileView extends LitReduxBoostPortalMixin(LitElement) {
    constructor() {
        super(...arguments);
        this.memberships = [];
        this.sessionHandler = new BoostPortalSessionHandler();
    }
    static get is() {
        return 'boost-portal-profile-view';
    }
    static get styles() {
        return css `
            :host {
                width: 80%;
            }

            .section {
                margin-bottom: 10px;
            }

            a:link,
            a:visited,
            a:active {
                text-decoration: underline;
                color: var(--portalLink, #2a9ba3);
            }

            a:hover {
                text-decoration: none;
            }
        `;
    }
    _getActiveMemberships() {
        return this.memberships.filter((membership) => membership.status === true);
    }
    _getInactiveMemberships() {
        return this.memberships.filter((membership) => !membership.status);
    }
    /** @override */ render() {
        return html `
            <boost-portal-header></boost-portal-header>
            ${this.session
            ? html `
                      <h2>Min profil</h2>

                      <span style="font-size:20px;">Aktive medlemskap:</span>

                      ${this._getActiveMemberships().length
                ? this._renderActiveMemberships()
                : this._isSponsoredStatusContainer()}
                      <boost-portal-personal-information
                          ?hideApproval="${true}"
                          ?memberLoggedIn="${true}"
                      ></boost-portal-personal-information>

                      <div style="padding-top:20px; padding-bottom:20px;">
                          <boost-button
                              type="submit"
                              name="Oppdater informasjon"
                              @click="${this._updateMember}"
                              ?disabled="${!this._isValidMemberInformation()}"
                              width-full
                          ></boost-button>
                      </div>
                  `
            : html `<boost-status-container type="danger">
                      Du må være innlogget for å se profilen din. Logg inn
                      <a href="${router.baseUrl}${this.centerShort}/login">her</a>.
                  </boost-status-container>`}
        `;
    }
    _renderActiveMemberships() {
        return this._getActiveMemberships().map((membership) => this._renderActiveMembership(membership));
    }
    _isSponsoredStatusContainer() {
        var _a;
        if ((_a = this.loggedInProfile) === null || _a === void 0 ? void 0 : _a.is_sponsored) {
            return html `<boost-status-container type="info" style="font-size:18px">
                Det er registrert en annen betaler på din profil og du kan derfor ikke inngå ny avtale, ta
                kontakt med oss så skal vi hjelpe deg.
            </boost-status-container>`;
        }
        return html `<boost-status-container type="info" style="font-size:18px">
            Du har ingen aktive medlemskap. Inngå ny avtale
            <a href="${router.baseUrl}${this.centerShort}/member?directdebit=false">her</a>.
        </boost-status-container>`;
    }
    _renderActiveMembership(membership) {
        return html ` <boost-status-container type="info">
            <div style="display: flex; padding:6px;">
                <div style="margin-right: 40px;">
                    <div class="section">
                        <div style="font-size:18px">Medlemskap:</div>
                        <div>
                            ${membership.linjer.map((line) => html `${line.tittel && line.tittel.split('/').pop()}, ${line.pris}<br />`)}
                        </div>
                    </div>
                    <div class="section">
                        <div>AvtaleGiro</div>
                        <div>${membership.bbs_status ? html `Aktiv` : html `Inaktiv`}</div>
                    </div>
                </div>
                <div>
                    <div class="section">
                        <div>Status</div>
                        <div>${membership.status ? html `Aktiv` : html `Inaktiv`}</div>
                    </div>
                    <div class="section">
                        <div>Bindingstid</div>
                        <div>${this._getContractDate(membership.binding_dato)}</div>
                    </div>
                </div>
            </div>

            ${membership.bbs_status
            ? html ``
            : html `<div style="text-align: center;">
                      <boost-button
                          type="submit"
                          name="Sett opp AvtaleGiro"
                          width-full
                          @click="${() => {
                if (!membership.nets_url) {
                    return;
                }
                this._openAvtaleGiro(membership.nets_url);
            }}"
                      ></boost-button>
                      <div style="padding-bottom: 10px;">
                          Hvis du har signert AvtaleGiro nylig, behøver du ikke gjøre dette på nytt.
                      </div>
                  </div>`}
        </boost-status-container>`;
    }
    _updateMember(event) {
        return __awaiter(this, void 0, void 0, function* () {
            window.dispatchEvent(new CustomEvent('validateFields', { bubbles: true, composed: true }));
            const buttonElement = event.srcElement;
            if (buttonElement.disabled) {
                return;
            }
            if (!this.loggedInProfile || !this.memberInformation || !this._isValidMemberInformation()) {
                return;
            }
            if (!this.loggedInProfile.memberid) {
                return;
            }
            buttonElement.setLoading(true);
            const updatedMember = yield MembersignupAPI.putV1BoostsignupMemberUsingMemberId(this.loggedInProfile.memberid, this.memberInformation);
            if (isSuccessHTTP(updatedMember) && updatedMember) {
                this.memberInformation = Object.assign(Object.assign({}, this.memberInformation), updatedMember);
                this.dispatch(BoostPortalStore.Actions.SetMemberInformation(this.memberInformation));
                SnackBar.push({ title: 'Medlemsinformasjon oppdatert' });
            }
            else if (isErrorHTTP(updatedMember)) {
                SnackBar.pushError(updatedMember.error);
            }
            buttonElement.setLoading(false);
        });
    }
    _isValidMemberInformation() {
        const { memberInformation } = this;
        if (!memberInformation) {
            return false;
        }
        if (!memberInformation.firstname ||
            !memberInformation.lastname ||
            !memberInformation.address ||
            !memberInformation.postal_code ||
            !memberInformation.municipality ||
            !memberInformation.phone_number ||
            !memberInformation.email ||
            !memberInformation.date_of_birth ||
            !memberInformation.gender) {
            return false;
        }
        return true;
    }
    _getContractDate(timestamp) {
        if (!timestamp || timestamp == null) {
            return 'Ingen bindingstid';
        }
        if (timestamp < Date.now() / 1000) {
            return 'Utgått';
        }
        else {
            if (typeof timestamp === 'number') {
                const date = new Date(timestamp * 1000);
                return date.toLocaleDateString('no-NO', {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                });
            }
            return timestamp;
        }
    }
    _openAvtaleGiro(netsUrl) {
        window.location.replace(netsUrl + window.location.origin + router.baseUrl + this.centerShort + '/avtalegiro?status=ok');
    }
    /** @override */ updated(changedProperties) {
        changedProperties.forEach((_oldValue, propertyName) => {
            switch (propertyName) {
                case 'session':
                    this._sessionObserver();
                    break;
            }
        });
        super.updated(changedProperties);
    }
    _sessionObserver() {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.session) {
                return;
            }
            const profileResult = yield BoostMetoderTilgjengeligEtterInnloggAPI.getExerciseProfile({
                session: this.session,
            });
            if (isSuccessHTTP(profileResult) && profileResult) {
                this.memberships = profileResult.avtaler;
                this.dispatch(BoostPortalStore.Actions.SetLoggedInProfile(profileResult));
            }
            else if (isErrorHTTP(profileResult)) {
                SnackBar.pushError(profileResult.error);
            }
        });
    }
};
BoostPortalProfileView._deps = [
    BoostButton,
    BoostStatusContainer,
    BoostPortalPersonalInformation,
    BoostPortalHeader,
];
__decorate([
    reduxBind(BoostPortalStore.path('centerShort')),
    state(),
    __metadata("design:type", String)
], BoostPortalProfileView.prototype, "centerShort", void 0);
__decorate([
    reduxBind(BoostPortalStore.path('session')),
    state(),
    __metadata("design:type", String)
], BoostPortalProfileView.prototype, "session", void 0);
__decorate([
    state(),
    __metadata("design:type", Array)
], BoostPortalProfileView.prototype, "memberships", void 0);
__decorate([
    reduxBind(BoostPortalStore.path('memberInformation')),
    state(),
    __metadata("design:type", Object)
], BoostPortalProfileView.prototype, "memberInformation", void 0);
__decorate([
    reduxBind(BoostPortalStore.path('loggedInProfile')),
    state(),
    __metadata("design:type", Object)
], BoostPortalProfileView.prototype, "loggedInProfile", void 0);
BoostPortalProfileView = __decorate([
    customElement(BoostPortalProfileView.is)
], BoostPortalProfileView);
export { BoostPortalProfileView };

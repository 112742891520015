import { CerumReduxSubStore } from './CerumReduxSubStore';
class CerumParticipantStoreLayout {
    constructor() {
        this.selectedParticipants = [];
    }
}
export class CerumParticipantStore extends CerumReduxSubStore {
    constructor() {
        super(...arguments);
        /** @override */ this.base_key = 'CerumParticipantStore';
    }
    static get instance() {
        return this._instance || (this._instance = new CerumParticipantStore());
    }
    static path(property) {
        return this.staticPath('CerumParticipantStore', property);
    }
    /** @override */ reducer() {
        return {
            [this.base_key]: (state = new CerumParticipantStoreLayout(), action) => {
                switch (action.type) {
                    case CerumParticipantStore.Actions.TRUNCATE_PARTICIPANTS:
                        return Object.assign(Object.assign({}, state), { selectedParticipants: [] });
                    case CerumParticipantStore.Actions.ADD_PARTICIPANT:
                        return Object.assign(Object.assign({}, state), { selectedParticipants: [...state.selectedParticipants, action.payload.participant] });
                    case CerumParticipantStore.Actions.REMOVE_PARTICIPANT:
                        return Object.assign(Object.assign({}, state), { selectedParticipants: [
                                ...state.selectedParticipants.filter((participant) => participant.personId !== action.payload.participant.personId),
                            ] });
                    case CerumParticipantStore.Actions.UPDATE_PARTICIPANT_ROLES:
                        return Object.assign(Object.assign({}, state), { selectedParticipants: state.selectedParticipants.map((participant, index) => {
                                if (index === action.payload.participantIndex) {
                                    return Object.assign(Object.assign({}, participant), { roles: action.payload.roles });
                                }
                                return participant;
                            }) });
                    case CerumParticipantStore.Actions.SET_PARTICIPANTS:
                        return Object.assign(Object.assign({}, state), { selectedParticipants: action.payload.participants });
                }
                return state;
            },
        };
    }
}
(function (CerumParticipantStore) {
    let Actions;
    (function (Actions) {
        Actions.TRUNCATE_PARTICIPANTS = 'TRUNCATE_PARTICIPANTS';
        Actions.ADD_PARTICIPANT = 'ADD_PARTICIPANT';
        Actions.REMOVE_PARTICIPANT = 'REMOVE_PARTICIPANT';
        Actions.UPDATE_PARTICIPANT_ROLES = 'UPDATE_PARTICIPANT_ROLES';
        Actions.SET_PARTICIPANTS = 'SET_PARTICIPANTS';
        Actions.TruncateParticipants = () => ({
            type: Actions.TRUNCATE_PARTICIPANTS,
            payload: {},
        });
        Actions.AddParticipant = (participant) => ({
            type: Actions.ADD_PARTICIPANT,
            payload: {
                participant,
            },
        });
        Actions.RemoveParticipant = (participant) => ({
            type: Actions.REMOVE_PARTICIPANT,
            payload: {
                participant,
            },
        });
        Actions.UpdateParticipantRoles = (participantIndex, roles) => ({
            type: Actions.UPDATE_PARTICIPANT_ROLES,
            payload: {
                participantIndex,
                roles,
            },
        });
        Actions.SetParticipants = (participants) => ({
            type: Actions.SET_PARTICIPANTS,
            payload: {
                participants,
            },
        });
    })(Actions = CerumParticipantStore.Actions || (CerumParticipantStore.Actions = {}));
})(CerumParticipantStore || (CerumParticipantStore = {}));

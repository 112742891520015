var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { reduxBind } from 'badphraim/ui/LitReduxMixinBase';
import { css, html, LitElement } from 'lit';
import { customElement, state } from 'lit/decorators.js';
import { BoostPortalStore } from '../redux/BoostPortalStore';
import { LitReduxBoostPortalMixin } from '../redux/LitReduxBoostPortalMixin';
import { BoostButton } from './common/BoostButton';
import { BoostPortalSponsorProgress } from './BoostPortalSponsorProgress';
let BoostPortalSponsorConfirm = class BoostPortalSponsorConfirm extends LitReduxBoostPortalMixin(LitElement) {
    static get is() {
        return 'boost-portal-sponsor-confirm';
    }
    static get styles() {
        return css `
            .progress {
                display: flex;
                margin: 20px 0;
                font-size: 140%;
                font-weight: 300;
            }

            .progress div {
                flex: 1;
            }

            .progress div:first-child {
                margin-right: 10px;
            }

            hr {
                border: 0;
                border-top: 1px solid var(--portalAccentBackground, #e5efbb);
                border-bottom: 1px solid var(--portalAccentBackgroundActive, #f7f7f7);
            }
        `;
    }
    /** @override */ render() {
        return html `
            <boost-portal-sponsor-progress></boost-portal-sponsor-progress>

            <div class="progress">
                <div>Signering</div>
                <div style="text-align: right;">
                    <boost-button
                        type="submit"
                        name="Signer med BankID"
                        @click="${this._openAvtalegiro}"
                    ></boost-button>
                </div>
            </div>
        `;
    }
    _openAvtalegiro() {
        if (!this.memberInformation) {
            return;
        }
        window.location.replace(this.memberInformation.link_to_nets);
    }
};
BoostPortalSponsorConfirm._deps = [BoostButton, BoostPortalSponsorProgress];
__decorate([
    reduxBind(BoostPortalStore.path('memberInformation')),
    state(),
    __metadata("design:type", Object)
], BoostPortalSponsorConfirm.prototype, "memberInformation", void 0);
BoostPortalSponsorConfirm = __decorate([
    customElement(BoostPortalSponsorConfirm.is)
], BoostPortalSponsorConfirm);
export { BoostPortalSponsorConfirm };

var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
/* tslint:disable: class-name */
/*
 * AUTOGENERATED FILE - DO NOT EDIT
 */
import { ewContactJson } from './ewContactJson';
import { ewExpenseArticleJson } from './ewExpenseArticleJson';
import { ewExpenseOrderJson } from './ewExpenseOrderJson';
import { ewExpenseOrderLineJson } from './ewExpenseOrderLineJson';
import { ewPersonJSON } from './ewPersonJSON';
import { ewUserJson } from './ewUserJson';
import { JSONStruct, nullable, property } from 'badphraim/data/JSONStruct';
export class ewExpenseJson extends JSONStruct {
    constructor() {
        super(...arguments);
        /** @override */ this.expense_id = 0;
    }
}
__decorate([
    nullable,
    __metadata("design:type", Number)
], ewExpenseJson.prototype, "approved_by", void 0);
__decorate([
    nullable,
    __metadata("design:type", ewExpenseArticleJson)
], ewExpenseJson.prototype, "article", void 0);
__decorate([
    nullable,
    __metadata("design:type", ewContactJson)
], ewExpenseJson.prototype, "contact", void 0);
__decorate([
    nullable,
    __metadata("design:type", Number)
], ewExpenseJson.prototype, "cost", void 0);
__decorate([
    nullable,
    __metadata("design:type", Number)
], ewExpenseJson.prototype, "count", void 0);
__decorate([
    nullable,
    __metadata("design:type", Number)
], ewExpenseJson.prototype, "date", void 0);
__decorate([
    property,
    __metadata("design:type", Number)
], ewExpenseJson.prototype, "expense_id", void 0);
__decorate([
    nullable,
    __metadata("design:type", Number)
], ewExpenseJson.prototype, "mva_sats", void 0);
__decorate([
    nullable,
    __metadata("design:type", ewExpenseOrderJson)
], ewExpenseJson.prototype, "order", void 0);
__decorate([
    nullable,
    __metadata("design:type", ewExpenseOrderLineJson)
], ewExpenseJson.prototype, "order_line", void 0);
__decorate([
    nullable,
    __metadata("design:type", ewPersonJSON)
], ewExpenseJson.prototype, "person", void 0);
__decorate([
    nullable,
    __metadata("design:type", Number)
], ewExpenseJson.prototype, "price", void 0);
__decorate([
    nullable,
    __metadata("design:type", ewUserJson)
], ewExpenseJson.prototype, "registered_by_user", void 0);
__decorate([
    nullable,
    __metadata("design:type", ewContactJson)
], ewExpenseJson.prototype, "registered_to_contact", void 0);
__decorate([
    nullable,
    __metadata("design:type", Number)
], ewExpenseJson.prototype, "sum", void 0);
__decorate([
    nullable,
    __metadata("design:type", String)
], ewExpenseJson.prototype, "text", void 0);
__decorate([
    nullable,
    __metadata("design:type", String)
], ewExpenseJson.prototype, "title", void 0);

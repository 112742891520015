/* tslint:disable: class-name */
/*
 * AUTOGENERATED FILE - DO NOT EDIT
 */
import { APIRestClient } from '../../../APIRestClient';
import { Article } from '../../../schemas/Article';
import { ArticleResources } from '../../../schemas/ArticleResources';
export class ArticlesAPI extends APIRestClient {
    /**
     * Get articles
     * Implementert hovedsakelig i *ewRestResourceV1Articles*::*getArticles*
     */
    static getV1Articles(__QUERY) {
        return this._get('/v1/articles', {}, __QUERY, null, Article.classOf(Article, new Article()).arrayOf(), ['https://api.cerum.no/cerumx/login'], 'json');
    }
    /**
     * Get articles with connected resources
     * Implementert hovedsakelig i *ewRestResourceV1Articles*::*getArticleResources*
     */
    static getV1ArticlesResources(__QUERY) {
        return this._get('/v1/articles/resources', {}, __QUERY, null, ArticleResources.classOf(ArticleResources, new ArticleResources()).arrayOf(), ['https://api.cerum.no/cerumx/login'], 'json');
    }
}

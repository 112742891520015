var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { css, html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';
let BoostStatus = class BoostStatus extends LitElement {
    constructor() {
        super(...arguments);
        this.type = 'info';
    }
    static get is() {
        return 'boost-status';
    }
    static get styles() {
        return css `
            .badge {
                width: 145px;
                height: 145px;
                margin: 0 auto;
            }

            .success svg {
                stroke: var(--portalButtonSuccessForeground, hsl(159, 75%, 16%));
            }

            .info svg {
                stroke: var(--portalButtonInfoForeground, hsl(210, 75%, 16%));
            }

            .warning svg {
                stroke: var(--portalButtonWarningForeground, hsl(28, 74%, 24%));
            }

            .danger svg {
                stroke: var(--portalButtonDangerForeground, hsl(360, 76%, 20%));
            }

            .unknown svg {
                stroke: var(--portalButtonPrimaryForeground, hsl(211.2, 13%, 20%));
            }

            .content {
                text-align: center;
                padding: 40px 0;
                font-size: 120%;
            }
        `;
    }
    /** @override */ render() {
        return html `
            <div>
                <div class="badge ${this.type}">${this._getIconForType()}</div>
                <div class="content">
                    <slot></slot>
                </div>
            </div>
        `;
    }
    _getDangerIcon() {
        return html `
            <svg
                xmlns="http://www.w3.org/2000/svg"
                version="1.1"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                xmlns:svgjs="http://svgjs.com/svgjs"
                viewBox="0 0 140 140"
                width="140"
                height="140"
            >
                <g transform="matrix(5.833333333333333,0,0,5.833333333333333,0,0)">
                    <path
                        d="M4.199 4.307 L19.755 4.307 L19.755 19.863 L4.199 19.863 Z"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        transform="translate(-5.038 12.008) rotate(-45)"
                    ></path>
                    <path
                        d="M12 14.11L12 7.11"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    ></path>
                    <path
                        d="M11.996 16.11L11.996 16.11"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    ></path>
                    <path
                        d="M11.987,16.11a.245.245,0,0,0-.237.255A.254.254,0,0,0,12,16.61h.009a.246.246,0,0,0,.237-.255A.253.253,0,0,0,12,16.11"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    ></path>
                    <path
                        d="M12,16.11h-.009"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    ></path>
                </g>
            </svg>
        `;
    }
    _getIconForType() {
        switch (this.type) {
            case 'success':
                return this._getSuccessIcon();
            case 'info':
                return this._getInfoIcon();
            case 'warning':
                return this._getWarningIcon();
            case 'danger':
                return this._getDangerIcon();
            case 'unknown':
                return this._getUnknownIcon();
        }
    }
    _getInfoIcon() {
        return html `
            <svg
                xmlns="http://www.w3.org/2000/svg"
                version="1.1"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                xmlns:svgjs="http://svgjs.com/svgjs"
                viewBox="0 0 140 140"
                width="140"
                height="140"
            >
                <g transform="matrix(5.833333333333333,0,0,5.833333333333333,0,0)">
                    <path
                        d="M1.000 12.001 A11.000 11.000 0 1 0 23.000 12.001 A11.000 11.000 0 1 0 1.000 12.001 Z"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    ></path>
                    <path
                        d="M14.5,17.005H13a1,1,0,0,1-1-1v-6.5a.5.5,0,0,0-.5-.5H10"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    ></path>
                    <path
                        d="M11.745 6.504L11.745 6.504"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    ></path>
                    <path
                        d="M11.745,6.5a.25.25,0,1,0,.25.25.25.25,0,0,0-.25-.25"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    ></path>
                </g>
            </svg>
        `;
    }
    _getSuccessIcon() {
        return html `
            <svg
                xmlns="http://www.w3.org/2000/svg"
                version="1.1"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                xmlns:svgjs="http://svgjs.com/svgjs"
                viewBox="0 0 140 140"
                width="140"
                height="140"
            >
                <g transform="matrix(5.833333333333333,0,0,5.833333333333333,0,0)">
                    <path
                        d="M18 6.999L11 16.499 6 12.499"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    ></path>
                    <path
                        d="M0.500 11.999 A11.500 11.500 0 1 0 23.500 11.999 A11.500 11.500 0 1 0 0.500 11.999 Z"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    ></path>
                </g>
            </svg>
        `;
    }
    _getUnknownIcon() {
        return html `
            <svg
                xmlns="http://www.w3.org/2000/svg"
                version="1.1"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                xmlns:svgjs="http://svgjs.com/svgjs"
                viewBox="0 0 140 140"
                width="140"
                height="140"
            >
                <g transform="matrix(5.833333333333333,0,0,5.833333333333333,0,0)">
                    <path
                        d="M8.25,9.742A3.752,3.752,0,1,1,12.812,13.4a1.007,1.007,0,0,0-.812.975v1.113"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    ></path>
                    <path
                        d="M12,17.992a.25.25,0,1,1-.25.25.25.25,0,0,1,.25-.25"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    ></path>
                    <path
                        d="M0.500 12.001 A11.500 11.500 0 1 0 23.500 12.001 A11.500 11.500 0 1 0 0.500 12.001 Z"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    ></path>
                </g>
            </svg>
        `;
    }
    _getWarningIcon() {
        return html `
            <svg
                xmlns="http://www.w3.org/2000/svg"
                version="1.1"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                xmlns:svgjs="http://svgjs.com/svgjs"
                viewBox="0 0 140 140"
                width="140"
                height="140"
            >
                <g transform="matrix(5.833333333333333,0,0,5.833333333333333,0,0)">
                    <path
                        d="M1.311,20.479,11.123,2.561a1,1,0,0,1,1.754,0l9.812,17.918a1,1,0,0,1-.877,1.48H2.188A1,1,0,0,1,1.311,20.479Z"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    ></path>
                    <path
                        d="M12 9.459L12 15.959"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    ></path>
                    <path
                        d="M12,18.209a.25.25,0,0,1,.25.25"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    ></path>
                    <path
                        d="M11.75,18.459a.25.25,0,0,1,.25-.25"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    ></path>
                    <path
                        d="M12,18.709a.25.25,0,0,1-.25-.25"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    ></path>
                    <path
                        d="M12.25,18.459a.25.25,0,0,1-.25.25"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    ></path>
                </g>
            </svg>
        `;
    }
};
BoostStatus._deps = [];
__decorate([
    property({ type: String }),
    __metadata("design:type", String)
], BoostStatus.prototype, "type", void 0);
BoostStatus = __decorate([
    customElement(BoostStatus.is)
], BoostStatus);
export { BoostStatus };

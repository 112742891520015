var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { css, html, LitElement } from 'lit';
import { customElement, state } from 'lit/decorators.js';
import { BoostButton } from '../components/common/BoostButton';
import { LitReduxBoostPortalMixin } from '../redux/LitReduxBoostPortalMixin';
import { InnloggingPortalAPI } from 'boost-api/boost/signup/innlogging_portal/InnloggingPortalAPI';
import { reduxBind } from 'badphraim/ui/LitReduxMixinBase';
import { BoostPortalStore } from '../redux/BoostPortalStore';
import { isSuccessHTTP, isErrorHTTP } from 'boost-api/APIRestClient';
import { BoostPortalHeader } from '../components/BoostPortalHeader';
import { SnackBar } from 'common-uicomponents/components/SnackBar';
import { TextField } from '@vaadin/text-field';
import { TrackingFactorySingleton } from '../tracking/TrackingFactorySingleton';
import { TrackingEvent } from '../tracking/TrackingEvent';
let BoostPortalForgottenPasswordView = class BoostPortalForgottenPasswordView extends LitReduxBoostPortalMixin(LitElement) {
    static get is() {
        return 'boost-portal-forgotten-password-view';
    }
    static get styles() {
        return css `
            h4 {
                color: var(--portalForeground, #404040);
                font-weight: 500;
                margin-bottom: 20px;
                margin-top: 0;
            }

            vaadin-text-field {
                width: 100%;
            }
        `;
    }
    /** @override */ render() {
        return html ` <boost-portal-header></boost-portal-header>
            <h4>Glemt passord</h4>

            <div style="margin-bottom: 40px;">
                <div>
                    <vaadin-text-field
                        label="Telefonnummer"
                        required
                        error-message="Telefonnummer må fylles ut"
                        @value-changed="${(event) => {
            this.phoneNumber = parseInt(event.detail.value, 10);
        }}"
                    ></vaadin-text-field>
                </div>
                <div>
                    <boost-button
                        type="submit"
                        name="Send nytt passord"
                        width-full
                        @click="${this._resetPassword}"
                    ></boost-button>
                </div>
                <div>
                    <boost-status-container type="info">
                        <strong>NB</strong> Ditt passord i app vil også endres
                    </boost-status-container>
                </div>
            </div>`;
    }
    _resetPassword() {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.center || !this.phoneNumber) {
                return;
            }
            const newPasswordResult = yield InnloggingPortalAPI.postPortalNewpassword({
                eier: this.center.id,
                type: 'boost',
                username: String(this.phoneNumber),
            });
            if (isSuccessHTTP(newPasswordResult)) {
                SnackBar.push({ title: 'Nytt passord er sendt på SMS' });
                TrackingFactorySingleton.getTracker().completedEvent(TrackingEvent.RequestedNewPassword);
            }
            else if (isErrorHTTP(newPasswordResult)) {
                SnackBar.pushError(newPasswordResult.error);
            }
        });
    }
};
BoostPortalForgottenPasswordView._deps = [BoostButton, BoostPortalHeader, TextField];
__decorate([
    state(),
    __metadata("design:type", Number)
], BoostPortalForgottenPasswordView.prototype, "phoneNumber", void 0);
__decorate([
    reduxBind(BoostPortalStore.path('session')),
    state(),
    __metadata("design:type", String)
], BoostPortalForgottenPasswordView.prototype, "session", void 0);
__decorate([
    reduxBind(BoostPortalStore.path('center')),
    state(),
    __metadata("design:type", Object)
], BoostPortalForgottenPasswordView.prototype, "center", void 0);
BoostPortalForgottenPasswordView = __decorate([
    customElement(BoostPortalForgottenPasswordView.is)
], BoostPortalForgottenPasswordView);
export { BoostPortalForgottenPasswordView };

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
export class GenericReduxSubStoreBase {
    static staticPath(clsname, path) {
        return clsname + '.' + path;
    }
    dispatch(action) {
        return __awaiter(this, void 0, void 0, function* () {
            const root = yield this.getRootStore();
            root.dispatch(action);
        });
    }
    getState() {
        return __awaiter(this, void 0, void 0, function* () {
            const store = yield this.getRootStore();
            const state = store.getState();
            const key = this.base_key;
            if (key in state) {
                return state[key];
            }
            throw new Error('Finnes ikke redux-state-state ' + key);
        });
    }
}

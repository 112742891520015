var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { isErrorHTTP } from 'cerumx-api/APIRestClient';
import { ProjectAPI } from 'cerumx-api/cerum/desktop/project/ProjectAPI';
import { CerumReduxSubStore } from './CerumReduxSubStore';
class CerumProjectViewStateStoreLayout {
    constructor() {
        this.phaseId = 0;
        this.phases = [];
        this.remainingPhases = [];
        this.startedPhases = [];
        this.completedPhases = [];
        this.expandCompletedPhases = false;
        this.expandRemainingPhases = false;
        this.expandStartedPhases = true;
        this.isLoading = false;
    }
}
export class CerumProjectViewStateStore extends CerumReduxSubStore {
    constructor() {
        super(...arguments);
        /** @override */ this.base_key = 'CerumProjectViewStateStore';
    }
    static get instance() {
        return this._instance || (this._instance = new CerumProjectViewStateStore());
    }
    static path(property) {
        return this.staticPath('CerumProjectViewStateStore', property);
    }
    /** @override */ reducer() {
        return {
            [this.base_key]: (state = new CerumProjectViewStateStoreLayout(), action) => {
                switch (action.type) {
                    case CerumProjectViewStateStore.Actions.SET_PHASE_ID:
                        return Object.assign(Object.assign({}, state), { phaseId: action.payload.phaseId });
                    case CerumProjectViewStateStore.Actions.SET_PHASES:
                        return Object.assign(Object.assign({}, state), { phases: action.payload.phases });
                    case CerumProjectViewStateStore.Actions.SET_COMPLETED_PHASES:
                        return Object.assign(Object.assign({}, state), { completedPhases: [...action.payload.completedPhases] });
                    case CerumProjectViewStateStore.Actions.SET_REMAINING_PHASES:
                        return Object.assign(Object.assign({}, state), { remainingPhases: [...action.payload.remainingPhases] });
                    case CerumProjectViewStateStore.Actions.SET_STARTED_PHASES:
                        return Object.assign(Object.assign({}, state), { startedPhases: [...action.payload.startedPhases] });
                    case CerumProjectViewStateStore.Actions.LOAD_PHASES:
                        return Object.assign(Object.assign({}, state), { phases: action.payload.phases });
                    case CerumProjectViewStateStore.Actions.SET_EXPAND_COMPLETEDPHASES:
                        return Object.assign(Object.assign({}, state), { expandCompletedPhases: action.payload.expandCompletedPhases });
                    case CerumProjectViewStateStore.Actions.SET_EXPAND_REMAININGPHASES:
                        return Object.assign(Object.assign({}, state), { expandRemainingPhases: action.payload.expandRemainingPhases });
                    case CerumProjectViewStateStore.Actions.SET_EXPAND_STARTEDPHASES:
                        return Object.assign(Object.assign({}, state), { expandStartedPhases: action.payload.expandStartedPhases });
                    case CerumProjectViewStateStore.Actions.SET_IS_LOADING:
                        return Object.assign(Object.assign({}, state), { isLoading: action.payload.isLoading });
                }
                return state;
            },
        };
    }
}
(function (CerumProjectViewStateStore) {
    let Actions;
    (function (Actions) {
        Actions.SET_PHASE_ID = 'SET_PHASE_ID';
        Actions.SetPhaseId = (phaseId) => ({
            type: Actions.SET_PHASE_ID,
            payload: {
                phaseId,
            },
        });
        Actions.SET_PHASES = 'SET_PHASES';
        Actions.SetPhases = (phases) => ({
            type: Actions.SET_PHASES,
            payload: {
                phases,
            },
        });
        Actions.SET_COMPLETED_PHASES = 'SET_COMPLETED_PHASES';
        Actions.SetCompletedPhases = (completedPhases) => ({
            type: Actions.SET_COMPLETED_PHASES,
            payload: {
                completedPhases,
            },
        });
        Actions.SET_STARTED_PHASES = 'SET_STARTED_PHASES';
        Actions.SetStartedPhases = (startedPhases) => ({
            type: Actions.SET_STARTED_PHASES,
            payload: {
                startedPhases,
            },
        });
        Actions.SET_REMAINING_PHASES = 'SET_REMAINING_PHASES';
        Actions.SetRemainingPhases = (remainingPhases) => ({
            type: Actions.SET_REMAINING_PHASES,
            payload: {
                remainingPhases,
            },
        });
        Actions.LOAD_PHASES = 'LOAD_PHASES';
        Actions.LoadPhases = (projectId) => __awaiter(this, void 0, void 0, function* () {
            if (!projectId) {
                return {
                    type: Actions.LOAD_PHASES,
                    payload: { phasesResult: [] },
                };
            }
            const phasesResult = yield ProjectAPI.getV1ProjectsProgress_summaryUsingProjectId(projectId);
            if (isErrorHTTP(phasesResult)) {
                return {
                    type: Actions.LOAD_PHASES,
                    payload: { phases: [] },
                };
            }
            return {
                type: Actions.LOAD_PHASES,
                payload: { phases: phasesResult },
            };
        });
        Actions.SET_EXPAND_COMPLETEDPHASES = 'SET_EXPAND_COMPLETEDPHASES';
        Actions.SetExpandCompletedPhases = (expandCompletedPhases) => ({
            type: Actions.SET_EXPAND_COMPLETEDPHASES,
            payload: {
                expandCompletedPhases,
            },
        });
        Actions.SET_EXPAND_STARTEDPHASES = 'SET_EXPAND_STARTEDPHASES';
        Actions.SetExpandStartedPhases = (expandStartedPhases) => ({
            type: Actions.SET_EXPAND_STARTEDPHASES,
            payload: {
                expandStartedPhases,
            },
        });
        Actions.SET_EXPAND_REMAININGPHASES = 'SET_EXPAND_REMAININGPHASES';
        Actions.SetExpandRemainingPhases = (expandRemainingPhases) => ({
            type: Actions.SET_EXPAND_REMAININGPHASES,
            payload: {
                expandRemainingPhases,
            },
        });
        Actions.SET_IS_LOADING = 'SET_IS_LOADING';
        Actions.SetLoading = (isLoading) => ({
            type: Actions.SET_IS_LOADING,
            payload: {
                isLoading,
            },
        });
    })(Actions = CerumProjectViewStateStore.Actions || (CerumProjectViewStateStore.Actions = {}));
})(CerumProjectViewStateStore || (CerumProjectViewStateStore = {}));

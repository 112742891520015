var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { BoostStatus } from '../components/common/BoostStatus';
import { html, LitElement } from 'lit';
import { customElement, state } from 'lit/decorators.js';
import { LitReduxBoostPortalMixin } from '../redux/LitReduxBoostPortalMixin';
import { BoostPortalParamsHandler } from '../helpers/BoostPortalParamsHandler';
import { MembersignupAPI } from 'boost-api/boost/signup/membersignup/MembersignupAPI';
import { isErrorHTTP } from 'boost-api/APIRestClient';
import { BoostPortalHeader } from '../components/BoostPortalHeader';
let BoostPortalSponsorAcceptView = class BoostPortalSponsorAcceptView extends LitReduxBoostPortalMixin(LitElement) {
    static get is() {
        return 'boost-portal-sponsor-accept-view';
    }
    constructor() {
        super();
        this.sponsedId = 0;
        this.loading = true;
        this.updateComplete.then(() => this._whenUpdateComplete());
    }
    /** @override */ render() {
        return html `
            <boost-portal-header></boost-portal-header>
            ${!this._isLoading()
            ? html `${this._sponsorView()}`
            : html `<div style="display:flex; width:100%; justify-content:center">
                      <div><img src="images/ajax-loader.gif" alt="" style="margin-right: 10px" /></div>
                      Laster inn
                  </div>`}
        `;
    }
    _whenUpdateComplete() {
        return __awaiter(this, void 0, void 0, function* () {
            const sponsorConnectionHash = BoostPortalParamsHandler.getParameter('hash');
            if (!sponsorConnectionHash) {
                return;
            }
            const connectSponsorToMemberResult = yield MembersignupAPI.postV1BoostsignupSponsor_connection({
                hash: sponsorConnectionHash,
            });
            if (isErrorHTTP(connectSponsorToMemberResult)) {
                return;
            }
            if (!connectSponsorToMemberResult.success) {
                return;
            }
            this.sponsoredMemberName =
                connectSponsorToMemberResult.firstname + ' ' + connectSponsorToMemberResult.lastname;
            this.sponsorDidAccept = true;
        });
    }
    _isLoading() {
        if (this.sponsorDidAccept == true) {
            this.loading = false;
            return false;
        }
        else if (this.sponsorDidAccept == false) {
            this.loading = false;
            return false;
        }
        else
            return true;
    }
    _sponsorView() {
        return html ` ${this.sponsorDidAccept
            ? html `
                  <boost-status type="success">
                      ${this.sponsoredMemberName
                ? html `Du er nå registrert som betaler for ${this.sponsoredMemberName}.`
                : html ``}
                  </boost-status>
              `
            : html ` <boost-status type="warning">Avtalegiro ble ikke signert.</boost-status>`}`;
    }
};
BoostPortalSponsorAcceptView._deps = [BoostStatus, BoostPortalHeader];
__decorate([
    state(),
    __metadata("design:type", Object)
], BoostPortalSponsorAcceptView.prototype, "sponsedMember", void 0);
__decorate([
    state(),
    __metadata("design:type", String)
], BoostPortalSponsorAcceptView.prototype, "sponsoredMemberName", void 0);
__decorate([
    state(),
    __metadata("design:type", Number)
], BoostPortalSponsorAcceptView.prototype, "sponsedId", void 0);
__decorate([
    state(),
    __metadata("design:type", Boolean)
], BoostPortalSponsorAcceptView.prototype, "sponsorDidAccept", void 0);
__decorate([
    state(),
    __metadata("design:type", Boolean)
], BoostPortalSponsorAcceptView.prototype, "loading", void 0);
BoostPortalSponsorAcceptView = __decorate([
    customElement(BoostPortalSponsorAcceptView.is),
    __metadata("design:paramtypes", [])
], BoostPortalSponsorAcceptView);
export { BoostPortalSponsorAcceptView };

var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var CerumConfirm_1;
import { css, html, LitElement } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { CerumModalFooter } from 'cerum-modal-footer/CerumModalFooter';
import { OverlayWindow } from 'common-uicomponents/components/OverlayWindow';
import { StatusContainer } from 'common-uicomponents/components/StatusContainer';
import { styleMap } from 'lit/directives/style-map.js';
import { AsyncContainer } from 'badphraim/core/AsyncContainer';
import { SingletonInstanceOf } from 'badphraim/core/Singleton';
import { CerumFormHelper } from './helpers/CerumFormHelper';
let CerumConfirm = CerumConfirm_1 = class CerumConfirm extends LitElement {
    constructor() {
        super(...arguments);
        this.type = 'default';
        this.text = '';
        this.cerumConfirmation = new AsyncContainer();
    }
    static get is() {
        return 'cerum-confirm';
    }
    static get styles() {
        return css ``;
    }
    static instance() {
        if (this._instance) {
            return this._instance;
        }
        return SingletonInstanceOf(this);
    }
    disconnectedCallback() {
        CerumConfirm_1.instance().cerumConfirmation = new AsyncContainer();
    }
    /** @override */ render() {
        return html ` <status-container type="${this.type}">
                <span style="${styleMap(this._getTextStyles())}">${this.text} </span>
            </status-container>
            <cerum-modal-footer hideKeepWindowOpen
                ><div slot="actions">
                    <cerum-legacy-button
                        name="Nei"
                        type="danger"
                        @click="${() => this._onActionClick(false)}"
                    ></cerum-legacy-button>
                    <cerum-legacy-button
                        name="Ja"
                        type="success"
                        @click="${() => this._onActionClick(true)}"
                    ></cerum-legacy-button></div
            ></cerum-modal-footer>`;
    }
    _onActionClick(state) {
        return __awaiter(this, void 0, void 0, function* () {
            const isDirtyHelper = CerumFormHelper.instance();
            if (isDirtyHelper.formBuilder && isDirtyHelper.formBuilder.isDirty) {
                this.cerumConfirmation.setValue(state);
                return;
            }
            OverlayWindow.asyncPopWindow(state);
        });
    }
    getConfirmation() {
        return __awaiter(this, void 0, void 0, function* () {
            return CerumConfirm_1.instance().cerumConfirmation.getValue();
        });
    }
    _getTextStyles() {
        const styles = {
            fontSize: 'large',
            fontWeight: 'bold',
        };
        if (this.type === 'danger') {
            styles.color = '#ff1c1c';
        }
        return styles;
    }
};
CerumConfirm._deps = [CerumModalFooter, StatusContainer];
__decorate([
    property({ type: String }),
    __metadata("design:type", String)
], CerumConfirm.prototype, "type", void 0);
__decorate([
    property({ type: String }),
    __metadata("design:type", String)
], CerumConfirm.prototype, "text", void 0);
__decorate([
    state(),
    __metadata("design:type", AsyncContainer)
], CerumConfirm.prototype, "cerumConfirmation", void 0);
CerumConfirm = CerumConfirm_1 = __decorate([
    customElement(CerumConfirm.is)
], CerumConfirm);
export { CerumConfirm };

import { CerumOAuth2ClientBase } from 'spa-core/CerumOAuth2ClientBase';
import { CerumServices } from './CerumServices';
import { CerumTokenStore } from './CerumTokenStore';
import { CerumXRouterHelper } from './cerumx/CerumXRouterHelper';
export class CerumOAuth2Client extends CerumOAuth2ClientBase {
    static getInstance() {
        if (window._cerum_token_store) {
            return window._cerum_token_store;
        }
        const as_endpoint_promise = CerumServices.instance().getAuthorizationServer();
        return (window._cerum_token_store = new CerumOAuth2Client(as_endpoint_promise));
    }
    /** @override */ createTokenStore() {
        return new CerumTokenStore(() => this.getOAuth2());
    }
    /*
    Usikker på om den her treng cerum-ajax eller om det hold med badphraim-ajax uten token

     public getAjaxHandler(): Ajax {
        return Ajax.stdInstance();
    }*/
    /** @override */ /**  */ getClientId() {
        return 'cerumc';
    }
    /** @override */ getRedirectUri() {
        //console.log('Lager redirecturl fra ' + redir);
        let redir = CerumXRouterHelper.makeUrlRedirectSafe(document.location);
        //redir = redir.replace(/#.*/, '');
        //redir = redir.replace(/\?.*/, '');
        redir += (redir.indexOf('?') >= 0 ? '&' : '?') + 'action=oauth_response';
        const m = document.location.href.match(/\bservice=([^&]+)\b/);
        if (m) {
            redir += '&service=' + m[1];
        }
        //console.log('Ender opp med ', redir);
        return redir;
    }
    /** @override */ getScopes() {
        return ['https://api.cerum.no/cerumx/login', 'https://api.cerum.no/cerum/standalone'];
    }
}

var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { reduxBind } from 'badphraim/ui/LitReduxMixinBase';
import { isErrorHTTP, isSuccessHTTP } from 'boost-api/APIRestClient';
import { MembersignupAPI } from 'boost-api/boost/signup/membersignup/MembersignupAPI';
import { css, html, LitElement } from 'lit';
import { customElement, state } from 'lit/decorators.js';
import { BoostPortalStore } from '../redux/BoostPortalStore';
import { LitReduxBoostPortalMixin } from '../redux/LitReduxBoostPortalMixin';
import { router } from '../router/BoostPortalRouter';
import { BoostPortalMemberStandardConfirm } from '../components/BoostPortalMemberStandardConfirm';
import { BoostPortalMemberStandardProgress } from '../components/BoostPortalMemberStandardProgress';
import { BoostButton } from '../components/common/BoostButton';
import { BoostPortalChooseDepartment } from '../components/BoostPortalChooseDepartment';
import { BoostPortalChooseMembership } from '../components/BoostPortalChooseMembership';
import { BoostPortalPersonalInformation } from '../components/BoostPortalPersonalInformation';
import { BoostStatusContainer } from '../components/common/BoostStatusContainer';
import { BoostStepIndicator } from '../components/common/BoostStepIndicator';
import { SnackBar } from 'common-uicomponents/components/SnackBar';
import { BoostPortalHeader } from '../components/BoostPortalHeader';
import { TrackingFactorySingleton } from '../tracking/TrackingFactorySingleton';
import { TrackingEvent } from '../tracking/TrackingEvent';
let BoostPortalRegisterMemberView = class BoostPortalRegisterMemberView extends LitReduxBoostPortalMixin(LitElement) {
    constructor() {
        super(...arguments);
        this.currentStep = 1;
        this.memberIsUnderaged = false;
        this.meetsMinimumAge = true;
        this.isSponsorSetup = false;
        this.memberExists = false;
    }
    static get is() {
        return 'boost-portal-register-member-view';
    }
    static get styles() {
        return css `
            h3 {
                color: var(--portalForeground, #404040);
                font-weight: 500;
                margin-bottom: 10px;
            }

            a:link,
            a:visited,
            a:active {
                text-decoration: underline;
                color: var(--portalLink, #2a9ba3);
            }

            a:hover {
                text-decoration: none;
            }
        `;
    }
    /** @override */ render() {
        return html `
            <boost-portal-header .viewLoginButton="${false}"></boost-portal-header>
            <h3>Bli medlem</h3>

            <boost-step-indicator
                currentStep="${this.currentStep}"
                .steps="${this._getSteps(this.currentStep)}"
                @currentStep-changed="${(event) => (this.currentStep = event.detail.currentStep)}"
            ></boost-step-indicator>

            ${this._showViewForCurrentStep(this.currentStep)}
        `;
    }
    _tokenObserver() {
        return __awaiter(this, void 0, void 0, function* () {
            const sponsorSetupResult = yield MembersignupAPI.getV1BoostsignupSponsorsetup();
            if (isSuccessHTTP(sponsorSetupResult)) {
                this.isSponsorSetup = sponsorSetupResult.status;
            }
            else if (isErrorHTTP(sponsorSetupResult)) {
                SnackBar.pushError(sponsorSetupResult.error);
            }
        });
    }
    get validationFields() {
        return this.shadowRoot && this.shadowRoot.querySelectorAll('.validate');
    }
    _memberHandler(event) {
        return __awaiter(this, void 0, void 0, function* () {
            var _a;
            window.dispatchEvent(new CustomEvent('validateFields', { bubbles: true, composed: true }));
            const buttonElement = event.target;
            if (buttonElement.disabled) {
                return;
            }
            this.memberExists = false;
            if (!this.memberInformation ||
                !this._isValidMemberInformation() ||
                !this.selectedDepartmentId ||
                !this.selectedMembershipId) {
                return;
            }
            if (this.session && ((_a = this.loggedInProfile) === null || _a === void 0 ? void 0 : _a.memberid)) {
                this._updateMember(buttonElement, this.loggedInProfile.memberid);
            }
            else {
                this._createMember(buttonElement);
            }
        });
    }
    _updateMember(buttonElement, memberId) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.memberInformation ||
                !this._isValidMemberInformation() ||
                !this.selectedDepartmentId ||
                !this.selectedMembershipId) {
                return;
            }
            buttonElement.setLoading(true);
            this.memberInformation.departmentId = this.selectedDepartmentId;
            this.memberInformation.membershipId = this.selectedMembershipId;
            this.memberInformation.netsCallbackUrl =
                window.location.origin +
                    router.baseUrl +
                    this.centerShort +
                    '/avtalegiro?departmentId=' +
                    this.selectedDepartmentId;
            this.requestUpdate('memberInformation');
            this.dispatch(BoostPortalStore.Actions.SetMemberInformation(this.memberInformation));
            const updatedMemberResult = yield MembersignupAPI.putV1BoostsignupMemberUsingMemberId(memberId, this.memberInformation);
            if (isSuccessHTTP(updatedMemberResult)) {
                this.memberInformation = Object.assign(Object.assign({}, this.memberInformation), updatedMemberResult);
                this.dispatch(BoostPortalStore.Actions.SetMemberInformation(this.memberInformation));
                const createMembershipResult = yield MembersignupAPI.postV1BoostsignupMemberContractUsingMemberId(updatedMemberResult.memberId, {
                    membershipId: this.selectedMembershipId,
                    departmentId: this.selectedDepartmentId,
                    netsCallbackUrl: this.memberInformation.netsCallbackUrl,
                });
                if (isSuccessHTTP(createMembershipResult)) {
                    this.memberInformation.link_to_nets = createMembershipResult.link_to_nets;
                    this.currentStep++;
                }
                else if (isErrorHTTP(createMembershipResult)) {
                    if (createMembershipResult.error.http_status === 409) {
                        this.memberExists = true;
                        return;
                    }
                    SnackBar.pushError(createMembershipResult.error);
                }
            }
            else if (isErrorHTTP(updatedMemberResult)) {
                SnackBar.pushError(updatedMemberResult.error);
            }
            buttonElement.setLoading(false);
        });
    }
    _createMember(buttonElement) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.memberInformation ||
                !this._isValidMemberInformation() ||
                !this.selectedDepartmentId ||
                !this.selectedMembershipId) {
                return;
            }
            buttonElement.setLoading(true);
            this.memberInformation.departmentId = this.selectedDepartmentId;
            this.memberInformation.membershipId = this.selectedMembershipId;
            this.memberInformation.netsCallbackUrl =
                window.location.origin +
                    router.baseUrl +
                    this.centerShort +
                    '/avtalegiro?departmentId=' +
                    this.selectedDepartmentId;
            this.requestUpdate('memberInformation');
            const createdMemberResult = yield MembersignupAPI.postV1BoostsignupMember(this.memberInformation);
            if (isSuccessHTTP(createdMemberResult)) {
                this.memberInformation = createdMemberResult;
                this.dispatch(BoostPortalStore.Actions.SetMemberInformation(this.memberInformation));
                this.currentStep += 1;
                // Facebook Pixel Tracking
                TrackingFactorySingleton.getTracker().completedEvent(TrackingEvent.PersonalInformationFilled);
            }
            else if (isErrorHTTP(createdMemberResult)) {
                if (createdMemberResult.error.http_status === 409) {
                    this.memberExists = true;
                }
                else {
                    SnackBar.pushError(createdMemberResult.error);
                }
            }
            buttonElement.setLoading(false);
        });
    }
    _currentStepObserver() {
        if (this.currentStep > this._determineCurrentStep()) {
            this.currentStep = this._determineCurrentStep();
        }
    }
    _determineCurrentStep() {
        if (!this.selectedDepartmentId || !this.selectedMembershipId) {
            return 1;
        }
        else if (!this._isValidMemberInformation()) {
            return 2;
        }
        return 3;
    }
    _getSteps(currentStep) {
        const steps = [
            {
                title: 'Senter',
                isActive: currentStep === 1,
                subtitle: '',
            },
            {
                title: 'Personalia',
                isActive: currentStep === 2,
                subtitle: '',
            },
            {
                title: 'Bekreft',
                isActive: currentStep === 3,
                subtitle: '',
            },
        ];
        return steps;
    }
    _isValidMemberInformation() {
        if (!this.memberInformation) {
            return false;
        }
        if (!this.memberInformation.firstname ||
            !this.memberInformation.lastname ||
            !this.memberInformation.address ||
            !this.memberInformation.postal_code ||
            !this.memberInformation.municipality ||
            !this.memberInformation.phone_number ||
            !this.memberInformation.email ||
            !this.memberInformation.date_of_birth ||
            !this.memberInformation.gender ||
            !this.memberInformation.approve_tos) {
            return false;
        }
        return true;
    }
    _routeToPrivateSponsor() {
        window.history.pushState(null, document.title, router.baseUrl + this.centerShort + '/member/private');
        window.dispatchEvent(new PopStateEvent('popstate'));
    }
    isLoggedIn() {
        var _a;
        return this.session && ((_a = this.loggedInProfile) === null || _a === void 0 ? void 0 : _a.memberid);
    }
    _showViewForCurrentStep(currentStep) {
        switch (currentStep) {
            case 1:
                return this._renderDepartmentMembershipOptions();
            case 2:
                return this._renderPersonalInformation();
            case 3:
                return html ` <boost-portal-member-standard-confirm></boost-portal-member-standard-confirm> `;
        }
    }
    _renderDepartmentMembershipOptions() {
        return html `
            <boost-portal-choose-department></boost-portal-choose-department>

            ${this.selectedDepartmentId
            ? html `<boost-portal-choose-membership></boost-portal-choose-membership>`
            : html `<div style="padding-bottom:16px;"></div>`}

            <div>
                <boost-button
                    type="submit"
                    name="Gå videre"
                    @click="${() => this._nextStep()}"
                    ?disabled="${!this.selectedDepartmentId || !this.selectedMembershipId}"
                    width-full
                ></boost-button>
            </div>
        `;
    }
    _nextStep() {
        if (!this.selectedDepartmentId || !this.selectedMembershipId) {
            return;
        }
        this.currentStep++;
    }
    _renderPersonalInformation() {
        return html `
            <boost-portal-member-standard-progress></boost-portal-member-standard-progress>

            <boost-portal-personal-information
                @memberPhoneNumber-changed="${(_event) => (this.memberExists = false)}"
                @memberIsUnderaged-changed="${(event) => (this.memberIsUnderaged = event.detail.memberIsUnderaged)}"
                @meetsMinimumAge="${(event) => {
            this.meetsMinimumAge = event.detail.value;
            this.requestUpdate('meetsMinimumAge');
        }}"
            ></boost-portal-personal-information>

            ${this.memberExists
            ? html `
                      <boost-status-container type="info">
                          ${this.isLoggedIn()
                ? 'Du har allerede et aktivt medlemskap og kan ikke melde deg inn på nytt'
                : html ` Du har vært medlem tidligere.
                                    <a href="${router.baseUrl}${this.centerShort}/login">Trykk her</a> for å
                                    inngå nytt medlemskap.`}
                      </boost-status-container>
                  `
            : this._renderAgeRequirements()}
        `;
    }
    _renderAgeRequirements() {
        var _a;
        if (!this.meetsMinimumAge) {
            return html `<boost-status-container type="info">
                For å registrere deg hos oss må du være minst ${(_a = this.center) === null || _a === void 0 ? void 0 : _a.minimum_age} år gammel. Ta
                kontakt med senteret hvis du lurer på noe.
            </boost-status-container>`;
        }
        if (this.memberIsUnderaged) {
            return html `
                ${this.isSponsorSetup
                ? html `<boost-status-container type="info">
                              Personer under 18 år må betales for av en annen myndig person.
                          </boost-status-container>

                          <boost-button
                              type="submit"
                              name="Klikk her for å gå til Bli medlem der andre skal betale for deg"
                              @click="${this._routeToPrivateSponsor}"
                              width-full
                          ></boost-button>`
                : html `<boost-status-container type="info">
                          Personer under 18 år må betales for av en annen myndig person. Ta kontakt med
                          senteret.
                      </boost-status-container>`}
            `;
        }
        return html `
            <div>
                <boost-button
                    type="submit"
                    name="Gå videre"
                    @click="${this._memberHandler}"
                    ?disabled="${!this._isValidMemberInformation()}"
                    width-full
                ></boost-button>
            </div>
        `;
    }
    /** @override */ updated(changedProperties) {
        changedProperties.forEach((_oldValue, propertyName) => {
            switch (propertyName) {
                case 'currentStep':
                    this._currentStepObserver();
                    break;
                case 'token':
                    this._tokenObserver();
                    break;
            }
        });
        super.updated(changedProperties);
    }
};
BoostPortalRegisterMemberView._deps = [
    BoostButton,
    BoostStepIndicator,
    BoostPortalChooseDepartment,
    BoostPortalChooseMembership,
    BoostPortalPersonalInformation,
    BoostPortalMemberStandardProgress,
    BoostPortalMemberStandardConfirm,
    BoostStatusContainer,
    BoostPortalHeader,
];
__decorate([
    reduxBind(BoostPortalStore.path('centerShort')),
    state(),
    __metadata("design:type", String)
], BoostPortalRegisterMemberView.prototype, "centerShort", void 0);
__decorate([
    reduxBind(BoostPortalStore.path('center')),
    state(),
    __metadata("design:type", Object)
], BoostPortalRegisterMemberView.prototype, "center", void 0);
__decorate([
    state(),
    __metadata("design:type", Number)
], BoostPortalRegisterMemberView.prototype, "currentStep", void 0);
__decorate([
    reduxBind(BoostPortalStore.path('memberInformation')),
    state(),
    __metadata("design:type", Object)
], BoostPortalRegisterMemberView.prototype, "memberInformation", void 0);
__decorate([
    state(),
    __metadata("design:type", Boolean)
], BoostPortalRegisterMemberView.prototype, "memberIsUnderaged", void 0);
__decorate([
    state(),
    __metadata("design:type", Boolean)
], BoostPortalRegisterMemberView.prototype, "meetsMinimumAge", void 0);
__decorate([
    reduxBind(BoostPortalStore.path('selectedDepartmentId')),
    state(),
    __metadata("design:type", Number)
], BoostPortalRegisterMemberView.prototype, "selectedDepartmentId", void 0);
__decorate([
    reduxBind(BoostPortalStore.path('selectedMembershipId')),
    state(),
    __metadata("design:type", Number)
], BoostPortalRegisterMemberView.prototype, "selectedMembershipId", void 0);
__decorate([
    state(),
    __metadata("design:type", Boolean)
], BoostPortalRegisterMemberView.prototype, "isSponsorSetup", void 0);
__decorate([
    reduxBind(BoostPortalStore.path('token')),
    state(),
    __metadata("design:type", String)
], BoostPortalRegisterMemberView.prototype, "token", void 0);
__decorate([
    reduxBind(BoostPortalStore.path('session')),
    state(),
    __metadata("design:type", String)
], BoostPortalRegisterMemberView.prototype, "session", void 0);
__decorate([
    state(),
    __metadata("design:type", Boolean)
], BoostPortalRegisterMemberView.prototype, "memberExists", void 0);
__decorate([
    reduxBind(BoostPortalStore.path('loggedInProfile')),
    state(),
    __metadata("design:type", Object)
], BoostPortalRegisterMemberView.prototype, "loggedInProfile", void 0);
BoostPortalRegisterMemberView = __decorate([
    customElement(BoostPortalRegisterMemberView.is)
], BoostPortalRegisterMemberView);
export { BoostPortalRegisterMemberView };

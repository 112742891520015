var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { reduxBind } from 'badphraim/ui/LitReduxMixinBase';
import { css, html, LitElement } from 'lit';
import { customElement, state } from 'lit/decorators.js';
import { BoostPortalStore } from '../redux/BoostPortalStore';
import { LitReduxBoostPortalMixin } from '../redux/LitReduxBoostPortalMixin';
import { BoostPortalParamsHandler } from '../helpers/BoostPortalParamsHandler';
import { MembersignupAPI } from 'boost-api/boost/signup/membersignup/MembersignupAPI';
import { isErrorHTTP, isSuccessHTTP } from 'boost-api/APIRestClient';
let BoostPortalSponsorProgress = class BoostPortalSponsorProgress extends LitReduxBoostPortalMixin(LitElement) {
    static get is() {
        return 'boost-portal-sponsor-progress';
    }
    static get styles() {
        return css `
            hr {
                border: 0;
                border-top: 1px solid var(--portalAccentBackground, #e5efbb);
                border-bottom: 1px solid var(--portalAccentBackgroundActive, #e0eda8);
            }

            .progress {
                display: flex;
                align-items: center;
                margin: 20px 0;
            }

            .progress div:last-child {
                flex: 1;
            }

            .progress div:first-child {
                margin-right: 10px;
            }

            .progress-heading {
                font-size: 140%;
                font-weight: 300;
            }

            .progress-info {
                font-size: 120%;
                font-weight: 300;
                text-align: right;
            }
        `;
    }
    constructor() {
        super();
        this.updateComplete.then(() => __awaiter(this, void 0, void 0, function* () {
            const sponsoredId = BoostPortalParamsHandler.getParameter('sponsedId');
            if (sponsoredId) {
                const sponsoredMember = yield MembersignupAPI.getV1BoostsignupSponsed({
                    sponsorConnectionHash: sponsoredId,
                });
                if (isSuccessHTTP(sponsoredMember)) {
                    this.sponsoredMember = sponsoredMember;
                }
                else if (isErrorHTTP(sponsoredMember)) {
                    console.log(sponsoredMember.error);
                }
            }
        }));
    }
    /** @override */ render() {
        return html `
            <div>
                ${this.memberInformation && this._isValidMemberInformation()
            ? html `
                          <div class="progress">
                              <div class="progress-heading">Navn på betaler</div>
                              <div class="progress-info">
                                  ${this.memberInformation.firstname} ${this.memberInformation.lastname},
                                  ${this.memberInformation.postal_code} ${this.memberInformation.municipality}
                              </div>
                          </div>
                      `
            : html ``}
                ${this.sponsoredMember
            ? html `
                          <div class="progress">
                              <div class="progress-heading">Betaler for</div>
                              <div class="progress-info">
                                  ${this.sponsoredMember.firstname} ${this.sponsoredMember.lastname}
                              </div>
                          </div>
                      `
            : html ``}
            </div>

            <hr />
        `;
    }
    _getSelectedDepartment() {
        const selectedDepartment = this.departments &&
            this.departments.find((department) => department.id === this.selectedDepartmentId);
        if (!selectedDepartment) {
            return html ``;
        }
        return html ` ${selectedDepartment.navn} `;
    }
    _isValidMemberInformation() {
        return (this.memberInformation &&
            this.memberInformation.firstname &&
            this.memberInformation.lastname &&
            this.memberInformation.address &&
            this.memberInformation.postal_code &&
            this.memberInformation.municipality &&
            this.memberInformation.phone_number &&
            this.memberInformation.email &&
            this.memberInformation.date_of_birth &&
            this.memberInformation.gender &&
            this.memberInformation.approve_tos);
    }
};
BoostPortalSponsorProgress._deps = [];
__decorate([
    reduxBind(BoostPortalStore.path('departments')),
    state(),
    __metadata("design:type", Array)
], BoostPortalSponsorProgress.prototype, "departments", void 0);
__decorate([
    reduxBind(BoostPortalStore.path('memberInformation')),
    state(),
    __metadata("design:type", Object)
], BoostPortalSponsorProgress.prototype, "memberInformation", void 0);
__decorate([
    reduxBind(BoostPortalStore.path('selectedDepartmentId')),
    state(),
    __metadata("design:type", Number)
], BoostPortalSponsorProgress.prototype, "selectedDepartmentId", void 0);
__decorate([
    state(),
    __metadata("design:type", Object)
], BoostPortalSponsorProgress.prototype, "sponsoredMember", void 0);
BoostPortalSponsorProgress = __decorate([
    customElement(BoostPortalSponsorProgress.is),
    __metadata("design:paramtypes", [])
], BoostPortalSponsorProgress);
export { BoostPortalSponsorProgress };

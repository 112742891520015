import { CerumReduxSubStore } from './CerumReduxSubStore';
class CerumMainUIStateSubStoreLayout {
    constructor() {
        this.activeMenuItemId = '';
        this.collapsedMenu = false;
    }
}
export class CerumMainUIStateStore extends CerumReduxSubStore {
    constructor() {
        super(...arguments);
        /** @override */ this.base_key = 'CerumMainUIStateStore';
    }
    static get instance() {
        return this._instance || (this._instance = new CerumMainUIStateStore());
    }
    static path(property) {
        return this.staticPath('CerumMainUIStateStore', property);
    }
    /** @override */ reducer() {
        return {
            [this.base_key]: (state = new CerumMainUIStateSubStoreLayout(), action) => {
                switch (action.type) {
                    case CerumMainUIStateStore.COLLAPSE_MENU:
                        return Object.assign(Object.assign({}, state), {
                            collapsedMenu: true,
                        });
                    case CerumMainUIStateStore.EXPAND_MENU:
                        return Object.assign(Object.assign({}, state), {
                            collapsedMenu: false,
                        });
                    case CerumMainUIStateStore.ACTIVATE_MENU_ITEM:
                        if (action.payload.menuItemId !== state.activeMenuItemId) {
                            return Object.assign(Object.assign({}, state), {
                                activeMenuItemId: action.payload.menuItemId,
                            });
                        }
                        break;
                    case CerumMainUIStateStore.DEACTIVATE_MENU_ITEM:
                        if (action.payload.menuItemId === state.activeMenuItemId) {
                            return Object.assign(Object.assign({}, state), {
                                activeMenuItemId: '',
                            });
                        }
                        break;
                }
                return state;
            },
        };
    }
}
(function (CerumMainUIStateStore) {
    CerumMainUIStateStore.COLLAPSE_MENU = 'COLLAPSE_MENU';
    CerumMainUIStateStore.EXPAND_MENU = 'EXPAND_MENU';
    CerumMainUIStateStore.ACTIVATE_MENU_ITEM = 'ACTIVATE_MENU_ITEM';
    CerumMainUIStateStore.DEACTIVATE_MENU_ITEM = 'DEACTIVATE_MENU_ITEM';
    let Actions;
    (function (Actions) {
        Actions.CollapseMenu = () => ({
            type: CerumMainUIStateStore.COLLAPSE_MENU,
        });
        Actions.ExpandMenu = () => ({
            type: CerumMainUIStateStore.EXPAND_MENU,
        });
        Actions.ActivateMenuItem = (itemId) => ({
            type: CerumMainUIStateStore.ACTIVATE_MENU_ITEM,
            payload: {
                menuItemId: itemId,
            },
        });
        Actions.DeactivateMenuItem = (itemId) => ({
            type: CerumMainUIStateStore.DEACTIVATE_MENU_ITEM,
            payload: {
                menuItemId: itemId,
            },
        });
    })(Actions = CerumMainUIStateStore.Actions || (CerumMainUIStateStore.Actions = {}));
})(CerumMainUIStateStore || (CerumMainUIStateStore = {}));

/* eslint-disable id-length */
import { exhaustiveMatchingGuard } from 'badphraim/core/ExhaustiveMatchingGuard';
import { TrackingEvent } from './TrackingEvent';
/**
 * Wrapper-implementation for the google tag/analytics tracking facility.
 *
 * Relevant documentation
 * *
 * * https://developers.google.com/analytics/devguides/collection/gtagjs
 * * Recommended events: https://support.google.com/analytics/answer/9267735?hl=en&ref_topic=9756175
 * *
 */
export class GoogleTagTracking {
    constructor(googleTag) {
        this.googleTag = googleTag;
        this.disabled = false;
    }
    initialize() {
        // Slightly refactored from https://developers.google.com/analytics/devguides/collection/gtagjs
        const self = this;
        (function (globalWindow, doc, libraryScriptSource) {
            const scriptTag = doc.createElement('script');
            scriptTag.async = true;
            scriptTag.src = libraryScriptSource;
            self.scriptTag = scriptTag;
            doc.head.appendChild(scriptTag);
            globalWindow.dataLayer = globalWindow.dataLayer || [];
            globalWindow.gtag =
                globalWindow.gtag ||
                    function () {
                        globalWindow.dataLayer.push(arguments);
                    };
        })(window, document, 'https://www.googletagmanager.com/gtag/js?id=' + encodeURIComponent(this.googleTag));
        this.gtag('js', new Date());
        this.gtag('config', this.googleTag);
    }
    gtag(...args) {
        if (this.disabled) {
            return;
        }
        window.gtag(...args);
    }
    disable() {
        var _a;
        this.disabled = true;
        if (this.scriptTag) {
            // Try to remove as much as possible
            (_a = this.scriptTag.parentElement) === null || _a === void 0 ? void 0 : _a.removeChild(this.scriptTag);
            this.scriptTag = undefined;
            delete window['gtag'];
            delete window['dataLayer'];
        }
    }
    completedEvent(...args) {
        const [event, parameters] = args;
        switch (event) {
            case TrackingEvent.MemberSignupStarted:
                this.gtag('event', 'sign_up', parameters);
                break;
            case TrackingEvent.SelectedMembership:
                this.gtag('event', 'SelectedProduct', parameters);
                break;
            case TrackingEvent.PersonalInformationFilled:
                this.gtag('event', 'PersonalInformationFilled', parameters);
                break;
            case TrackingEvent.SponsorConfirmed:
                this.gtag('event', 'SponsorConfirmed', parameters);
                break;
            case TrackingEvent.BankIDSigningStarted:
                this.gtag('event', 'BankIDSigningStarted', parameters);
                break;
            case TrackingEvent.AvtalegiroSigningCompleted:
                this.gtag('event', 'AvtalegiroSigningCompleted', parameters);
                break;
            case TrackingEvent.AvtalegiroSigningFailed:
                this.gtag('event', 'AvtalegiroSigningFailed', parameters);
                break;
            case TrackingEvent.VippsPaymentComplete:
                this.gtag('event', 'VippsPaymentComplete', parameters);
                break;
            case TrackingEvent.CompanySponsoredSignupStarted:
                this.gtag('event', 'CompanySponsoredSignupStarted', parameters);
                break;
            case TrackingEvent.CompanySponsoredSelectedDepartment:
                this.gtag('event', 'CompanySponsoredSelectedDepartment', parameters);
                break;
            case TrackingEvent.PrivatelySponsoredSignupStarted:
                this.gtag('event', 'PrivatelySponsoredSignupStarted', parameters);
                break;
            case TrackingEvent.PrivatelySponsoredSelectedDepartment:
                this.gtag('event', 'PrivatelySponsoredSelectedDepartment', parameters);
                break;
            case TrackingEvent.MemberSignupCompleted:
                this.gtag('event', 'CompleteRegistration', parameters);
                break;
            case TrackingEvent.RequestedNewPassword:
                this.gtag('event', 'RequestNewPassword', parameters);
                break;
            case TrackingEvent.SelectedDepartment:
                this.gtag('event', 'SelectedDepartment', parameters);
                break;
            default:
                exhaustiveMatchingGuard(event);
        }
    }
}

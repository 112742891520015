var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { CerumServices } from '../CerumServices';
import { CerumConnectBase } from './CerumConnectBase';
export class CerumConnect extends CerumConnectBase {
    static getGlobalInstance(ident) {
        return super._getOrCreateGlobalInstance((_ident) => {
            return new CerumConnect(_ident);
        }, ident);
    }
    /** @override */ getBaseUrl() {
        return __awaiter(this, void 0, void 0, function* () {
            return CerumServices.instance().getBaseUrl();
        });
    }
    /** @override */ getPubSubTokenUrl() {
        return __awaiter(this, void 0, void 0, function* () {
            return (yield CerumServices.instance().getRestBaseUrl()) + '/pubsub/auth/gettoken';
            //		return this.tokenurl;
        });
    }
    /** @override */ getVersionString() {
        return __awaiter(this, void 0, void 0, function* () {
            return (yield CerumServices.instance().getVersion()) || '';
        });
    }
    /** @override */ getWebSocketUrl() {
        return __awaiter(this, void 0, void 0, function* () {
            return CerumServices.instance().getWebSocketUrl();
            // return "wss://ws.lab2.cerum.no/bear35/head";
        });
    }
}

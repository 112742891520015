/* tslint:disable: class-name */
/*
 * AUTOGENERATED FILE - DO NOT EDIT
 */
import { APIRestClient } from '../../../APIRestClient';
import { NewsArticle } from '../../../schemas/NewsArticle';
import { NewsArticleStatus } from '../../../schemas/NewsArticleStatus';
import { SignupInitiatePaymentResponse } from '../../../schemas/SignupInitiatePaymentResponse';
import { ewConditionURL } from '../../../schemas/ewConditionURL';
import { ewJSONContractTerms } from '../../../schemas/ewJSONContractTerms';
import { ewJSONDepartment } from '../../../schemas/ewJSONDepartment';
import { ewJSONExerciseAttendClass } from '../../../schemas/ewJSONExerciseAttendClass';
import { ewJSONExerciseCancelPayment } from '../../../schemas/ewJSONExerciseCancelPayment';
import { ewJSONExerciseClass } from '../../../schemas/ewJSONExerciseClass';
import { ewJSONFilters } from '../../../schemas/ewJSONFilters';
import { ewJSONInvoice2 } from '../../../schemas/ewJSONInvoice2';
import { ewJSONPerson } from '../../../schemas/ewJSONPerson';
import { ewJSONSignonPerskoblLog } from '../../../schemas/ewJSONSignonPerskoblLog';
import { ewJSONSwapResult } from '../../../schemas/ewJSONSwapResult';
import { ewJSONVisits } from '../../../schemas/ewJSONVisits';
export class BoostMetoderTilgjengeligEtterInnloggAPI extends APIRestClient {
    /**
     * Returnerer statistikk over bes�k siste 30 dager
     * Implementert hovedsakelig i *ewRestHandlerExerciseClosed*::*getAvailability*
     */
    static getExerciseAvailability(__QUERY) {
        return this._get('/exercise/availability', {}, __QUERY, null, ewJSONVisits.classOf(ewJSONVisits, new ewJSONVisits()), ['https://api.boostsystem.no/signup', 'https://api.boostsystem.no/app'], 'json');
    }
    /**
     * Returnerer timer i handlekurven
     * Implementert hovedsakelig i *ewRestHandlerExerciseClosed*::*getHandlekurv*
     */
    static getExerciseBookedCart(__QUERY) {
        return this._get('/exercise/booked/cart', {}, __QUERY, null, ewJSONExerciseClass.classOf(ewJSONExerciseClass, new ewJSONExerciseClass()).arrayOf(), ['https://api.boostsystem.no/signup', 'https://api.boostsystem.no/app'], 'json');
    }
    /**
     * Returnerer bookede timer som snart skjer
     * Implementert hovedsakelig i *ewRestHandlerExerciseClosed*::*getImminentClasses*
     */
    static getExerciseBookedImminent(__QUERY) {
        return this._get('/exercise/booked/imminent', {}, __QUERY, null, ewJSONExerciseClass.classOf(ewJSONExerciseClass, new ewJSONExerciseClass()).arrayOf(), ['https://api.boostsystem.no/signup', 'https://api.boostsystem.no/app'], 'json');
    }
    /**
     * Returnerer fremtidige bookede timer ved koordinat
     * Implementert hovedsakelig i *ewRestHandlerExerciseClosed*::*getNearbyClasses*
     */
    static getExerciseBookedNearby(__QUERY) {
        return this._get('/exercise/booked/nearby', {}, __QUERY, null, ewJSONExerciseClass.classOf(ewJSONExerciseClass, new ewJSONExerciseClass()).arrayOf(), ['https://api.boostsystem.no/signup', 'https://api.boostsystem.no/app'], 'json');
    }
    /**
     * Returnerer alle bookede timer fremover i tid
     * Implementert hovedsakelig i *ewRestHandlerExerciseClosed*::*getNewBookedClasses*
     */
    static getExerciseBookedNew(__QUERY) {
        return this._get('/exercise/booked/new', {}, __QUERY, null, ewJSONExerciseClass.classOf(ewJSONExerciseClass, new ewJSONExerciseClass()).arrayOf(), ['https://api.boostsystem.no/signup', 'https://api.boostsystem.no/app'], 'json');
    }
    /**
     * Returnerer alle bookede timer bakover i tid
     * Implementert hovedsakelig i *ewRestHandlerExerciseClosed*::*getOldBookedClasses*
     */
    static getExerciseBookedOld(__QUERY) {
        return this._get('/exercise/booked/old', {}, __QUERY, null, ewJSONExerciseClass.classOf(ewJSONExerciseClass, new ewJSONExerciseClass()).arrayOf(), ['https://api.boostsystem.no/signup', 'https://api.boostsystem.no/app'], 'json');
    }
    /**
     * Returnerer bookede timer p� en dag som timestamp
     * Implementert hovedsakelig i *ewRestHandlerExerciseClosed*::*getBookedClasses*
     */
    static getExerciseBookedUsingDate(date, __QUERY) {
        return this._get('/exercise/booked/{date}', { date }, __QUERY, null, ewJSONExerciseClass.classOf(ewJSONExerciseClass, new ewJSONExerciseClass()).arrayOf(), ['https://api.boostsystem.no/signup', 'https://api.boostsystem.no/app'], 'json');
    }
    /**
     * Vilk�r for avtale
     * Implementert hovedsakelig i *ewRestHandlerExerciseContracts*::*getContractTerms*
     */
    static getExerciseContractsTermsUsingContractId(contract_id) {
        return this._get('/exercise/contracts/{contract_id}/terms', { contract_id }, null, null, ewJSONContractTerms.classOf(ewJSONContractTerms, new ewJSONContractTerms()), ['https://api.boostsystem.no/signup', 'https://api.boostsystem.no/app'], 'json');
    }
    /**
     * Returnerer tilgjengelige timer p� en dag
     * Implementert hovedsakelig i *ewRestHandlerExerciseClosed*::*getClassesForDate*
     */
    static getExerciseDateUsingDate(date, __QUERY) {
        return this._get('/exercise/date/{date}', { date }, __QUERY, null, ewJSONExerciseClass.classOf(ewJSONExerciseClass, new ewJSONExerciseClass()).arrayOf(), ['https://api.boostsystem.no/signup', 'https://api.boostsystem.no/app'], 'json');
    }
    /**
     * Returnerer 1 time p� en dag som timestamp
     * Implementert hovedsakelig i *ewRestHandlerExerciseClosed*::*getAClass*
     */
    static getExerciseDateUsingDateId(date, id, __QUERY) {
        return this._get('/exercise/date/{date}/{id}', { date, id }, __QUERY, null, ewJSONExerciseClass.classOf(ewJSONExerciseClass, new ewJSONExerciseClass()), ['https://api.boostsystem.no/signup', 'https://api.boostsystem.no/app'], 'json');
    }
    /**
     * Returnerer tilgjengelige timer for en uke
     * Implementert hovedsakelig i *ewRestHandlerExerciseClosed*::*getClassesForWeek*
     */
    static getExerciseDateWeekUsingWeek(week, __QUERY) {
        return this._get('/exercise/date/week/{week}', { week }, __QUERY, null, ewJSONExerciseClass.classOf(ewJSONExerciseClass, new ewJSONExerciseClass()).arrayOf(), ['https://api.boostsystem.no/signup', 'https://api.boostsystem.no/app'], 'json');
    }
    /**
     * Returnerer medlemmets avdeling
     * Implementert hovedsakelig i *ewRestHandlerExerciseClosed*::*getDepartment*
     */
    static getExerciseDepartment() {
        return this._get('/exercise/department', {}, null, null, ewJSONDepartment.classOf(ewJSONDepartment, new ewJSONDepartment()), ['https://api.boostsystem.no/signup', 'https://api.boostsystem.no/app'], 'json');
    }
    /**
     * Returnerer tilgjengelige avdelinger
     * Implementert hovedsakelig i *ewRestHandlerExerciseClosed*::*getDepartments*
     */
    static getExerciseDepartments() {
        return this._get('/exercise/departments', {}, null, null, ewJSONDepartment.classOf(ewJSONDepartment, new ewJSONDepartment()).arrayOf(), ['https://api.boostsystem.no/signup', 'https://api.boostsystem.no/app'], 'json');
    }
    /**
     * Henter filterverdier
     * Implementert hovedsakelig i *ewRestHandlerExerciseClosed*::*getFilterValues*
     */
    static getExerciseFilter(__QUERY) {
        return this._get('/exercise/filter', {}, __QUERY, null, ewJSONFilters.classOf(ewJSONFilters, new ewJSONFilters()), ['https://api.boostsystem.no/signup', 'https://api.boostsystem.no/app'], 'json');
    }
    /**
     * Returnerer alle betalte fakturaer
     * Implementert hovedsakelig i *ewRestHandlerExerciseClosed*::*getPaidInvoices*
     */
    static getExerciseInvoicesPaid(__QUERY) {
        return this._get('/exercise/invoices/paid', {}, __QUERY, null, ewJSONInvoice2.classOf(ewJSONInvoice2, new ewJSONInvoice2()).arrayOf(), ['https://api.boostsystem.no/signup', 'https://api.boostsystem.no/app'], 'json');
    }
    /**
     * Returnerer alle ubetalte fakturaer
     * Implementert hovedsakelig i *ewRestHandlerExerciseClosed*::*getUnpaidInvoices*
     */
    static getExerciseInvoicesUnpaid(__QUERY) {
        return this._get('/exercise/invoices/unpaid', {}, __QUERY, null, ewJSONInvoice2.classOf(ewJSONInvoice2, new ewJSONInvoice2()).arrayOf(), ['https://api.boostsystem.no/signup', 'https://api.boostsystem.no/app'], 'json');
    }
    /**
     * Hent nyheter for senter
     * Implementert hovedsakelig i *ewRestHandlerExerciseNews*::*getNews*
     */
    static getExerciseNews(__QUERY) {
        return this._get('/exercise/news', {}, __QUERY, null, NewsArticle.classOf(NewsArticle, new NewsArticle()).arrayOf(), ['https://api.boostsystem.no/signup', 'https://api.boostsystem.no/app'], 'json');
    }
    /**
     * Finnes det uleste nyheter
     * Implementert hovedsakelig i *ewRestHandlerExerciseNews*::*getUnread*
     */
    static getExerciseNewsUnread() {
        return this._get('/exercise/news/unread', {}, null, null, APIRestClient.Native.boolean(), ['https://api.boostsystem.no/signup', 'https://api.boostsystem.no/app'], 'json');
    }
    /**
     * Starter betalings prosess
     * Implementert hovedsakelig i *ewRestHandlerExerciseClosed*::*getVippsURL*
     */
    static getExercisePayment(__QUERY) {
        return this._get('/exercise/payment', {}, __QUERY, null, SignupInitiatePaymentResponse.classOf(SignupInitiatePaymentResponse, new SignupInitiatePaymentResponse()), ['https://api.boostsystem.no/signup', 'https://api.boostsystem.no/app'], 'json');
    }
    /**
     * Vipps betaling avbrutt av bruker
     * Implementert hovedsakelig i *ewRestHandlerExerciseClosed*::*doVippsCancel*
     */
    static getExercisePaymentCancelUsingId(id, __QUERY) {
        return this._get('/exercise/payment/{id}/cancel', { id }, __QUERY, null, ewJSONExerciseCancelPayment.classOf(ewJSONExerciseCancelPayment, new ewJSONExerciseCancelPayment()), ['https://api.boostsystem.no/signup', 'https://api.boostsystem.no/app'], 'json');
    }
    /**
     * Returnerer kj�psvilk�r for en avdeling
     * Implementert hovedsakelig i *ewRestHandlerExerciseClosed*::*getConditions*
     */
    static getExercisePaymentConditions(__QUERY) {
        return this._get('/exercise/payment/conditions', {}, __QUERY, null, APIRestClient.Native.Blob(), ['https://api.boostsystem.no/signup', 'https://api.boostsystem.no/app'], 'blob');
    }
    /**
     * Returnerer URL for � laste ned kj�psvilk�r for en avdeling
     * Implementert hovedsakelig i *ewRestHandlerExerciseClosed*::*getConditionURL*
     */
    static getExercisePaymentConditionurl(__QUERY) {
        return this._get('/exercise/payment/conditionurl', {}, __QUERY, null, ewConditionURL.classOf(ewConditionURL, new ewConditionURL()), ['https://api.boostsystem.no/signup', 'https://api.boostsystem.no/app'], 'json');
    }
    /**
     * Sjekk siste transaction status hos Vipps
     * Implementert hovedsakelig i *ewRestHandlerExerciseClosed*::*getVippsStatus*
     */
    static getExercisePaymentStatusUsingId(id, __QUERY) {
        return this._get('/exercise/payment/{id}/status', { id }, __QUERY, null, APIRestClient.Native.string(), ['https://api.boostsystem.no/signup', 'https://api.boostsystem.no/app'], 'json');
    }
    /**
     * Returnerer medlemmets profil
     * Implementert hovedsakelig i *ewRestHandlerExerciseClosed*::*getProfile*
     */
    static getExerciseProfile(__QUERY) {
        return this._get('/exercise/profile', {}, __QUERY, null, ewJSONPerson.classOf(ewJSONPerson, new ewJSONPerson()), ['https://api.boostsystem.no/signup', 'https://api.boostsystem.no/app'], 'json');
    }
    /**
     * Returnerer medlemmets profil-bilde eller NULL
     * Implementert hovedsakelig i *ewRestHandlerExerciseClosed*::*getProfileImage*
     */
    static getExerciseProfileImage(__QUERY) {
        return this._get('/exercise/profile/image', {}, __QUERY, null, APIRestClient.Native.Blob(), ['https://api.boostsystem.no/signup', 'https://api.boostsystem.no/app'], 'blob');
    }
    /**
     * Pr�ver � bytte enhet
     * Implementert hovedsakelig i *ewRestHandlerExerciseClosed*::*setPrimaryToken*
     */
    static getExerciseSetprimarytoken(__QUERY) {
        return this._get('/exercise/setprimarytoken', {}, __QUERY, null, ewJSONSwapResult.classOf(ewJSONSwapResult, new ewJSONSwapResult()), ['https://api.boostsystem.no/signup', 'https://api.boostsystem.no/app'], 'json');
    }
    /**
     * Returnerer registrerte bes�k
     * Implementert hovedsakelig i *ewRestHandlerExerciseClosed*::*getVisits*
     */
    static getExerciseVisits(__QUERY) {
        return this._get('/exercise/visits', {}, __QUERY, null, ewJSONSignonPerskoblLog.classOf(ewJSONSignonPerskoblLog, new ewJSONSignonPerskoblLog()).arrayOf(), ['https://api.boostsystem.no/signup', 'https://api.boostsystem.no/app'], 'json');
    }
    /**
     * Registrer oppm�te p� en time
     * Implementert hovedsakelig i *ewRestHandlerExerciseClosed*::*attendClass*
     */
    static postExerciseAttendUsingDateClass(date, p_class, __QUERY) {
        return this._post('/exercise/attend/{date}/{class}', { date, class: p_class }, __QUERY, null, ewJSONExerciseAttendClass.classOf(ewJSONExerciseAttendClass, new ewJSONExerciseAttendClass()), ['https://api.boostsystem.no/signup', 'https://api.boostsystem.no/app'], 'json');
    }
    /**
     * Bestill time p� en dag
     * Implementert hovedsakelig i *ewRestHandlerExerciseClosed*::*bookClass*
     */
    static postExerciseBookUsingDateClass(date, p_class, __QUERY) {
        return this._post('/exercise/book/{date}/{class}', { date, class: p_class }, __QUERY, null, ewJSONExerciseClass.classOf(ewJSONExerciseClass, new ewJSONExerciseClass()), ['https://api.boostsystem.no/signup', 'https://api.boostsystem.no/app'], 'json');
    }
    /**
     * Oppdater alle som lest
     * Implementert hovedsakelig i *ewRestHandlerExerciseNews*::*markAllAsRead*
     */
    static postExerciseNewsRead(__QUERY) {
        return this._post('/exercise/news/read', {}, __QUERY, null, APIRestClient.Native.string(), ['https://api.boostsystem.no/signup', 'https://api.boostsystem.no/app'], 'json');
    }
    /**
     * Lagre medlemmets profil-bilde
     * Implementert hovedsakelig i *ewRestHandlerExerciseClosed*::*saveProfileImage*
     */
    static postExerciseProfileImageSave() {
        return this._post('/exercise/profile/image/save', {}, null, null, APIRestClient.Native.boolean(), ['https://api.boostsystem.no/signup', 'https://api.boostsystem.no/app'], 'json');
    }
    /**
     * Avbestill time p� en dag
     * Implementert hovedsakelig i *ewRestHandlerExerciseClosed*::*unbookClass*
     */
    static postExerciseUnbookUsingDateClassBooking(date, p_class, booking, __QUERY) {
        return this._post('/exercise/unbook/{date}/{class}/{booking}', { date, class: p_class, booking }, __QUERY, null, ewJSONExerciseClass.classOf(ewJSONExerciseClass, new ewJSONExerciseClass()), ['https://api.boostsystem.no/signup', 'https://api.boostsystem.no/app'], 'json');
    }
    /**
     * Cancel contract
     * Implementert hovedsakelig i *ewRestHandlerExerciseContracts*::*cancelContract*
     */
    static putExerciseContractsCancelUsingContractId(contract_id, __DATA) {
        return this._put('/exercise/contracts/{contract_id}/cancel', { contract_id }, null, JSON.stringify(__DATA), APIRestClient.Native.string(), ['https://api.boostsystem.no/signup', 'https://api.boostsystem.no/app'], 'json');
    }
    /**
     * Update news article read status
     * Implementert hovedsakelig i *ewRestHandlerExerciseNews*::*updateNewsArticleStatus*
     */
    static putExerciseNewsStatusUsingArticleid(articleID, __DATA) {
        return this._put('/exercise/news/{articleID}/status', { articleID }, null, JSON.stringify(__DATA), NewsArticleStatus.classOf(NewsArticleStatus, new NewsArticleStatus()), ['https://api.boostsystem.no/signup', 'https://api.boostsystem.no/app'], 'json');
    }
    /**
     * Lagre profil-data oppdaterer person og kontakt
     * Implementert hovedsakelig i *ewRestHandlerExerciseClosed*::*saveProfile*
     */
    static putExerciseProfileSave(__DATA) {
        return this._put('/exercise/profile/save', {}, null, JSON.stringify(__DATA), ewJSONPerson.classOf(ewJSONPerson, new ewJSONPerson()), ['https://api.boostsystem.no/signup', 'https://api.boostsystem.no/app'], 'json');
    }
}

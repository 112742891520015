import * as Redux from 'redux';
import ReduxThunk from 'redux-thunk';
import { composeEnhancers } from 'cerum-redux-helper/ComposeEnhancers';
import { BoostPortalStore } from './BoostPortalStore';
import { BoostPortalSubStore } from './BoostPortalSubStore';
export class BoostPortalRootStore {
    static instance() {
        if (this._instance) {
            return this._instance;
        }
        const enhancers = composeEnhancers(Redux.applyMiddleware(ReduxThunk));
        const store = Redux.createStore(this.rootReducer(), enhancers);
        // For live debugging
        store._sub_stores = BoostPortalRootStore.global_sub_stores;
        store._BoostPortalStore = BoostPortalRootStore;
        this._instance = window.BOOST_REDUX_STORE = store;
        BoostPortalSubStore.rootstore.setValue(store);
        return store;
    }
    static rootReducer() {
        let reducer_map = {};
        const stores = this.global_sub_stores;
        for (const store of stores) {
            reducer_map = Object.assign(Object.assign({}, reducer_map), store.instance.reducer());
        }
        return Redux.combineReducers(reducer_map);
    }
}
BoostPortalRootStore.global_sub_stores = [BoostPortalStore];

export var TaskStatus;
(function (TaskStatus) {
    TaskStatus["CANCELLED"] = "CANCELLED";
    TaskStatus["COMPLETED"] = "COMPLETED";
    TaskStatus["DELETED"] = "DELETED";
    TaskStatus["DEVIANCE"] = "DEVIANCE";
    TaskStatus["HOLIDAY"] = "HOLIDAY";
    TaskStatus["INCOMPLETE"] = "INCOMPLETE";
    TaskStatus["INPROGRESS"] = "INPROGRESS";
    TaskStatus["OVERDUE"] = "OVERDUE";
    TaskStatus["PROPOSED"] = "PROPOSED";
    TaskStatus["SCHEDULED"] = "SCHEDULED";
    TaskStatus["SIMULATED"] = "SIMULATED";
    TaskStatus["STARTED"] = "STARTED";
})(TaskStatus || (TaskStatus = {}));

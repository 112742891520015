import { render } from 'lit';
export function vaadinHtmlRendererStaticWrapper(template) {
    return (root) => {
        if (template instanceof Promise) {
            template.then((templateResult) => render(templateResult, root));
        }
        else {
            render(template, root);
        }
    };
}
/**
 * @see also vaadinSelectRenderer
 */
export function vaadinSelectRendererCallbackWrapper(templateProvider) {
    return (root) => {
        let templateResult;
        if (typeof templateProvider == 'function') {
            templateResult = templateProvider();
        }
        else {
            templateResult = templateProvider;
        }
        let vaadinListBox = root.firstElementChild;
        if (!vaadinListBox) {
            vaadinListBox = document.createElement('vaadin-list-box');
            root.appendChild(vaadinListBox);
        }
        if (vaadinListBox.localName !== 'vaadin-list-box') {
            throw new Error('Bad root node');
        }
        render(templateResult, vaadinListBox);
    };
}
export function vaadinSelectRenderer(templateProvider) {
    return (root) => {
        if (root.firstElementChild) {
            return;
        }
        vaadinSelectInnerRenderer(templateProvider)(root);
    };
}
function vaadinSelectInnerRenderer(templateProvider) {
    return (root) => {
        let templateResult;
        if (typeof templateProvider == 'function') {
            templateResult = templateProvider();
        }
        else {
            templateResult = templateProvider;
        }
        const tempRenderTarget = document.createElement('div');
        render(templateResult, tempRenderTarget);
        if (tempRenderTarget.childElementCount == 0) {
            return;
        }
        if (tempRenderTarget.childElementCount > 1) {
            console.error('vaadin-select renderer can only render content with a single root node. Did you forgot to wrap the content in i vaadin-list-box?', tempRenderTarget.innerHTML);
            return;
        }
        const vaadinListBox = tempRenderTarget.firstElementChild;
        if (!vaadinListBox) {
            console.error('vaadin-select renderer empty', root.innerHTML);
            return;
        }
        if (vaadinListBox.localName !== 'vaadin-list-box') {
            console.error('vaadin-select renderer renderer content without vaadin-list-box-root');
            throw new Error('Bad root node');
        }
        const previousVaadinListBox = root.firstElementChild;
        if (previousVaadinListBox) {
            root.replaceChild(vaadinListBox, previousVaadinListBox);
        }
        else {
            root.appendChild(vaadinListBox);
        }
    };
}
export function vaadinHtmlGridRendererWrapper(callback) {
    return (root, column, model) => {
        const templateResult = callback(column, model);
        const renderer = vaadinHtmlRendererStaticWrapper(templateResult);
        renderer(root);
    };
}
export function vaadinHtmlGridHeaderFooterRendererWrapper(callback) {
    return (root, column) => {
        const templateResult = callback(column);
        const renderer = vaadinHtmlRendererStaticWrapper(templateResult);
        renderer(root);
    };
}
/*
This is a helper function to extract parentItem from
IVaadinGridDataProviderParams with the correct type
*/
export function getParentItem(params) {
    if (params.parentItem) {
        return params.parentItem;
    }
    return null;
}

import { Router } from '@vaadin/router';
import { BoostPortalRegisterMemberCompanySponsoredView } from '../views/BoostPortalRegisterMemberCompanySponsoredView';
import { BoostPortalRegisterMemberPrivatelySponsoredView } from '../views/BoostPortalRegisterMemberPrivatelySponsoredView';
import { BoostPortalNotFoundView } from '../views/BoostPortalNotFoundView';
import { BoostPortalRegisterSponsorView } from '../views/BoostPortalRegisterSponsorView';
import { BoostPortalNavigationView } from '../views/BoostPortalNavigationView';
import { BoostPortalLoginView } from '../views/BoostPortalLoginView';
import { BoostPortalProfileView } from '../views/BoostPortalProfileView';
import { BoostPortalForgottenPasswordView } from '../views/BoostPortalForgottenPasswordView';
import { BoostPortalRegisterProfileView } from '../views/BoostPortalRegisterProfileView';
import { BoostPortalRegisterProfileSuccessView } from '../views/BoostPortalRegisterProfileSuccessView';
import { BoostPortalSponsorAcceptView } from '../views/BoostPortalSponsorAcceptView';
import { BoostPortalDirectDebitView } from '../views/BoostPortalDirectDebitView';
import { BoostPortalVippsWrapper } from '../helpers/BoostPortalVippsWrapper';
import { TrackingFactorySingleton } from '../tracking/TrackingFactorySingleton';
import { TrackingEvent } from '../tracking/TrackingEvent';
import { BoostPortalInvoicePaymentSuccessView } from '../views/BoostPortalInvoicePaymentSuccessView';
import { BoostPortalPaymentVippsCondition } from '../views/BoostPortalPaymentVippsCondition';
export const router = new Router();
router.setRoutes([
    {
        path: '/:center',
        component: BoostPortalNavigationView.is,
    },
    {
        path: '/:center/member',
        action: () => {
            //  En mulig plass å registrere eventer
            TrackingFactorySingleton.getTracker().completedEvent(TrackingEvent.MemberSignupStarted);
        },
        component: BoostPortalVippsWrapper.is,
    },
    {
        path: '/:center/member/company',
        action: () => {
            TrackingFactorySingleton.getTracker().completedEvent(TrackingEvent.CompanySponsoredSignupStarted);
        },
        component: BoostPortalRegisterMemberCompanySponsoredView.is,
    },
    {
        path: '/:center/member/private',
        action: () => {
            TrackingFactorySingleton.getTracker().completedEvent(TrackingEvent.PrivatelySponsoredSignupStarted);
        },
        component: BoostPortalRegisterMemberPrivatelySponsoredView.is,
    },
    {
        path: '/:center/member/profile',
        component: BoostPortalRegisterProfileView.is,
    },
    {
        path: '/:center/member/profile/success',
        component: BoostPortalRegisterProfileSuccessView.is,
    },
    {
        path: '/:center/sponsor',
        component: BoostPortalRegisterSponsorView.is,
    },
    {
        path: '/:center/sponsor/accept',
        component: BoostPortalSponsorAcceptView.is,
    },
    {
        path: '/:center/login',
        component: BoostPortalLoginView.is,
    },
    {
        path: '/:center/profile',
        component: BoostPortalProfileView.is,
    },
    {
        path: '/:center/forgotten-password',
        component: BoostPortalForgottenPasswordView.is,
    },
    {
        path: '/:center/payment',
        component: BoostPortalInvoicePaymentSuccessView.is,
    },
    {
        path: '/:center/vilkar',
        component: BoostPortalPaymentVippsCondition.is,
    },
    {
        path: '/:center/avtalegiro',
        component: BoostPortalDirectDebitView.is,
    },
    {
        path: '(.*)',
        component: BoostPortalNotFoundView.is,
    },
]);

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { CerumServices } from './CerumServices';
import { LoadingBox } from './LoadingBox';
export class LoadingBoxZoot extends LoadingBox {
    /** @override */ _getLoadingDiv() {
        const doc = this.dom.getDocument();
        const div = doc.getElementById('_x_loading_div');
        if (typeof div !== 'undefined' && div) {
            return div;
        }
        const d = doc.createElement('div');
        const _1 = doc.firstElementChild ? doc.firstElementChild : doc.body; // (dojo.doc.compatMode=="BackCompat")?dojo.body():dojo.doc.documentElement;
        const _width = _1.clientWidth;
        const _height = _1.clientHeight;
        const params = {
            display: 'none',
            position: 'absolute',
            left: _width / 2 + 'px',
            top: _height / 2 + 'px',
            backgroundColor: 'none',
            padding: '3px',
            border: '0px solid gray',
            fontSize: '10px',
            verticalAlign: 'top',
        };
        for (const i in params) {
            d.style.setProperty(i, params[i]);
        }
        d.id = '_x_loading_div';
        //        const url = this._getLoadingImg();
        //			'<img src="'+i+'" /> '
        const img = document.createElement('img');
        img.alt = 'Loading';
        (() => __awaiter(this, void 0, void 0, function* () {
            img.src = (yield CerumServices.instance().getStaticUrl()) + '/bilder/loading_logo.gif';
        }))();
        d.appendChild(img);
        // d.innerHTML = '<img src="' + url + '" alt="Loading"/>';
        doc.body.appendChild(d);
        return d;
    }
}

var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
/* tslint:disable: class-name */
/*
 * AUTOGENERATED FILE - DO NOT EDIT
 */
import { ewProjectsStatisticsResultCheckins } from './ewProjectsStatisticsResultCheckins';
import { ewProjectStatisticsResulCheckins_OneProject } from './ewProjectStatisticsResulCheckins_OneProject';
import { JSONStruct, array_property, property } from 'badphraim/data/JSONStruct';
export class ewProjectStatisticsResulCheckins_PerProject extends JSONStruct {
    constructor() {
        super(...arguments);
        /** @override */ this.last_thirty_days = new ewProjectsStatisticsResultCheckins();
        /** @override */ this.now = new ewProjectsStatisticsResultCheckins();
        /** @override */ this.project_statistics = [];
        /** @override */ this.today = new ewProjectsStatisticsResultCheckins();
        /** @override */ this.yesterday = new ewProjectsStatisticsResultCheckins();
    }
}
__decorate([
    property,
    __metadata("design:type", ewProjectsStatisticsResultCheckins)
], ewProjectStatisticsResulCheckins_PerProject.prototype, "last_thirty_days", void 0);
__decorate([
    property,
    __metadata("design:type", ewProjectsStatisticsResultCheckins)
], ewProjectStatisticsResulCheckins_PerProject.prototype, "now", void 0);
__decorate([
    array_property(ewProjectStatisticsResulCheckins_OneProject),
    __metadata("design:type", Array)
], ewProjectStatisticsResulCheckins_PerProject.prototype, "project_statistics", void 0);
__decorate([
    property,
    __metadata("design:type", ewProjectsStatisticsResultCheckins)
], ewProjectStatisticsResulCheckins_PerProject.prototype, "today", void 0);
__decorate([
    property,
    __metadata("design:type", ewProjectsStatisticsResultCheckins)
], ewProjectStatisticsResulCheckins_PerProject.prototype, "yesterday", void 0);

/**
 * @TODO, autogenerer den her
 */
export class DatatypeConstantsEnum {
}
DatatypeConstantsEnum.ARTICLE = 29;
DatatypeConstantsEnum.ARTICLE_CATEGORY = 33;
DatatypeConstantsEnum.CALENDAR = 13;
DatatypeConstantsEnum.CALEVENT_INSTANCE = 47;
DatatypeConstantsEnum.CONTACT = 3;
DatatypeConstantsEnum.CONTRACT = 26;
DatatypeConstantsEnum.CONTRACT_DEVIATION = 40;
DatatypeConstantsEnum.CONTRACT_LINE = 41;
DatatypeConstantsEnum.CONTROLLED_DOCUMENT = 35;
DatatypeConstantsEnum.DEPARTMENT = 2;
DatatypeConstantsEnum.DOCUMENT = 5;
DatatypeConstantsEnum.DOCUMENT_CONTROL_SYSTEM = 34;
DatatypeConstantsEnum.DOCUMENT_SYSTEM = 38;
DatatypeConstantsEnum.EMAIL = 6;
DatatypeConstantsEnum.EMAILFOLDER = 7;
DatatypeConstantsEnum.EXPENSE = 45;
DatatypeConstantsEnum.FOLDER = 12;
DatatypeConstantsEnum.GROUP = 9;
DatatypeConstantsEnum.HELPDESK = 48;
DatatypeConstantsEnum.IMAP_MESSAGE = 28;
DatatypeConstantsEnum.INVOICE = 30;
DatatypeConstantsEnum.INVOICE_APPENDIX = 31;
DatatypeConstantsEnum.INVOICE_BUNDLE = 32;
DatatypeConstantsEnum.KEYWORD = 22;
DatatypeConstantsEnum.KEYWORD_CATEGORY = 24;
DatatypeConstantsEnum.LOGIN = 8;
DatatypeConstantsEnum.MMS = 20;
DatatypeConstantsEnum.NODE_LOG = 42;
DatatypeConstantsEnum.NOTE = 17;
DatatypeConstantsEnum.OWNER = 1;
DatatypeConstantsEnum.PERSON = 14;
DatatypeConstantsEnum.PROJECT = 4;
DatatypeConstantsEnum.RESOURCE = 36;
DatatypeConstantsEnum.RESOURCE_CATEGORY = 37;
DatatypeConstantsEnum.ROLE = 15;
DatatypeConstantsEnum.SELECTION = 46;
DatatypeConstantsEnum.SMS = 16;
DatatypeConstantsEnum.SMS_TEMPLATE = 27;
DatatypeConstantsEnum.SSL_SERVICECARD = 23;
DatatypeConstantsEnum.STANDARDURL = 19;
DatatypeConstantsEnum.STRUCTURE_LINK = 25;
DatatypeConstantsEnum.TEMPLATE = 18;
DatatypeConstantsEnum.TEXT = 11;
DatatypeConstantsEnum.URL = 10;
export class DatatypeConstants extends DatatypeConstantsEnum {
    static getDatatypeName(dt) {
        if (dt in DatatypeConstants.z_string_map) {
            return DatatypeConstants.z_string_map[dt];
        }
        return 'ukjent';
    }
    static getDatatypeSymbol(dt) {
        for (const [key, value] of Object.entries(DatatypeConstantsEnum)) {
            if (typeof value === 'number') {
                if (value === dt) {
                    return key;
                }
            }
        }
        throw new Error('Uknown datatype-id: ' + dt);
    }
}
DatatypeConstants.z_string_map = {
    [DatatypeConstants.OWNER]: 'Owner',
    [DatatypeConstants.DEPARTMENT]: 'Department',
    [DatatypeConstants.CONTACT]: 'Contact',
    [DatatypeConstants.PROJECT]: 'Project',
    [DatatypeConstants.DOCUMENT]: 'Document',
    [DatatypeConstants.EMAIL]: 'Email',
    [DatatypeConstants.EMAILFOLDER]: 'Emailfolder',
    [DatatypeConstants.LOGIN]: 'Login',
    [DatatypeConstants.GROUP]: 'Group',
    [DatatypeConstants.URL]: 'Nettlink',
    [DatatypeConstants.TEXT]: 'Text',
    [DatatypeConstants.FOLDER]: 'Folder',
    [DatatypeConstants.CALENDAR]: 'Calendar',
    [DatatypeConstants.PERSON]: 'Person',
    [DatatypeConstants.ROLE]: 'Role',
    [DatatypeConstants.SMS]: 'SMS',
    [DatatypeConstants.NOTE]: 'Note',
    [DatatypeConstants.TEMPLATE]: 'Template',
    [DatatypeConstants.STANDARDURL]: 'Standard nettlink',
    [DatatypeConstants.MMS]: 'MMS',
    [DatatypeConstants.KEYWORD]: 'Keyword',
    [DatatypeConstants.KEYWORD_CATEGORY]: 'Keyword category',
    [DatatypeConstants.SSL_SERVICECARD]: 'SSL Servicecard',
    [DatatypeConstants.STRUCTURE_LINK]: 'Snarvei',
    [DatatypeConstants.CONTRACT]: 'Contract',
    [DatatypeConstants.SMS_TEMPLATE]: 'SMS-mal',
    [DatatypeConstants.IMAP_MESSAGE]: 'IMAP Melding',
    [DatatypeConstants.ARTICLE]: 'Article',
    [DatatypeConstants.INVOICE]: 'Invoice',
    [DatatypeConstants.INVOICE_APPENDIX]: 'Invoice appendix',
    [DatatypeConstants.INVOICE_BUNDLE]: 'Invoice bundle',
    [DatatypeConstants.ARTICLE_CATEGORY]: 'Article category',
    [DatatypeConstants.DOCUMENT_CONTROL_SYSTEM]: 'Document control system',
    [DatatypeConstants.CONTROLLED_DOCUMENT]: 'Controlled document',
    [DatatypeConstants.RESOURCE]: 'Resource',
    [DatatypeConstants.RESOURCE_CATEGORY]: 'Ressource category',
    [DatatypeConstants.DOCUMENT_SYSTEM]: 'Document system',
    [DatatypeConstants.CONTRACT_LINE]: 'Agreement line',
    [DatatypeConstants.CONTRACT_DEVIATION]: 'Agreement deviation',
    [DatatypeConstants.NODE_LOG]: 'Nodelogg',
    [DatatypeConstants.EXPENSE]: 'Expense',
    [DatatypeConstants.SELECTION]: 'Utvalg',
    [DatatypeConstants.CALEVENT_INSTANCE]: 'Hendelse instanse',
    [DatatypeConstants.HELPDESK]: 'Helpdesk',
};

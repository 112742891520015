var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { css, html, LitElement } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { Checkbox } from '@vaadin/checkbox';
let CerumModalFooter = class CerumModalFooter extends LitElement {
    constructor() {
        super(...arguments);
        this.hideKeepWindowOpen = false;
        this.keepWindowOpen = false;
    }
    static get is() {
        return 'cerum-modal-footer';
    }
    static get styles() {
        return css `
            hr {
                background-color: #1d2a3a;
                height: 1px;
                border: none;
                margin: 20px 0;
            }
        `;
    }
    /** @override */ render() {
        return html `<hr />

            <div style="display: flex; justify-content: space-between;">
                <div>${this._renderKeepWindowOpenToggle()}</div>

                <slot name="actions" style="text-align: right;"></slot>
            </div>`;
    }
    _renderKeepWindowOpenToggle() {
        if (this.hideKeepWindowOpen) {
            return;
        }
        return html `<vaadin-checkbox
                ?checked="${this.keepWindowOpen}"
                @change="${() => (this.keepWindowOpen = !this.keepWindowOpen)}"
            ></vaadin-checkbox>
            Hold dette vinduet åpent etter oppretting`;
    }
    /** @override */ updated(changedProperties) {
        changedProperties.forEach((_oldValue, propertyName) => {
            switch (propertyName) {
                case 'keepWindowOpen':
                    this._keepWindowOpenObserver();
                    break;
            }
        });
        super.updated(changedProperties);
    }
    _keepWindowOpenObserver() {
        this.dispatchEvent(new CustomEvent('keepWindowOpen-changed', {
            bubbles: true,
            composed: true,
            detail: {
                value: this.keepWindowOpen,
            },
        }));
    }
};
CerumModalFooter._deps = [Checkbox];
__decorate([
    property({ type: Boolean }),
    __metadata("design:type", Boolean)
], CerumModalFooter.prototype, "hideKeepWindowOpen", void 0);
__decorate([
    state(),
    __metadata("design:type", Boolean)
], CerumModalFooter.prototype, "keepWindowOpen", void 0);
CerumModalFooter = __decorate([
    customElement(CerumModalFooter.is)
], CerumModalFooter);
export { CerumModalFooter };

export class VoidTracking {
    initialize() {
        // void
    }
    disable() {
        // void
    }
    completedEvent(..._args) {
        // void
    }
}

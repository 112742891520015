import { Datatype } from 'cerum-data/Datatype';
import { CerumReduxSubStore } from './CerumReduxSubStore';
class CerumMainSearchStoreLayout {
}
export class CerumMainSearchStore extends CerumReduxSubStore {
    constructor() {
        super(...arguments);
        /** @override */ this.base_key = 'CerumMainSearchStore';
    }
    static get instance() {
        return this._instance || (this._instance = new CerumMainSearchStore());
    }
    static path(property) {
        return this.staticPath('CerumMainSearchStore', property);
    }
    /** @override */ reducer() {
        return {
            [this.base_key]: (state = new CerumMainSearchStoreLayout(), action) => {
                switch (action.type) {
                    case CerumMainSearchStore.Actions.SET_SEARCH_QUERY:
                        return Object.assign(Object.assign({}, state), { query: action.payload.query });
                    case CerumMainSearchStore.Actions.SHOW_MAIN_SEARCH:
                        return Object.assign(Object.assign({}, state), { showMainSearch: action.payload.showMainSearch });
                    case CerumMainSearchStore.Actions.SHOW_PREVIEW_SEARCH:
                        return Object.assign(Object.assign({}, state), { showPreviewSearch: action.payload.showPreviewSearch });
                    case CerumMainSearchStore.Actions.SET_SEARCH_LOADING:
                        return Object.assign(Object.assign({}, state), { loading: action.payload.loading });
                    case CerumMainSearchStore.Actions.SET_SEARCH_GROUPED_NODES:
                        return Object.assign(Object.assign({}, state), { groupedNodes: action.payload.groupedNodes });
                }
                return state;
            },
        };
    }
}
(function (CerumMainSearchStore) {
    let Actions;
    (function (Actions) {
        Actions.SET_SEARCH_QUERY = 'SET_SEARCH_QUERY';
        Actions.SHOW_MAIN_SEARCH = 'SET_SEARCH_STATUS';
        Actions.SHOW_PREVIEW_SEARCH = 'SET_PREVIEW_SEARCH_STATUS';
        Actions.SET_SEARCH_LOADING = 'SET_SEARCH_LOADING';
        Actions.SET_SEARCH_GROUPED_NODES = 'SET_SEARCH_GROUPEDNODES';
        Actions.SetSearchQuery = (query) => ({
            type: Actions.SET_SEARCH_QUERY,
            payload: {
                query,
            },
        });
        Actions.ShowMainSearch = (showMainSearch) => ({
            type: Actions.SHOW_MAIN_SEARCH,
            payload: {
                showMainSearch,
                showPreviewSearch: false,
            },
        });
        Actions.ShowPreviewSearch = (showPreviewSearch) => ({
            type: Actions.SHOW_PREVIEW_SEARCH,
            payload: {
                showPreviewSearch,
            },
        });
        Actions.SetLoading = (loading) => ({
            type: Actions.SET_SEARCH_LOADING,
            payload: {
                loading,
            },
        });
        Actions.SetSearchGroupedNodes = (searchStructure, invoiceSearchStructure) => ({
            type: Actions.SET_SEARCH_GROUPED_NODES,
            payload: {
                groupedNodes: Actions.aggregateStructureResults(searchStructure, invoiceSearchStructure),
            },
        });
        const groupedNodesComparator = (lhs, rhs) => {
            var _a, _b;
            return ((_a = rhs.priority) !== null && _a !== void 0 ? _a : 0) - ((_b = lhs.priority) !== null && _b !== void 0 ? _b : 0);
        };
        const getDatatypePriority = (datatype) => {
            let priority = undefined;
            switch (datatype) {
                case Datatype.CONTACT:
                    priority = 4;
                    break;
                case Datatype.RESOURCE:
                    priority = 3;
                    break;
                case Datatype.PERSON:
                    priority = 2;
                    break;
                case Datatype.PROJECT:
                    priority = 1;
                    break;
            }
            return priority;
        };
        Actions.aggregateStructureResults = (searchResults, searchInvoiceResults) => {
            var _a;
            const data = {};
            for (const invoice of searchInvoiceResults) {
                const dataId = 'node_invoice';
                if (!(dataId in data)) {
                    data[dataId] = {
                        group_id: dataId,
                        title: 'Faktura',
                        hits: [],
                    };
                }
                data[dataId].hits.push({
                    datatype_id: Datatype.INVOICE,
                    node_id: 0,
                    content_type: 'INVOICE',
                    major_parent_node_id: invoice.id,
                    major_parent_title: invoice.contact_name,
                    major_parent_datatype_id: Datatype.CONTACT,
                    major_parent_data_id: invoice.contact_id,
                    title: invoice.fakturanr,
                    dataid: (_a = invoice.contact_id) !== null && _a !== void 0 ? _a : 0,
                });
            }
            searchResults.map((item) => {
                const dataId = 'node_' + item.datatype_id;
                if (!(dataId in data)) {
                    data[dataId] = {
                        group_id: dataId,
                        title: Datatype.getDatatypeName(item.datatype_id),
                        hits: [],
                        priority: getDatatypePriority(item.datatype_id),
                    };
                }
                data[dataId].hits.push(item);
            });
            return Object.keys(data)
                .map((key) => data[key])
                .sort(groupedNodesComparator);
        };
    })(Actions = CerumMainSearchStore.Actions || (CerumMainSearchStore.Actions = {}));
})(CerumMainSearchStore || (CerumMainSearchStore = {}));

var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { css, html, LitElement } from 'lit';
import { customElement, state } from 'lit/decorators.js';
import { BoostButton } from '../components/common/BoostButton';
import { InnloggingPortalAPI } from 'boost-api/boost/signup/innlogging_portal/InnloggingPortalAPI';
import { isSuccessHTTP, isErrorHTTP } from 'boost-api/APIRestClient';
import { reduxBind } from 'badphraim/ui/LitReduxMixinBase';
import { BoostPortalStore } from '../redux/BoostPortalStore';
import { LitReduxBoostPortalMixin } from '../redux/LitReduxBoostPortalMixin';
import { router } from '../router/BoostPortalRouter';
import { SnackBar } from 'common-uicomponents/components/SnackBar';
import { BoostPortalSessionHandler } from '../helpers/BoostPortalSessionHandler';
import { BoostPortalHeader } from '../components/BoostPortalHeader';
import { TextField } from '@vaadin/text-field';
import { PasswordField } from '@vaadin/password-field';
let BoostPortalLoginView = class BoostPortalLoginView extends LitReduxBoostPortalMixin(LitElement) {
    constructor() {
        super(...arguments);
        this.username = '';
        this.password = '';
        this.sessionHandler = new BoostPortalSessionHandler();
    }
    static get is() {
        return 'boost-portal-login-view';
    }
    static get styles() {
        return css `
            h4 {
                color: var(--portalForeground, #404040);
                font-weight: 500;
                margin-bottom: 20px;
                margin-top: 0;
            }

            vaadin-text-field,
            vaadin-password-field {
                width: 100%;
            }

            a:link,
            a:visited,
            a:active {
                text-decoration: underline;
                color: var(--portalLink, #2a9ba3);
            }

            a:hover {
                text-decoration: none;
            }
        `;
    }
    /** @override */ render() {
        return html `
            <boost-portal-header .viewLoginButton="${false}"></boost-portal-header>

            ${this.session
            ? html `Du er allerede innlogget`
            : html `<div style="margin-bottom: 40px;">
                      <div>
                          <vaadin-text-field
                              label="Telefonnummer"
                              required
                              error-message="Telefonnummer må fylles ut"
                              @value-changed="${(event) => {
                this.username = event.detail.value;
            }}"
                          ></vaadin-text-field>
                      </div>
                      <div>
                          <vaadin-password-field
                              label="Passord"
                              required
                              error-message="Passord må fylles ut"
                              @value-changed="${(event) => {
                this.password = event.detail.value;
            }}"
                              @keydown="${(event) => {
                if (event.code == 'Enter') {
                    this._loginUser();
                }
            }}"
                          ></vaadin-password-field>
                      </div>
                      <div style="padding-top:15px; padding-bottom:15px;">
                          <boost-button
                              name="Logg Inn"
                              type="header-button"
                              @click="${this._loginUser}"
                          ></boost-button>
                      </div>
                      <div>
                          <a href="${this.centerShort}/forgotten-password">Glemt passord?</a>
                      </div>
                  </div>`}
        `;
    }
    _loginUser() {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.center) {
                return;
            }
            this.dispatch(BoostPortalStore.Actions.SetLoggedInProfile());
            const loginResult = yield InnloggingPortalAPI.postV1PortalLogin({
                username: this.username,
                password: this.password,
                eier: this.center.id,
                client: '',
                client_version: '',
            });
            if (isSuccessHTTP(loginResult)) {
                if (!loginResult.whoami) {
                    return;
                }
                this.dispatch(BoostPortalStore.Actions.SetSession(loginResult.whoami.sessid));
                window.history.pushState(null, document.title, router.baseUrl + this.centerShort + '/profile');
                window.dispatchEvent(new PopStateEvent('popstate'));
            }
            else if (isErrorHTTP(loginResult)) {
                SnackBar.pushError(loginResult.error);
            }
        });
    }
    /** @override */ updated(changedProperties) {
        changedProperties.forEach((_oldValue, propertyName) => {
            switch (propertyName) {
                case 'session':
                    this._sessionObserver();
                    break;
            }
        });
        super.updated(changedProperties);
    }
    _sessionObserver() {
        this.sessionHandler.setLoginSession(this.session);
    }
};
BoostPortalLoginView._deps = [BoostButton, BoostPortalHeader, TextField, PasswordField];
__decorate([
    state(),
    __metadata("design:type", String)
], BoostPortalLoginView.prototype, "username", void 0);
__decorate([
    state(),
    __metadata("design:type", String)
], BoostPortalLoginView.prototype, "password", void 0);
__decorate([
    reduxBind(BoostPortalStore.path('center')),
    state(),
    __metadata("design:type", Object)
], BoostPortalLoginView.prototype, "center", void 0);
__decorate([
    reduxBind(BoostPortalStore.path('session')),
    state(),
    __metadata("design:type", String)
], BoostPortalLoginView.prototype, "session", void 0);
__decorate([
    reduxBind(BoostPortalStore.path('centerShort')),
    state(),
    __metadata("design:type", String)
], BoostPortalLoginView.prototype, "centerShort", void 0);
BoostPortalLoginView = __decorate([
    customElement(BoostPortalLoginView.is)
], BoostPortalLoginView);
export { BoostPortalLoginView };

var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { reduxBind } from 'badphraim/ui/LitReduxMixinBase';
import { BoostPortalSessionHandler } from '../helpers/BoostPortalSessionHandler';
import { css, html, LitElement } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { BoostPortalStore } from '../redux/BoostPortalStore';
import { LitReduxBoostPortalMixin } from '../redux/LitReduxBoostPortalMixin';
import { router } from '../router/BoostPortalRouter';
import { BoostButton } from './common/BoostButton';
import { isSuccessHTTP } from 'boost-api/APIRestClient';
let BoostPortalHeader = class BoostPortalHeader extends LitReduxBoostPortalMixin(LitElement) {
    constructor() {
        super(...arguments);
        this.viewLoginButton = true;
        this.viewProfileButtons = true;
        this.disabled = false;
        this.sessionHandler = new BoostPortalSessionHandler();
    }
    static get is() {
        return 'boost-portal-header';
    }
    get routerOutlet() {
        return this.shadowRoot && this.shadowRoot.getElementById('routerOutlet');
    }
    static get styles() {
        return css `
            .content-header h1 {
                font-weight: 300;
                font-size: 1.5em;
            }

            .content-header {
                margin: 40px;
                display: flex;
                align-items: center;
                z-index: 2;
                justify-content: space-between;
            }

            .content-header a {
                color: var(--portalForeground);
                text-decoration: none;
            }

            #identity-wrapper {
                align-items: center;
                display: flex;
                height: 65px;
            }

            .logo {
                max-height: 65px;
                max-width: 150px;
                background-color: var(--portalSidebarContentBackground);
            }

            .logo-text {
                font-size: 18px;
                text-align: center;
                margin-left: 6px;
                color: #404040;
            }

            .logo-text:hover {
                color: #666666;
            }

            .a {
                fill: none;
                stroke: currentColor;
                stroke-linecap: round;
                stroke-width: 2px;
            }

            @media screen and (max-width: 1200px) {
                .content-header {
                    max-width: 100%;
                    margin: 20px;
                    gap: 0px;
                    flex-wrap: nowrap;
                }

                .content-header h1 {
                    margin-left: 10px;
                }

                .logo {
                    max-width: 200px;
                }

                .content-header h1 {
                    font-size: 150%;
                }

                #identity-wrapper {
                    width: 100%;
                }

                boost-button {
                    font-size: 14px;
                    width: 38%;
                }
            }
        `;
    }
    /** @override */ render() {
        return html ` <div class="content-header">
            <a
                id="identity-wrapper"
                @click="${() => !this.disabled
            ? window.location.replace(this.centerShort ? this.centerShort : router.baseUrl)
            : ''}"
                >${this.headerLogo ? this.headerLogo : ''}
            </a>
            ${this._getProfileButtons()}
        </div>`;
    }
    _getLogo(url) {
        return __awaiter(this, void 0, void 0, function* () {
            return new Promise((resolve, reject) => {
                const logoObj = new Image();
                logoObj.onload = () => resolve(logoObj);
                logoObj.onerror = reject;
                logoObj.classList.add('logo');
                logoObj.src = url;
            });
        });
    }
    _setLogo() {
        setTimeout(() => {
            if (!this.logo) {
                this.headerLogo = this._renderCenterName();
                return;
            }
            const { width, height } = this.logo;
            if (width < 20 || height < 20) {
                this.headerLogo = this._renderCenterName();
                return;
            }
            this.headerLogo = html `<div
                @mouseenter="${() => this._logoHover()}"
                @mouseleave="${() => this._setLogo()}"
            >
                ${this.logo}
            </div>`;
        }, 300);
    }
    _logoHover() {
        if (this.disabled) {
            return;
        }
        this.headerLogo = html `&lt; <span class="logo-text">GÅ TILBAKE</span>`;
    }
    _renderCenterName() {
        return html `<h1 @mouseenter="${() => this._logoHover()}" @mouseleave="${() => this._setLogo()}">
            ${this.center ? html ` ${this.center.title} ` : html ` Portal `}
        </h1>`;
    }
    _logIn() {
        window.history.pushState(null, document.title, router.baseUrl + this.centerShort + '/login');
        window.dispatchEvent(new PopStateEvent('popstate'));
    }
    _viewLoginButton() {
        return html ` ${this.viewLoginButton
            ? html `<boost-button
                  type="header-button"
                  name="Logg inn"
                  @click="${() => this._logIn()}"
              ></boost-button>`
            : ''}`;
    }
    _navigateToProfile() {
        window.history.pushState(null, document.title, router.baseUrl + this.centerShort + '/profile');
        window.dispatchEvent(new PopStateEvent('popstate'));
    }
    _logOut() {
        this.sessionHandler.deleteLoginSession();
        window.location.replace(window.origin + router.baseUrl + this.centerShort + '/login');
    }
    _getProfileButtons() {
        if (!this.viewProfileButtons) {
            return;
        }
        if (this.session) {
            return html `<div style="display: flex;">
                <boost-button
                    style="margin-right: 10px;"
                    type="header-button"
                    name="Min profil"
                    @click="${() => this._navigateToProfile()}"
                ></boost-button>
                <boost-button type="logout" name="Logg ut" @click="${() => this._logOut()}"></boost-button>
            </div>`;
        }
        return this._viewLoginButton();
    }
    loadLogo() {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.center || !this.serviceUrl) {
                return;
            }
            const logoResult = yield this._getLogo(this.serviceUrl +
                '/rest/document/logo/' +
                this.center.id +
                (this.selectedDepartmentId ? '/' + this.selectedDepartmentId : ''));
            if (isSuccessHTTP(logoResult)) {
                this.logo = logoResult;
            }
            this._setLogo();
        });
    }
    /** @override */ updated(changedProperties) {
        changedProperties.forEach((_oldValue, propertyName) => {
            switch (propertyName) {
                case 'serviceUrl':
                case 'center':
                case 'selectedDepartmentId':
                    this.loadLogo();
                    break;
            }
        });
        super.updated(changedProperties);
    }
};
BoostPortalHeader._deps = [BoostButton];
__decorate([
    reduxBind(BoostPortalStore.path('centerShort')),
    state(),
    __metadata("design:type", String)
], BoostPortalHeader.prototype, "centerShort", void 0);
__decorate([
    reduxBind(BoostPortalStore.path('session')),
    state(),
    __metadata("design:type", String)
], BoostPortalHeader.prototype, "session", void 0);
__decorate([
    reduxBind(BoostPortalStore.path('serviceUrl')),
    state(),
    __metadata("design:type", String)
], BoostPortalHeader.prototype, "serviceUrl", void 0);
__decorate([
    reduxBind(BoostPortalStore.path('center')),
    state(),
    __metadata("design:type", Object)
], BoostPortalHeader.prototype, "center", void 0);
__decorate([
    reduxBind(BoostPortalStore.path('selectedDepartmentId')),
    state(),
    __metadata("design:type", Number)
], BoostPortalHeader.prototype, "selectedDepartmentId", void 0);
__decorate([
    property({ type: Boolean }),
    __metadata("design:type", Boolean)
], BoostPortalHeader.prototype, "viewLoginButton", void 0);
__decorate([
    state(),
    __metadata("design:type", HTMLImageElement)
], BoostPortalHeader.prototype, "logo", void 0);
__decorate([
    state(),
    __metadata("design:type", Object)
], BoostPortalHeader.prototype, "headerLogo", void 0);
__decorate([
    state(),
    __metadata("design:type", Boolean)
], BoostPortalHeader.prototype, "viewProfileButtons", void 0);
__decorate([
    property({ type: Boolean }),
    __metadata("design:type", Boolean)
], BoostPortalHeader.prototype, "disabled", void 0);
BoostPortalHeader = __decorate([
    customElement(BoostPortalHeader.is)
], BoostPortalHeader);
export { BoostPortalHeader };

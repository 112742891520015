var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
/* tslint:disable: class-name */
/*
 * AUTOGENERATED FILE - DO NOT EDIT
 */
import { ewRestRequestHandlerProjectProcess_PhaseProgress } from './ewRestRequestHandlerProjectProcess_PhaseProgress';
import { JSONStruct, nullable, property } from 'badphraim/data/JSONStruct';
export class ewProjectProcessSummary_JSON extends JSONStruct {
    constructor() {
        super(...arguments);
        /** @override */ this.phase_id = 0;
        /** @override */ this.phase_name = '';
        /** @override */ this.phase_shortname = '';
        /** @override */ this.progress = new ewRestRequestHandlerProjectProcess_PhaseProgress();
        /** @override */ this.project_id = 0;
    }
}
__decorate([
    nullable,
    __metadata("design:type", Boolean)
], ewProjectProcessSummary_JSON.prototype, "completed", void 0);
__decorate([
    property,
    __metadata("design:type", Number)
], ewProjectProcessSummary_JSON.prototype, "phase_id", void 0);
__decorate([
    property,
    __metadata("design:type", String)
], ewProjectProcessSummary_JSON.prototype, "phase_name", void 0);
__decorate([
    property,
    __metadata("design:type", String)
], ewProjectProcessSummary_JSON.prototype, "phase_shortname", void 0);
__decorate([
    property,
    __metadata("design:type", ewRestRequestHandlerProjectProcess_PhaseProgress)
], ewProjectProcessSummary_JSON.prototype, "progress", void 0);
__decorate([
    property,
    __metadata("design:type", Number)
], ewProjectProcessSummary_JSON.prototype, "project_id", void 0);
__decorate([
    nullable,
    __metadata("design:type", Boolean)
], ewProjectProcessSummary_JSON.prototype, "started", void 0);

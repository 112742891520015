var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
/* tslint:disable: class-name */
/*
 * AUTOGENERATED FILE - DO NOT EDIT
 */
import { ewRestRequestHandlerDashboard_Portlet } from './ewRestRequestHandlerDashboard_Portlet';
import { JSONStruct, array_property, nullable, property } from 'badphraim/data/JSONStruct';
export class ewRestRequestHandlerDashboard_DashboardWithPortlets extends JSONStruct {
    constructor() {
        super(...arguments);
        /** @override */ this.active = false;
        /** @override */ this.context = '';
        /** @override */ this.layout_id = 0;
        /** @override */ this.number_of_portlets = 0;
        /** @override */ this.portlets = [];
        /** @override */ this.read_only = false;
        /** @override */ this.shared = false;
    }
}
__decorate([
    property,
    __metadata("design:type", Boolean)
], ewRestRequestHandlerDashboard_DashboardWithPortlets.prototype, "active", void 0);
__decorate([
    property,
    __metadata("design:type", String)
], ewRestRequestHandlerDashboard_DashboardWithPortlets.prototype, "context", void 0);
__decorate([
    property,
    __metadata("design:type", Number)
], ewRestRequestHandlerDashboard_DashboardWithPortlets.prototype, "layout_id", void 0);
__decorate([
    nullable,
    __metadata("design:type", Number)
], ewRestRequestHandlerDashboard_DashboardWithPortlets.prototype, "login_id", void 0);
__decorate([
    nullable,
    __metadata("design:type", String)
], ewRestRequestHandlerDashboard_DashboardWithPortlets.prototype, "login_name", void 0);
__decorate([
    property,
    __metadata("design:type", Number)
], ewRestRequestHandlerDashboard_DashboardWithPortlets.prototype, "number_of_portlets", void 0);
__decorate([
    array_property(ewRestRequestHandlerDashboard_Portlet),
    __metadata("design:type", Array)
], ewRestRequestHandlerDashboard_DashboardWithPortlets.prototype, "portlets", void 0);
__decorate([
    property,
    __metadata("design:type", Boolean)
], ewRestRequestHandlerDashboard_DashboardWithPortlets.prototype, "read_only", void 0);
__decorate([
    property,
    __metadata("design:type", Boolean)
], ewRestRequestHandlerDashboard_DashboardWithPortlets.prototype, "shared", void 0);
__decorate([
    nullable,
    __metadata("design:type", String)
], ewRestRequestHandlerDashboard_DashboardWithPortlets.prototype, "title", void 0);

var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { css, html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';
let BoostStatusContainer = class BoostStatusContainer extends LitElement {
    static get is() {
        return 'boost-status-container';
    }
    static get styles() {
        return css `
            .container {
                padding: 10px;
                margin: 10px 0;
                border-radius: 6px;
            }

            .primary {
                background-color: var(--portalButtonPrimaryBackground, hsl(211.2, 13%, 90%));
                border: 1px solid var(--portalButtonPrimaryForeground, hsl(211.2, 13%, 20%));
                color: var(--portalButtonPrimaryForeground, hsl(211.2, 13%, 20%));
            }

            .success {
                background-color: var(--portalButtonSuccessBackground, hsl(159, 60%, 90%));
                border: 1px solid var(--portalButtonSuccessForeground, hsl(159, 75%, 16%));
                color: var(--portalButtonSuccessForeground, hsl(159, 75%, 16%));
            }

            .info {
                background-color: var(--portalButtonInfoBackground, hsl(210, 60%, 90%));
                color: var(--portalButtonInfoForeground, hsl(210, 75%, 16%));
                border: 1px solid #adbdcc;
            }

            .warning {
                background-color: var(--portalButtonWarningBackground, hsl(28, 80%, 93%));
                border: 1px solid var(--portalButtonWarningForeground, hsl(28, 74%, 24%));
                color: var(--portalButtonWarningForeground, hsl(28, 74%, 24%));
            }

            .danger {
                background-color: var(--portalButtonDangerBackground, hsl(3, 100%, 96%));
                border: 1px solid var(--portalButtonDangerForeground, hsl(360, 76%, 20%));
                color: var(--portalButtonDangerForeground, hsl(360, 76%, 20%));
            }
        `;
    }
    /** @override */ render() {
        return html `
            <div class="container ${this.type}">
                <slot></slot>
            </div>
        `;
    }
};
BoostStatusContainer._deps = [];
__decorate([
    property({ type: String }),
    __metadata("design:type", String)
], BoostStatusContainer.prototype, "type", void 0);
BoostStatusContainer = __decorate([
    customElement(BoostStatusContainer.is)
], BoostStatusContainer);
export { BoostStatusContainer };

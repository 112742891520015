/* tslint:disable: class-name */
/*
 * AUTOGENERATED FILE - DO NOT EDIT
 */
import { APIRestClient } from '../../../APIRestClient';
import { AgreementConversation } from '../../../schemas/AgreementConversation';
import { Attachment } from '../../../schemas/Attachment';
import { Conversation } from '../../../schemas/Conversation';
import { Message } from '../../../schemas/Message';
export class ChatAPI extends APIRestClient {
    /**
     * Henter en attachment
     * Implementert hovedsakelig i *ewRestResourceV1Attachments*::*read*
     * bpRestRequestStreamResponse
     */
    static getV1AttachmentsUsingAttachmentid(attachmentID, __QUERY) {
        return this._get('/v1/attachments/{attachmentID}', { attachmentID }, __QUERY, null, APIRestClient.Native.Blob(), ['https://api.cerum.no/cerumx/login'], 'blob');
    }
    /**
     * Hent alle samtaler for alle avtaler
     * Implementert hovedsakelig i *ewRestResourceV1AgreementConversations*::*read*
     */
    static getV1ConversationsAgreement_conversations() {
        return this._get('/v1/conversations/agreement_conversations', {}, null, null, AgreementConversation.classOf(AgreementConversation, new AgreementConversation()).arrayOf(), ['https://api.cerum.no/cerumx/login'], 'json');
    }
    /**
     * Hent alle meldinger
     * Implementert hovedsakelig i *ewRestResourceV1ConversationMessages*::*read*
     */
    static getV1ConversationsMessagesUsingConversationid(conversationID, __QUERY) {
        return this._get('/v1/conversations/{conversationID}/messages', { conversationID }, __QUERY, null, Message.classOf(Message, new Message()).arrayOf(), ['https://api.cerum.no/cerumx/login'], 'json');
    }
    /**
     * Hent en melding
     * Implementert hovedsakelig i *ewRestResourceV1Conversation*::*read*
     */
    static getV1ConversationsUsingConversationid(conversationID) {
        return this._get('/v1/conversations/{conversationID}', { conversationID }, null, null, Conversation.classOf(Conversation, new Conversation()), ['https://api.cerum.no/cerumx/login'], 'json');
    }
    /**
     * Hent antall uleste meldinger
     * Implementert hovedsakelig i *ewRestResourceV1MessagesUnread*::*getUnreadMessageCount*
     */
    static getV1MessagesUnread(__QUERY) {
        return this._get('/v1/messages/unread', {}, __QUERY, null, APIRestClient.Native.number(), ['https://api.cerum.no/cerumx/login'], 'json');
    }
    /**
     * Hent meldinger for en node
     * Implementert hovedsakelig i *ewRestResourceV1NodesConversations*::*read*
     */
    static getV1NodesConversationsUsingNodeId(node_id, __QUERY) {
        return this._get('/v1/nodes/{node_id}/conversations', { node_id }, __QUERY, null, Conversation.classOf(Conversation, new Conversation()).arrayOf(), ['https://api.cerum.no/cerumx/login'], 'json');
    }
    /**
     * Lag ny samtale
     * Implementert hovedsakelig i *ewRestResourceV1Conversations*::*create*
     */
    static postV1Conversations(__QUERY, __DATA) {
        return this._post('/v1/conversations', {}, __QUERY, JSON.stringify(__DATA), Conversation.classOf(Conversation, new Conversation()), ['https://api.cerum.no/cerumx/login'], 'json');
    }
    /**
     * Lag ny melding
     * Implementert hovedsakelig i *ewRestResourceV1ConversationMessages*::*create*
     */
    static postV1ConversationsMessagesUsingConversationid(conversationID, __DATA) {
        return this._post('/v1/conversations/{conversationID}/messages', { conversationID }, null, JSON.stringify(__DATA), Message.classOf(Message, new Message()), ['https://api.cerum.no/cerumx/login'], 'json');
    }
    /**
     * Legger til attachment
     * Implementert hovedsakelig i *ewRestResourceV1MessageAttachments*::*create*
     * bpRestRequestJsonResponse
     */
    static postV1MessagesAttachmentsUsingMessageid(messageID, __DATA) {
        return this._post('/v1/messages/{messageID}/attachments', { messageID }, null, this._formData(__DATA), Attachment.classOf(Attachment, new Attachment()), ['https://api.cerum.no/cerumx/login'], 'json');
    }
    /**
     * Oppdatere meldingsstatus
     * Implementert hovedsakelig i *ewRestResourceV1ConversationMessages*::*update*
     */
    static putV1ConversationsMessagesStatusUsingConversationid(conversationID, __QUERY, __DATA) {
        return this._put('/v1/conversations/{conversationID}/messages/status', { conversationID }, __QUERY, JSON.stringify(__DATA), APIRestClient.Native.boolean(), ['https://api.cerum.no/cerumx/login'], 'json');
    }
    /**
     * Oppdater samtale
     * Implementert hovedsakelig i *ewRestResourceV1Conversation*::*update*
     */
    static putV1ConversationsUsingConversationid(conversationID, __DATA) {
        return this._put('/v1/conversations/{conversationID}', { conversationID }, null, JSON.stringify(__DATA), Conversation.classOf(Conversation, new Conversation()), ['https://api.cerum.no/cerumx/login'], 'json');
    }
}

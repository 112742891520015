/**
 * Writes to console.log
 */
export class LoggingTracking {
    initialize() {
        // void
    }
    disable() {
        // void
    }
    completedEvent(...args) {
        const [event, parameters] = args;
        console.log('LoggingTracking.completedEvent:', [event, parameters]);
    }
}

import { CerumXRouterConfig } from './CerumXRouterConfig';
export class CerumXRouterHelper {
    static makeUrlRedirectSafe(location) {
        let redir = location.href;
        if (!CerumXRouterConfig.isEnabled()) {
            return redir;
        }
        const page = this.getPageFromUrl(location.pathname, location.search, location.hash);
        redir = redir.replace(/#.*/, '');
        redir = redir.replace(/\?.*/, '');
        if (page) {
            redir += '?p=' + page;
        }
        return redir;
    }
    static getPageFromUrl(path, query, hash) {
        if (!CerumXRouterConfig.isEnabled()) {
            return;
        }
        //console.log('Fikk hash', hash);
        if (hash) {
            const hm = hash.match(/^#!\/(.*)/);
            //console.log('Hash match', hm);
            if (hm) {
                //console.log('Fount page: ', hm[1]);
                return hm[1];
            }
            else {
                //console.log('Miss 2');
            }
        }
        if (query) {
            //console.log('fikk query', query);
            const qm = query.match(/[?&]p=([^&]+)([&]|$)/);
            //console.log('Fikk match', qm);
            if (qm && qm[1]) {
                return qm[1];
            }
        }
        const pm = path.match(/^\/x\/-\/(.*)/);
        if (pm && pm[1]) {
            return pm[1];
        }
        //console.log('miss 1');
    }
    static getUrlForPage(page) {
        if (!CerumXRouterConfig.isEnabled()) {
            return;
        }
        if (!page) {
            page = '';
        }
        const { location } = document;
        if (location.pathname.match(/^\/x\//)) {
            return '/x/-/' + page;
        }
        let url = location.pathname + location.search;
        if (page) {
            url += '#!/' + page;
        }
        return url;
    }
}

var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { css, html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { CerumIcon } from 'common-uicomponents/components/CerumIcon';
let CerumEmpty = class CerumEmpty extends LitElement {
    constructor() {
        super(...arguments);
        this.type = '';
        /** @override */ this.title = '';
        this.subtitle = '';
    }
    static get is() {
        return 'cerum-empty';
    }
    static get styles() {
        return css `
            :host {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding: 20px;
            }

            .icon-container {
                background-color: hsl(208, 13%, 98%);
                border-radius: 50%;
                padding: var(--icon-container-padding, 60px);
            }

            cerum-icon {
                --cerum-icon-height: 144px;
                --cerum-icon-width: 144px;
                margin: 0 10px;
                stroke: hsl(208, 13%, 81%);
            }

            :host([small]) .icon-container {
                padding: 30px;
            }

            :host([small]) cerum-icon {
                --cerum-icon-height: 78px;
                --cerum-icon-width: 78px;
            }

            div,
            h4 {
                color: hsl(208, 14%, 20%);
            }

            h4 {
                font-weight: 500;
            }

            div.progress-container {
                width: 50%;
            }

            paper-progress {
                width: 100%;
            }
        `;
    }
    /** @override */ render() {
        return html `
            ${this.type
            ? html `<div class="icon-container">
                          <cerum-icon icon="cerum:${this.type}"></cerum-icon>
                      </div>

                      <div>
                          <h4>${this.title}</h4>
                      </div>
                      ${this.subtitle ? html `<div>${this.subtitle}</div>` : html ``}
                      <slot name="actions"></slot>`
            : html ``}
        `;
    }
};
CerumEmpty._deps = [CerumIcon];
__decorate([
    property({ type: String }),
    __metadata("design:type", String)
], CerumEmpty.prototype, "type", void 0);
__decorate([
    property({ type: String }),
    __metadata("design:type", String)
], CerumEmpty.prototype, "title", void 0);
__decorate([
    property({ type: String }),
    __metadata("design:type", String)
], CerumEmpty.prototype, "subtitle", void 0);
CerumEmpty = __decorate([
    customElement(CerumEmpty.is)
], CerumEmpty);
export { CerumEmpty };

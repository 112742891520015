var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { ObjectHelper } from 'badphraim/core/ObjectHelper';
export class TokenStoreBase {
    //    client?: ClientOAuth2;
    constructor(clientProvider) {
        this.clientProvider = clientProvider;
        this.storeName = 'TokenStoreBase::';
    }
    getAccessToken() {
        return __awaiter(this, void 0, void 0, function* () {
            const token = yield this.loadAccessToken();
            if (!token) {
                //console.log('TokenStoreBase.getAccessToken(): har inget token fra loadAccessToken');
                return null;
            }
            if (!token.expired()) {
                return token;
            }
            //console.log('TokenStoreBase.getAccessToken(): token er utløpt');
            return null;
        });
    }
    getAccessTokenForScope(_scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.getAccessToken();
        });
    }
    getAccessTokenFor(_url) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.getAccessToken();
        });
    }
    getRefreshToken() {
        return __awaiter(this, void 0, void 0, function* () {
            const storedToken = this.getStorageItem(this.storeName);
            if (storedToken && 'refresh_token' in storedToken && storedToken.refresh_token) {
                return this.unstripToken(storedToken.refresh_token);
            }
            return null;
            // return this._access_token;
        });
    }
    loadAccessToken() {
        return __awaiter(this, void 0, void 0, function* () {
            const storedToken = this.getStorageItem(this.storeName);
            if (storedToken && 'access_token' in storedToken && storedToken.access_token) {
                return this.unstripToken(storedToken.access_token);
            }
            return null; // this._access_token;
        });
    }
    setAccessToken(token) {
        let storedToken = this.getStorageItem(this.storeName);
        if (!storedToken) {
            storedToken = {};
        }
        storedToken.access_token = this.stripToken(token);
        this.setStorageItem(this.storeName, storedToken);
        //this._access_token = token;
    }
    setRefreshToken(token) {
        let storedToken = this.getStorageItem(this.storeName);
        if (!storedToken) {
            storedToken = {};
        }
        storedToken.refresh_token = token;
        this.setStorageItem(this.storeName, storedToken);
        // this._access_token = token;
    }
    stripToken(token) {
        if (!token) {
            return null;
        }
        //var data = this.cloneStruct(token.data);
        const data = ObjectHelper.cloneStruct(token.data);
        if ('expires_in' in data) {
            data.expires = Math.floor(new Date().getTime() / 1000) + data.expires_in;
            delete data.expires_in;
        }
        return {
            data,
            accessToken: token.accessToken,
            refreshToken: token.refreshToken,
            tokenType: token.tokenType,
        };
    }
    unstripToken(token) {
        return __awaiter(this, void 0, void 0, function* () {
            //        createToken(accessToken: string, refreshToken: string, type: string, data: ClientOAuth2.Data): ClientOAuth2.Token
            if ('expires' in token.data) {
                token.data.expires_in = String(Number(token.data.expires) - Math.floor(new Date().getTime() / 1000)); // +data.expires_in;
                delete token.data.expires;
            }
            return (yield this.clientProvider()).createToken(token.accessToken, token.refreshToken, token.tokenType, token.data);
        });
    }
    getStorageItem(key) {
        const item = this.storage.getItem(this.prefix + key);
        if (item) {
            return JSON.parse(item);
        }
        return null;
    }
    setStorageItem(key, item) {
        if (item === null) {
            this.storage.removeItem(this.prefix + key);
        }
        else {
            this.storage.setItem(this.prefix + key, JSON.stringify(item));
        }
    }
}

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
export function attachAllBindings(comp, postUpdateCallback) {
    return __awaiter(this, void 0, void 0, function* () {
        const store = yield comp.getStore();
        const map = new Map();
        const path_map = comp.constructor.__redux_state_paths;
        for (const prop of path_map.keys()) {
            const path = path_map.get(prop);
            if (path) {
                map.set(prop, (state) => {
                    const value = getStateValueByPath(state, path);
                    const c = comp;
                    if (value !== c[prop]) {
                        // console.log(path, ['ble satt:', value, 'fra', c[prop]]);
                        c[prop] = value;
                    }
                    else {
                        //console.log(path, 'ikke endret:', value);
                    }
                });
            }
            else {
                console.error('Ingen path å binde til på property ' + prop);
            }
        }
        const updateValuesFromState = () => {
            const state = store.getState();
            map.forEach((e) => e(state));
            if (postUpdateCallback) {
                postUpdateCallback();
            }
        };
        // Initialize to current values
        updateValuesFromState();
        // Subscribe for future changes
        return {
            unsubcb: store.subscribe(updateValuesFromState),
            map,
        };
    });
}
export function releaseAllBindings(_comp, bind_data_promise) {
    return __awaiter(this, void 0, void 0, function* () {
        //console.log('releaseAllBindings');
        const bind_data = yield bind_data_promise;
        bind_data.unsubcb();
        for (const _key in bind_data.map.keys()) {
            // void
        }
    });
}
export function getStateValueByPath(state, path) {
    const properties = Array.isArray(path) ? path : path.split('.');
    return properties.reduce((prev, curr) => {
        if (prev && curr in prev) {
            return prev[curr];
        }
        return undefined;
    }, state);
}
function legacyReduxBind(path, proto, name) {
    // void
    const cls = proto.constructor;
    if (typeof name === 'number') {
        name = name.toString();
    }
    if (typeof name === 'string') {
        commonReduxBind(path, cls, name);
        return;
    }
    throw new Error('Bad arguments');
}
function commonReduxBind(path, cls, name) {
    if ('__redux_state_paths' in cls && cls.__redux_state_paths instanceof Map && typeof name === 'string') {
        const m = cls.__redux_state_paths;
        m.set(name, path);
    }
    else {
        console.error('Failed to bind property ' + name + ' to redux-path ' + path + ' in ', cls);
    }
}
function standardReduxBind(path, cls) {
    // void
    console.error('standardReduxBind is not implemented', [path, cls]);
}
export function reduxBind(path) {
    //console.log('@reduxBind(' + path + '): I ytre funksjon', arguments);
    return (protoOrDescriptor, name) => name !== undefined
        ? legacyReduxBind(path, protoOrDescriptor, name)
        : standardReduxBind(path, protoOrDescriptor);
}

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Datatype } from 'cerum-data/Datatype';
import { DataEventsDispatcher } from 'cerum-data/DataEvents';
import { CerumReduxSubStore } from './CerumReduxSubStore';
import { VisitlogAPI } from 'cerumx-api/cerum/desktop/visitlog/VisitlogAPI';
import { isErrorHTTP } from 'cerumx-api/APIRestClient';
export class CerumLastVisited {
    constructor(datatype, nodeId, dataid, name) {
        this.dataid = 0;
        this.datatype = 0;
        this.name = '';
        this.node_id = 0;
        this.time = 0;
        this.datatype = datatype;
        this.node_id = nodeId;
        this.dataid = dataid;
        this.name = name;
        this.time = new Date().getTime();
    }
}
export class CerumLastVisitedDatatypeLayout {
    constructor() {
        this.count = 0;
        this.entries = {};
    }
}
export class CerumLastVisitedStoreLayout {
    constructor() {
        this.serial = 0;
        this.visits = {};
    }
}
export class CerumLastVisitedStore extends CerumReduxSubStore {
    constructor() {
        super(...arguments);
        /** @override */ this.base_key = 'CerumLastVisitedStore';
        /*    constructor() {
                super();
        //        cerum.dataevents.registerEventListener(this.onVisitEvent.bind(this), Datatype.PROJECT, "visited");
         //       cerum.registerEventListener("main.search.query", this.onSearchEvents.bind(this));
            }
        */
        this._listeners = {};
    }
    static get instance() {
        return this._instance || (this._instance = new CerumLastVisitedStore());
    }
    static path(property) {
        return this.staticPath('CerumLastVisitedStore', property);
    }
    onVisitEvent(datatypeId, _evntname, _payload) {
        if (this._timeout) {
            window.clearTimeout(this._timeout);
        }
        this._timeout = window.setTimeout(() => {
            const action = CerumLastVisitedStore.Actions.loadVisits(datatypeId); //  ;.DoSearch({query, context, context_relation_id});
            this.dispatch(action);
        }, 1 * 1000);
    }
    /** @override */ reducer() {
        return {
            [this.base_key]: (state = new CerumLastVisitedStoreLayout(), action) => {
                switch (action.type) {
                    //  case CerumLastVisitedStore.NEW_VISIT:
                    //         const nw_datatype = action.payload.datatype;
                    //         const nwnew_visits = (nw_datatype in state.visits) ? state.visits[nw_datatype] : new CerumLastVisitedDatatypeLayout();
                    //         nwnew_visits.entries = {};
                    //         nwnew_visits.entries[action.payload.visit.node_id] = action.payload.visit;
                    //         state = {
                    //             ...state,
                    //             visits: {
                    //                 [action.payload.datatype]: nwnew_visits,
                    //                 ...state.visits,
                    //             },
                    //             serial: state.serial + 1,
                    //         };
                    //         break;
                    case CerumLastVisitedStore.LOAD_VISITS_COMPLETED:
                        const loadVisitsCompletedDatatype = Number(action.payload.datatype);
                        this.seenDatatype(loadVisitsCompletedDatatype);
                        const loadVisitsCompletedNewVisits = loadVisitsCompletedDatatype in state.visits
                            ? state.visits[loadVisitsCompletedDatatype]
                            : new CerumLastVisitedDatatypeLayout();
                        loadVisitsCompletedNewVisits.entries = {};
                        for (const visit of action.payload.visits) {
                            loadVisitsCompletedNewVisits.entries[visit.node_id] = visit;
                            loadVisitsCompletedNewVisits.count++;
                        }
                        state = Object.assign(Object.assign({}, state), { visits: Object.assign(Object.assign({}, state.visits), { [action.payload.datatype]: loadVisitsCompletedNewVisits }), serial: state.serial + 1 });
                        break;
                }
                return state;
            },
        };
    }
    // Denne datastoren har
    seenDatatype(datatypeId) {
        if (datatypeId in this._listeners) {
            return;
        }
        this._listeners[datatypeId] = DataEventsDispatcher.instance().registerEventListener(this.onVisitEvent.bind(this), datatypeId, 'visited');
    }
}
(function (CerumLastVisitedStore) {
    // export const NEW_VISIT = 'NEW_VISIT';
    CerumLastVisitedStore.LOAD_VISITS_COMPLETED = 'LOAD_VISITS_COMPLETED';
    let Actions;
    (function (Actions) {
        Actions.loadVisits = (datatype, _count) => {
            return (dispatch) => __awaiter(this, void 0, void 0, function* () {
                const response = yield VisitlogAPI.getVisitlogUsingTypeCount(Datatype.getDatatypeName(datatype), 10);
                if (isErrorHTTP(response)) {
                    console.error('ERROR', response);
                    throw response;
                }
                const visits = Array();
                for (const item of response) {
                    const cvisit = new CerumLastVisited(datatype, item.node_id, item.dataid, item.name);
                    visits.push(cvisit);
                }
                dispatch(Actions.loadVisitsCompleted(datatype, visits));
            });
        };
        Actions.loadVisitsCompleted = (datatype, visits) => ({
            type: CerumLastVisitedStore.LOAD_VISITS_COMPLETED,
            payload: { datatype, visits },
        });
        Actions.newVisit = (type, idType, visitedId) => {
            return (dispatch) => __awaiter(this, void 0, void 0, function* () {
                yield VisitlogAPI.postVisitlogUsingTypeIdtypeId(type, idType, visitedId);
                let datatypeId = undefined;
                switch (type) {
                    case 'person':
                        datatypeId = Datatype.PERSON;
                        break;
                    case 'resource':
                        datatypeId = Datatype.RESOURCE;
                        break;
                    case 'contact':
                        datatypeId = Datatype.CONTACT;
                        break;
                    case 'project':
                        datatypeId = Datatype.PROJECT;
                        break;
                    default:
                        return;
                }
                dispatch(Actions.loadVisits(datatypeId));
            });
        };
    })(Actions = CerumLastVisitedStore.Actions || (CerumLastVisitedStore.Actions = {}));
})(CerumLastVisitedStore || (CerumLastVisitedStore = {}));

var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { JSONStruct, nullable, property } from 'badphraim/data/JSONStruct';
export class ewJSONExerciseClass extends JSONStruct {
    constructor() {
        super(...arguments);
        /** @override */ this.eier = 0;
        /** @override */ this.id = 0;
    }
}
__decorate([
    nullable,
    __metadata("design:type", Number)
], ewJSONExerciseClass.prototype, "avdeling", void 0);
__decorate([
    nullable,
    __metadata("design:type", String)
], ewJSONExerciseClass.prototype, "avdelingnavn", void 0);
__decorate([
    nullable,
    __metadata("design:type", Number)
], ewJSONExerciseClass.prototype, "book_inntil", void 0);
__decorate([
    nullable,
    __metadata("design:type", String)
], ewJSONExerciseClass.prototype, "booked", void 0);
__decorate([
    nullable,
    __metadata("design:type", String)
], ewJSONExerciseClass.prototype, "booked_text", void 0);
__decorate([
    nullable,
    __metadata("design:type", Number)
], ewJSONExerciseClass.prototype, "booking", void 0);
__decorate([
    nullable,
    __metadata("design:type", Number)
], ewJSONExerciseClass.prototype, "booking_starts_at", void 0);
__decorate([
    nullable,
    __metadata("design:type", String)
], ewJSONExerciseClass.prototype, "color", void 0);
__decorate([
    nullable,
    __metadata("design:type", Number)
], ewJSONExerciseClass.prototype, "cost", void 0);
__decorate([
    nullable,
    __metadata("design:type", Number)
], ewJSONExerciseClass.prototype, "dagerfor", void 0);
__decorate([
    nullable,
    __metadata("design:type", Number)
], ewJSONExerciseClass.prototype, "dato", void 0);
__decorate([
    property,
    __metadata("design:type", Number)
], ewJSONExerciseClass.prototype, "eier", void 0);
__decorate([
    nullable,
    __metadata("design:type", String)
], ewJSONExerciseClass.prototype, "error", void 0);
__decorate([
    nullable,
    __metadata("design:type", Number)
], ewJSONExerciseClass.prototype, "free", void 0);
__decorate([
    nullable,
    __metadata("design:type", Number)
], ewJSONExerciseClass.prototype, "gren", void 0);
__decorate([
    nullable,
    __metadata("design:type", String)
], ewJSONExerciseClass.prototype, "grennavn", void 0);
__decorate([
    nullable,
    __metadata("design:type", Number)
], ewJSONExerciseClass.prototype, "gyldig_fra", void 0);
__decorate([
    nullable,
    __metadata("design:type", Number)
], ewJSONExerciseClass.prototype, "gyldig_til", void 0);
__decorate([
    property,
    __metadata("design:type", Number)
], ewJSONExerciseClass.prototype, "id", void 0);
__decorate([
    nullable,
    __metadata("design:type", String)
], ewJSONExerciseClass.prototype, "instructor", void 0);
__decorate([
    nullable,
    __metadata("design:type", Boolean)
], ewJSONExerciseClass.prototype, "is_attending", void 0);
__decorate([
    nullable,
    __metadata("design:type", Number)
], ewJSONExerciseClass.prototype, "klipp", void 0);
__decorate([
    nullable,
    __metadata("design:type", String)
], ewJSONExerciseClass.prototype, "lang_beskrivelse", void 0);
__decorate([
    nullable,
    __metadata("design:type", String)
], ewJSONExerciseClass.prototype, "min", void 0);
__decorate([
    nullable,
    __metadata("design:type", Number)
], ewJSONExerciseClass.prototype, "res_slettes", void 0);
__decorate([
    nullable,
    __metadata("design:type", Number)
], ewJSONExerciseClass.prototype, "sal", void 0);
__decorate([
    nullable,
    __metadata("design:type", String)
], ewJSONExerciseClass.prototype, "salnavn", void 0);
__decorate([
    nullable,
    __metadata("design:type", Number)
], ewJSONExerciseClass.prototype, "slett_vente_min", void 0);
__decorate([
    nullable,
    __metadata("design:type", String)
], ewJSONExerciseClass.prototype, "source", void 0);
__decorate([
    nullable,
    __metadata("design:type", String)
], ewJSONExerciseClass.prototype, "tema_time", void 0);
__decorate([
    nullable,
    __metadata("design:type", String)
], ewJSONExerciseClass.prototype, "tidspunkt", void 0);
__decorate([
    nullable,
    __metadata("design:type", String)
], ewJSONExerciseClass.prototype, "tim", void 0);
__decorate([
    nullable,
    __metadata("design:type", String)
], ewJSONExerciseClass.prototype, "tittel", void 0);
__decorate([
    nullable,
    __metadata("design:type", Number)
], ewJSONExerciseClass.prototype, "varighet", void 0);
__decorate([
    nullable,
    __metadata("design:type", Number)
], ewJSONExerciseClass.prototype, "waitingspot", void 0);

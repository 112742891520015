var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { CerumLoading } from '../CerumLoading';
import { css, html, LitElement } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { isErrorHTTP } from 'base-api/APIBase';
let DocumentViewer = class DocumentViewer extends LitElement {
    constructor() {
        super(...arguments);
        this.source = '';
    }
    static get is() {
        return 'document-viewer';
    }
    static get styles() {
        return css `
            #container {
                height: var(--document-viewer-height, calc(100vh - 200px));
            }

            iframe {
                width: 100%;
                height: 100%;
                border: 1px solid #00000082;
                border-radius: 4px;
            }

            #text-container {
                display: none;
            }

            @media screen and (max-width: 900px) {
                #container {
                    display: none;
                }

                p {
                    font-size: 16px;
                }

                a {
                    font-size: 16px;
                }

                #text-container {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    margin: 0 auto;
                }
            }
        `;
    }
    /** @override */ render() {
        return html `
            <div id="text-container">
                <p>PDF-visning støttes ikke av nettleser, trykk på linken under for å åpne kjøpsvilkår.</p>
                <a href="${this.source}" download="foo">Klikk her for å åpne PDF</a>
            </div>
            <div id="container">
                ${this.documentUrl
            ? html `<iframe
                          src="${this.source}"
                          @load="${(event) => URL.revokeObjectURL(event.target.src)}"
                          @error="${(event) => URL.revokeObjectURL(event.target.src)}"
                      ></iframe>`
            : html `<cerum-loading title="Laster inn dokument..."></cerum-loading>`}
            </div>
        `;
    }
    _resolveDocumentUrl() {
        return __awaiter(this, void 0, void 0, function* () {
            var _a;
            if (this.documentUrl instanceof Promise) {
                const sourceResult = yield this.documentUrl;
                if (isErrorHTTP(sourceResult)) {
                    throw new Error('Could not fetch file.');
                }
                this.source = sourceResult;
                return;
            }
            this.source = (_a = this.documentUrl) !== null && _a !== void 0 ? _a : '';
        });
    }
    /** @override */ updated(changedProperties) {
        changedProperties.forEach((_oldValue, propertyName) => {
            switch (propertyName) {
                case 'documentUrl':
                    this._documentUrlObserver();
                    break;
            }
        });
        super.updated(changedProperties);
    }
    _documentUrlObserver() {
        this._resolveDocumentUrl();
    }
};
DocumentViewer._deps = [CerumLoading];
__decorate([
    property({ type: String }),
    __metadata("design:type", Object)
], DocumentViewer.prototype, "documentUrl", void 0);
__decorate([
    state(),
    __metadata("design:type", Object)
], DocumentViewer.prototype, "source", void 0);
DocumentViewer = __decorate([
    customElement(DocumentViewer.is)
], DocumentViewer);
export { DocumentViewer };

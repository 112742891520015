var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { JSONStruct, nullable, nullable_array_property, property } from 'badphraim/data/JSONStruct';
export class exCheckin extends JSONStruct {
    constructor() {
        super(...arguments);
        /** @override */ this.id = 0;
    }
}
__decorate([
    nullable,
    __metadata("design:type", Number)
], exCheckin.prototype, "birthday", void 0);
__decorate([
    nullable,
    __metadata("design:type", String)
], exCheckin.prototype, "cardStatus", void 0);
__decorate([
    nullable,
    __metadata("design:type", String)
], exCheckin.prototype, "card_no", void 0);
__decorate([
    nullable,
    __metadata("design:type", Boolean)
], exCheckin.prototype, "checkedOut", void 0);
__decorate([
    nullable,
    __metadata("design:type", Number)
], exCheckin.prototype, "checkinLoginId", void 0);
__decorate([
    nullable,
    __metadata("design:type", String)
], exCheckin.prototype, "checkinName", void 0);
__decorate([
    nullable,
    __metadata("design:type", Number)
], exCheckin.prototype, "checkinPersonId", void 0);
__decorate([
    nullable,
    __metadata("design:type", Number)
], exCheckin.prototype, "checkinTimestamp", void 0);
__decorate([
    nullable,
    __metadata("design:type", Number)
], exCheckin.prototype, "checkoutLoginId", void 0);
__decorate([
    nullable,
    __metadata("design:type", String)
], exCheckin.prototype, "checkoutName", void 0);
__decorate([
    nullable,
    __metadata("design:type", Number)
], exCheckin.prototype, "checkoutPersonId", void 0);
__decorate([
    nullable,
    __metadata("design:type", Number)
], exCheckin.prototype, "checkoutTimestamp", void 0);
__decorate([
    nullable,
    __metadata("design:type", String)
], exCheckin.prototype, "companyName", void 0);
__decorate([
    nullable,
    __metadata("design:type", Number)
], exCheckin.prototype, "contactId", void 0);
__decorate([
    nullable_array_property(String),
    __metadata("design:type", Array)
], exCheckin.prototype, "error", void 0);
__decorate([
    nullable,
    __metadata("design:type", String)
], exCheckin.prototype, "firstName", void 0);
__decorate([
    nullable,
    __metadata("design:type", Boolean)
], exCheckin.prototype, "guest", void 0);
__decorate([
    property,
    __metadata("design:type", Number)
], exCheckin.prototype, "id", void 0);
__decorate([
    nullable,
    __metadata("design:type", String)
], exCheckin.prototype, "lastName", void 0);
__decorate([
    nullable,
    __metadata("design:type", String)
], exCheckin.prototype, "orgnr", void 0);
__decorate([
    nullable,
    __metadata("design:type", Number)
], exCheckin.prototype, "personId", void 0);
__decorate([
    nullable,
    __metadata("design:type", String)
], exCheckin.prototype, "phone", void 0);
__decorate([
    nullable,
    __metadata("design:type", Number)
], exCheckin.prototype, "projectId", void 0);
__decorate([
    nullable,
    __metadata("design:type", Boolean)
], exCheckin.prototype, "valid", void 0);

var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { JSONStruct, property } from 'badphraim/data/JSONStruct';
export class ewProjectStatisticsPhaseState extends JSONStruct {
    constructor() {
        super(...arguments);
        /** @override */ this.deadlines_passed = 0;
        /** @override */ this.deadlines_scheduled = 0;
        /** @override */ this.deadlines_total = 0;
        /** @override */ this.hours_budget = 0;
        /** @override */ this.hours_registered = 0;
        /** @override */ this.milestones_completed = 0;
        /** @override */ this.milestones_total = 0;
        /** @override */ this.phase_id = 0;
    }
}
__decorate([
    property,
    __metadata("design:type", Number)
], ewProjectStatisticsPhaseState.prototype, "deadlines_passed", void 0);
__decorate([
    property,
    __metadata("design:type", Number)
], ewProjectStatisticsPhaseState.prototype, "deadlines_scheduled", void 0);
__decorate([
    property,
    __metadata("design:type", Number)
], ewProjectStatisticsPhaseState.prototype, "deadlines_total", void 0);
__decorate([
    property,
    __metadata("design:type", Number)
], ewProjectStatisticsPhaseState.prototype, "hours_budget", void 0);
__decorate([
    property,
    __metadata("design:type", Number)
], ewProjectStatisticsPhaseState.prototype, "hours_registered", void 0);
__decorate([
    property,
    __metadata("design:type", Number)
], ewProjectStatisticsPhaseState.prototype, "milestones_completed", void 0);
__decorate([
    property,
    __metadata("design:type", Number)
], ewProjectStatisticsPhaseState.prototype, "milestones_total", void 0);
__decorate([
    property,
    __metadata("design:type", Number)
], ewProjectStatisticsPhaseState.prototype, "phase_id", void 0);

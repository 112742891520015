var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { reduxBind } from 'badphraim/ui/LitReduxMixinBase';
import { css, html, LitElement } from 'lit';
import { customElement, state } from 'lit/decorators.js';
import { BoostPortalStore } from '../redux/BoostPortalStore';
import { LitReduxBoostPortalMixin } from '../redux/LitReduxBoostPortalMixin';
import { ewNewMemberInfoResult } from 'boost-api/schemas/ewNewMemberInfoResult';
import { BoostPortalHeader } from '../components/BoostPortalHeader';
let BoostPortalNavigationView = class BoostPortalNavigationView extends LitReduxBoostPortalMixin(LitElement) {
    static get is() {
        return 'boost-portal-navigation-view';
    }
    static get styles() {
        return css `
            .type {
                margin-bottom: 10px;
                padding: 20px 70px 20px 20px;
                border-radius: 8px;
                color: var(--portalCardForeground, #fff);
                background-color: var(--portalCardBackground, #1c6b8c);
            }

            .type:hover {
                background-color: var(--portalCardBackgroundHover, #004765);
                color: var(--portalCardForegroundHover, #fff);
            }

            h4 {
                color: var(--portalForeground, #404040);
                font-weight: 500;
                margin-bottom: 20px;
                margin-top: 0;
            }

            a {
                font-size: 125%;
                font-weight: 100;
                color: var(--portalForeground, #404040);
                text-decoration: none;
            }

            .description {
                font-size: 70%;
            }

            :host {
                width: 100%;
            }

            @media only screen and (max-width: 800px) {
                .type {
                    padding: 10px;
                    font-size: 18px;
                    font-weight: 100;
                }
            }
        `;
    }
    constructor() {
        super();
        this.isSponsorSetup = false;
        this.updateComplete.then(() => __awaiter(this, void 0, void 0, function* () {
            this.dispatch(BoostPortalStore.Actions.SetDepartments([]));
            this.dispatch(BoostPortalStore.Actions.SetMemberInformation(new ewNewMemberInfoResult()));
            this.dispatch(BoostPortalStore.Actions.SetMemberships([]));
            this.dispatch(BoostPortalStore.Actions.SetSelectedDepartmentId());
            this.dispatch(BoostPortalStore.Actions.SetSelectedMembershipId());
            this.dispatch(BoostPortalStore.Actions.SetSponsorCode(''));
            this.dispatch(BoostPortalStore.Actions.SetAvailableDepartments([]));
        }));
    }
    /** @override */ render() {
        return html `
            <boost-portal-header @logo-load="${this.requestUpdate}"></boost-portal-header>
            <div style="display:flex; justify-content:center;">
                <div
                    style="max-width: 500px; display:flex; justify-content:center; flex-direction:column; padding:25px;"
                >
                    <h4>Velg hvilket medlemskap du vil opprette</h4>
                    <div>
                        <a href="${this.centerShort}/member">
                            <div class="type">
                                <div>Bli medlem</div>
                                <div class="description">
                                    Klikk her for å bli medlem hvis du er over 18 år.
                                </div>
                            </div>
                        </a>

                        ${this.isSponsorSetup
            ? html `<a href="${this.centerShort}/member/company">
                                      <div class="type">
                                          <div>Bedriftsmedlem</div>
                                          <div class="description">Krever kode</div>
                                      </div>
                                  </a>

                                  <a href="${this.centerShort}/member/private">
                                      <div class="type">
                                          <div>Bli medlem der andre skal betale for deg</div>
                                          <div class="description">
                                              Hvis du er under 18 år og foresatte skal betale
                                          </div>
                                      </div>
                                  </a> `
            : html ``}
                        <a href="${this.centerShort}/login">
                            <div class="type">
                                <div>Har du vært medlem hos oss før?</div>
                                <div class="description">Klikk her hvis du har vært medlem tidligere.</div>
                            </div>
                        </a>
                        <a href="${this.centerShort}/member/profile">
                            <div class="type">
                                <div>Opprett profil</div>
                                <div class="description">Registrer din profil uten medlemskap</div>
                            </div>
                        </a>
                        ${this.paymentCondition
            ? html ` <a href="${this.centerShort}/vilkar">
                                  <div class="type">
                                      <div>Kjøpsvilkår</div>
                                      <div class="description">
                                          Klikk her for å lese vilkår for kjøp på vår nettside/app
                                      </div>
                                  </div>
                              </a>`
            : html ``}
                    </div>
                </div>
            </div>
        `;
    }
};
BoostPortalNavigationView._deps = [BoostPortalHeader];
__decorate([
    reduxBind(BoostPortalStore.path('centerShort')),
    state(),
    __metadata("design:type", String)
], BoostPortalNavigationView.prototype, "centerShort", void 0);
__decorate([
    reduxBind(BoostPortalStore.path('isSponsorSetup')),
    state(),
    __metadata("design:type", Boolean)
], BoostPortalNavigationView.prototype, "isSponsorSetup", void 0);
__decorate([
    reduxBind(BoostPortalStore.path('paymentCondition')),
    state(),
    __metadata("design:type", String)
], BoostPortalNavigationView.prototype, "paymentCondition", void 0);
BoostPortalNavigationView = __decorate([
    customElement(BoostPortalNavigationView.is),
    __metadata("design:paramtypes", [])
], BoostPortalNavigationView);
export { BoostPortalNavigationView };

import { TaskStatus } from 'cerum-work-schedule-defs/TaskStatus';
import moment from 'moment';
import { EngagementStatus } from 'cerum-work-schedule-defs/AssigneeWithStatus';
import { isTaskStatusType } from 'cerum-work-schedule-data/CerumWorkScheduleTaskHelper';
export class WorkSchedulePortletStoreHelper {
    _mapMetricsToArray(aggregatedMetrics) {
        const metrics = [];
        for (const key in aggregatedMetrics) {
            const aggregatedMetric = aggregatedMetrics[key];
            metrics.push(aggregatedMetric);
        }
        return metrics;
    }
    _getMetricKey(task, workhoursPerPersonWithAbsence) {
        var _a;
        if (task.completion_status === TaskStatus.DELETED) {
            return TaskStatus.DELETED;
        }
        if (task.assignees.find((assignee) => assignee.status === EngagementStatus.TERMINATED)) {
            return EngagementStatus.TERMINATED;
        }
        const hasPassiveAssignees = ((_a = task.assignee) === null || _a === void 0 ? void 0 : _a.status) === EngagementStatus.PASSIVE ||
            task.assignees.find((assignee) => assignee.status === EngagementStatus.PASSIVE);
        if (hasPassiveAssignees) {
            return TaskStatus.DEVIANCE;
        }
        if (task.assignees.length) {
            let workhourDaysDateEqual;
            const taskTime = moment(task.start);
            const assigneeIds = task.assignees.map((assignee) => assignee.data_id);
            if (workhoursPerPersonWithAbsence === null || workhoursPerPersonWithAbsence === void 0 ? void 0 : workhoursPerPersonWithAbsence.length) {
                for (const hour of workhoursPerPersonWithAbsence) {
                    const days = hour.days;
                    for (let i = 0; i < days.length; ++i) {
                        const dayTime = moment.unix(days[i].date);
                        if (taskTime.isBetween(dayTime, taskTime, 'day') ||
                            (taskTime.isSame(dayTime, 'day') && assigneeIds.includes(hour.personId))) {
                            workhourDaysDateEqual = days.find((day) => { var _a; return day.holiday || ((_a = day.expenses) === null || _a === void 0 ? void 0 : _a.find((expense) => expense)); });
                        }
                    }
                }
            }
            if (workhourDaysDateEqual) {
                if (workhourDaysDateEqual.holiday) {
                    return TaskStatus.HOLIDAY;
                }
                else {
                    return TaskStatus.DEVIANCE;
                }
            }
        }
        if (task.completion_status === TaskStatus.SCHEDULED) {
            return TaskStatus.INPROGRESS;
        }
        return task.completion_status;
    }
    getAggregateTaskMetrics(aggregatedData, onlyDeviations, availableAssignees, includeDuplicateTasks, deletedTasks) {
        var _a;
        const { tasks, hoursPerPerson } = aggregatedData;
        let allTasks = [...tasks];
        if (deletedTasks === null || deletedTasks === void 0 ? void 0 : deletedTasks.length) {
            allTasks = [...allTasks, ...deletedTasks];
        }
        let tasklist = this.mapTaskSummaryAssigneesToAssigneeWithStatus(allTasks, availableAssignees);
        const aggregatedMetrics = {};
        // Filter out workhours for persons that have no absence
        const workhoursPerPersonWithAbsence = hoursPerPerson === null || hoursPerPerson === void 0 ? void 0 : hoursPerPerson.filter((hours) => hours.days.find((day) => { var _a; return ((_a = day.expenses) === null || _a === void 0 ? void 0 : _a.find((expense) => expense.absense)) || day.holiday; }));
        // Filter out days that have no absence or holiday for each person in workhours,
        // done this way because original data is immutable
        const updatedWorkhoursPerPersonWithAbsence = (_a = workhoursPerPersonWithAbsence === null || workhoursPerPersonWithAbsence === void 0 ? void 0 : workhoursPerPersonWithAbsence.map((workhoursForPerson) => (Object.assign(Object.assign({}, workhoursForPerson), { days: workhoursForPerson.days.filter((day) => { var _a; return day.holiday || ((_a = day.expenses) === null || _a === void 0 ? void 0 : _a.find((expense) => expense.absense)); }) })))) !== null && _a !== void 0 ? _a : [];
        if (!includeDuplicateTasks) {
            const taskIds = tasklist.map((task) => task.id);
            tasklist = tasklist.filter(({ id, metricKey }, index) => {
                if (metricKey === TaskStatus.SIMULATED) {
                    return true;
                }
                return !taskIds.includes(id, index + 1);
            });
        }
        for (const task of tasklist) {
            if (!task.completion_status) {
                continue;
            }
            const metricKey = this._getMetricKey(task, updatedWorkhoursPerPersonWithAbsence);
            task.metricKey = metricKey;
            if (isTaskStatusType(metricKey) &&
                [TaskStatus.HOLIDAY, TaskStatus.DEVIANCE].includes(metricKey)) {
                task.extendedAbsence = {
                    reason: metricKey,
                };
            }
            if (!(metricKey in aggregatedMetrics)) {
                aggregatedMetrics[metricKey] = {
                    name: metricKey,
                    tasks: [task],
                };
            }
            else {
                const { tasks } = aggregatedMetrics[metricKey];
                aggregatedMetrics[metricKey] = Object.assign(Object.assign({}, aggregatedMetrics[metricKey]), { tasks: [...tasks, task] });
            }
        }
        const mappedMetrics = this._mapMetricsToArray(aggregatedMetrics);
        const filteredMetrics = this.filterMetrics(mappedMetrics, onlyDeviations);
        return filteredMetrics;
    }
    _getAssigneeStatus(assigneeDataId, assignees) {
        var _a;
        const assigneeMatch = assignees.find((findAssignee) => findAssignee.data_id === assigneeDataId);
        return (_a = assigneeMatch === null || assigneeMatch === void 0 ? void 0 : assigneeMatch.status) !== null && _a !== void 0 ? _a : EngagementStatus.UNASSIGNED;
    }
    mapTaskSummaryAssigneesToAssigneeWithStatus(tasks, assignees) {
        const aggregatedTasks = tasks.map((task) => {
            const assigneeWithStatus = this._mapWorkPlanningAssigneeToAssigneeWithStatus(assignees, task.assignee);
            const assigneesWithStatus = task.assignees.map((assignee) => this._mapWorkPlanningAssigneeToAssigneeWithStatus(assignees, assignee));
            const aggregatedTask = Object.assign(Object.assign({}, task), { assignee: assigneeWithStatus, assignees: assigneesWithStatus, metricKey: task.completion_status });
            return aggregatedTask;
        });
        return aggregatedTasks;
    }
    _mapWorkPlanningAssigneeToAssigneeWithStatus(assignees, assignee) {
        var _a, _b, _c, _d, _e, _f;
        return Object.assign(Object.assign({}, assignee), { status: this._getAssigneeStatus((_a = assignee === null || assignee === void 0 ? void 0 : assignee.data_id) !== null && _a !== void 0 ? _a : -1, assignees), data_id: (_b = assignee === null || assignee === void 0 ? void 0 : assignee.data_id) !== null && _b !== void 0 ? _b : -1, name: (_c = assignee === null || assignee === void 0 ? void 0 : assignee.name) !== null && _c !== void 0 ? _c : '', datatype_id: (_d = assignee === null || assignee === void 0 ? void 0 : assignee.datatype_id) !== null && _d !== void 0 ? _d : -1, id: (_e = assignee === null || assignee === void 0 ? void 0 : assignee.id) !== null && _e !== void 0 ? _e : -1, node_id: (_f = assignee === null || assignee === void 0 ? void 0 : assignee.node_id) !== null && _f !== void 0 ? _f : -1 });
    }
    filterMetrics(metrics, onlyDeviations) {
        if (onlyDeviations) {
            metrics = metrics.filter((metric) => isTaskStatusType(metric.name) && [TaskStatus.DEVIANCE].includes(metric.name));
            // Remove cancelled tasks from deviations
            for (const metric of metrics) {
                metric.tasks = metric.tasks.filter((task) => task.completion_status &&
                    ![TaskStatus.CANCELLED].includes(task.completion_status));
            }
        }
        return metrics.filter((metric) => ![TaskStatus.HOLIDAY, TaskStatus.PROPOSED].includes(metric.name));
    }
}

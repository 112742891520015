var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { ChatFilterStatus } from 'cerumx-chat-defs/ChatFilterStatus';
import { CerumReduxSubStore } from './CerumReduxSubStore';
import { ChatMode } from 'cerumx-chat-defs/ChatMode';
import { isSuccessHTTP, isErrorHTTP } from 'cerumx-api/APIRestClient';
import { ChatAPI } from 'cerumx-api/cerum/desktop/chat/ChatAPI';
class CerumChatStoreLayout {
    constructor() {
        this.chatFilter = {
            status: ChatFilterStatus.All,
            keyword: undefined,
        };
        this.selectedConversationMessages = [];
        this.conversations = [];
        this.chatData = [];
    }
}
export class CerumChatStore extends CerumReduxSubStore {
    constructor() {
        super(...arguments);
        /** @override */ this.base_key = 'CerumChatStore';
    }
    static get instance() {
        return this._instance || (this._instance = new CerumChatStore());
    }
    static path(property) {
        return this.staticPath('CerumChatStore', property);
    }
    /** @override */ reducer() {
        return {
            [this.base_key]: (state = new CerumChatStoreLayout(), action) => {
                switch (action.type) {
                    case CerumChatStore.Actions.SET_LOADING:
                        return Object.assign(Object.assign({}, state), { isLoading: action.payload.isLoading });
                    case CerumChatStore.Actions.SET_SELECTED_CONVERSATION:
                        return Object.assign(Object.assign({}, state), { selectedConversation: action.payload.selectedConversation });
                    case CerumChatStore.Actions.SET_SELECTED_CONVERSATION_MESSAGES:
                        return Object.assign(Object.assign({}, state), { selectedConversationMessages: action.payload.selectedConversationMessages });
                    case CerumChatStore.Actions.LOAD_CHAT_DATA:
                        return Object.assign(Object.assign({}, state), { chatData: action.payload.chatData });
                    case CerumChatStore.Actions.SET_CHAT_DATA:
                        return Object.assign(Object.assign({}, state), { chatData: action.payload.chatData });
                    case CerumChatStore.Actions.SET_CHAT_MODE:
                        return Object.assign(Object.assign({}, state), { chatMode: action.payload.chatMode, selectedConversationMessages: [], selectedConversation: undefined });
                }
                return state;
            },
        };
    }
}
export const mapToConversationWithAgreementName = (conversations, agreementName) => {
    const mappedConversation = [];
    for (const conversation of conversations) {
        const { id, title, unread_messages_count: unreadMessagesCount, most_recent_message: mostRecentMessage, closed, } = conversation;
        mappedConversation.push({
            id,
            title,
            unread_messages_count: unreadMessagesCount,
            agreementName,
            most_recent_message: mostRecentMessage,
            closed,
            created_at: conversation.created_at,
        });
    }
    return mappedConversation;
};
(function (CerumChatStore) {
    let Actions;
    (function (Actions) {
        Actions.SET_SELECTED_CONVERSATION = 'SET_SELECTED_CONVERSATION';
        Actions.SET_SELECTED_CONVERSATION_MESSAGES = 'SET_SELECTED_CONVERSATION_MESSAGES';
        Actions.SET_CHAT_MODE = 'SET_CHAT_MODE';
        Actions.LOAD_CHAT_DATA = 'LOAD_CHAT_DATA';
        Actions.SET_LOADING = 'SET_LOADING';
        Actions.SET_CHAT_DATA = 'SET_CHAT_DATA';
        Actions.setChatMode = (chatMode = ChatMode.PUBLIC) => ({
            type: Actions.SET_CHAT_MODE,
            payload: {
                chatMode,
            },
        });
        Actions.setLoading = (isLoading) => ({
            type: Actions.SET_LOADING,
            payload: {
                isLoading,
            },
        });
        Actions.setSelectedConversation = (selectedConversation) => ({
            type: Actions.SET_SELECTED_CONVERSATION,
            payload: {
                selectedConversation,
            },
        });
        Actions.setSelectedConversationMessages = (selectedConversationMessages) => ({
            type: Actions.SET_SELECTED_CONVERSATION_MESSAGES,
            payload: {
                selectedConversationMessages,
            },
        });
        Actions.setChatData = (chatData) => ({
            type: Actions.SET_CHAT_DATA,
            payload: {
                chatData: sortChatData(chatData),
            },
        });
        Actions.loadChatData = () => __awaiter(this, void 0, void 0, function* () {
            let chatData = [];
            const chatDataResult = yield ChatAPI.getV1ConversationsAgreement_conversations();
            if (isSuccessHTTP(chatDataResult)) {
                chatData = sortChatData(chatDataResult);
            }
            else if (isErrorHTTP(chatDataResult)) {
                console.error('Feil ved lasting av avtaler', chatDataResult);
                chatData = [];
            }
            return {
                type: Actions.LOAD_CHAT_DATA,
                payload: {
                    chatData,
                },
            };
        });
        const sortChatData = (chatDataResult) => {
            return chatDataResult.map((data) => {
                const { internal_conversations: internalConversations, public_conversations: publicConversations, } = data;
                return Object.assign(Object.assign({}, data), { internal_conversations: mapToConversationWithAgreementName(internalConversations, data.agreement.name), public_conversations: mapToConversationWithAgreementName(publicConversations, data.agreement.name) });
            });
        };
    })(Actions = CerumChatStore.Actions || (CerumChatStore.Actions = {}));
})(CerumChatStore || (CerumChatStore = {}));

var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
/* tslint:disable: class-name */
/*
 * AUTOGENERATED FILE - DO NOT EDIT
 */
import { ewJSONContract } from './ewJSONContract';
import { JSONStruct, array_property, nullable, property } from 'badphraim/data/JSONStruct';
export class ewJSONPerson extends JSONStruct {
    constructor() {
        super(...arguments);
        /** @override */ this.avtaler = [];
        /** @override */ this.created = 0;
        /** @override */ this.eier = 0;
        /** @override */ this.hascard = false;
        /** @override */ this.id = 0;
        /** @override */ this.is_sponsored = false;
        /** @override */ this.isactive = false;
    }
}
__decorate([
    nullable,
    __metadata("design:type", String)
], ewJSONPerson.prototype, "access", void 0);
__decorate([
    nullable,
    __metadata("design:type", String)
], ewJSONPerson.prototype, "adresse", void 0);
__decorate([
    nullable,
    __metadata("design:type", String)
], ewJSONPerson.prototype, "ansattnr", void 0);
__decorate([
    nullable,
    __metadata("design:type", String)
], ewJSONPerson.prototype, "avdeling", void 0);
__decorate([
    array_property(ewJSONContract),
    __metadata("design:type", Array)
], ewJSONPerson.prototype, "avtaler", void 0);
__decorate([
    nullable,
    __metadata("design:type", String)
], ewJSONPerson.prototype, "birth", void 0);
__decorate([
    property,
    __metadata("design:type", Number)
], ewJSONPerson.prototype, "created", void 0);
__decorate([
    property,
    __metadata("design:type", Number)
], ewJSONPerson.prototype, "eier", void 0);
__decorate([
    nullable,
    __metadata("design:type", String)
], ewJSONPerson.prototype, "epost", void 0);
__decorate([
    nullable,
    __metadata("design:type", String)
], ewJSONPerson.prototype, "etternavn", void 0);
__decorate([
    nullable,
    __metadata("design:type", Number)
], ewJSONPerson.prototype, "fodttimestamp", void 0);
__decorate([
    nullable,
    __metadata("design:type", String)
], ewJSONPerson.prototype, "fornavn", void 0);
__decorate([
    property,
    __metadata("design:type", Boolean)
], ewJSONPerson.prototype, "hascard", void 0);
__decorate([
    property,
    __metadata("design:type", Number)
], ewJSONPerson.prototype, "id", void 0);
__decorate([
    nullable,
    __metadata("design:type", String)
], ewJSONPerson.prototype, "imageURL", void 0);
__decorate([
    nullable,
    __metadata("design:type", String)
], ewJSONPerson.prototype, "info", void 0);
__decorate([
    property,
    __metadata("design:type", Boolean)
], ewJSONPerson.prototype, "is_sponsored", void 0);
__decorate([
    property,
    __metadata("design:type", Boolean)
], ewJSONPerson.prototype, "isactive", void 0);
__decorate([
    nullable,
    __metadata("design:type", Number)
], ewJSONPerson.prototype, "kjonn", void 0);
__decorate([
    nullable,
    __metadata("design:type", Number)
], ewJSONPerson.prototype, "klipp", void 0);
__decorate([
    nullable,
    __metadata("design:type", Boolean)
], ewJSONPerson.prototype, "mail_ok", void 0);
__decorate([
    nullable,
    __metadata("design:type", Number)
], ewJSONPerson.prototype, "memberid", void 0);
__decorate([
    nullable,
    __metadata("design:type", String)
], ewJSONPerson.prototype, "mobil", void 0);
__decorate([
    nullable,
    __metadata("design:type", String)
], ewJSONPerson.prototype, "personnr", void 0);
__decorate([
    nullable,
    __metadata("design:type", String)
], ewJSONPerson.prototype, "postnr", void 0);
__decorate([
    nullable,
    __metadata("design:type", String)
], ewJSONPerson.prototype, "poststed", void 0);
__decorate([
    nullable,
    __metadata("design:type", String)
], ewJSONPerson.prototype, "rolle", void 0);
__decorate([
    nullable,
    __metadata("design:type", Boolean)
], ewJSONPerson.prototype, "sms_ok", void 0);
__decorate([
    nullable,
    __metadata("design:type", String)
], ewJSONPerson.prototype, "telefax", void 0);
__decorate([
    nullable,
    __metadata("design:type", String)
], ewJSONPerson.prototype, "telefon", void 0);

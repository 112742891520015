var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { reduxBind } from 'badphraim/ui/LitReduxMixinBase';
import { isErrorHTTP, isSuccessHTTP } from 'boost-api/APIRestClient';
import { MembersignupAPI } from 'boost-api/boost/signup/membersignup/MembersignupAPI';
import { OverlayWindow } from 'common-uicomponents/components/OverlayWindow';
import { css, html, LitElement } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { BoostPortalNavigationSteps } from '../../enums/BoostPortalNavigationSteps';
import { Callback } from '../../enums/Callback';
import { BoostPortalStore } from '../../redux/BoostPortalStore';
import { LitReduxBoostPortalMixin } from '../../redux/LitReduxBoostPortalMixin';
import { BoostLoading } from './BoostLoading';
import { VippsPaymentHelper } from '../VippsPaymentHelper';
import { EitherOrSpacer } from 'cerumx-shared/EitherOrSpacer';
import { BoostPortalPaymentStatusHelper, PaymentStatus } from '../../helpers/BoostPortalPaymentStatusHelper';
import { VippsPaymentStatus } from '../../interfaces/VippsCheckoutDefs';
import { TrackingFactorySingleton } from '../../tracking/TrackingFactorySingleton';
import { TrackingEvent } from '../../tracking/TrackingEvent';
let BoostPortalPaymentOptions = class BoostPortalPaymentOptions extends LitReduxBoostPortalMixin(LitElement) {
    constructor() {
        super(...arguments);
        this.shortTerm = false;
        this.noDirectDebit = false;
        this.departments = [];
        this.isLoading = false;
    }
    static get is() {
        return 'boost-portal-payment-options';
    }
    static get styles() {
        return css `
            .payment-text-container {
                padding: 15px;
                background-color: #aed1e0;
                border-radius: 6px;
                margin-bottom: 10px;
                border: 1px solid #89aebd;
                text-align: center;
                display: flex;
                flex-direction: column;
                line-height: 2;
                font-size: 18px;
                margin-top: 30px;
            }

            .payment-container {
                display: flex;
                flex-direction: column;
                align-items: center;
            }

            .vipps-payment-container {
                padding: 15px;
                background-color: rgb(71, 124, 161) !important;
                margin-top: 40px;
                width: 50%;
                border-radius: 10px;
                justify-content: center;
                display: flex;
                margin-top: 20px;
            }

            .mail-payment-container {
                padding: 15px;
                background-color: rgb(72, 186, 151);
                width: 34%;
                border-radius: 8px;
                color: rgb(255, 255, 255);
                display: flex;
                justify-content: center;
                border-radius: 8px;
            }

            .mail-payment-container:hover,
            .selected-payment {
                background-color: #139b69 !important;
                color: #fff;
                cursor: pointer;
            }

            .vipps-payment-container:hover {
                background-color: rgb(62, 120, 172) !important;
                color: #fff;
                cursor: pointer;
            }

            either-or-spacer {
                width: 100%;
                --text-2xs: 14px;
                margin-top: 3rem;
                margin-bottom: 0.5rem;
            }

            @media only screen and (max-width: 600px) {
                .vipps-payment-container,
                .mail-payment-container {
                    width: 70%;
                }

                either-or-spacer {
                    margin-top: 1.5rem;
                }

                .payment-text-container {
                    padding: 20px;
                    line-height: 25px;
                }
            }
        `;
    }
    /** @override */ render() {
        return html `${this.isLoading
            ? html ` <div style="padding-top:60px; ">
                  <boost-loading title="Venter på status"></boost-loading>
              </div>`
            : this._renderPaymentStatusOptions()}`;
    }
    _renderPaymentStatusOptions() {
        if (this.callback === Callback.NETS) {
            return this._renderPaymentOptions();
        }
        if (this.callback === Callback.VIPPS) {
            if (this.paymentStatus === PaymentStatus.PAYMENTABORT) {
                return html `<span> Noe gikk feil! </span>
                    <cerum-legacy-button @click="${() => this._loadPaymentStatus()}"
                        >Prøv på nytt</cerum-legacy-button
                    >`;
            }
            else if (this.paymentStatus === PaymentStatus.PAYMENTFAILED) {
                return this._renderVippsStatusFailed();
            }
        }
    }
    _loadPaymentStatus() {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.invoice) {
                return;
            }
            this.isLoading = true;
            this.paymentStatus = yield BoostPortalPaymentStatusHelper.getPaymentStatus(this.invoice.invoice.id);
            if (this.paymentStatus === PaymentStatus.PAYMENTSUCCESS) {
                this._changeStepToSuccess();
            }
            this.isLoading = false;
        });
    }
    _renderPaymentOptions() {
        var _a;
        const isInvoicePaid = Boolean((_a = this.invoice) === null || _a === void 0 ? void 0 : _a.invoice.rest);
        return html `<div class="container">
            <div class="payment-text-container">${this._renderPaymentOptionText(isInvoicePaid)}</div>
            ${this._renderPaymentButtons(isInvoicePaid)}
        </div>`;
    }
    _renderPaymentOptionText(isInvoicePaid) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
        if (!isInvoicePaid) {
            return html `Dine personopplysninger er nå lagret. <br />
                Trykk på Fullfør for å fullføre din registring.`;
        }
        if (!this.shortTerm && !this.noDirectDebit) {
            return html `Din avtalegiro er nå aktivert.<br />

                Oppstartsfaktura er sendt til din epostadresse. Denne gjelder for resterende av inneværende
                måned + neste måned. <br />Første ordinære trekk på avtalegiro blir
                ${(_a = this.invoice) === null || _a === void 0 ? void 0 : _a.first_invoice_date.slice(3, 10)} <br />
                <br />
                <br />
                Trykk på Gå til betaling under for å betale oppstartsfaktura nå eller klikk Fullfør hvis du
                ønsker å betale tilsendt faktura i nettbanken.`;
        }
        else if (this.shortTerm && !this.noDirectDebit) {
            return html `Din avtalegiro er nå aktivert og faktura er sendt til din epostadresse.
                <br />
                ${(_c = (_b = this.selectedMembership) === null || _b === void 0 ? void 0 : _b.title) !== null && _c !== void 0 ? _c : (_e = (_d = this.invoice) === null || _d === void 0 ? void 0 : _d.invoice.linjer[0].tittel) === null || _e === void 0 ? void 0 : _e.toUpperCase()},
                kr.${(_f = this.invoice) === null || _f === void 0 ? void 0 : _f.invoice.linjer[0].pris},- <br />
                Trykk på Gå til betaling under for å betale nå eller klikk Fullfør hvis du ønsker å betale
                tilsendt faktura i nettbanken. `;
        }
        return html ` Dine personopplysninger er nå lagret og du har bestilt: <br />
            ${(_h = (_g = this.selectedMembership) === null || _g === void 0 ? void 0 : _g.title) !== null && _h !== void 0 ? _h : (_k = (_j = this.invoice) === null || _j === void 0 ? void 0 : _j.invoice.linjer[0].tittel) === null || _k === void 0 ? void 0 : _k.toUpperCase()}
            kr.${(_l = this.invoice) === null || _l === void 0 ? void 0 : _l.invoice.linjer[0].pris} for hele perioden.
            <br />
            Trykk på Gå til betaling for å betale og aktivere ditt medlemskap.`;
    }
    _renderVippsStatusFailed() {
        return html ` <div>
            <div style="padding-top:20px">
                <div class="payment-text-container">${this._renderPaymentFailed()}</div>
                ${this._renderPaymentButtons(false)}
            </div>
        </div>`;
    }
    _renderPaymentFailed() {
        var _a;
        if (!this.shortTerm && !this.noDirectDebit) {
            return html `<span style="font-size:30px; padding-bottom:6px;"> OBS! </span>
                <span>
                    Betalingen feilet. Velg betaligsmetode og prøv på nytt. <br />

                    Oppstartsfaktura er sendt til din epostadresse. Denne gjelder for resterende av
                    inneværende måned + neste måned. <br />Første ordinære trekk på avtalegiro blir
                    ${(_a = this.invoice) === null || _a === void 0 ? void 0 : _a.first_invoice_date.slice(3, 10)} <br />
                    <br />
                    <br />
                    Trykk på Gå til betaling under for å betale oppstartsfaktura nå eller klikk Fullfør hvis
                    du ønsker å betale tilsendt faktura i nettbanken.
                </span>`;
        }
        else if (this.shortTerm && !this.noDirectDebit) {
            return html `<span style="font-size:30px; padding-bottom:6px;"> OBS! </span>
                <span>
                    Betalingen feilet. Velg betalingsmåte og prøv på nytt. <br />
                    <br />
                </span> `;
        }
        return html `<span style="font-size:30px; padding-bottom:6px;"> OBS! </span>
            <span>
                Betalingen feilet, og ble ikke registrert. <br />
                Trykk på Gå til betaling for å prøve på nytt. <br
            /></span>`;
    }
    _renderPaymentButtons(isInvoicePaid) {
        if (!isInvoicePaid) {
            return html `<div class="payment-container">
                <div class="mail-payment-container" @click="${() => this._changeStepToSuccess()}">
                    <span style="align-self:center; font-size:20px;">Fullfør</span>
                </div>
            </div>`;
        }
        if (!this.shortTerm && !this.noDirectDebit) {
            return html `<div class="payment-container">
                <div class="vipps-payment-container" @click="${() => this._openPayment()}">
                    <span style="align-self:center; font-size:20px; color: white">Gå til betaling</span>
                </div>
                <either-or-spacer text="eller få tilsendt faktura på e-post"></either-or-spacer>
                <div class="mail-payment-container" @click="${() => this._changeStepToSuccess()}">
                    <span style="align-self:center; font-size:20px;">Fullfør</span>
                </div>
            </div> `;
        }
        else if (this.shortTerm && !this.noDirectDebit) {
            return html `<div class="payment-container">
                <div class="vipps-payment-container" @click="${() => this._openPayment()}">
                    <span style="align-self:center; font-size:20px; color: white">Gå til betaling</span>
                </div>
                <either-or-spacer text="eller få tilsendt faktura på e-post"></either-or-spacer>
                <div class="mail-payment-container" @click="${() => this._changeStepToSuccess()}">
                    <span style="align-self:center; font-size:20spx;">Fullfør</span>
                </div>
            </div>`;
        }
        return html `<div class="payment-container">
            <div class="vipps-payment-container" @click="${() => this._openPayment()}">
                <span style="align-self:center; font-size:20px; color: white">Gå til betaling</span>
            </div>
        </div>`;
    }
    _changeStepToSuccess() {
        this.redirectUrl = this._getRedirectUrl();
        TrackingFactorySingleton.getTracker().completedEvent(TrackingEvent.MemberSignupCompleted);
        if (this.redirectUrl) {
            window.location.href = this.redirectUrl;
        }
        this.dispatchEvent(new CustomEvent('currentStep-changed', {
            detail: {
                currentStep: BoostPortalNavigationSteps.SUCCESS,
            },
            bubbles: true,
            composed: true,
        }));
    }
    _renderMembership() {
        var _a, _b;
        return html `
            <div>${(_a = this.selectedMembership) === null || _a === void 0 ? void 0 : _a.title} ${(_b = this.selectedMembership) === null || _b === void 0 ? void 0 : _b.cost_included_vat},-</div>
        `;
    }
    _getFallbackUrl() {
        var _a;
        if (this.redirectUrl) {
            return this.redirectUrl;
        }
        const url = window.location.href.split('?')[0];
        if (!url) {
            console.error('Mangler url?');
            return '';
        }
        return (url +
            '?callback=vipps&invoiceId=' +
            ((_a = this.invoice) === null || _a === void 0 ? void 0 : _a.invoice.id) +
            '&shortterm=' +
            this.shortTerm +
            '&directdebit=' +
            this.noDirectDebit);
    }
    _openPayment() {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.invoice || this.isLoading) {
                console.error('Mangler invoiceID');
                return;
            }
            OverlayWindow.asyncInject({
                title: '',
                componentTagName: BoostLoading.is,
                attributes: { title: 'Laster inn...' },
            });
            this.redirectUrl = this._getRedirectUrl();
            const fallback = this._getFallbackUrl();
            this.isLoading = true;
            const vippsUrlRequest = yield MembersignupAPI.postV2BoostsignupInitiatepayment({
                invoice_id: this.invoice.invoice.id,
                fallback,
            });
            if (isSuccessHTTP(vippsUrlRequest)) {
                const { token, checkout_url: checkoutUrl, payment_url: paymentUrl } = vippsUrlRequest;
                if (paymentUrl) {
                    window.location.href = paymentUrl;
                    return;
                }
                if (token && checkoutUrl) {
                    const boostPortalVippsPaymentOptions = new VippsPaymentHelper(token, checkoutUrl);
                    const iframeElement = boostPortalVippsPaymentOptions.getIframeContainer();
                    const checkoutOn = this._getVippsCheckoutOn();
                    yield boostPortalVippsPaymentOptions.setupVippsCheckout(checkoutOn);
                    OverlayWindow.asyncPushWindow({
                        title: '',
                        element: iframeElement,
                        onClose: () => OverlayWindow.asyncPopAllWindows(),
                    });
                }
            }
            else if (isErrorHTTP(vippsUrlRequest)) {
                console.error('Vipps payment initiation failed', vippsUrlRequest);
            }
            this.isLoading = false;
        });
    }
    _getVippsCheckoutOn() {
        return {
            session_status_changed: (data) => {
                if (data === VippsPaymentStatus.PaymentSuccessful) {
                    this._addCallbackToUrl();
                    this._loadPaymentStatus();
                    TrackingFactorySingleton.getTracker().completedEvent(TrackingEvent.VippsPaymentComplete);
                }
            },
        };
    }
    _addCallbackToUrl() {
        const url = new URL(window.location.href);
        url.searchParams.set('callback', 'vipps');
        window.history.replaceState({}, '', url.toString());
    }
    _getSelectedDepartment() {
        var _a;
        const departmentId = (_a = this.invoice) === null || _a === void 0 ? void 0 : _a.invoice.avdeling;
        if (departmentId) {
            return this.departments.find((department) => department.id === departmentId);
        }
    }
    _getRedirectUrl() {
        const selectedDepartment = this._getSelectedDepartment();
        if (selectedDepartment === null || selectedDepartment === void 0 ? void 0 : selectedDepartment.portal_redirect_url) {
            return selectedDepartment.portal_redirect_url;
        }
        return '';
    }
    /** @override */ updated(changedProperties) {
        changedProperties.forEach((_oldValue, propertyName) => {
            switch (propertyName) {
                case 'invoice':
                    this._loadPaymentStatus();
            }
        });
        super.updated(changedProperties);
    }
};
BoostPortalPaymentOptions._deps = [OverlayWindow, BoostLoading, EitherOrSpacer];
__decorate([
    property({ type: Object }),
    __metadata("design:type", Object)
], BoostPortalPaymentOptions.prototype, "selectedMembership", void 0);
__decorate([
    property({ type: Object }),
    __metadata("design:type", Object)
], BoostPortalPaymentOptions.prototype, "invoice", void 0);
__decorate([
    state(),
    __metadata("design:type", String)
], BoostPortalPaymentOptions.prototype, "paymentStatus", void 0);
__decorate([
    property({ type: String }),
    __metadata("design:type", String)
], BoostPortalPaymentOptions.prototype, "callback", void 0);
__decorate([
    property({ type: Boolean }),
    __metadata("design:type", Boolean)
], BoostPortalPaymentOptions.prototype, "shortTerm", void 0);
__decorate([
    property({ type: Boolean }),
    __metadata("design:type", Boolean)
], BoostPortalPaymentOptions.prototype, "noDirectDebit", void 0);
__decorate([
    state(),
    __metadata("design:type", String)
], BoostPortalPaymentOptions.prototype, "redirectUrl", void 0);
__decorate([
    reduxBind(BoostPortalStore.path('departments')),
    state(),
    __metadata("design:type", Array)
], BoostPortalPaymentOptions.prototype, "departments", void 0);
__decorate([
    state(),
    __metadata("design:type", Boolean)
], BoostPortalPaymentOptions.prototype, "isLoading", void 0);
BoostPortalPaymentOptions = __decorate([
    customElement(BoostPortalPaymentOptions.is)
], BoostPortalPaymentOptions);
export { BoostPortalPaymentOptions };

/* tslint:disable: class-name */
/*
 * AUTOGENERATED FILE - DO NOT EDIT
 */
import { APIRestClient } from '../../../APIRestClient';
import { ewRestV1BoostSignupTokenHandler_Response } from '../../../schemas/ewRestV1BoostSignupTokenHandler_Response';
export class MembersignupaccessAPI extends APIRestClient {
    /**
     * Hent ut vilk�r for en avdeling
     * Implementert hovedsakelig i *ewRestV1BoostAgreementHandler*::*read*
     */
    static getV1BoostsignupAgreement(__QUERY) {
        return this._get('/v1/boostsignup/agreement', {}, __QUERY, null, APIRestClient.Native.Blob(), ['https://api.boostsystem.no/signup', 'https://api.boostsystem.no/app'], 'blob');
    }
    /**
     * Basis-token
     * Implementert hovedsakelig i *ewRestV1BoostSignupTokenHandler*::*read*
     */
    static getV1BoostsignupTokenUsingCenterident(centerident) {
        return this._get('/v1/boostsignup/token/{centerident}', { centerident }, null, null, ewRestV1BoostSignupTokenHandler_Response.classOf(ewRestV1BoostSignupTokenHandler_Response, new ewRestV1BoostSignupTokenHandler_Response()), ['https://api.boostsystem.no/signup', 'https://api.boostsystem.no/app'], 'json');
    }
}

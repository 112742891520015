import { CerumServices } from './CerumServices';
import { TokenStoreBase } from 'spa-core/TokenStoreBase';
export class CerumTokenStore extends TokenStoreBase {
    /** @override */ get prefix() {
        return CerumServices.instance()
            .getApplicationRoot()
            .replace(/[^a-zA-Z0-9]/, '_');
    }
    /** @override */ get storage() {
        return window.sessionStorage;
    }
}

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Ajax as badphraim_ajax } from 'badphraim/ajax/Ajax';
import { SingletonInstanceLike } from 'badphraim/core/Singleton';
import { CerumOAuth2Client } from './CerumOAuth2Client';
import { CerumXState } from './cerumx/CerumXState';
import { Dom } from './Dom';
import { LoadingBoxCerumX } from './LoadingBoxCerumX';
import { LoadingBoxZoot } from './LoadingBoxZoot';
import { UrlMangler } from 'badphraim/UrlMangler';
export class Ajax extends badphraim_ajax {
    static stdInstance() {
        return SingletonInstanceLike(Ajax, () => {
            return new Ajax(new Dom(document));
        });
    }
    addAccessTokenToGetUrl(url) {
        return __awaiter(this, void 0, void 0, function* () {
            /**
             * https://tools.ietf.org/html/rfc6750#section-2.3
             */
            const urlMangler = new UrlMangler(url);
            const token = yield CerumOAuth2Client.getInstance().getTokenStore().getAccessTokenFor(url);
            if (token) {
                return (url +
                    (urlMangler.getQuery() ? '&' : '?') +
                    'access_token=' +
                    encodeURIComponent(token.accessToken));
            }
            return url;
        });
    }
    /** @override */ getLoadingHandler() {
        if (!this._loading_box) {
            this._loading_box = CerumXState.isCerumX()
                ? new LoadingBoxCerumX(this.dom)
                : new LoadingBoxZoot(this.dom);
        }
        return this._loading_box;
    }
    /** @override */ _sendRequest(xhrq, url, data, loadingBoxHandle, scopes) {
        const _super = Object.create(null, {
            _sendRequest: { get: () => super._sendRequest }
        });
        return __awaiter(this, void 0, void 0, function* () {
            if (CerumXState.isCerumX()) {
                const tokens = CerumOAuth2Client.getInstance().getTokenStore();
                let token;
                try {
                    token = yield tokens.getAccessTokenFor(url);
                }
                catch (error) {
                    console.error('Fikk ikke tak i tokens ifb med [' + url + ']', error);
                }
                if (token && token.data && token.data.access_token) {
                    xhrq.setRequestHeader('Authorization', 'Bearer ' + token.data.access_token);
                }
            }
            return _super._sendRequest.call(this, xhrq, url, data, loadingBoxHandle, scopes);
        });
    }
}

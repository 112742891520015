import * as Redux from 'redux';
import ReduxThunk from 'redux-thunk';
import { CerumLastVisitedStore } from './cerum-lastvisited-store';
import { CerumMainUIStateStore } from './cerum-main-ui-state-store';
import { CerumNotificationStore } from './cerum-notification-store';
import { CerumPanelStore } from './cerum-panel-store';
import { CerumMainSearchStore } from './CerumMainSearchStore';
import { CerumProjectViewStateStore } from './CerumProjectViewStateStore';
import { CerumReduxSubStore } from './CerumReduxSubStore';
import { DashboardStateStore } from './DashboardStateStore';
import { PersistentSettingsStore } from './PersistentSettingsStore';
import { ProjectNewStore } from './ProjectNewStore';
import { CerumUserStore } from './CerumUserStore';
import { CerumChatStore } from './CerumChatStore';
import { CerumParticipantStore } from './CerumParticipantStore';
import { CerumOverviewStore } from './CerumOverviewStore';
import { ActiveDepartmentStore } from './ActiveDepartmentStore';
import { composeEnhancers } from 'cerum-redux-helper/ComposeEnhancers';
import { WorkSchedulePortletStore } from './WorkSchedulePortletStore';
export class CerumReduxStore {
    static getStateValueByPath(state, path) {
        const properties = Array.isArray(path) ? path : path.split('.');
        return properties.reduce((prev, curr) => {
            if (prev && curr in prev) {
                return prev[curr];
            }
            return undefined;
        }, state);
    }
    static getStoreValueByPath(store, path) {
        return this.getStateValueByPath(store.getState(), path);
    }
    static instance() {
        if (this._instance) {
            return this._instance;
        }
        const enhancers = composeEnhancers(Redux.applyMiddleware(ReduxThunk));
        const store = Redux.createStore(this.rootReducer(), enhancers);
        // For live debugging
        store._sub_stores = CerumReduxStore.global_sub_stores;
        store._CerumReduxStore = CerumReduxStore;
        this._instance = window.CERUM_REDUX_STORE = store;
        CerumReduxSubStore.rootstore.setValue(store);
        return store;
    }
    static isOuterFrame() {
        return window === top;
    }
    static rootReducer() {
        let reducer_map = {};
        const stores = this.isOuterFrame() ? this.global_sub_stores : this.inner_sub_stores;
        for (const store of stores) {
            reducer_map = Object.assign(Object.assign({}, reducer_map), store.instance.reducer());
        }
        return Redux.combineReducers(reducer_map);
    }
}
CerumReduxStore.global_sub_stores = [
    CerumMainUIStateStore,
    CerumNotificationStore,
    CerumMainSearchStore,
    CerumPanelStore,
    CerumLastVisitedStore,
    PersistentSettingsStore,
    CerumProjectViewStateStore,
    ProjectNewStore,
    DashboardStateStore,
    CerumUserStore,
    CerumChatStore,
    CerumParticipantStore,
    CerumOverviewStore,
    ActiveDepartmentStore,
    WorkSchedulePortletStore,
];
CerumReduxStore.inner_sub_stores = [CerumPanelStore];

import { SingletonInstanceOf } from 'badphraim/core/Singleton';
/**
 * Denne klassen brukes lenger ned. Den tar vare p� en eventhandler
 */
export class DataEventHandler {
    constructor(eventtype, closure) {
        this.eventtype = eventtype;
        this.closure = closure;
    }
    /**
     * Vil den her handlern ha den her eventen?
     */
    receivesEvent(_datatype, eventtype, _params) {
        if (this.eventtype === 0) {
            return true;
        }
        if (this.eventtype === '') {
            return true;
        }
        if (this.eventtype === eventtype) {
            return true;
        }
        return false;
    }
    /**
     * Trigg eventen
     */
    triggerEvent(datatype, eventtype, params) {
        this.closure(datatype, eventtype, params);
    }
}
export class DataEventsDispatcher {
    constructor() {
        this.eventListenerMap = {};
    }
    static instance() {
        return SingletonInstanceOf(DataEventsDispatcher);
    }
    /**
     * Registrer ny event-listener, og returner en id som kan brukes til � fjerne den
     */
    registerEventListener(closure, datatype, eventtype) {
        /**
         * Initisier hvis tom
         */
        if (typeof this.eventListenerMap[datatype] === 'undefined') {
            this.eventListenerMap[datatype] = [];
        }
        const eventMap = this.eventListenerMap[datatype];
        //		var offset = map.length;
        /**
         * Lagre eventclosure i en map
         */
        const offset = eventMap.push(new DataEventHandler(eventtype || '', closure)) - 1;
        return offset;
    }
    /**
     * Fjern event listener
     */
    removeEventListener(eventListenerId, datatype) {
        if (typeof this.eventListenerMap[datatype] === 'undefined') {
            return false;
        }
        const eventMap = this.eventListenerMap[datatype];
        if (!eventMap[eventListenerId]) {
            return false;
        }
        eventMap[eventListenerId] = null;
        // Vi kan ikke delete den, for da vil man kunne tildele samme id flere gang
        // Og da risikerer vi at andre sletter eventhandleren din
        //		delete map[event_listener_map];
        return true;
    }
    /**
     * Start en eventrunde...
     */
    triggerEvent(datatype, eventtype, params) {
        /**
         * Noen som vil ha varsel?
         */
        let eventMap = [];
        if (typeof this.eventListenerMap[datatype] !== 'undefined') {
            eventMap = this.eventListenerMap[datatype];
        }
        if (typeof this.eventListenerMap[0] !== 'undefined') {
            // Registrert p� datatype 0 ska ha ALLE eventan
            const allEventListeners = this.eventListenerMap[0];
            for (const i in allEventListeners) {
                const eventListener = allEventListeners[i];
                if (eventListener) {
                    eventMap.push(eventListener);
                }
            }
        }
        if (eventMap.length === 0) {
            return false;
        }
        /**
         * Send eventen tell alle som vil ha den...
         * @todo ska den her pr�v � va asynkron eller?
         */
        for (const i in eventMap) {
            try {
                const handler = eventMap[i];
                if (!handler) {
                    continue;
                }
                if (handler.receivesEvent(datatype, eventtype, params)) {
                    handler.triggerEvent(datatype, eventtype, params);
                }
            }
            catch (error) {
                console.error('Følgende debugmelding skyldes en exception kasta ved utsending av dataevent: ', [eventMap[i], error]);
            }
        }
        return true;
    }
}

var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { css, html, LitElement } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { LitReduxBoostPortalMixin } from '../../redux/LitReduxBoostPortalMixin';
import { vaadinSelectRenderer } from 'badphraim/ui/vaadinHtmlRendererWrapper';
import { CustomField } from '@vaadin/custom-field';
import { ListBox } from '@vaadin/list-box';
import { Select } from '@vaadin/select';
import { Item } from '@vaadin/item';
import { reduxBind } from 'badphraim/ui/LitReduxMixinBase';
import { BoostPortalStore } from '../../redux/BoostPortalStore';
let BoostDatePicker = class BoostDatePicker extends LitReduxBoostPortalMixin(LitElement) {
    static get is() {
        return 'boost-date-picker';
    }
    static get styles() {
        return css `
            .group vaadin-select:not(:last-child) {
                margin-right: 10px;
            }

            vaadin-select.day {
                --vaadin-text-field-default-width: 6em;
            }

            vaadin-select.month {
                --vaadin-text-field-default-width: 8.3em;
            }

            vaadin-select.year {
                --vaadin-text-field-default-width: 6em;
            }
        `;
    }
    get validationFields() {
        var _a;
        return (_a = this.shadowRoot) === null || _a === void 0 ? void 0 : _a.querySelectorAll('.validate');
    }
    constructor() {
        super();
        this.daysArray = [];
        this.monthsArray = [
            'Januar',
            'Februar',
            'Mars',
            'April',
            'Mai',
            'Juni',
            'Juli',
            'August',
            'September',
            'Oktober',
            'November',
            'Desember',
        ];
        this.yearsArray = [];
        this.disabled = false;
        this.loadYears();
    }
    /** @override */ render() {
        var _a, _b, _c, _d, _e, _f;
        return html `
            <div class="group" style="display: flex; margin-right: 10px;">
                <vaadin-custom-field
                    id="date"
                    label="Fødselsdato"
                    required
                    error-message="Fødselsdato må fylles ut"
                >
                    <vaadin-select
                        value=${(_b = (_a = this.date) === null || _a === void 0 ? void 0 : _a.date) !== null && _b !== void 0 ? _b : ''}
                        class="day validate"
                        placeholder="Dag"
                        @value-changed="${(event) => (this.dateDay = parseInt(event.detail.value, 10))}"
                        .renderer="${this._daysInMonthRenderer(this.daysArray)}"
                        required
                        ?disabled="${this.disabled}"
                    >
                    </vaadin-select>

                    <vaadin-select
                        value=${(_d = (_c = this.date) === null || _c === void 0 ? void 0 : _c.month) !== null && _d !== void 0 ? _d : ''}
                        class="month validate"
                        placeholder="Måned"
                        @value-changed="${(event) => {
            this.dateMonth = parseInt(event.detail.value, 10);
            this.daysArray = Array.from({ length: this._getDaysInMonth() });
        }}"
                        required
                        ?disabled="${this.disabled}"
                        .renderer=${vaadinSelectRenderer(html `
                            <vaadin-list-box>
                                ${Array.from({ length: 12 }).map((_, monthIndex) => html `<vaadin-item value="${monthIndex + 1}">
                                            ${this.monthsArray[monthIndex]}
                                        </vaadin-item>`)}
                            </vaadin-list-box>
                        `)}
                    >
                    </vaadin-select>

                    <vaadin-select
                        value=${(_f = (_e = this.date) === null || _e === void 0 ? void 0 : _e.year) !== null && _f !== void 0 ? _f : ''}
                        class="year validate"
                        placeholder="År"
                        @value-changed="${(event) => (this.dateYear = parseInt(event.detail.value, 10))}"
                        required
                        ?disabled="${this.disabled}"
                        .renderer=${vaadinSelectRenderer(html `
                            <vaadin-list-box>
                                ${this.yearsArray.map((year) => {
            return html `<vaadin-item value="${year}">${year}</vaadin-item>`;
        })}
                            </vaadin-list-box>
                        `)}
                    >
                    </vaadin-select>
                </vaadin-custom-field>
            </div>
        `;
    }
    validate() {
        var _a;
        let hasInvalidFields = false;
        (_a = this.validationFields) === null || _a === void 0 ? void 0 : _a.forEach((validationField) => {
            if (!validationField.validate()) {
                hasInvalidFields = true;
            }
        });
        return !hasInvalidFields;
    }
    loadYears() {
        for (let i = 0; i < 100; i++) {
            const date = new Date();
            const year = date.getFullYear() - i;
            const yearAsString = year.toString();
            this.yearsArray[i] = yearAsString;
        }
    }
    _getDaysInMonth() {
        var _a;
        switch ((_a = this.dateMonth) !== null && _a !== void 0 ? _a : 0) {
            case 4: // april
            case 6: // june
            case 9: // september
            case 11: // november
                return 30;
            case 2: // february
                return 28;
            case 1: // january
            case 3: // march
            case 5: // may
            case 7: // july
            case 8: // august
            case 10: // october
            case 12: // december
            default:
                return 31;
        }
    }
    /** @override */ updated(changedProperties) {
        changedProperties.forEach((_oldValue, propertyName) => {
            switch (propertyName) {
                case 'dateDay':
                case 'dateMonth':
                case 'dateYear':
                    this._dateObserver();
            }
        });
        super.updated(changedProperties);
    }
    _daysInMonthRenderer(daysArray) {
        return (root) => {
            if (root.firstChild) {
                return;
            }
            const listBox = document.createElement('vaadin-list-box');
            daysArray.forEach((_, day) => {
                const item = document.createElement('vaadin-item');
                item.setAttribute('value', String(day + 1));
                item.textContent = String(day + 1);
                listBox.appendChild(item);
            });
            root.appendChild(listBox);
        };
    }
    _dateObserver() {
        this.date = {
            date: this.dateDay,
            month: this.dateMonth,
            year: this.dateYear,
        };
        this.dispatchEvent(new CustomEvent('date-changed', {
            detail: {
                date: this.date,
            },
            bubbles: true,
            composed: true,
        }));
    }
};
BoostDatePicker._deps = [CustomField, Select, ListBox, Item];
__decorate([
    state(),
    __metadata("design:type", Array)
], BoostDatePicker.prototype, "daysArray", void 0);
__decorate([
    state(),
    __metadata("design:type", Array)
], BoostDatePicker.prototype, "monthsArray", void 0);
__decorate([
    state(),
    __metadata("design:type", Array)
], BoostDatePicker.prototype, "yearsArray", void 0);
__decorate([
    property({ type: Object }),
    __metadata("design:type", Object)
], BoostDatePicker.prototype, "date", void 0);
__decorate([
    state(),
    __metadata("design:type", Number)
], BoostDatePicker.prototype, "dateDay", void 0);
__decorate([
    state(),
    __metadata("design:type", Number)
], BoostDatePicker.prototype, "dateMonth", void 0);
__decorate([
    state(),
    __metadata("design:type", Number)
], BoostDatePicker.prototype, "dateYear", void 0);
__decorate([
    reduxBind(BoostPortalStore.path('session')),
    state(),
    __metadata("design:type", String)
], BoostDatePicker.prototype, "session", void 0);
__decorate([
    property({ type: Boolean }),
    __metadata("design:type", Boolean)
], BoostDatePicker.prototype, "disabled", void 0);
BoostDatePicker = __decorate([
    customElement(BoostDatePicker.is),
    __metadata("design:paramtypes", [])
], BoostDatePicker);
export { BoostDatePicker };

var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { BoostPortalTrackingNotification } from './BoostPortalTrackingNotification';
import { css, html, LitElement } from 'lit';
import { customElement, state } from 'lit/decorators.js';
import { TrackingAcceptanceStorage } from '../tracking/TrackingAcceptanceStorage';
import { BoostPortalStore } from '../redux/BoostPortalStore';
import { reduxBind } from 'badphraim/ui/LitReduxMixinBase';
import { LitReduxBoostPortalMixin } from '../redux/LitReduxBoostPortalMixin';
import { TrackingFactorySingleton } from '../tracking/TrackingFactorySingleton';
let ShowTrackingNotificationIfNeeded = class ShowTrackingNotificationIfNeeded extends LitReduxBoostPortalMixin(LitElement) {
    static get is() {
        return 'show-tracking-notification-if-needed';
    }
    static get styles() {
        return css ``;
    }
    constructor() {
        super();
        this.hasAcceptedTracking = new TrackingAcceptanceStorage().hasAccepted();
    }
    /** @override */ render() {
        if (!this.center) {
            return html ``;
        }
        const tracking = this.center.tracking;
        if (!tracking) {
            return html ``;
        }
        TrackingFactorySingleton.setTrackingSetup(tracking);
        if (!TrackingFactorySingleton.doesTracking()) {
            return html ``;
        }
        return html `
            ${this.hasAcceptedTracking === true || this.hasAcceptedTracking === false
            ? html ``
            : html `<boost-portal-tracking-notification></boost-portal-tracking-notification>`}
        `;
    }
};
ShowTrackingNotificationIfNeeded._deps = [BoostPortalTrackingNotification];
__decorate([
    reduxBind(BoostPortalStore.path('center')),
    state(),
    __metadata("design:type", Object)
], ShowTrackingNotificationIfNeeded.prototype, "center", void 0);
__decorate([
    state(),
    __metadata("design:type", Object)
], ShowTrackingNotificationIfNeeded.prototype, "hasAcceptedTracking", void 0);
ShowTrackingNotificationIfNeeded = __decorate([
    customElement(ShowTrackingNotificationIfNeeded.is),
    __metadata("design:paramtypes", [])
], ShowTrackingNotificationIfNeeded);
export { ShowTrackingNotificationIfNeeded };

var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { html } from 'lit';
import { property, state } from 'lit/decorators.js';
import { CerumEmpty } from './cerum-empty';
import { CerumLoading } from 'common-uicomponents/CerumLoading';
export function LoadingMixin(Base) {
    class Mixin extends Base {
        constructor() {
            super(...arguments);
            /** @override */ this.isLoading = true;
            /** @override */ this.showLoadingState = false;
        }
        /** @override */ showEmpty(item, type, title, actions) {
            let hasContents = false;
            if (item instanceof Array) {
                if (item.length > 0) {
                    hasContents = true;
                }
            }
            else if (item instanceof Object) {
                hasContents = true;
            }
            else if (typeof item === 'number' && item > 0) {
                hasContents = true;
            }
            if (!this.isLoading && !hasContents) {
                return html `
                    <cerum-empty type="${type}" title="${title}">
                        ${this.isLoading}${actions ? html ` <div slot="actions">${actions}</div> ` : html ``}
                    </cerum-empty>
                `;
            }
            return html ``;
        }
        /** @override */ showLoading() {
            if (this.showLoadingState) {
                return html ` <cerum-loading></cerum-loading> `;
            }
            return html ``;
        }
        /** @override */ updated(changedProperties) {
            changedProperties.forEach((_oldValue, propertyName) => {
                switch (propertyName) {
                    case 'isLoading':
                        this._isLoadingObserver();
                        break;
                }
            });
            super.updated(changedProperties);
        }
        _isLoadingObserver() {
            if (this.isLoading === true) {
                this.showLoadingStateTimeout = window.setTimeout(() => {
                    this.showLoadingState = true;
                }, 200);
            }
            else {
                this.showLoadingState = false;
                window.clearTimeout(this.showLoadingStateTimeout);
            }
        }
    }
    Mixin._deps = [CerumEmpty, CerumLoading];
    __decorate([
        property({ type: Boolean }),
        __metadata("design:type", Boolean)
    ], Mixin.prototype, "isLoading", void 0);
    __decorate([
        property({ type: Boolean }),
        __metadata("design:type", Boolean)
    ], Mixin.prototype, "showLoadingState", void 0);
    __decorate([
        state(),
        __metadata("design:type", Number)
    ], Mixin.prototype, "showLoadingStateTimeout", void 0);
    return Mixin;
}

/* tslint:disable: class-name */
/*
 * AUTOGENERATED FILE - DO NOT EDIT
 */
import { APIRestClient } from '../../../APIRestClient';
import { ewRestResourceHandlerVisitlogStructureAccess_JSON } from '../../../schemas/ewRestResourceHandlerVisitlogStructureAccess_JSON';
export class VisitlogAPI extends APIRestClient {
    /**
     * Hent ut siste n visning fra sys_name
     * Implementert hovedsakelig i *ewRestResourceHandlerVisitlogStructureAccess*::*read*
     */
    static getVisitlogUsingTypeCount(type, count) {
        return this._get('/visitlog/{type}/{count}', { type, count }, null, null, ewRestResourceHandlerVisitlogStructureAccess_JSON
            .classOf(ewRestResourceHandlerVisitlogStructureAccess_JSON, new ewRestResourceHandlerVisitlogStructureAccess_JSON())
            .arrayOf(), ['https://api.cerum.no/cerumx/login'], 'json');
    }
    /**
     * Logg siste n visning med sys_name
     * Implementert hovedsakelig i *ewRestResourceHandlerVisitlogStructureAccess*::*create*
     */
    static postVisitlogUsingTypeIdtypeId(type, idtype, id) {
        return this._post('/visitlog/{type}/{idtype}/{id}', { type, idtype, id }, null, null, APIRestClient.Native.string().hashMapOf(), ['https://api.cerum.no/cerumx/login'], 'json');
    }
}

var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
/* tslint:disable: class-name */
/*
 * AUTOGENERATED FILE - DO NOT EDIT
 */
import { ewDepartmentJSON } from './ewDepartmentJSON';
import { exAddress } from './exAddress';
import { exAddressWithCO } from './exAddressWithCO';
import { exPerson } from './exPerson';
import { JSONStruct, nullable, property } from 'badphraim/data/JSONStruct';
export class exContact extends JSONStruct {
    constructor() {
        super(...arguments);
        /** @override */ this.id = 0;
        /** @override */ this.structure_node_id = 0;
    }
}
__decorate([
    nullable,
    __metadata("design:type", exAddressWithCO)
], exContact.prototype, "billing_address", void 0);
__decorate([
    nullable,
    __metadata("design:type", Number)
], exContact.prototype, "billing_due_days", void 0);
__decorate([
    nullable,
    __metadata("design:type", String)
], exContact.prototype, "billing_email", void 0);
__decorate([
    nullable,
    __metadata("design:type", String)
], exContact.prototype, "billing_type", void 0);
__decorate([
    nullable,
    __metadata("design:type", Number)
], exContact.prototype, "client_category", void 0);
__decorate([
    nullable,
    __metadata("design:type", Number)
], exContact.prototype, "contact_keyword", void 0);
__decorate([
    nullable,
    __metadata("design:type", exPerson)
], exContact.prototype, "contact_manager", void 0);
__decorate([
    nullable,
    __metadata("design:type", Number)
], exContact.prototype, "contact_number", void 0);
__decorate([
    nullable,
    __metadata("design:type", Number)
], exContact.prototype, "contact_type", void 0);
__decorate([
    nullable,
    __metadata("design:type", Number)
], exContact.prototype, "country", void 0);
__decorate([
    nullable,
    __metadata("design:type", exAddress)
], exContact.prototype, "delivery_address", void 0);
__decorate([
    nullable,
    __metadata("design:type", ewDepartmentJSON)
], exContact.prototype, "department", void 0);
__decorate([
    nullable,
    __metadata("design:type", String)
], exContact.prototype, "email", void 0);
__decorate([
    property,
    __metadata("design:type", Number)
], exContact.prototype, "id", void 0);
__decorate([
    nullable,
    __metadata("design:type", String)
], exContact.prototype, "mobile", void 0);
__decorate([
    nullable,
    __metadata("design:type", String)
], exContact.prototype, "name", void 0);
__decorate([
    nullable,
    __metadata("design:type", String)
], exContact.prototype, "note", void 0);
__decorate([
    nullable,
    __metadata("design:type", String)
], exContact.prototype, "orgnr", void 0);
__decorate([
    nullable,
    __metadata("design:type", String)
], exContact.prototype, "phone", void 0);
__decorate([
    nullable,
    __metadata("design:type", exAddress)
], exContact.prototype, "postal_address", void 0);
__decorate([
    nullable,
    __metadata("design:type", Number)
], exContact.prototype, "status", void 0);
__decorate([
    property,
    __metadata("design:type", Number)
], exContact.prototype, "structure_node_id", void 0);
__decorate([
    nullable,
    __metadata("design:type", Number)
], exContact.prototype, "trade", void 0);
__decorate([
    nullable,
    __metadata("design:type", String)
], exContact.prototype, "web_site", void 0);

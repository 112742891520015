var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { css, html, LitElement } from 'lit';
import { customElement } from 'lit/decorators.js';
import { BoostStatus } from '../components/common/BoostStatus';
let BoostPortalInvoicePaymentSuccessView = class BoostPortalInvoicePaymentSuccessView extends LitElement {
    static get is() {
        return 'boost-portal-invoice-payment-success-view';
    }
    static get styles() {
        return css `
            span {
                font-size: 20px;
                color: #132c4f;
            }
        `;
    }
    /** @override */ render() {
        return html ` <div
            style="display:flex; margin-top:50px; align-items:center; flex-direction:column; text-align:center;"
        >
            <span
                >Hei! <br />Denne fakturaen er allerede betalt. <br />
                Ha en riktig fin dag!</span
            >
            <span style="color:#50c878; font-size:80px;">&#10003;</span>
        </div>`;
    }
};
BoostPortalInvoicePaymentSuccessView._deps = [BoostStatus];
BoostPortalInvoicePaymentSuccessView = __decorate([
    customElement(BoostPortalInvoicePaymentSuccessView.is)
], BoostPortalInvoicePaymentSuccessView);
export { BoostPortalInvoicePaymentSuccessView };

import { LoadingBox } from './LoadingBox';
export class LoadingBoxCerumX extends LoadingBox {
    /** @override */ _getLoadingDiv() {
        const documentElement = this.dom.getDocument();
        const div = documentElement.getElementById('_x_loading_div');
        if (typeof div !== 'undefined' && div) {
            return div;
        }
        const divElement = documentElement.createElement('div');
        divElement.setAttribute('id', '_x_loading_div');
        divElement.style.setProperty('display', 'none');
        divElement.style.setProperty('position', 'fixed');
        divElement.style.setProperty('top', '0');
        divElement.style.setProperty('left', '0');
        divElement.style.setProperty('right', '0');
        const progressElement = document.createElement('paper-progress');
        progressElement.setAttribute('indeterminate', '');
        progressElement.style.setProperty('--paper-progress-active-color', '#00adbb');
        progressElement.style.setProperty('--paper-progress-container-color', '#1d2939');
        progressElement.style.setProperty('width', '100%');
        divElement.appendChild(progressElement);
        documentElement.body.appendChild(divElement);
        return divElement;
    }
}

import { TypedLocalStorageAccess } from 'badphraim/dom/TypedLocalStorageAccess';
import { TrackingFactorySingleton } from './TrackingFactorySingleton';
/**
 * Hva har ansvaret for å sladre hjem til servern om at nå har enda et individ akseptert
 * eller ikke akseptert, slik at vi får måletall på det?
 */
export class TrackingAcceptanceStorage {
    constructor() {
        this.storage = new TypedLocalStorageAccess('boost_portal_tracking_accept_' + this.getCenterId());
    }
    getCenterId() {
        let path = document.location.pathname;
        while (path.startsWith('/')) {
            path = path.substring(1);
        }
        const slashAt = path.indexOf('/');
        if (slashAt >= 0) {
            path = path.substring(0, slashAt);
        }
        return path;
    }
    hasAccepted() {
        var _a;
        const value = (_a = this.storage.getData()) === null || _a === void 0 ? void 0 : _a.accepted;
        if (value !== undefined) {
            if (!TrackingAcceptanceStorage.notified) {
                TrackingAcceptanceStorage.notified = true;
                TrackingFactorySingleton.acceptanceStateReady(value);
            }
        }
        return value;
    }
    setAccepted(accepted) {
        this.storage.setData({
            accepted,
        });
        TrackingFactorySingleton.acceptanceStateReady(accepted);
    }
}
TrackingAcceptanceStorage.notified = false;

var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
/* tslint:disable: class-name */
/*
 * AUTOGENERATED FILE - DO NOT EDIT
 */
import { ewRestPersonRoleStatistics } from './ewRestPersonRoleStatistics';
import { exProjectRole } from './exProjectRole';
import { JSONStruct, array_property, nullable, property } from 'badphraim/data/JSONStruct';
export class exPerson extends JSONStruct {
    constructor() {
        super(...arguments);
        /** @override */ this.id = 0;
        /** @override */ this.last_used_roles = [];
        /** @override */ this.role_statistics = [];
        /** @override */ this.roles = [];
        /** @override */ this.structure_node_id = 0;
    }
}
__decorate([
    nullable,
    __metadata("design:type", String)
], exPerson.prototype, "address", void 0);
__decorate([
    nullable,
    __metadata("design:type", String)
], exPerson.prototype, "city", void 0);
__decorate([
    nullable,
    __metadata("design:type", String)
], exPerson.prototype, "date_of_birth", void 0);
__decorate([
    nullable,
    __metadata("design:type", String)
], exPerson.prototype, "email", void 0);
__decorate([
    nullable,
    __metadata("design:type", String)
], exPerson.prototype, "employee_no", void 0);
__decorate([
    nullable,
    __metadata("design:type", String)
], exPerson.prototype, "first_name", void 0);
__decorate([
    property,
    __metadata("design:type", Number)
], exPerson.prototype, "id", void 0);
__decorate([
    nullable,
    __metadata("design:type", String)
], exPerson.prototype, "information", void 0);
__decorate([
    nullable,
    __metadata("design:type", String)
], exPerson.prototype, "last_name", void 0);
__decorate([
    array_property(exProjectRole),
    __metadata("design:type", Array)
], exPerson.prototype, "last_used_roles", void 0);
__decorate([
    nullable,
    __metadata("design:type", String)
], exPerson.prototype, "mobile", void 0);
__decorate([
    nullable,
    __metadata("design:type", String)
], exPerson.prototype, "person_name", void 0);
__decorate([
    nullable,
    __metadata("design:type", String)
], exPerson.prototype, "phone", void 0);
__decorate([
    nullable,
    __metadata("design:type", String)
], exPerson.prototype, "postal_code", void 0);
__decorate([
    array_property(ewRestPersonRoleStatistics),
    __metadata("design:type", Array)
], exPerson.prototype, "role_statistics", void 0);
__decorate([
    array_property(String),
    __metadata("design:type", Array)
], exPerson.prototype, "roles", void 0);
__decorate([
    nullable,
    __metadata("design:type", Boolean)
], exPerson.prototype, "send_mail", void 0);
__decorate([
    nullable,
    __metadata("design:type", Boolean)
], exPerson.prototype, "send_sms", void 0);
__decorate([
    property,
    __metadata("design:type", Number)
], exPerson.prototype, "structure_node_id", void 0);

/* tslint:disable: class-name */
/*
 * AUTOGENERATED FILE - DO NOT EDIT
 */
import { APIRestClient } from '../../../APIRestClient';
import { ProjectDeadline } from '../../../schemas/ProjectDeadline';
import { ewExpenseJson } from '../../../schemas/ewExpenseJson';
import { ewJSONProjectActivity } from '../../../schemas/ewJSONProjectActivity';
import { ewProject2_JSON } from '../../../schemas/ewProject2_JSON';
import { ewProjectCheckpointsJSON } from '../../../schemas/ewProjectCheckpointsJSON';
import { ewProjectDeadlinesJSON } from '../../../schemas/ewProjectDeadlinesJSON';
import { ewProjectProcessSummary_JSON } from '../../../schemas/ewProjectProcessSummary_JSON';
import { ewProjectStatisticsGlueResultChecklistsPerProject } from '../../../schemas/ewProjectStatisticsGlueResultChecklistsPerProject';
import { ewProjectStatisticsGlueResultTasksPerProject } from '../../../schemas/ewProjectStatisticsGlueResultTasksPerProject';
import { ewProjectStatisticsPhotosResponse } from '../../../schemas/ewProjectStatisticsPhotosResponse';
import { ewProjectStatisticsProjectState } from '../../../schemas/ewProjectStatisticsProjectState';
import { ewProjectStatisticsResulCheckins_PerProject } from '../../../schemas/ewProjectStatisticsResulCheckins_PerProject';
import { ewProjectStatisticsResultChecklists } from '../../../schemas/ewProjectStatisticsResultChecklists';
import { ewProjectStatisticsResultContracts } from '../../../schemas/ewProjectStatisticsResultContracts';
import { ewProjectStatisticsResultHours } from '../../../schemas/ewProjectStatisticsResultHours';
import { ewProjectStatisticsResultNonConformities } from '../../../schemas/ewProjectStatisticsResultNonConformities';
import { ewProjectStatisticsResultNonConformitiesWithProject } from '../../../schemas/ewProjectStatisticsResultNonConformitiesWithProject';
import { ewProjectStatisticsResultTasks } from '../../../schemas/ewProjectStatisticsResultTasks';
import { ewProjectsStatisticsCheckinsProject } from '../../../schemas/ewProjectsStatisticsCheckinsProject';
import { ewRestProjectActivity } from '../../../schemas/ewRestProjectActivity';
import { ewRestProjectsStatisticsTasks } from '../../../schemas/ewRestProjectsStatisticsTasks';
import { ewRestProjectsStructureHandlerResponse } from '../../../schemas/ewRestProjectsStructureHandlerResponse';
import { ewRestRequestHandlerProjectProcess_PhaseDocumentRoot } from '../../../schemas/ewRestRequestHandlerProjectProcess_PhaseDocumentRoot';
import { ewRestRequestHandlerProjectProcess_PhaseMilestoneRoot } from '../../../schemas/ewRestRequestHandlerProjectProcess_PhaseMilestoneRoot';
import { ewRestRequestHandlerProjectProcess_PhaseRoot } from '../../../schemas/ewRestRequestHandlerProjectProcess_PhaseRoot';
import { ewRestRequestHandlerProjectProcess_PhaseTemplateRoot } from '../../../schemas/ewRestRequestHandlerProjectProcess_PhaseTemplateRoot';
import { ewRestRequestHandlerProjectStatistics_innsjekk_JSON } from '../../../schemas/ewRestRequestHandlerProjectStatistics_innsjekk_JSON';
import { ewRestRequestHandlerProjectView_DATA } from '../../../schemas/ewRestRequestHandlerProjectView_DATA';
import { ewRestRequestHandlerV1ProjectProcess_PhaseTemplate } from '../../../schemas/ewRestRequestHandlerV1ProjectProcess_PhaseTemplate';
import { ewRestRequestHandlerV1ProjectProcess_PhaseTemplateRoot } from '../../../schemas/ewRestRequestHandlerV1ProjectProcess_PhaseTemplateRoot';
import { ewRestRequestLogHandlerResponse } from '../../../schemas/ewRestRequestLogHandlerResponse';
import { exCheckin } from '../../../schemas/exCheckin';
import { exItem } from '../../../schemas/exItem';
import { exProject } from '../../../schemas/exProject';
import { exProjectArray } from '../../../schemas/exProjectArray';
import { exProjectProcessRoot } from '../../../schemas/exProjectProcessRoot';
import { exProjectRelation } from '../../../schemas/exProjectRelation';
export class ProjectAPI extends APIRestClient {
    /**
     * slette deltaker p� prosjekt
     * Implementert hovedsakelig i *ewRestRequestV1ProjectsParticipants*::*delete*
     */
    static deleteV1ProjectsParticipantsUsingProjectIdParticipantId(project_id, participant_id) {
        return this._delete('/v1/projects/{project_id}/participants/{participant_id}', { project_id, participant_id }, null, null, APIRestClient.Native.string(), ['https://api.cerum.no/cerumx/login'], 'json');
    }
    /**
     * Hent ut siste dokumenter for prosjekt?
     * Implementert hovedsakelig i *ewRestRequestHandlerProjectActivity*::*read*
     */
    static getProjectActivity(__QUERY) {
        return this._get('/project/activity', {}, __QUERY, null, ewJSONProjectActivity.classOf(ewJSONProjectActivity, new ewJSONProjectActivity()).arrayOf(), ['https://api.cerum.no/cerumx/login'], 'json');
    }
    /**
     * Hent ut prosjektdata
     * Implementert hovedsakelig i *ewRestRequestHandlerProjectDetail*::*read*
     */
    static getProjectDetailUsingProjectId(project_id) {
        return this._get('/project/detail/{project_id}', { project_id }, null, null, ewProject2_JSON.classOf(ewProject2_JSON, new ewProject2_JSON()), ['https://api.cerum.no/cerumx/login'], 'json');
    }
    /**
     * Hent prosjekt bilde
     * Implementert hovedsakelig i *ewRestRequestHandlerProjectPhoto*::*read*
     */
    static getProjectPhotoUsingTypeDataid(type, dataid, __QUERY) {
        return this._get('/project/photo/{type}/{dataid}', { type, dataid }, __QUERY, null, APIRestClient.Native.Blob(), ['https://api.cerum.no/cerumx/login'], 'blob');
    }
    /**
     * Hent ut dokumenter
     * Implementert hovedsakelig i *ewRestRequestHandlerProjectProcess*::*readProjectProcessPhaseDocuments*
     * ;
     */
    static getProjectProcessPhaseDocumentsUsingProjectIdPhaseId(project_id, phase_id) {
        return this._get('/project/process/{project_id}/phase/{phase_id}/documents', { project_id, phase_id }, null, null, ewRestRequestHandlerProjectProcess_PhaseDocumentRoot.classOf(ewRestRequestHandlerProjectProcess_PhaseDocumentRoot, new ewRestRequestHandlerProjectProcess_PhaseDocumentRoot()), ['https://api.cerum.no/cerumx/login'], 'json');
    }
    /**
     * Hent ut milestones
     * Implementert hovedsakelig i *ewRestRequestHandlerProjectProcess*::*readProjectProcessPhaseMilestones*
     * ;
     */
    static getProjectProcessPhaseMilestonesUsingProjectIdPhaseId(project_id, phase_id) {
        return this._get('/project/process/{project_id}/phase/{phase_id}/milestones', { project_id, phase_id }, null, null, ewRestRequestHandlerProjectProcess_PhaseMilestoneRoot.classOf(ewRestRequestHandlerProjectProcess_PhaseMilestoneRoot, new ewRestRequestHandlerProjectProcess_PhaseMilestoneRoot()), ['https://api.cerum.no/cerumx/login'], 'json');
    }
    /**
     * Hent ut fremdrift
     * Implementert hovedsakelig i *ewRestRequestHandlerProjectProcess*::*readProjectProcessPhaseProgress*
     * ;
     */
    static getProjectProcessPhaseProgressUsingProjectIdPhaseId(project_id, phase_id) {
        return this._get('/project/process/{project_id}/phase/{phase_id}/progress', { project_id, phase_id }, null, null, ewProjectProcessSummary_JSON.classOf(ewProjectProcessSummary_JSON, new ewProjectProcessSummary_JSON()), ['https://api.cerum.no/cerumx/login'], 'json');
    }
    /**
     * Hent ut maler
     * Implementert hovedsakelig i *ewRestRequestHandlerProjectProcess*::*readProjectProcessPhaseTemplates*
     * ;
     */
    static getProjectProcessPhaseTemplatesUsingProjectIdPhaseId(project_id, phase_id) {
        return this._get('/project/process/{project_id}/phase/{phase_id}/templates', { project_id, phase_id }, null, null, ewRestRequestHandlerProjectProcess_PhaseTemplateRoot.classOf(ewRestRequestHandlerProjectProcess_PhaseTemplateRoot, new ewRestRequestHandlerProjectProcess_PhaseTemplateRoot()), ['https://api.cerum.no/cerumx/login'], 'json');
    }
    /**
     * Hent ut faser
     * Implementert hovedsakelig i *ewRestRequestHandlerProjectProcess*::*readProjectProcessPhases*
     */
    static getProjectProcessPhasesUsingProjectId(project_id, __QUERY) {
        return this._get('/project/process/{project_id}/phases', { project_id }, __QUERY, null, ewRestRequestHandlerProjectProcess_PhaseRoot.classOf(ewRestRequestHandlerProjectProcess_PhaseRoot, new ewRestRequestHandlerProjectProcess_PhaseRoot()), ['https://api.cerum.no/cerumx/login'], 'json');
    }
    /**
     * Hent ut innsjekk gruppert p� tid
     * Implementert hovedsakelig i *ewRestRequestHandlerProjectStatistics*::*readCheckins*
     */
    static getProjectStatisticsCheckins(__QUERY) {
        return this._get('/project/statistics/checkins', {}, __QUERY, null, ewProjectsStatisticsCheckinsProject.classOf(ewProjectsStatisticsCheckinsProject, new ewProjectsStatisticsCheckinsProject()), ['https://api.cerum.no/cerumx/login'], 'json');
    }
    /**
     * Hent ut innsjekk gruppert p� tid og prosjekt
     * Implementert hovedsakelig i *ewRestRequestHandlerProjectStatistics*::*readCheckinsPerProject*
     */
    static getProjectStatisticsCheckinsperproject(__QUERY) {
        return this._get('/project/statistics/checkinsperproject', {}, __QUERY, null, ewProjectStatisticsResulCheckins_PerProject.classOf(ewProjectStatisticsResulCheckins_PerProject, new ewProjectStatisticsResulCheckins_PerProject()), ['https://api.cerum.no/cerumx/login'], 'json');
    }
    /**
     * Hent ut sjekklister
     * Implementert hovedsakelig i *ewRestRequestHandlerProjectStatistics*::*readChecklists*
     */
    static getProjectStatisticsChecklists(__QUERY) {
        return this._get('/project/statistics/checklists', {}, __QUERY, null, ewProjectStatisticsResultChecklists.classOf(ewProjectStatisticsResultChecklists, new ewProjectStatisticsResultChecklists()), ['https://api.cerum.no/cerumx/login'], 'json');
    }
    /**
     * Hent ut sjekklister
     * Implementert hovedsakelig i *ewRestRequestHandlerProjectStatistics*::*readChecklistsPerProject*
     */
    static getProjectStatisticsChecklistsperproject(__QUERY) {
        return this._get('/project/statistics/checklistsperproject', {}, __QUERY, null, ewProjectStatisticsGlueResultChecklistsPerProject
            .classOf(ewProjectStatisticsGlueResultChecklistsPerProject, new ewProjectStatisticsGlueResultChecklistsPerProject())
            .arrayOf(), ['https://api.cerum.no/cerumx/login'], 'json');
    }
    /**
     * Hent ut kontraktstatistikk
     * Implementert hovedsakelig i *ewRestRequestHandlerProjectStatistics*::*readContracts*
     */
    static getProjectStatisticsContracts(__QUERY) {
        return this._get('/project/statistics/contracts', {}, __QUERY, null, ewProjectStatisticsResultContracts.classOf(ewProjectStatisticsResultContracts, new ewProjectStatisticsResultContracts()), ['https://api.cerum.no/cerumx/login'], 'json');
    }
    /**
     * Hent ut avviksstatistikk
     * Implementert hovedsakelig i *ewRestRequestHandlerProjectStatistics*::*readDeviations*
     */
    static getProjectStatisticsDeviations(__QUERY) {
        return this._get('/project/statistics/deviations', {}, __QUERY, null, ewProjectStatisticsResultNonConformities.classOf(ewProjectStatisticsResultNonConformities, new ewProjectStatisticsResultNonConformities()), ['https://api.cerum.no/cerumx/login'], 'json');
    }
    /**
     * Hent ut avviksstatistikk med prosjekter
     * Implementert hovedsakelig i *ewRestRequestHandlerProjectStatistics*::*readDeviationsPerProject*
     */
    static getProjectStatisticsDeviationsperproject(__QUERY) {
        return this._get('/project/statistics/deviationsperproject', {}, __QUERY, null, ewProjectStatisticsResultNonConformitiesWithProject
            .classOf(ewProjectStatisticsResultNonConformitiesWithProject, new ewProjectStatisticsResultNonConformitiesWithProject())
            .arrayOf(), ['https://api.cerum.no/cerumx/login'], 'json');
    }
    /**
     * Hent ut timer
     * Implementert hovedsakelig i *ewRestRequestHandlerProjectStatistics*::*readHours*
     */
    static getProjectStatisticsHours(__QUERY) {
        return this._get('/project/statistics/hours', {}, __QUERY, null, ewProjectStatisticsResultHours.classOf(ewProjectStatisticsResultHours, new ewProjectStatisticsResultHours()), ['https://api.cerum.no/cerumx/login'], 'json');
    }
    /**
     * Hent ut innsjekk gruppert p� variant
     * Implementert hovedsakelig i *ewRestRequestHandlerProjectStatistics*::*readInnsjekk*
     */
    static getProjectStatisticsInnsjekk(__QUERY) {
        return this._get('/project/statistics/innsjekk', {}, __QUERY, null, ewRestRequestHandlerProjectStatistics_innsjekk_JSON.classOf(ewRestRequestHandlerProjectStatistics_innsjekk_JSON, new ewRestRequestHandlerProjectStatistics_innsjekk_JSON()), ['https://api.cerum.no/cerumx/login'], 'json');
    }
    /**
     * Hent ut status-per-phase
     * Implementert hovedsakelig i *ewRestRequestHandlerProjectStatistics*::*readPhaseState*
     */
    static getProjectStatisticsPhasestate(__QUERY) {
        return this._get('/project/statistics/phasestate', {}, __QUERY, null, ewProjectStatisticsProjectState
            .classOf(ewProjectStatisticsProjectState, new ewProjectStatisticsProjectState())
            .arrayOf(), ['https://api.cerum.no/cerumx/login'], 'json');
    }
    /**
     * Hent ut photoer
     * Implementert hovedsakelig i *ewRestRequestHandlerProjectStatistics*::*readPhotos*
     */
    static getProjectStatisticsPhotos(__QUERY) {
        return this._get('/project/statistics/photos', {}, __QUERY, null, ewProjectStatisticsPhotosResponse.classOf(ewProjectStatisticsPhotosResponse, new ewProjectStatisticsPhotosResponse()), ['https://api.cerum.no/cerumx/login'], 'json');
    }
    /**
     * Hent ut oppgavestatistikk
     * Implementert hovedsakelig i *ewRestRequestHandlerProjectStatistics*::*readTasks*
     */
    static getProjectStatisticsTasks(__QUERY) {
        return this._get('/project/statistics/tasks', {}, __QUERY, null, ewProjectStatisticsResultTasks.classOf(ewProjectStatisticsResultTasks, new ewProjectStatisticsResultTasks()), ['https://api.cerum.no/cerumx/login'], 'json');
    }
    /**
     * Hent ut oppgavestatistikk
     * Implementert hovedsakelig i *ewRestRequestHandlerProjectStatistics*::*readTasksPerProject*
     */
    static getProjectStatisticsTasksperproject(__QUERY) {
        return this._get('/project/statistics/tasksperproject', {}, __QUERY, null, ewProjectStatisticsGlueResultTasksPerProject
            .classOf(ewProjectStatisticsGlueResultTasksPerProject, new ewProjectStatisticsGlueResultTasksPerProject())
            .arrayOf(), ['https://api.cerum.no/cerumx/login'], 'json');
    }
    /**
     * Hent ut prosjektdata
     * Implementert hovedsakelig i *ewRestRequestHandlerProjectView*::*read*
     */
    static getProjectViewUsingProjectId(project_id) {
        return this._get('/project/view/{project_id}', { project_id }, null, null, ewRestRequestHandlerProjectView_DATA.classOf(ewRestRequestHandlerProjectView_DATA, new ewRestRequestHandlerProjectView_DATA()), ['https://api.cerum.no/cerumx/login'], 'json');
    }
    /**
     * Hent deadlines
     * Implementert hovedsakelig i *ewRestResourceProjectDeadlines*::*read*
     */
    static getV1Projectdeadlines(__QUERY) {
        return this._get('/v1/projectdeadlines', {}, __QUERY, null, ProjectDeadline.classOf(ProjectDeadline, new ProjectDeadline()).arrayOf(), ['https://api.cerum.no/cerumx/login'], 'json');
    }
    /**
     * Get all projects that the user has access to
     * Implementert hovedsakelig i *ewRestRequestProject*::*read*
     */
    static getV1Projects(__QUERY) {
        return this._get('/v1/projects', {}, __QUERY, null, exProjectArray.classOf(exProjectArray, new exProjectArray()), ['https://api.cerum.no/cerumx/login'], 'json');
    }
    /**
     * Checkins for project
     * Implementert hovedsakelig i *ewRestRequestV1ProjectCheckinPdf*::*read*
     */
    static getV1ProjectsCheckinpdfUsingProjectId(project_id, __QUERY) {
        return this._get('/v1/projects/{project_id}/checkinpdf', { project_id }, __QUERY, null, APIRestClient.Native.Blob(), ['https://api.cerum.no/cerumx/login'], 'blob');
    }
    /**
     * Checkins for project
     * Implementert hovedsakelig i *ewRestRequestV1ProjectCheckin*::*read*
     */
    static getV1ProjectsCheckinsUsingProjectId(project_id, __QUERY) {
        return this._get('/v1/projects/{project_id}/checkins', { project_id }, __QUERY, null, exCheckin.classOf(exCheckin, new exCheckin()).arrayOf(), ['https://api.cerum.no/cerumx/login'], 'json');
    }
    /**
     * Hent ut expenses
     * Implementert hovedsakelig i *ewRestRequestV1ProjectsExpenses*::*read*
     */
    static getV1ProjectsExpensesUsingProjectId(project_id, __QUERY) {
        return this._get('/v1/projects/{project_id}/expenses', { project_id }, __QUERY, null, ewExpenseJson.classOf(ewExpenseJson, new ewExpenseJson()).arrayOf(), ['https://api.cerum.no/cerumx/login'], 'json');
    }
    /**
     * Hent ut mappestruktur til Prosjekt
     * Implementert hovedsakelig i *ewRestRequestV1ProjectsFolders*::*read*
     */
    static getV1ProjectsFoldersUsingProjectId(project_id) {
        return this._get('/v1/projects/{project_id}/folders', { project_id }, null, null, ewRestProjectsStructureHandlerResponse.classOf(ewRestProjectsStructureHandlerResponse, new ewRestProjectsStructureHandlerResponse()), ['https://api.cerum.no/cerumx/login'], 'json');
    }
    /**
     * Hent ut prosjektlog
     * Implementert hovedsakelig i *ewRestRequestV1ProjectsHistory*::*read*
     */
    static getV1ProjectsHistoryUsingProjectId(project_id) {
        return this._get('/v1/projects/{project_id}/history', { project_id }, null, null, ewRestRequestLogHandlerResponse
            .classOf(ewRestRequestLogHandlerResponse, new ewRestRequestLogHandlerResponse())
            .arrayOf(), ['https://api.cerum.no/cerumx/login'], 'json');
    }
    /**
     * Hent ut bilde et prosjekt
     * Implementert hovedsakelig i *ewRestRequestV1ProjectsImage*::*read*
     */
    static getV1ProjectsImageUsingProjectId(project_id) {
        return this._get('/v1/projects/{project_id}/image', { project_id }, null, null, APIRestClient.Native.Blob(), ['https://api.cerum.no/cerumx/login'], 'blob');
    }
    /**
     * Hent ut deltakere i Prosjekt
     * Implementert hovedsakelig i *ewRestRequestV1ProjectsParticipants*::*read*
     */
    static getV1ProjectsParticipantsUsingProjectId(project_id) {
        return this._get('/v1/projects/{project_id}/participants', { project_id }, null, null, exProjectRelation.classOf(exProjectRelation, new exProjectRelation()).arrayOf(), ['https://api.cerum.no/cerumx/login'], 'json');
    }
    /**
     * Activities for phase
     * Implementert hovedsakelig i *ewRestRequestV1ProjectPhaseActivities*::*read*
     */
    static getV1ProjectsPhasesActivitiesUsingProjectIdPhaseId(project_id, phase_id) {
        return this._get('/v1/projects/{project_id}/phases/{phase_id}/activities', { project_id, phase_id }, null, null, ewRestProjectActivity.classOf(ewRestProjectActivity, new ewRestProjectActivity()).arrayOf(), ['https://api.cerum.no/cerumx/login'], 'json');
    }
    /**
     * Deadlines for phase
     * Implementert hovedsakelig i *ewRestRequestV1ProjectPhaseCheckpoints*::*read*
     */
    static getV1ProjectsPhasesCheckpointsUsingProjectIdPhaseId(project_id, phase_id) {
        return this._get('/v1/projects/{project_id}/phases/{phase_id}/checkpoints', { project_id, phase_id }, null, null, ewProjectCheckpointsJSON
            .classOf(ewProjectCheckpointsJSON, new ewProjectCheckpointsJSON())
            .arrayOf(), ['https://api.cerum.no/cerumx/login'], 'json');
    }
    /**
     * Deadlines for phase
     * Implementert hovedsakelig i *ewRestRequestV1ProjectPhaseDeadlines*::*read*
     */
    static getV1ProjectsPhasesDeadlinesUsingProjectIdPhaseId(project_id, phase_id) {
        return this._get('/v1/projects/{project_id}/phases/{phase_id}/deadlines', { project_id, phase_id }, null, null, ewProjectDeadlinesJSON.classOf(ewProjectDeadlinesJSON, new ewProjectDeadlinesJSON()).arrayOf(), ['https://api.cerum.no/cerumx/login'], 'json');
    }
    /**
     * Progress for phase
     * Implementert hovedsakelig i *ewRestRequestV1ProjectPhaseProgress*::*read*
     */
    static getV1ProjectsPhasesProgressUsingProjectIdPhaseId(project_id, phase_id) {
        return this._get('/v1/projects/{project_id}/phases/{phase_id}/progress', { project_id, phase_id }, null, null, exProjectProcessRoot.classOf(exProjectProcessRoot, new exProjectProcessRoot()), ['https://api.cerum.no/cerumx/login'], 'json');
    }
    /**
     * Templates for phase
     * Implementert hovedsakelig i *ewRestRequestV1ProjectPhaseTemplates*::*read*
     */
    static getV1ProjectsPhasesTemplatesUsingProjectIdPhaseId(project_id, phase_id) {
        return this._get('/v1/projects/{project_id}/phases/{phase_id}/templates', { project_id, phase_id }, null, null, ewRestRequestHandlerV1ProjectProcess_PhaseTemplateRoot.classOf(ewRestRequestHandlerV1ProjectProcess_PhaseTemplateRoot, new ewRestRequestHandlerV1ProjectProcess_PhaseTemplateRoot()), ['https://api.cerum.no/cerumx/login'], 'json');
    }
    /**
     * Get project poster
     * Implementert hovedsakelig i *ewRestRequestV1ProjectPoster*::*read*
     */
    static getV1ProjectsPosterUsingProjectId(project_id, __QUERY) {
        return this._get('/v1/projects/{project_id}/poster', { project_id }, __QUERY, null, APIRestClient.Native.Blob(), ['https://api.cerum.no/cerumx/login'], 'blob');
    }
    /**
     * Progress summary
     * Implementert hovedsakelig i *ewRestRequestV1ProjectProgressSummary*::*read*
     */
    static getV1ProjectsProgress_summaryUsingProjectId(project_id, __QUERY) {
        return this._get('/v1/projects/{project_id}/progress_summary', { project_id }, __QUERY, null, ewProjectProcessSummary_JSON
            .classOf(ewProjectProcessSummary_JSON, new ewProjectProcessSummary_JSON())
            .arrayOf(), ['https://api.cerum.no/cerumx/login'], 'json');
    }
    /**
     * Hent ut innsjekk-statistikk for et Prosjekt
     * Implementert hovedsakelig i *ewRestRequestV1ProjectsStatisticsCheckins*::*read*
     */
    static getV1ProjectsStatisticsCheckinsUsingProjectId(project_id) {
        return this._get('/v1/projects/{project_id}/statistics/checkins', { project_id }, null, null, ewProjectsStatisticsCheckinsProject.classOf(ewProjectsStatisticsCheckinsProject, new ewProjectsStatisticsCheckinsProject()), ['https://api.cerum.no/cerumx/login'], 'json');
    }
    /**
     * Hent ut oppgaver-statistikk for et Prosjekt
     * Implementert hovedsakelig i *ewRestRequestV1ProjectsStatisticsTasks*::*read*
     */
    static getV1ProjectsStatisticsTasksUsingProjectId(project_id) {
        return this._get('/v1/projects/{project_id}/statistics/tasks', { project_id }, null, null, ewRestProjectsStatisticsTasks.classOf(ewRestProjectsStatisticsTasks, new ewRestProjectsStatisticsTasks()), ['https://api.cerum.no/cerumx/login'], 'json');
    }
    /**
     * Hent ut et prosjekt
     * Implementert hovedsakelig i *ewRestRequestV1Projects*::*read*
     */
    static getV1ProjectsUsingProjectId(project_id) {
        return this._get('/v1/projects/{project_id}', { project_id }, null, null, exProject.classOf(exProject, new exProject()), ['https://api.cerum.no/cerumx/login'], 'json');
    }
    /**
     * Endre et prosjekt
     * Implementert hovedsakelig i *ewRestRequestV1Projects*::*patch*
     */
    static patchV1ProjectsUsingProjectId(project_id, __DATA) {
        return this._patch('/v1/projects/{project_id}', { project_id }, null, JSON.stringify(__DATA), exProject.classOf(exProject, new exProject()), ['https://api.cerum.no/cerumx/login'], 'json');
    }
    /**
     * Create project
     * Implementert hovedsakelig i *ewRestRequestProject*::*create*
     */
    static postV1Projects(__DATA) {
        return this._post('/v1/projects', {}, null, JSON.stringify(__DATA), exProject.classOf(exProject, new exProject()), ['https://api.cerum.no/cerumx/login'], 'json');
    }
    /**
     * Legge til deltakere i Prosjekt
     * Implementert hovedsakelig i *ewRestRequestV1ProjectsParticipants*::*create*
     */
    static postV1ProjectsParticipantsUsingProjectId(project_id, __QUERY) {
        return this._post('/v1/projects/{project_id}/participants', { project_id }, __QUERY, null, exProjectRelation.classOf(exProjectRelation, new exProjectRelation()), ['https://api.cerum.no/cerumx/login'], 'json');
    }
    /**
     * Update project photo
     * Implementert hovedsakelig i *ewRestRequestV1ProjectPhoto*::*create*
     */
    static postV1ProjectsPhotoUsingProjectId(project_id, __DATA) {
        return this._post('/v1/projects/{project_id}/photo', { project_id }, null, __DATA, APIRestClient.Native.string(), ['https://api.cerum.no/cerumx/login'], 'json');
    }
    /**
     * Endre synligheten til en template i en prosjekth�ndbok
     * Implementert hovedsakelig i *ewRestRequestV1ProjectDocHandbook*::*update*
     */
    static putV1ProjectsDochandbookUsingProjectIdHandbookId(project_id, handbook_id, __DATA) {
        return this._put('/v1/projects/{project_id}/dochandbook/{handbook_id}', { project_id, handbook_id }, null, JSON.stringify(__DATA), ewRestRequestHandlerV1ProjectProcess_PhaseTemplate.classOf(ewRestRequestHandlerV1ProjectProcess_PhaseTemplate, new ewRestRequestHandlerV1ProjectProcess_PhaseTemplate()), ['https://api.cerum.no/cerumx/login'], 'json');
    }
    /**
     * Endre deltaker p� prosjekt
     * Implementert hovedsakelig i *ewRestRequestV1ProjectsParticipants*::*update*
     */
    static putV1ProjectsParticipantsUsingProjectIdParticipantId(project_id, participant_id, __QUERY) {
        return this._put('/v1/projects/{project_id}/participants/{participant_id}', { project_id, participant_id }, __QUERY, null, exProjectRelation.classOf(exProjectRelation, new exProjectRelation()), ['https://api.cerum.no/cerumx/login'], 'json');
    }
    /**
     * Oppbygning av ny prosjekth�ndbok
     * Implementert hovedsakelig i *ewRestRequestV1ProjectRebuildHandbook*::*update*
     */
    static putV1ProjectsRebuild_handbookUsingProjectId(project_id, __QUERY) {
        return this._put('/v1/projects/{project_id}/rebuild_handbook', { project_id }, __QUERY, null, exItem.classOf(exItem, new exItem()).arrayOf(), ['https://api.cerum.no/cerumx/login'], 'json');
    }
    /**
     * Endre et prosjekt
     * Implementert hovedsakelig i *ewRestRequestV1Projects*::*update*
     */
    static putV1ProjectsUsingProjectId(project_id, __DATA) {
        return this._put('/v1/projects/{project_id}', { project_id }, null, JSON.stringify(__DATA), exProject.classOf(exProject, new exProject()), ['https://api.cerum.no/cerumx/login'], 'json');
    }
}

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { ExceptionHandler } from '../core/ExceptionHandler';
import { SingletonInstanceLike } from '../core/Singleton';
import { Dom } from '../dom/Dom';
import { AjaxDelegate } from './AjaxDelegate';
import { PolyfillHeaders, PolyfillResponse } from './AjaxPolyfill';
import { LoadingBoxVoid } from './LoadingBoxVoid';
export class HTTPError {
    constructor(xhr, response) {
        this.xhr = xhr;
        this.response = response;
        this.message = xhr ? xhr.statusText : 'Unknown HTTP Error';
        this.name = this.constructor.name;
    }
}
export class HTTP500Error extends HTTPError {
}
export class HTTP401Error extends HTTPError {
}
export class HTTP403Error extends HTTPError {
}
export class HTTP404Error extends HTTPError {
}
export class HTTPUnknownError extends HTTPError {
}
export class Ajax {
    static stdInstance() {
        return SingletonInstanceLike(Ajax, () => {
            return new Ajax(new Dom(document));
        });
    }
    constructor(dom) {
        this.dom = dom;
        this.loading = 0;
    }
    _dispatchResponseFromXHR(xhrq) {
        const respInit = {
            headers: this._parseHeaders(xhrq),
            status: xhrq.status,
            statusText: xhrq.statusText,
        };
        let response;
        if (typeof xhrq.response == 'string') {
            response = xhrq.response;
        }
        else if (xhrq.response instanceof Blob) {
            response = xhrq.response;
        }
        else if (xhrq.response instanceof ArrayBuffer) {
            response = xhrq.response;
        }
        else {
            // void
            throw new Error('Unknown response type');
        }
        return 'Response' in window
            ? new Response(response, respInit)
            : new PolyfillResponse(xhrq, response, respInit);
    }
    _parseHeaders(xhrq) {
        const headers = 'Headers' in window ? new Headers() : new PolyfillHeaders();
        const responseHeaders = xhrq.getAllResponseHeaders().split(/\r?\n/);
        for (let i = 0; i < responseHeaders.length; i++) {
            const line = responseHeaders[i];
            const match = line.match(/^([^:]+):(.*)$/);
            if (match) {
                headers.append(match[1], match[2]);
            }
        }
        return headers;
    }
    ajaxIntoElement(url, domId, delegate) {
        const localDom = this.dom;
        return this.doAjax(url, (result) => {
            localDom.intoElement(result, domId, delegate);
        }, delegate);
    }
    /**
     * Fetch js from server and execute.
     */
    ajaxRunScript(url, delegate) {
        return this.doAjax(url, (rawJs) => {
            this.runScriptHandler(rawJs);
        }, delegate);
    }
    /**
     * push badPhraim values, and then run ajax into element...
     */
    ajaxValuesAndMethodIntoElement(elementid, url, params, delegate) {
        return this.ajaxValuesAndMethodIntoMultipleElements([elementid], url, params, delegate);
    }
    ajaxValuesAndMethodIntoMultipleElements(targetelements, url, params, delegate) {
        const localDom = this.dom;
        return this.ajaxValuesWithClosure(url, params, (result) => {
            for (let j = 0; j < targetelements.length; j++) {
                const elementid = targetelements[j];
                localDom.intoElement(result, elementid);
            }
        }, delegate);
    }
    ajaxValuesAndRunScript(url, params, delegate) {
        return this.ajaxValuesWithClosure(url, params, (rawJs) => {
            this.runScriptHandler(rawJs);
        }, delegate);
    }
    ajaxValuesAndVoid(url, params, delegate) {
        return this.ajaxValuesWithClosure(url, params, () => {
            /** void */
        }, delegate);
    }
    ajaxValuesWithClosure(url, params, closure, delegate) {
        // pre-js
        if (delegate && delegate.pre_js) {
            try {
                delegate.pre_js(delegate);
            }
            catch (error) {
                /* void */
            }
            delegate.pre_js = null;
        }
        const data = this.dom.getFormValues();
        for (const i in params) {
            data[i] = params[i];
        }
        data._with_bp_values = '1';
        return this.doPostAjax(url, data, closure, delegate);
    }
    ajaxVoid(url, delegate) {
        return this.doAjax(url, (_result) => {
            /* void */
        }, delegate);
    }
    boundAjax(delegate) {
        return (url) => {
            return this.doAjax(url, null, delegate);
        };
    }
    doAjax(url, closure, delegate) {
        return this.reallyDoAjax(url, 'GET', '', closure, delegate);
    }
    doPostAjax(url, data, closure, delegate) {
        let poststr = '';
        /**
         * Find correct encoding function
         */
        const encode = typeof encodeURIComponent !== 'undefined'
            ? encodeURIComponent
            : (inString) => {
                const encodedString = escape(inString);
                return encodedString.replace(/\+/, '%2b');
            };
        for (const i in data) {
            poststr += poststr ? '&' : '';
            poststr += i + '=' + encode(data[i]);
        }
        return this.reallyDoAjax(url, 'POST', poststr, closure, delegate);
    }
    doXmlAjax(url, closure, delegate) {
        return this.reallyDoAjax(url, 'GET', '', closure, delegate, true);
    }
    fetch(url, init, scopes, responseType, aborter) {
        return __awaiter(this, void 0, void 0, function* () {
            return new Promise((resolve, reject) => {
                const xhrq = this.newXHR();
                const method = (init && init.method) || 'GET';
                const onabort = () => {
                    if (xhrq.readyState == XMLHttpRequest.DONE) {
                        console.info('Trying to abort request which has completed', url);
                        return;
                    }
                    xhrq.abort();
                    const reason = init && init.signal ? init.signal.reason : null;
                    reject(reason);
                };
                if (aborter) {
                    const husk = aborter.abort;
                    aborter.abort = () => {
                        husk.apply(aborter);
                        onabort();
                    };
                }
                if (init && init.signal) {
                    if (init.signal.aborted) {
                        const reason = init.signal.reason;
                        reject(reason);
                        return;
                    }
                    // MERK MAA BRUKE .onabort, ikke addEventListener
                    init.signal.onabort = onabort;
                }
                xhrq.open(method, url);
                if (init && init.headers) {
                    init.headers.forEach((value, key, _parent) => {
                        xhrq.setRequestHeader(key, value);
                    });
                }
                if (responseType) {
                    xhrq.responseType = responseType;
                }
                xhrq.onreadystatechange = (_event) => {
                    if (init && init.signal && init.signal.aborted) {
                        xhrq.abort();
                        reject(init.signal.reason);
                        return;
                    }
                    if (xhrq.readyState === XMLHttpRequest.DONE) {
                        if (init && init.signal && init.signal.aborted) {
                            console.error('Completed request which was aborted?!? ' + url, xhrq);
                        }
                        if (xhrq.status >= 200 && xhrq.status < 300) {
                            const response = this._dispatchResponseFromXHR(xhrq);
                            resolve(response);
                            return;
                        }
                        else {
                            let maybeResponse;
                            if (xhrq.status) {
                                maybeResponse = this._dispatchResponseFromXHR(xhrq);
                            }
                            reject(this.getErrorForResponse(xhrq, maybeResponse));
                        }
                    }
                };
                xhrq.onerror = (event) => {
                    reject(event);
                };
                this._sendRequest(xhrq, url, init ? init.body : undefined, undefined, scopes).catch(reject);
            });
        });
    }
    getErrorForResponse(xhrq, response) {
        switch (xhrq.status) {
            case 200:
                return null;
            case 401:
                return new HTTP401Error(xhrq, response);
            case 403:
                return new HTTP403Error(xhrq, response);
            case 404:
                return new HTTP404Error(xhrq, response);
            case 500:
                return new HTTP500Error(xhrq, response);
        }
        return new HTTPUnknownError(xhrq, response);
    }
    getLoadingHandler() {
        return new LoadingBoxVoid();
    }
    getWindow() {
        return this.dom.getWindow();
    }
    postUrl(url, params) {
        const data = this.dom.getFormValues();
        if (typeof params === 'object') {
            for (const i in params) {
                data[i] = params[i];
            }
        }
        data._with_bp_values = '1';
        const localDom = this.dom;
        const form = localDom.createElement('form');
        form.action = url;
        form.method = 'POST';
        form.target = '_blank';
        //			dojo.style(form, 'display', 'none');
        form.style.display = 'none';
        for (const dataKey in data) {
            const inputElement = localDom.createElement('input');
            inputElement.type = 'hidden';
            inputElement.name = dataKey;
            inputElement.value = data[dataKey];
            form.appendChild(inputElement);
        }
        const document = localDom.getDocument();
        document.body.appendChild(form);
        form.submit();
        document.body.removeChild(form);
    }
    /**
     * A 6th argument may be appended. Specifiying that
     * the closure expects the entire request object
     * as an argument
     *
     * The delegate-object may have the following callbacks
     *
     * readyStateChange()   called when corresponing event from xhr is fired
     * load()               called when readyStateChange turns 200, before closure
     * loaded()             called when readyStateChange turns 200, after closure
     * error()              called in case of error
     *
     * all methods are called as instance-methods of delegate, so "this" should
     * point to the delegate object.
     *
     * Two instance-variables of the delegate-objects will be populated:
     *
     *	xhr                points directly to the XMLHttpRequest object in question
     *   loading_handle     points directly to the loadin-handle associated with this request
     *
     *
     */
    reallyDoAjax(url, method, data, closure, delegate, closureexpectsrequest = false) {
        return new Promise((resolve, reject) => {
            const xhrq = this.newXHR();
            // initialize delegate
            if (!delegate) {
                delegate = new AjaxDelegate();
            }
            if (delegate.withCredentials) {
                xhrq.withCredentials = delegate.withCredentials;
            }
            // 			delegate = delegate || new AjaxDelegate();
            const voidFn = () => {
                /** void  */
            };
            delegate.readyStateChange = delegate.readyStateChange || voidFn;
            delegate.load = delegate.load || voidFn;
            delegate.loaded = delegate.loaded || voidFn;
            delegate.error = delegate.error || voidFn;
            delegate.url = url;
            delegate.method = method;
            delegate.data = data;
            // no spinner
            let loadingBoxHandle;
            if (!delegate.nospinner) {
                loadingBoxHandle = this.getLoadingHandler().getHandle();
            }
            delegate.loading_handle = loadingBoxHandle;
            delegate.xhr = xhrq;
            const self = this;
            xhrq.onreadystatechange = function (event) {
                // Kjør readystatechange på delegate først
                try {
                    if (delegate && delegate.readyStateChange) {
                        delegate.readyStateChange.apply(delegate, [event]);
                    }
                }
                catch (error) {
                    /* void */
                }
                try {
                    if (xhrq.readyState === XMLHttpRequest.DONE) {
                        try {
                            if (xhrq.status === 200) {
                                if (loadingBoxHandle) {
                                    loadingBoxHandle.end();
                                }
                                try {
                                    if (delegate && delegate.load) {
                                        delegate.load(xhrq.responseText);
                                    }
                                }
                                catch (_error) {
                                    /* void */
                                }
                                if (typeof closure === 'function') {
                                    if (closureexpectsrequest) {
                                        closure(xhrq);
                                    }
                                    else {
                                        closure(xhrq.responseText);
                                    }
                                }
                                resolve(xhrq.responseText);
                                try {
                                    if (delegate && delegate.loaded) {
                                        delegate.loaded(xhrq.responseText);
                                    }
                                }
                                catch (_error) {
                                    /* void */
                                }
                            }
                            else {
                                if (delegate && delegate.error) {
                                    delegate.error(xhrq);
                                }
                                const httpError = self.getErrorForResponse(xhrq);
                                reject(httpError);
                            }
                        }
                        catch (error) {
                            ExceptionHandler.handle(error);
                            if (loadingBoxHandle) {
                                loadingBoxHandle.end();
                            }
                        }
                    }
                }
                catch (error) {
                    ExceptionHandler.handle(error);
                    reject(error);
                } // end try/catch
            }; // end anonymous function
            //			alert('kj�rer ajax '+url);
            //			alert(x);
            xhrq.open(method, url, true);
            try {
                xhrq.setRequestHeader('X-Cerum-Ajax', '1');
                if ('contentType' in delegate && delegate.contentType) {
                    xhrq.setRequestHeader('Content-Type', delegate.contentType);
                }
                else if (method.match(/post/i)) {
                    xhrq.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8'); //charset=iso-8859-1");
                }
            }
            catch (_error) {
                //				alert('headerbang:'+ e.message);
            }
            //			alert('ajax starta');
            //			alert(x);
            this._sendRequest(xhrq, url, data, loadingBoxHandle).catch((reason) => {
                console.error('_sendRequest.catch', reason);
                if (loadingBoxHandle) {
                    loadingBoxHandle.end();
                }
            });
        });
    }
    /**
     * Run a script fetched from server
     */
    runScriptHandler(jscript) {
        //				alert('ferdig med ajax...'+ret);
        // check "signing" (to verify that it intentionally tries to look like js)
        if (jscript.match(/^\/\*314159js\*\//)) {
            //					alert('fant javascript: '+ret);
            try {
                this.dom.instScript(jscript);
                //						eval(ret);
            }
            catch (error) {
                console.error('javascriptet vi fikk crasha...: ', [error, jscript]);
                ExceptionHandler.handle(error);
            }
        }
        else {
            console.error('ikke gyldig js ', jscript);
            //					alert('ikke gyldig js');
        }
    }
    _sendRequest(xhrq, _url, data, loadingBoxHandle, _scopes) {
        return __awaiter(this, void 0, void 0, function* () {
            return new Promise((resolve, reject) => {
                try {
                    if (loadingBoxHandle) {
                        loadingBoxHandle.start();
                    }
                    xhrq.send(data);
                    resolve('sent');
                }
                catch (error) {
                    ExceptionHandler.handle(error);
                    if (loadingBoxHandle) {
                        loadingBoxHandle.end();
                    }
                    reject(error);
                }
            });
        });
    }
    newXHR() {
        const window = this.getWindow();
        const _xhr = window.XMLHttpRequest ? window.XMLHttpRequest : XMLHttpRequest;
        return new _xhr();
    }
} // end klasse-definisjon
